/**
 * InitialLaunchScreen
 */

import React, { Component } from 'react';
import config from '../../config/config'
import { connect } from 'react-redux';
import AppConfig from '../../constants/AppConfig';
import { withAuth0 } from "@auth0/auth0-react";
import { configureStore } from '../../store';
import LifeSherpaLoading from '../LifeSherpaLoading';
import {delay, openURL, isMobile} from "../../helpers/helpers";
// redux action
import {
   removeFirebaseErrorMessage,
   clearUserOrgAndRegion,
   clearValidateInviteCodeError,
   getUserOrgAndRegion
} from '../../actions';
import queryString from 'query-string';
import SweetAlert from 'react-bootstrap-sweetalert'
// import InitialScreenForm from './InitialScreenForm';
import AppToolBar from './AppToolBar';
import { Capacitor } from '@capacitor/core';
import withRouter from '../../router';
import LSInput from '../CustomInput/LSInput';
import { Form, FormGroup, FormFeedback, Label } from 'reactstrap';
import Button from '@mui/material/Button';

class InitialLaunchScreen extends Component {
   state = {
      defaultAuth0Login:false,
      count:0,
      maximanRetry: 3,
      email: '',
      token: '',
      emailEmpty: null,
      inValidEmail: null,
      emailExist: true,
      tokenEmpty: null,
      isContinueEnabled: null,
      isTokenEnabled: null,
      loginAlert:false,
      showErrorAlert: false,
   }

   componentDidMount() {
      console.group("%c[InitialLaunchScreen] %c Lifesherpa lauchscreen displayed","color:black","color:green");
      //take from signin page
      this.props.clearUserOrgAndRegion();
      this.props.clearValidateInviteCodeError();

      let testing = localStorage.getItem("testing");

      if (!testing && !isMobile()) {
         this.setState({...this.state,defaultAuth0Login:true}, () => {
            this.getAuth0AccessToken()
         });
      } 
   }

  getAuth0AccessToken = () => {
      const {getAccessTokenSilently} = this.props.auth0;
      let defaultAudiance = config.defaultAuth0DataForForUSA.audience;
      let defaultRegion = config.defaultAuth0DataForForUSA.region;
      this.getAuth0DefaultAcceesToken(defaultAudiance, getAccessTokenSilently, defaultRegion);
  }
  
  getAuth0DefaultAcceesToken = async(audience, getAccessTokenSilently, region) => {
    
   console.log("Getting auth0 acess token for "+region+" region.....");  
    try {
        const token = await getAccessTokenSilently({authorizationParams: {audience: audience},});
        const { user, isAuthenticated} = this.props.auth0;
        if (isAuthenticated){
            const authResult = {accessToken: token}
            const profileResult = user
            localStorage.setItem("user_id", "user-id");
            localStorage.setItem("region",region);
            localStorage.setItem("auth0ClientConfig",JSON.stringify(config.defaultAuth0DataForForUSA));
            const fakeData =  {auth0ClientConfig: config.defaultAuth0DataForForUSA};
           
            const store = configureStore();
            store.dispatch({ type: 'LOGIN_USER_SUCCESS', payload: authResult })
            store.dispatch({ type: 'GET_USER_INFO', payload: profileResult });
            store.dispatch({ type: 'GET_SUCCESS_USER_ORGANIZATION', payload: fakeData });
            console.log("%cGot auth0acess token ","color:green");
            console.log("Redirecting on login success screen....."); 
            console.groupEnd();
            this.setState({...this.state,defaultAuth0Login:false}, () => {
               window.location.replace(`/loginsuccess?region=${region}`);
            }) 
         } else {
            const {count, maximanRetry} = this.state;
            console.log("Auth0 not authenticated");
            if(count < maximanRetry) {
               await delay(5000)
               console.log("Retying auth0 authentication: ", count + 1);
               this.setState({...this.state, count: count + 1});
               this.getAuth0AccessToken(region);
            } else {
               throw new Error("Auth0 is not authenticated");
            } 
        }
    } catch(e) {
         localStorage.removeItem("auth0region");
         console.log("%cError occured while fetching auth0 access token for US region ------>","color:red",e);
         this.setState({...this.state,defaultAuth0Login:false}) 
      }
   }

   onSignInWithToken() {
      const theme = JSON.parse(localStorage.getItem('theme'))
      console.log("Redirecting to registration app ");
      if (theme && theme.name === 'Theme4'){
         const tokenRegistrationAppURL = Capacitor.getPlatform() === 'web' ? config.tokenRegistrationURL : config.tokenRegistrationURL.replace("mode=web", "")
         openURL(tokenRegistrationAppURL)
      } else if (theme && theme.name) {
         const registrationAppURL = `${config.registraionappurl}&theme=${theme.name}`
         openURL(registrationAppURL)
      } else {
         const registrationAppURL = `${config.registraionappurl}&theme=Default`
         openURL(registrationAppURL)
      }
      console.log("%cRedirected on registration app ","color:green");
   }

   //handle returning success url
   onSignInWithUsernameAndPassord(){
      console.log("Redirecting on signin with email screen ",);
      this.props.navigate('/signinEmail');
      console.groupEnd();
   }

   onConfirm() {
      localStorage.removeItem("testing")
      this.props.removeFirebaseErrorMessage();
   }

   //move from signin
   validateUsername(username){
      if(username && username.length > 2 && username.length < 100){
         return true
      } else {
         return false
      }
   }
     
   getUserOrganizationFromEmail(e) {
      e.preventDefault();
      if (this.state.email === ''){
         this.setState({emailEmpty: true});
         console.log("%cPlease enter username/email ","color:red");
      } else if (!this.validateUsername(this.state.email)) {
         console.log("%cPlease enter username/email of length between 3 to 100 characters ","color:red");
         this.setState({emailEmpty: true})
      } else {
         console.log("Authenticating username/email.....");
         this.props.getUserOrgAndRegion(this.state.email.trim(), this.props.navigate)
      }
   }

   handleBack=()=>{
      this.setState({loginAlert:false})
      this.props.navigate('/')
   }

   handleChange = (e) => {
      const { userOrgDetail, userOrgFromToken } = this.props;
      const showTokenfield = userOrgDetail && (userOrgDetail.region  === "" || userOrgDetail.message) ? true : false 
      if(showTokenfield) {
         this.props.clearUserOrgAndRegion();
      }
      const emailEmpty = e.target.value === '' || !this.validateUsername(e.target.value) ? true : false
      this.setState({ email: e.target.value, emailEmpty});
   }

   handleAlertMessage(){
      localStorage.removeItem('error-message')
      this.setState({showErrorAlert: false})
   }
   
   render() {
      const { loading,screenThemeClass, themeData , themeLoading, getFirebaseTokenFailed, userOrgDetail} = this.props;
      let params = queryString.parse(this.props.location.search);
      const showTokenfield = userOrgDetail && (userOrgDetail.region  === "" || userOrgDetail.message) ? true : false 
      const applogo= localStorage.getItem('activeTheme') === '5' || localStorage.getItem('activeTheme') === '7' || localStorage.getItem('activeTheme') === '10' ? AppConfig.appLogoWhite  : AppConfig.appLogo;
      const themeLogo= localStorage.getItem("loginLogoImage") || this.props.themeLogo;
      let {defaultAuth0Login} = this.state;
      let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};
      const displayLoading = (!themeLoading && defaultAuth0Login) || loading
      return (
         <div type="bottom" duration={2000}>
            <div className={`rct-session-wrapper ${screenThemeClass}`}>
               <div className='login-screen' style={themeData && themeData.loginScreenFillPaternImage ?{backgroundImage:`url(${themeData.loginScreenFillPaternImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}:{}}>
                  {getFirebaseTokenFailed ?
                     <SweetAlert
                        warning
                        btnSize="sm"
                        customClass="warningText"
                        show={getFirebaseTokenFailed.message !== ''}
                        confirmBtnText="OK"
                        confirmBtnBsStyle="warning"
                        title={`Warning`}
                        onConfirm={this.onConfirm.bind(this)}
                     >
                        {getFirebaseTokenFailed.message}
                     </SweetAlert>
                     : this.state.loginAlert ?
                     <div className="col-12 p-4">
                        <SweetAlert
                           warning
                           btnSize="sm"
                           customClass="warningText"
                           confirmBtnText="OK"
                           confirmBtnBsStyle="warning"
                           title="Login"
                           onConfirm={()=>this.handleBack()}
                        >
                           <div style={{fontFamily:"'Lato'",fontWeight:400}}>Not a valid user!</div>
                        </SweetAlert>
                     </div>
                     : localStorage.getItem('error-message') &&
                     <div className="col-12 p-4">
                        <SweetAlert
                           danger
                           btnSize="sm"
                           customClass="dangerText"
                           confirmBtnText="OK"
                           confirmBtnBsStyle="danger"
                           title="Alerts!"
                           onConfirm={() => this.handleAlertMessage()}
                        >
                           <div style={{fontFamily:"'Lato'",fontWeight:600}}>{localStorage.getItem("error-message")}</div>
                        </SweetAlert>
                     </div>
                     
                  }
                  <LifeSherpaLoading loading={displayLoading}/>
                  <AppToolBar
                     activeTheme={activeTheme}
                     themeLogo={themeLogo}
                     // applogo={applogo}
                     themeData={themeData}
                  />
                  <div  data-testid="initial-launch-screen" className="session-inner-wrapper">
                     <div className="container">
                        <div className="row row-eq-height">
                           <div className="col-sm-12 col-md-3 col-lg-3"></div>
                           <div className="col-sm-12 col-md-6 col-lg-6">
                              {/* <InitialScreenForm
                                 params={params}
                                 onSignInWithToken={() => this.onSignInWithToken()}
                                 onSignInWithUsernameAndPassord={() => this.onSignInWithUsernameAndPassord()}
                              /> */}
                              <div className={"session-body text-center text-white my-auto"}>
                                 <div className="session-head mb-30">
                                    <p className="base-font-color">Login</p>
                                 </div>
                                 <Form onKeyPress={e => {
                                    if (this.props.status && this.props.status === 'failed') this.props.clearValidateInviteCodeError();
                                    }}>
                                    <FormGroup className="has-wrapper">
                                       <LSInput
                                          datatestid="signin-email-text-field"
                                          type="text"
                                          value={this.state.email}
                                          autoCapitalize="none"
                                          name="user-mail"
                                          id="user-mail"
                                          className="has-input input-lg text-center w-100"
                                          placeholder="Username/Email"
                                          invalid={(this.state.emailEmpty ? true : false) || showTokenfield}
                                          onChange={(e) => this.handleChange(e)}
                                          onKeyPress={e => {
                                             if (e.key === 'Enter') this.getUserOrganizationFromEmail(e);
                                          }}
                                       />
                                       {this.state.emailEmpty ?
                                          <FormFeedback data-testid="empty-signin-email-address"  className="d-block" style={{ fontSize: 'larger' }}>Please enter a valid username/email of length between 3 to 50 characters. It is a required field.</FormFeedback>
                                          : this.state.inValidEmail ?
                                             <FormFeedback data-testid="invalid-signin-email-format" className="d-block" style={{ fontSize: 'larger' }}>Invalid email address format.  Please provide a valid email address</FormFeedback>
                                             : showTokenfield &&
                                             <FormFeedback data-testid="signin-email-does-not-exist" className="d-block" style={{ fontSize: 'larger' }}>{userOrgDetail && userOrgDetail.message != "Invalid email/username or user doesn't exist." ? userOrgDetail.message : "This username/email is not registered with us. You may use an invitation token to register with us."}</FormFeedback>
                                       }
                                    </FormGroup>
                                    <FormGroup className='pb-4'>
                                       <Button
                                          data-testid="signin-continue"
                                          className="btn btn-block w-100 login-screen-button "
                                          variant="contained"
                                          size="large"
                                          onClick={(e) => this.getUserOrganizationFromEmail(e)}
                                          style={this.state.isContinueEnabled ? { backgroundColor: 'gray', fontSize: 'large' } : { fontSize: 'large' }}
                                          disabled={this.state.isContinueEnabled}
                                       >
                                          Continue
                                       </Button>
                                    </FormGroup>
                                    <FormGroup className='pt-4'>
                                       <Button
                                          className="btn btn-block w-100 login-screen-token-button"
                                          variant="contained"
                                          size="large"
                                          onClick={() => this.onSignInWithToken()}
                                          data-testid="i-have-a-token"
                                          style={this.state.isTokenEnabled ? { backgroundColor: 'gray', fontSize: 'large' } : { fontSize: 'large' }}
                                          disabled={this.state.isTokenEnabled}
                                       >
                                          I Have a Token
                                          </Button>
                                    </FormGroup>
                                 </Form>
                              </div>
                           </div>
                           <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        </div>
                     </div>
                     <div className='text-right p-3'>
                        <Label className='app-version-font-color'>{`v${process.env.APP_VERSION}(${process.env.BUILD})`}</Label>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser , settings}) => {
   const { user, loading, themeData, themeLoading, getFirebaseTokenFailed, userOrgDetail} = authUser; 
   const { screenThemeClass, themeLogo } = settings; 
   return { user, loading, screenThemeClass, themeLogo, themeData, themeLoading, getFirebaseTokenFailed, userOrgDetail}
}

export default withRouter(withAuth0(connect(mapStateToProps, {
   removeFirebaseErrorMessage,
   clearUserOrgAndRegion,
   clearValidateInviteCodeError,
   getUserOrgAndRegion
})(InitialLaunchScreen)))

