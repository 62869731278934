import React, { Component } from 'react';

import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import LifeSherpaLoading from '../LifeSherpaLoading';
import PostMembers from './PostMembers';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { connect } from 'react-redux';
import moment from 'moment'
// reactstrap components

import RctPDFViewer from '../RctPDFViewer';
import {
    getNoticePostById,
} from '../../actions';
import WidgetServices from "../../services/widgetServices";
import { isMobile, openURL, shareFileFromUrl, getLocalTempPathFile } from '../../helpers/helpers';
import { Capacitor } from '@capacitor/core';
import CircularProgress from '@mui/material/CircularProgress';
import { FileOpener } from '@capacitor-community/file-opener';

class AlertView extends Component {
    state = {
        iframeLoading: true,
        openList: false,
        windowWidth: window.innerWidth,
        showSpinner: false,
        pdfLoading: false
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        const { postId, categoryId, organization } = this.props;
        const orgId = organization || localStorage.getItem('selectedOrgId');
        const userId = localStorage.getItem('auth_uid');
        this.props.getNoticePostById({ postId, categoryId, openList: false, userId, orgId })
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }

    scrollToTop = () => {
        var objDiv = document.getElementsByClassName('MuiDialogContent-root')[0] || document.getElementsByClassName('alert-view-page')[0];
        if (objDiv) {
            objDiv.scrollTop = 0
        }
    };

    handleOpenMemberList = () => {
        this.setState({ openList: !this.state.openList }, () => {
            this.scrollToTop();
        });
    }

    async handleShare(post) {
        const data = {
            title: post.title,
            description: post.description,
            url: post.fileURL,
            contentType: post.contentType
        }
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            widgetServices.handleShareNotice(data);
        } else if (isMobile()) {
            shareFileFromUrl(post, this.setLoading.bind(this))
        }
    }

    setLoading(showSpinner) {
        this.setState({showSpinner}, () => {
            this.scrollToTop();
        })
    }

    setPDFLoading(pdfLoading) {
        this.setState({pdfLoading})
    }

    async handlePDFViewer(post) {
        const data = {
            title: post.title,
            description: post.description,
            url: post.fileURL,
            contentType: post.contentType
        }
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            widgetServices.handleOpenNoticePDF(data);
        } else if (Capacitor.getPlatform() === 'android') {
            const fileOpenerOptions = await getLocalTempPathFile(post, this.setPDFLoading.bind(this))
            await FileOpener.open(fileOpenerOptions);
        } else if (Capacitor.getPlatform() === 'ios') {
            openURL(post.fileURL)
        }
    }


    render() {
        const { noticePostLoading } = this.props;
        const post = this.props.noticePost || {};
        const { iframeLoading, windowWidth, showSpinner, pdfLoading } = this.state;
        const isWidgetApp = new WidgetServices().isWidgetApp()
        return (
            <div>
                <LifeSherpaLoading loading={noticePostLoading} />
                {this.props.noticePost ?
                    <div className='row' data-testid="client-alert-post" >
                        <div className={`col-sm-12 col-md-8 clo-xl-9 col-lg-9 ${windowWidth > 700 ? "pr-0" : ""}`}>
                            <div>
                                <div className={`d-flex justify-content-between pl-3 pr-2 py-1  w-100 align-items-center  ${windowWidth < 700 ? 'py-1' : 'py-3'} `}>
                                    <div className="d-flex">
                                        <h4 className="mb-0 mr-3">{post.title}</h4>
                                        <h4 className="mb-0">{moment(new Date(post.createdOn)).format('MMM DD, YYYY')}</h4>
                                    </div>
                                    {windowWidth < 700 ? 
                                        <IconButton title='Share Post' disabled={showSpinner} onClick={() => this.handleShare(post)}> 
                                            <ShareIcon color="primary" />
                                            {showSpinner &&
                                                <CircularProgress
                                                    size={40}
                                                    sx={{
                                                    color: "primary",
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-20px',
                                                    marginLeft: '-20px',
                                                    }}
                                                />
                                            }
                                        </IconButton> 
                                        : <></>
                                    }
                                </div>
                                <div data-testid="alert-history-viewer" className={`${windowWidth > 700 ? "d-none" : "d-block"}`}>
                                    <div className={"px-0"} >
                                        <div className='pl-3 pr-2 py-2'>
                                            <div className='d-flex align-items-center justify-content-between' onClick={() => this.handleOpenMemberList()}>
                                                <h4 className='my-2'>{`Viewed by ${post.readMembersCount} of ${post.totalMembersCount} required viewers`}</h4>
                                                <IconButton className=" ml-auto">
                                                    {this.state.openList ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
                                                </IconButton>
                                            </div>
                                        </div>
                                        {
                                            this.state.openList ?
                                                <PostMembers windowWidth={windowWidth} members={post.members} />
                                                : ""
                                        }

                                    </div>
                                </div>
                                <div className='px-3 notice-pdf-viewer'>
                                    {post.contentType && post.contentType.includes("image") ?
                                        <div className='image-container'>
                                            <img className="ing-fluid w-100" src={post.fileURL} alt="" />
                                        </div>
                                        : post.contentType && post.contentType.includes("video") ?
                                            <video width="100%" controls autoPlay loop muted>
                                                <source src={post.fileURL} type="video/mp4" />
                                            </video>
                                            : post.fileURL ? (isWidgetApp || windowWidth < 700) ?
                                                <>
                                                    <LifeSherpaLoading loading={pdfLoading} />
                                                    <RctPDFViewer openPDFViewer={this.handlePDFViewer.bind(this)} post={post} fileUrl={post.fileURL} fullPages={windowWidth < 700} initPageScale={windowWidth > 700 ? 0.72 : 0.51} />
                                                </>
                                                :
                                                <>
                                                    <LifeSherpaLoading loading={iframeLoading} />
                                                    <iframe data-testid="post-view-iframe"  width="100%" src={post.fileURL} onError={() => this.setState({ iframeLoading: false })} onLoad={() => this.setState({ iframeLoading: false })} loading={<LifeSherpaLoading />} />
                                                    {/* <RctPDFViewer width="100%" fileURL={post.fileURL} onError={() => this.setState({ iframeLoading: false })} onLoad={() => this.setState({ iframeLoading: false })} loading={<LifeSherpaLoading />}/> */}
                                                </>

                                            : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div data-testid="alert-history-viewer" className={`col-sm-12 col-md-4 clo-xl-3 col-lg-3 ${windowWidth > 700 ? "pl-0" : "d-none"}`}>
                            <div>
                                <div className='pl-3 pr-1 py-2'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h4 className='my-2'>{`Viewed by ${post.readMembersCount} of ${post.totalMembersCount} required viewers`}</h4>
                                    </div>
                                </div>
                                <PostMembers windowWidth={windowWidth} members={post.members} />
                            </div>
                        </div>
                    </div>
                    :
                    <div className='d-flex align-items-center my-4 py-4 mx-auto justify-content-between'>
                        <div className='text-center w-100 mt-4 pt-4'>
                            {noticePostLoading ? "Fetching alert data..." : "Alert Not Found"}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ noticesReducer, settings }) => {
    const { noticePost, noticePostLoading } = noticesReducer;
    const { popupThemeClass } = settings;
    return { noticePost, noticePostLoading, popupThemeClass };
}

export default connect(mapStateToProps, { getNoticePostById })(AlertView);
