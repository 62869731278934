import React, { Component } from "react";
import config from '../../config/config';
import LifeSherpaLoading from '../LifeSherpaLoading';
import Iframe from "../LifesherpaiFrame/iframe";
import { connect } from "react-redux";
import Dialog from '@mui/material/Dialog';

class LSPortalLogoutPopup extends Component {
  state = {
    configuratorLoading: true,
    lmsLoading: true,
    analyticsLoading: true,
  }

  // Use arrow functions to avoid binding issues
  configuratorIframeLoaded = () => {
    setTimeout(this.handleConfiguratorIframe, 5000);
  }

  handleConfiguratorIframe = () => {
    this.setState({ configuratorLoading: false, analyticsLoading: false }, this.handleCloseLogoutPopup);
  }

  analyticsPortalLogout = () => {
    this.setState({ analyticsLoading: false }, this.handleCloseLogoutPopup);
  }

  lmsIframeLoaded = () => {
    this.setState({ lmsLoading: false }, this.handleCloseLogoutPopup);
  }

  handleCloseLogoutPopup = () => {
    const { configuratorLoading, lmsLoading, analyticsLoading } = this.state;
    const { isLMSOpened, workspaceURL } = this.props;

    // Simplify the conditions
    if ((workspaceURL && !isLMSOpened && !configuratorLoading && !analyticsLoading) ||
        (!workspaceURL && isLMSOpened && !configuratorLoading && !lmsLoading) ||
        (!workspaceURL && !isLMSOpened && !configuratorLoading && !analyticsLoading) ||
        (!workspaceURL && !isLMSOpened && !configuratorLoading)) {
      this.props.handleCloseLogoutPopup();
    }
  }

  render() {
    const { configuratorLoading, lmsLoading, analyticsLoading } = this.state;
    const { openLogoutPopup, isLMSOpened, launchURL, lmsUrl, workspaceURL } = this.props;

    const lmsLogoutUrl = lmsUrl ? lmsUrl.replace("autologin", "logout") :
      launchURL?.url ? launchURL.url.replace("gotocourse", "logout") :
      "https://test.lms.3rbehavioralsolutions.com/index/logout";

    const configuratorUrl = `${config.configurator}/#/logout`;
    const analyticsUrl = config.analyticsUrl;

    return (
      <Dialog
        open={openLogoutPopup}
        aria-labelledby="responsive-dialog-title"
        className={`warning-dialog-popup ${this.props.popupThemeClass}`}
      >
        <LifeSherpaLoading loading={lmsLoading || configuratorLoading || analyticsLoading} />
        <div className="d-flex align-items-center justify-content-center my-4 pt-2 pb-4">
          <div className="mb-4 pb-4">Please wait, logging out.......</div>
        </div>
        <div style={{ visibility: "hidden" }} className="mt-2">
          {configuratorUrl && (
            <Iframe
              url={configuratorUrl}
              onLoad={this.configuratorIframeLoaded}
              height="2"
              width="300"
              scrolling="yes"
              id="myId"
              display="initial"
              position="relative"
            />
          )}
          {workspaceURL && (
            <Iframe
              url={analyticsUrl}
              onLoad={this.analyticsPortalLogout}
              height="2"
              width="300"
              scrolling="yes"
              id="myId"
              display="initial"
              position="relative"
            />
          )}
          {isLMSOpened && (
            <Iframe
              url={lmsLogoutUrl}
              onLoad={this.lmsIframeLoaded}
              height="2"
              width="300"
              scrolling="yes"
              id="myId"
              display="initial"
              position="relative"
            />
          )}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ settings, authUser, MyCourses }) => {
  const { popupThemeClass } = settings;
  const { launchURL, lmsUrl, isLMSOpened } = MyCourses;
  const { isConfiguratorOpened, isAnalyticsLoggedIn, workspaceURL } = authUser;

  return { popupThemeClass, isConfiguratorOpened, isLMSOpened, lmsUrl, launchURL, isAnalyticsLoggedIn, workspaceURL };
}

export default connect(mapStateToProps)(LSPortalLogoutPopup);