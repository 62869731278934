/**
 * User Block Component
 */
import React, { Component } from 'react';
import {  FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
//import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from '@mui/material/DialogActions';
import LifeSherpaLoading from '../LifeSherpaLoading';
// components
// import SupportPage from 'Components/Support/Support';
// redux action
import { clearUpdatePasswordError, updatePassword, showUpdatePasswordDialog } from '../../actions';

// intl messages
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { withAuth0 } from "@auth0/auth0-react";

class PasswordUpdate extends Component {

    state = {
        PasswordDialog: false,
        oldPassword: '',
        newPassword: '',
        error: '',
        error2: '',
        updated: false,
    }

    componentDidMount() {
        document.addEventListener('nativeBackClick', this.handleNativeBack);
        this.setState({ PasswordDialog: true, oldPassword: '', newPassword: '' })
    }
   
    handleNativeBack = () => {
        this.closePasswordDialog();
    }

    closePasswordDialog = () => {
        this.setState({ PasswordDialog: false, updated: true, oldPassword: '', newPassword: '', error: '', error2: '' });
        this.props.showUpdatePasswordDialog(false);
        document.removeEventListener('nativeBackClick', this.handleNativeBack);
    }
    handleUpdate = () => {
        const { oldPassword, newPassword } = this.state;
        const { updatePasswordLoading } = this.props;
        let smala = (/[a-z]/.test(newPassword))
        if (oldPassword === '') this.setState({ error2: "Old Password is a required field." })
        if (oldPassword && newPassword && newPassword.length >= 6 && smala && newPassword.charAt(0) == newPassword.charAt(0).toUpperCase() &&
            isNaN(newPassword) === true) {
            this.props.updatePassword(oldPassword, newPassword, this.props.user.firebaseToken);
            this.setState({ PasswordDialog: false })
        }
        else this.setState({ error: true })
    }
    handleChange = (e) => {
        const { oldPassword, newPassword } = this.state;
        let smala = (/[a-z]/.test(e.currentTarget.value))
        let num = /\d/.test(e.currentTarget.value)
        if (e.currentTarget.value === '') {
            this.setState({ error: "New Password is a required field." })
        }
        else if (e.currentTarget.value.length <= 7) this.setState({ error: "New Password should have at least 8 characters." })
        else if (e.currentTarget.value.charAt(0) != e.currentTarget.value.charAt(0).toUpperCase()) this.setState({ error: "First letter should be upper case lettes." })
        else if (!smala) this.setState({ error: "New Password should contains atleast one small lettes." })
        else if (!num) this.setState({ error: "New Password should contains atleast one Numeric value." })
        else this.setState({ error: true })
        if (this.props.updateError) this.props.clearUpdatePasswordError();
        this.setState({ newPassword: e.currentTarget.value })
    }
    handlePasswordChange = (e) => {
        let { updateError } = this.props;
        if (updateError) this.props.clearUpdatePasswordError();
        this.setState({ oldPassword: e.currentTarget.value, error2: '' })
    }
    render() {
        let { updatePasswordLoading, passwordDialog, updateError } = this.props;
        return (
            <div>
                {passwordDialog &&
                    <Dialog
                        fullScreen={false}
                        open={this.props.passwordDialog}
                        onClose={() => this.closePasswordDialog()}
                        fullWidth={true}
                        maxWidth="xl"
                        aria-labelledby="responsive-dialog-title"
                        className={this.props.popupThemeClass}
                        PaperProps={
                            { style: { maxWidth: "500px", borderRadius: 8, margin: 'auto' } }
                        }
                        id="password-update"
                    >
                        <LifeSherpaLoading loading={updatePasswordLoading} />
                        <DialogTitle>
							<div className="p-3 d-flex align-items-center justify-content-between bg-lifesherpa" >
								<h2 className="text-white">Password Update</h2>
								<IconButton data-testid="close-update-password-dialog" className="text-white" onClick={() => this.closePasswordDialog()}><CloseIcon /></IconButton>
							</div>
						</DialogTitle>
						<DialogContent className='pt-3' >
							<FormGroup>
								<Label>Old Password </Label>
								<Input data-testid="old-password-text-field" type='password' className="form-control my-2" placeholder="Enter Old Password" value={this.state.oldPassword} onChange={(e) =>this.handlePasswordChange(e)} />
								{this.state.error2 ? <FormFeedback data-testid="old-password-error"  className="d-block" style={{ fontFamily: "'Lato'" }}>{this.state.error2}</FormFeedback> : ""}
								{updateError ? <FormFeedback data-testid="update-password-error"  className="d-block" style={{ fontFamily: "'Lato'" }}>{updateError}</FormFeedback> : ""}
							</FormGroup>
							<FormGroup>
								<Label>New Password</Label>
								<Input data-testid="new-password-text-field" type='password' className="form-control my-2" placeholder="Enter New Password" value={this.state.newPassword} onChange={(e) => this.handleChange(e)} />
								{this.state.error ? <div data-testid="new-password-error" className="form_feedback" style={{ fontFamily: "'Lato'" }}>{this.state.error}</div> : ""}
							</FormGroup>
						</DialogContent>
						<DialogActions className='p-3'>
							<div className="w-100 d-flex align-items-center justify-content-between update-passwords-button">
								<Button color="primary" variant="outlined" data-testid="cancel-update-password-form" className="form-cancel-button" onClick={() => this.closePasswordDialog()}>Cancel </Button>
								<Button color="primary" variant="contained" data-testid="submit-update-password-form" className="primary-button" onClick={() => this.handleUpdate()}>Update </Button>
							</div>
						</DialogActions>
                    </Dialog>}
            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ settings, authUser}) => {
    const { updatePasswordLoading, passwordDialog, updateError, allowChangePassword, user, usersInfo, profile} = authUser;
    const { popupThemeClass } = settings;
    return {allowChangePassword, updatePasswordLoading, passwordDialog, updateError, popupThemeClass, user, usersInfo, profile}
}

export default withAuth0(withRouter(connect(mapStateToProps, {
    updatePassword,
    showUpdatePasswordDialog,
    clearUpdatePasswordError
})(PasswordUpdate)));
