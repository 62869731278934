/**
 * User List
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';

// component's
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import SweetAlert from 'react-bootstrap-sweetalert'
import AppConfig from "../../../constants/AppConfig"
import ClearIcon from '@mui/icons-material/Clear';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommentIcon from '@mui/icons-material/Comment';

//Custom Components
import RctCollapsibleCard from '../../RctCollapsibleCard/RctCollapsibleCard';
import ActivityTimerUpdate from "../../SherpaActivities/ActivityTimerUpdate";
import LifeSherpaLoading from "../../LifeSherpaLoading";
import ActivityConfirmForm from '../../ActivityForm/ActivityConfirmForm'
// redux actions
import {
    handleHideActivityDetailsPanel,
    clearHistoryData,
    showActivityHistoryDetail,
    getSelectedActivity,
    getHistoryList,
    setActivityReminder,
    adjustAchievementTimer,
    StartActivity,
    setActivityType,
    setActivityReset,
    adjustAchievementStepTimer,
    getUpdatedEvaluationForm,
    getPokeForm,
    handleShowEncounterActivityDetails,
    setSelectedMemberId,
    showStartActivityPanel
} from '../../../actions';
import { isMobile } from '../../../helpers/helpers';

class ActivityDetails extends Component {

    state = {
        notifyBox: false,
        disableReminder: false,
        anchorEl: null,
        showTimer: false,
        timerTime: 0,
        elapsedTime: 0,
        intervalID: null,
        achievementDelayed: false,
        expectedDurationSeconds: 0
    }
    componentDidMount() {
        const { selectedActivityDetails, isInprogress } = this.props;
        if (isInprogress && selectedActivityDetails.isTimerEnabled) {
            let { timerTime, elapsedTime, expectedDurationSeconds, achievementDelayed } = this.state;
            let totalTimeTaken = 0;
            if (selectedActivityDetails.startDate) {
                let dateObject = moment(selectedActivityDetails.startDate, "YYYY-MM-DD HH:mm:ss Z").toDate();
                totalTimeTaken = Math.round((new Date().getTime() - new Date(dateObject).getTime()) / 1000);
            }
            if (selectedActivityDetails.expectedDuration) {
                expectedDurationSeconds = this.getExpectedDurationSeconds(selectedActivityDetails.expectedDuration);
            }
            console.log("expectedDurationSeconds --> ", expectedDurationSeconds);
            if (expectedDurationSeconds > totalTimeTaken) {
                elapsedTime = expectedDurationSeconds;
                timerTime = expectedDurationSeconds - totalTimeTaken;
                elapsedTime = elapsedTime - timerTime;
                achievementDelayed = false;
            } else {
                achievementDelayed = true;
                elapsedTime = totalTimeTaken;
                timerTime = totalTimeTaken - expectedDurationSeconds;
            }
            if (this.state.intervalID) {
                clearInterval(this.state.intervalID);
            }
            let intervalID = setInterval(
                this.hanldeUpdateActivityTimer.bind(this)
                , 1000);
            this.setState({ timerTime: timerTime, elapsedTime: elapsedTime, expectedDurationSeconds: expectedDurationSeconds, achievementDelayed: achievementDelayed, intervalID: intervalID })
        }
        document.addEventListener('nativeBackClick', this.handleCloseMenu);
    }

    componentWillUnmount() {
        if (this.state.intervalID) {
            clearInterval(this.state.intervalID);
        }
        document.removeEventListener('nativeBackClick', this.handleCloseMenu);

    }

    hanldeUpdateActivityTimer = () => {
        const { selectedActivityDetails } = this.props;
        let { expectedDurationSeconds, timerTime, elapsedTime, achievementDelayed } = this.state;
        let updatedDurationSeconds = 0;
        if (selectedActivityDetails && selectedActivityDetails.expectedDuration) {
            updatedDurationSeconds = this.getExpectedDurationSeconds(selectedActivityDetails.expectedDuration);
        }
        if (expectedDurationSeconds !== updatedDurationSeconds) {
            let totalTimeTaken = 0;
            if (selectedActivityDetails.startDate) {
                let dateObject = moment(selectedActivityDetails.startDate, "YYYY-MM-DD HH:mm:ss Z").toDate();
                totalTimeTaken = Math.round((new Date().getTime() - new Date(dateObject).getTime()) / 1000);
            }
            console.log("expectedDurationSeconds --> ", updatedDurationSeconds);
            if (updatedDurationSeconds > totalTimeTaken) {
                elapsedTime = updatedDurationSeconds;
                timerTime = updatedDurationSeconds - totalTimeTaken;
                elapsedTime = elapsedTime - timerTime;
                achievementDelayed = false;
            } else {
                achievementDelayed = true;
                elapsedTime = totalTimeTaken;
                timerTime = totalTimeTaken - updatedDurationSeconds;
            }
            this.setState({ timerTime: timerTime, elapsedTime: elapsedTime, expectedDurationSeconds: updatedDurationSeconds, achievementDelayed: achievementDelayed })
        } else {
            if (elapsedTime !== 0 && timerTime === 0) {
                achievementDelayed = true;
            }
            if (achievementDelayed) {
                elapsedTime = elapsedTime + 1;
                timerTime = timerTime + 1;
            } else {
                timerTime = timerTime - 1;
                elapsedTime = elapsedTime + 1;
            }
            this.setState({ timerTime: timerTime, elapsedTime: elapsedTime, expectedDurationSeconds: updatedDurationSeconds, achievementDelayed: achievementDelayed })
        }
    }

    
    componentDidUpdate(prevProps) {
        const { selectedActivityDetails, isInprogress } = this.props;
        if (isInprogress && prevProps.isInprogress !== isInprogress && selectedActivityDetails.isTimerEnabled) {
            let { timerTime, elapsedTime, expectedDurationSeconds, achievementDelayed } = this.state;
            let totalTimeTaken = 0;
            if (selectedActivityDetails.startDate) {
                let dateObject = moment(selectedActivityDetails.startDate, "YYYY-MM-DD HH:mm:ss Z").toDate();
                totalTimeTaken = Math.round((new Date().getTime() - new Date(dateObject).getTime()) / 1000);
            }
            if (selectedActivityDetails.expectedDuration) {
                expectedDurationSeconds = this.getExpectedDurationSeconds(selectedActivityDetails.expectedDuration);
            }
            console.log("expectedDurationSeconds --> ", expectedDurationSeconds);
            if (expectedDurationSeconds > totalTimeTaken) {
                elapsedTime = expectedDurationSeconds;
                timerTime = expectedDurationSeconds - totalTimeTaken;
                elapsedTime = elapsedTime - timerTime;
                achievementDelayed = false;
            } else {
                achievementDelayed = true;
                elapsedTime = totalTimeTaken;
                timerTime = totalTimeTaken - expectedDurationSeconds;
            }
            if (this.state.intervalID) {
                clearInterval(this.state.intervalID);
            }
            let intervalID = setInterval(
                this.hanldeUpdateActivityTimer.bind(this)
                , 1000);
            this.setState({ timerTime: timerTime, elapsedTime: elapsedTime, expectedDurationSeconds: expectedDurationSeconds, achievementDelayed: achievementDelayed, intervalID: intervalID })
        }

    }


    handleReminderButton = (key) => {
        this.setState({ notifyBox: true })
    }

    handleCloseActivityDetailsPanel = () => {
        const {showEncounterActivityDetails} = this.props;
        const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
        const orgId = localStorage.getItem("orgId")
        if(showEncounterActivityDetails) {
            localStorage.setItem('uid', auth_uid);
            localStorage.setItem("selectedOrgId", orgId)
            this.props.setSelectedMemberId(null);
            this.props.handleShowEncounterActivityDetails(false)
        }
        this.props.handleHideActivityDetailsPanel();
        const {clientPanel} = this.props;
        let redirectUrl = '/app/lsdashboard';
        if(clientPanel) {
            redirectUrl = '/app/lsdashboard/client';
        } 
        if(isMobile()) {
            redirectUrl = '/app/lsdashboard/client/activities';
        }
        this.props.navigate(redirectUrl);
    }

    handleNotify = () => {
        const { selectedActivityDetails } = this.props;
        let userId = localStorage.getItem("uid");
        const activityId = selectedActivityDetails ? selectedActivityDetails.id : null
        const orgId = selectedActivityDetails ? selectedActivityDetails.organization : localStorage.getItem('selectedOrgId')
        this.props.setActivityReminder(userId, activityId, orgId)
        this.setState({ notifyBox: false, disableReminder: true })
    }

    handleShowActivityHistoryList = () => {
        let activity = this.props.selectedActivityDetails;
        this.props.clearHistoryData();
        const activityConfirmation = localStorage.getItem("activityConfirmation");
        if(activityConfirmation) {
            localStorage.removeItem("activityConfirmation");
        }
        this.props.showActivityHistoryDetail(true);
        this.props.getSelectedActivity(activity);
        const userId = localStorage.getItem("uid");
        this.props.getHistoryList(userId, activity.id, null, !isMobile());
        localStorage.setItem('prevUrl', window.location.pathname);
        this.props.navigate('/app/lsdashboard/activities/history');
        // this.props.showAddButton()
    }

    handleClickMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null, anchorEl1: null, step: null });
    }

    handleAdjustActivityTimer = () => {
        let { duration, anchorEl1 } = this.state;
        let { selectedActivityDetails } = this.props;
        if (anchorEl1) {
            this.setState({ anchorEl: null, anchorEl1: null, selectedActivity: selectedActivityDetails, showTimer: true });
        } else {
            duration = selectedActivityDetails.expectedDuration ? selectedActivityDetails.expectedDuration : duration;
            this.setState({ anchorEl: null, anchorEl1: null, selectedActivity: selectedActivityDetails, showTimer: true, duration: duration });
        }
    }

    handleCloseTimerUpdate = () => {
        this.setState({ selectedActivity: null, showTimer: false });
    }

    handleUpdateTimer = (hr, min, sec) => {
        let { selectedActivity, step } = this.state;
        console.log("hr, min, sec, stepId --> ", hr, min, sec);
        let date = moment().format('YYYY-MM-DD HH:mm:ss Z')
        let duration = `${hr}:${min}:${sec}`;
        if (step && step.id) {
            this.props.adjustAchievementStepTimer(selectedActivity.achievementId, step.id, duration, date)
        } else {
            this.props.adjustAchievementTimer(selectedActivity.achievementId, duration, date)
        }

        this.setState({ selectedActivity: null, showTimer: false, step: null, duration: duration });
    }

    handleStartActivity = () => {
        let { selectedActivityDetails } = this.props;
        this.props.showStartActivityPanel({action:'preview', ...selectedActivityDetails})
        this.setState({ anchorEl: null })
    }

    handleResetActivity = () => {
        let { selectedActivityDetails } = this.props;
        let userId = localStorage.getItem('uid');
        this.props.setActivityReset(userId, selectedActivityDetails);
        this.setState({ anchorEl: null })
    }

    handleClickMenuForStep = (event, step) => {
        const { selectedActivityDetails, isInprogress } = this.props;
        if (selectedActivityDetails && selectedActivityDetails.isTimerEnabled && isInprogress && !step.isCompleted) {
            if (step.expectedDuration) {
                this.setState({ anchorEl1: event.currentTarget, step: step, duration: step.expectedDuration });
            } else {
                this.setState({ anchorEl1: event.currentTarget, step: step, duration: null });
            }

        }
    }

    getFormatedDuration = (duration) => {
        var timeleft = (+duration) * 1000;
        let seconds = (Math.floor(timeleft / 1000) % 60)
        let minutes = (Math.floor(timeleft / 60000) % 60)
        let hours = Math.floor(timeleft / 3600000);
        seconds = seconds < 10 ? "0" + seconds : seconds;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        hours = hours < 10 ? "0" + hours : hours;
        return `${hours}:${minutes}:${seconds}`;
    }

    parseDuration(duration){
        var timeleft = (+duration) * 1000;
        let seconds = (Math.floor(timeleft / 1000) % 60)
        let minutes = (Math.floor(timeleft / 60000) % 60)
        let hours = Math.floor(timeleft / 3600000);
		return hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`
	}

    getExpectedDurationSeconds = (formatedString) => {
        let total = 0;
        if (formatedString) {
            const dateArray = formatedString.split(":");
            let hr = dateArray[0];
            let min = dateArray[1];
            let sec = dateArray[2];
            if (!sec) {
                if (min) {
                    sec = min;
                    min = hr;
                    hr = 0
                } else {
                    sec = hr;
                    min = 0;
                    hr = 0;
                }
            }
            let totalhrs = hr ? Number(hr) * 3600 : 0;
            let totalmin = min ? Number(min) * 60 : 0;
            total = totalhrs + totalmin + Number(sec);
        }
        return total;
    }



    handleOpenStepsNotesForm = (formId, stepId, stepNotesResponseId) => {
        console.log(" Opening activity form -->", stepId)
        const stepResponseId = stepNotesResponseId;
        if (stepResponseId) {
            console.log(" stepNotesResponse Id -->", stepResponseId);
            this.props.getUpdatedEvaluationForm(this.props.selectedMemberId, stepResponseId);
            this.setState({selectedStepId: stepId, formId: stepResponseId, openForm: true})
        } else if (formId) {
            console.log(" Form Id -->", formId);
            this.props.getPokeForm(formId)
            this.setState({selectedStepId: stepId, formId: formId, openForm: true})
        }
    }

    handleCloseForm = () => {
        this.setState({selectedStepId: null, formId: null, openForm: false})
    }

    render() {
        const { anchorEl, anchorEl1, achievementDelayed, timerTime, elapsedTime  } = this.state;
        const { selectedMemberId, groupMembersDetails, selectedActivityDetails, isInprogress, loading , realtimeUpdate} = this.props;
        const selectedClient = groupMembersDetails && selectedMemberId ? groupMembersDetails.filter(client => client.userId === selectedMemberId)[0] : null;
        const stepsList = [];
        const auth_uid = localStorage.getItem("auth_uid")
        const uid = localStorage.getItem("uid")

        if (selectedActivityDetails && selectedActivityDetails.steps) {
            Object.keys(selectedActivityDetails.steps).map((id, index) => {
                let stepDetails = selectedActivityDetails.steps[id];
                stepDetails.id = id;
                stepDetails.status = null;
                stepDetails.duration = null;
                stepDetails.formatedDuration = null;
                stepDetails.delayed = false;
                stepDetails.isCompleted = false;
                if (selectedActivityDetails.stepsCompletionList && isInprogress) {
                    selectedActivityDetails.stepsCompletionList.map((stepCompletionDetails, key) => {
                        if (stepCompletionDetails.step.id === id) {
                            stepDetails.status = isInprogress && stepCompletionDetails.status ? stepCompletionDetails.status : null;
                            stepDetails.stepNotesResponseId = stepCompletionDetails.stepNotesResponseId ? stepCompletionDetails.stepNotesResponseId : null;
                            if (stepDetails.status === "Done" || stepDetails.status === "Skipped") {
                                stepDetails.isCompleted = true;
                            }
                            if (stepCompletionDetails.duration) {
                                stepDetails.duration = stepCompletionDetails.duration;
                                let expectedDuration = this.getExpectedDurationSeconds(stepDetails.expectedDuration);
                                if (expectedDuration && expectedDuration >= stepDetails.duration) {
                                    stepDetails.formatedDuration = this.getFormatedDuration(stepDetails.duration);
                                    stepDetails.delayed = false;
                                } else {
                                    let duration = stepDetails.duration - expectedDuration;
                                    stepDetails.formatedDuration = this.getFormatedDuration(duration);
                                    stepDetails.delayed = true;
                                }
                            }
                        }
                    })
                }
                stepsList.push(stepDetails);
            })
            stepsList.sort((a, b) => a.position - b.position);
        }
        console.log("selectedActivityDetails ----> ", selectedActivityDetails);
        // console.log("stepsList ----> ", stepsList, isInprogress);
        return (
            <div className='row'>
            <RctCollapsibleCard
                colClasses="col-sm-12 col-md-12 col-lg-12"
                heading={<div className="d-flex align-items-center justify-content-between text-white">
                    <div className="d-flex align-items-center">
                        <IconButton title="Back To List" onClick={() => this.handleCloseActivityDetailsPanel()}><ArrowBackIcon /></IconButton>
                        <div className="cps">
                            <div className="d-flex align-items-center">
                                {selectedActivityDetails && selectedActivityDetails.title ? selectedActivityDetails.title : ""}
                                {selectedActivityDetails && selectedActivityDetails.expectedDuration && isInprogress ? <span className="d-none d-md-block ml-1">{`[${selectedActivityDetails.expectedDuration}]`}</span> : "" }
                                <IconButton
                                    onClick={(e) => this.handleClickMenu(e)}
                                    title="More Actions"
                                    className="menu-icon-button">
                                    <MoreVertIcon color="primary" aria-label="More" aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" />
                                </IconButton>
                            </div>
                            {selectedActivityDetails && selectedActivityDetails.expectedDuration && isInprogress ? <div className="d-block d-md-none">{` [${selectedActivityDetails.expectedDuration}]`}</div> : "" }
                            {isInprogress && <div className="fs-12">In Progress</div>}

                        </div>
                        <Menu id="simple-menu-activity-detail" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleCloseMenu.bind(this)}
                            getcontentanchorel={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {selectedActivityDetails && selectedActivityDetails.isTimerEnabled && isInprogress && <MenuItem className="menuEditItem" onClick={() => this.handleAdjustActivityTimer()}>Adjust Timer</MenuItem>}
                            {/* {<MenuItem className="menuEditItem" onClick={() => this.handleShowActivityHistoryList()}>Activity History</MenuItem>} */}
                            {selectedActivityDetails && selectedActivityDetails.achievementId && isInprogress && <MenuItem className="menuEditItem" onClick={() => this.handleResetActivity()}>Reset Activity</MenuItem>}
                            {<MenuItem className="menuEditItem" onClick={() => this.handleStartActivity()}>Preview Activity</MenuItem>}
                            {/*allActivities[editScheduleKey]&&typeof allActivities[editScheduleKey].showPreviewButton==='undefined'&&<MenuItem><div className="menuEditItem" onClick={handlePreviewDialod}>Preview</div></MenuItem>*/}

                            {/*  <MenuItem><div className="menuEditItem" onClick={handleChart}>Chart</div></MenuItem>
                            <MenuItem><div className="menuEditItem" onClick={EditActivity}>
                                    Edit
                            </div></MenuItem>
                            <MenuItem><div className="menuEditItem" onClick={handleDeletePop}>Delete</div></MenuItem>  
                            */}
                            <MenuItem className="menuEditItem" onClick={this.handleCloseMenu.bind(this)}>Cancel</MenuItem>
                        </Menu>
                    </div>
                    {(isInprogress && selectedActivityDetails.startDate) ?
                        <div className="fs-14">
                            <div className="d-none d-md-block right-item-field-lg">{moment(selectedActivityDetails.startDate, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY hh:mm A')}</div>
                            <div className="d-block d-md-none right-item-field-sm">
                                <div>{moment(selectedActivityDetails.startDate, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY')}</div>
                                <div className="d-flex justify-content-end">{moment(selectedActivityDetails.startDate, "YYYY-MM-DD HH:mm:ss Z").format('hh:mm A')}</div>
                            </div>
                        </div>
                        : stepsList.length > 0 ?
                            <div className="right-item-field-sm">
                                {stepsList.length + " steps"}
                            </div> : ""
                    }
                </div>
                }
                fullBlock
                customClasses="overflow-hidden theme-background actvity-details-panel"
                headingCustomClasses="bg-lifesherpa "
            >
                <LifeSherpaLoading loading={loading} />
                {selectedActivityDetails.isTimerEnabled && isInprogress &&
                    <div>
                        <div className="row p-2 border-bottom border-top bg-lifesherpa">
                            <h2 className={` my-auto mx-auto`} style={achievementDelayed ? { color: "red" } : {}}>{`+${this.getFormatedDuration(timerTime)}`}</h2>
                        </div>
                        <div className="row p-2 border-bottom bg-lifesherpa">
                            <p className="text-white my-auto ml-1 mx-2">{'Elapsed Time ' + `${this.getFormatedDuration(elapsedTime)}`}</p>
                        </div>
                    </div>
                }
                {stepsList && stepsList.length > 0 && stepsList.map((step, key) =>
                    <li key={step.id} className="d-flex align-items-center steps-list-item list-card mb-1 list-item p-2 my-2 border-bottom">
                        <div className="avatar-wrap w-15 align-self-center position-relative" >
                            {isInprogress ?
                                <Avatar
                                   title = {step.status || "Not Started"}
                                    className={`align-self-center step-status-avatar  ${step.status === 'Skipped' ?
                                        "achievementStatusCancelled"
                                        : step.status === 'Done' ?
                                            "achievementStatusConfirmed"
                                            : "achievementStatusNotFound"}`}  
                                >
                                    {step.status === 'Skipped' ?
                                        <ClearIcon />
                                        : step.status === 'Done' ?
                                            <DoneRoundedIcon />
                                            :
                                            <DoneRoundedIcon />
                                    }

                                </Avatar>
                                :
                                <Avatar className={`align-self-center step-count-avatar achievementStatusNotFound`}> {key + 1} </Avatar>
                            }
                        </div>
                        <div className='d-flex align-items-center justify-content-between w-100'>
                            <div className="actvity-routine mx-2 ml-3">
                                <h4 className='d-flex align-items-center'>
                                    <span>{isInprogress ? `[${key + 1}] ${step.title}` : step.title}</span>
                                    {selectedActivityDetails && selectedActivityDetails.isTimerEnabled && isInprogress && !step.isCompleted &&
                                        <IconButton className="px-1 py-0" onClick={(e) => this.handleClickMenuForStep(e, step)}>
                                            <MoreVertIcon className='more-icon-button'/>
                                        </IconButton>
                                    }
                                </h4>
                                {step.expectedDuration && isInprogress && step.expectedDuration.split(":")[2] ?
                                    <div>
                                        <span>{step.expectedDuration}</span>
                                        <span style={step.delayed ? { color: "red" } : {}}>{` [${step.delayed ? "+" : ""}${step.formatedDuration || "00:00:00"}]`}</span>
                                    </div>
                                : step.duration &&
                                <span>{ step.duration > 60 ? `${this.parseDuration(step.duration)}` : `${step.duration}s`}</span>
                                }
                            </div>
                            {selectedActivityDetails.stepNotesId && isInprogress && 
                                <div>
                                    <IconButton disabled={!step.isCompleted} title="Step Notes Form" onClick={() => this.handleOpenStepsNotesForm(selectedActivityDetails.stepNotesId, step.id, step.stepNotesResponseId)} size="small">
                                        <CommentIcon className={step.isCompleted ? "step-form-enable":"step-form-disable"} style={selectedActivityDetails && selectedActivityDetails.stepNotesId && step.stepNotesResponseId ? { color: 'green' } : { }} />
                                    </IconButton>
                                </div>
                            }
                        </div>
                        {this.state.step && this.state.step.id === step.id &&
                            <Menu anchorEl={anchorEl1} open={Boolean(anchorEl1)} onClose={this.handleCloseMenu.bind(this)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                getcontentanchorel={null}
                            >
                                {selectedActivityDetails && selectedActivityDetails.isTimerEnabled && isInprogress && <MenuItem className="menuEditItem" onClick={() => this.handleAdjustActivityTimer()}>Adjust Timer</MenuItem>}
                                <MenuItem className="menuEditItem" onClick={this.handleCloseMenu.bind(this)}>Cancel</MenuItem>
                            </Menu>
                        }
                    </li>
                )
                }
                {selectedActivityDetails &&
                    <div className={`actions-button row mt-3 ${isInprogress || selectedActivityDetails.type === "Touchpoint" ? "justify-content-center" : "justify-content-between"}`}>
                        <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3">
                            <Button color="primary" variant="outlined" className="" onClick={() => this.handleShowActivityHistoryList()}>
                                View Activity History
                            </Button>
                        </div>
                        {!isInprogress && selectedActivityDetails.type !== "Touchpoint" &&
                            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-3">
                                <Button /*disabled={this.state.disableReminder}*/ color="primary" variant="contained" className="" onClick={() => this.handleReminderButton()}>
                                    {`Remind ${selectedClient && selectedClient.name || "Client"} to start activity`}
                                </Button>
                            </div>
                        }
                    </div>
                }
                {this.state.showTimer &&
                    <ActivityTimerUpdate showTimer={this.state.showTimer}
                        handleCloseTimerUpdate={this.handleCloseTimerUpdate}
                        handleUpdateTimer={this.handleUpdateTimer}
                        details={this.state.selectedActivity}
                        duration={this.state.duration}
                        step={this.state.step}
                    />
                }
                {this.state.notifyBox &&
                    <div className="col-12 p-4">
                        <SweetAlert
                            custom
                            showCancel
                            btnSize="sm"
                            customClass="warningText"
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="warning"
                            cancelBtnBsStyle="default"
                            title="Confirmation"
                            customIcon={require('../../../assets/img/help-logo.png')}
                            onConfirm={() => this.handleNotify()}
                            onCancel={() => this.setState({ notifyBox: false })}
                        >
                            <div style={{ fontFamily: "'Lato'", fontWeight: 400 }}>{`Would you like to notify ${selectedClient ? selectedClient.name : "Client"} to start ${selectedActivityDetails.title}?`}</div>
                        </SweetAlert>
                    </div>}
                    {this.state.openForm ?
                        <ActivityConfirmForm
                            open={this.state.openForm}
                            handleClose={this.handleCloseForm.bind(this)}
                            displayName={this.props.displayName}
                            profileImage={this.props.profileImage}
                            title={selectedActivityDetails && selectedActivityDetails.title ?  selectedActivityDetails.title : ""}
                            loading={this.props.pokeFormLoading}
                            pokeForm={this.props.pokeForm}
                            selectedMemberId={this.props.selectedMemberId}
                            achievementId = {selectedActivityDetails.achievementId || selectedActivityDetails.id}
                            selectedStepId={this.state.selectedStepId}
                            formId={this.state.formId}
                        />
                       :""
                    }
            </RctCollapsibleCard>
            </div>
        );
    }
}

const mapStateToProps = ({ GroupList, chatAppReducer, activitiesList , PokeForm, clientsList}) => {
    let { groupMembersDetails } = GroupList
    const { selectedMemberId } = chatAppReducer
    let { selectedActivityDetails, loading, showEncounterActivityDetails, realtimeUpdate } = activitiesList;
    selectedActivityDetails = selectedActivityDetails || {};
    let isInprogress = false;
    if (selectedActivityDetails && selectedActivityDetails.state && (selectedActivityDetails.state === "paused" || selectedActivityDetails.state === "inProgress")) {
        isInprogress = true
    } else {
        selectedActivityDetails ? selectedActivityDetails.expectedDuration = null : null;
    }
    let { clientPanel } = clientsList;
    const {pokeForm} = PokeForm;
    let pokeFormLoading = PokeForm.loading;
    const selectedClient = groupMembersDetails && selectedMemberId ? groupMembersDetails.filter(client => client.userId === selectedMemberId)[0] : null;
    const profileImage = selectedClient && selectedClient.profileImageURL ? selectedClient.profileImageURL: null;
    const displayName = selectedClient && selectedClient.name ? selectedClient.name : '';
    return {realtimeUpdate, selectedMemberId, groupMembersDetails, selectedActivityDetails, isInprogress, loading, pokeForm, pokeFormLoading, profileImage, displayName, showEncounterActivityDetails, clientPanel};
}

export default withRouter(connect(mapStateToProps, {
    handleHideActivityDetailsPanel,
    clearHistoryData,
    showActivityHistoryDetail,
    getSelectedActivity,
    getHistoryList,
    setActivityReminder,
    adjustAchievementTimer,
    StartActivity,
    setActivityType,
    setActivityReset,
    adjustAchievementStepTimer,
    getUpdatedEvaluationForm,
    getPokeForm,
    handleShowEncounterActivityDetails,
    setSelectedMemberId,
    showStartActivityPanel
})(ActivityDetails))
