/**
* Chat
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
// reactstrap components
import {
    clearHistoryData,
    getClientUnconfirmedAchievementsList,
    showActivityHistoryDetail,
    handleNoticesPanel,
    addSelectedChatUser,
    clientNetworkActivity,
    getUserInfo,
    handleUserWorkflowPanel,
    getUserPendingWorkflowList,
    showFullChatPanelForSelectedUser
} from '../../../actions';

import { isMobile, scrollMaincontainerToTop } from "../../../helpers/helpers";
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import WidgetServices from "../../../services/widgetServices";
import LifeSherpaLoading from '../../LifeSherpaLoading';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CustomScrollbars from '../../CustomScrollbars';

class Dashboard extends Component {

    componentDidMount() {
        const uid = localStorage.getItem("uid");
        const orgId = localStorage.getItem("selectedOrgId")
        this.props.clientNetworkActivity(uid);
        if(this.props.enableWorkflows) {
            this.props.getUserPendingWorkflowList({userId: uid, orgId})
        }
    }

    getScrollHeight() {
        if (localStorage.getItem("widgetApp")) {
            return 'calc(100vh - 0px)';
        } else {
            return '630px';
        }
    }

    handleOpenUnconfirmAchievements = (user) => {
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const data = { clientId: user.userId };
            widgetServices.openUnconfirmedAchievementsHandler(data);
        } else {
            this.props.clearHistoryData();
            const activityConfirmation = localStorage.getItem("activityConfirmation");
            if (activityConfirmation) {
                localStorage.removeItem("activityConfirmation");
            }
            this.setState({ anchorEl: null });
            this.props.getClientUnconfirmedAchievementsList(user.userId, null, !isMobile());
            scrollMaincontainerToTop()
            this.props.showActivityHistoryDetail(true);
            localStorage.setItem('prevUrl', window.location.pathname);
            this.props.navigate("/app/lsdashboard/activities/history")
        }
    }

    handleOpenNoticePanel = (user) => {
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const data = { clientId: user.userId };
            widgetServices.handleOpenNoticePanel(data);
        } else {
            this.props.handleNoticesPanel(true, user.userId)
        }
    }

    handleOpenWorkflowPanel = (user) => {
      this.props.handleUserWorkflowPanel(true, user.userId)
    }

    handleOpenUserChatPanel = (user) => {
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const uid = localStorage.getItem("uid");
            const data = { clientId: user.userId };
            widgetServices.openClientChatHandler(data);
        } else {
            const { contactList } = this.props;
            let chatUser = contactList ? contactList.find(contact => contact.userId == user.userId) : null;
            if(chatUser) {
                this.showChatPanelForSelectedUser(chatUser);
            } else {
                this.props.getUserInfo(user.userId).then(result => {
                    const {displayName, firstName, lastName, profileImageURL } = result || {};
                    const userDetails = {userId: user.userId, name: displayName || `${firstName || 'Client'} ${lastName || ''}`, profileImageURL: profileImageURL};
                    this.showChatPanelForSelectedUser(userDetails);
                })
            }
        }
    }

    showChatPanelForSelectedUser = (chatUser) => {
        let { selectedChatUsers } = this.props;
        console.log(" Chat opening for user ->", chatUser);
        if(isMobile()) {
            this.props.showFullChatPanelForSelectedUser(chatUser);
			localStorage.setItem('prevUrl', window.location.pathname);
			this.props.navigate("/app/lsdashboard/chat");
            return;
        }
       
        if (chatUser) {
            if (selectedChatUsers && selectedChatUsers.length > 0) {
                let index = selectedChatUsers.findIndex(user => user && user.userId == chatUser.userId);
                if (index >= 0) {
                    // selectedChatUsers.splice(index,1)
                    // this.props.addSelectedChatUser(selectedChatUsers);
                    // selectedChatUsers.push(chatUser);  
                } else {
                    selectedChatUsers.push(chatUser);
                }
            } else {
                let usersList = [];
                usersList.push(chatUser);
                selectedChatUsers = usersList;
            }

            console.log(" Chat opened ->", selectedChatUsers);
            this.props.addSelectedChatUser(selectedChatUsers);
        }
    }

    render() {
        const { selectedMemberId, clientNetworkActivities, clientNetworkActivitiesLoading, themeLoading, userLoading, userWorkflows, userWorkflowLoading, widgetVersion, enableWorkflows } = this.props;
        const uid = localStorage.getItem("uid");
        let user = clientNetworkActivities || { userId: selectedMemberId || uid };
        return (
            <div data-testid="client-dashboard">
                <CustomScrollbars
                    className="rct-scroll "
                    autoHide
                    style={{ height: this.getScrollHeight() }}
                    hideTracksWhenNotNeeded={isMobile()}
                >
                    <LifeSherpaLoading loading={ (themeLoading || userLoading) ? false : clientNetworkActivitiesLoading || userWorkflowLoading} />
                    <div className="list-card mb-2 justify-content-between align-items-center p-2 mx-2 my-3">
                        <div className={`${(clientNetworkActivities && clientNetworkActivities.unreadClientAlerts) ? 'text-danger' : 'heading-text'} d-flex cps px-2 align-items-center justify-content-between`} onClick={() => this.handleOpenNoticePanel(user)}>
                            <div className='d-flex align-items-center justify-content-center'><WarningRoundedIcon className='mr-2' />Unread Client Alerts</div>
                            <div data-testid="unread-client-alert-count">{(clientNetworkActivities && clientNetworkActivities.unreadClientAlerts) || 0}</div>
                        </div>
                        <hr className="my-2" />
                        <div className={`${(clientNetworkActivities && clientNetworkActivities.unreadMessages) ? 'text-danger' : 'heading-text'} d-flex heading-text cps px-2 align-items-center justify-content-between`} onClick={() => this.handleOpenUserChatPanel(user)}>
                            <div className='d-flex align-items-center justify-content-center'><MarkUnreadChatAltRoundedIcon className='mr-2' />Unread Text Messages</div>
                            <div data-testid="unread-text-messages-count">{(clientNetworkActivities && clientNetworkActivities.unreadMessages) || 0}</div>
                        </div>
                        {/* <hr className="my-2" />
                                <div className="d-flex heading-text cps px-2 align-items-center justify-content-between">
                                    <div className='d-flex align-items-center justify-content-center'><MissedVideoCallRoundedIcon  className='mr-2'/>Missed Video Calls</div>
                                    <div>0</div>
                                </div> */}
                        <hr className="my-2" />
                        <div className={`${(clientNetworkActivities && clientNetworkActivities.unconfirmedAchievements) ? 'text-danger' : 'heading-text'} cps d-flex px-2 align-items-center justify-content-between`} onClick={() => this.handleOpenUnconfirmAchievements(user)}>
                            <div className='d-flex align-items-center justify-content-center'><ListAltRoundedIcon className='mr-2' />Unconfirmed Activities</div>
                            <div data-testid="unconfirmed-activities-count">{(clientNetworkActivities && clientNetworkActivities.unconfirmedAchievements) || 0}</div>
                        </div>
                        {!widgetVersion && enableWorkflows ?
                        <>
                            <hr className="my-2" />
                            <div className={`${userWorkflows && userWorkflows.length > 0 ? 'text-danger' : 'heading-text'} cps d-flex px-2 align-items-center justify-content-between`} onClick={() => this.handleOpenWorkflowPanel(user)}>
                                <div className='d-flex align-items-center justify-content-center'><Diversity3Icon className='mr-2' />Tasks Awaiting Completion in Workflows</div>
                                <div data-testid="user-pending-workflow-tasks-count">{ userWorkflows ? userWorkflows.count : 0}</div>
                            </div>
                        </>
                        :<></>
                        }
                    </div>
                </CustomScrollbars>
            </div>
        );
    }
}

const mapStateToProps = ({ chatAppReducer, Contacts, clientsList, authUser, workflowData }) => {
    const { clientNetworkActivities, clientNetworkActivitiesLoading } = clientsList;
    const { selectedMemberId, selectedChatUsers } = chatAppReducer;
    const { themeLoading, userLoading } = authUser
    const { contactList } = Contacts;
    const { userWorkflows, userWorkflowLoading } = workflowData
    return { selectedMemberId, selectedChatUsers, contactList, clientNetworkActivities, clientNetworkActivitiesLoading,  themeLoading, userLoading, userWorkflows, userWorkflowLoading };
}

export default withRouter(connect(mapStateToProps, {
    clearHistoryData,
    getClientUnconfirmedAchievementsList,
    showActivityHistoryDetail,
    handleNoticesPanel,
    addSelectedChatUser,
    clientNetworkActivity,
    getUserInfo,
    handleUserWorkflowPanel,
    getUserPendingWorkflowList,
    showFullChatPanelForSelectedUser
})(Dashboard));

