/**
 * User List
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';\
import withRouter from '../../../router';
import LifeSherpaLoading from '../../../components/LifeSherpaLoading';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
// components
import RecentChatUsers from './RecentChatUsers';
import TouchAppIcon from '@mui/icons-material/TouchApp';
// redux actions
import {
    changeRole,
    addSelectedChatUser,
    getGroupPokeForm,
    setsavePokeForGroup,
    clearUserOrgFilter,
    networkActivity,
    showFullChatPanelForSelectedUser,
    setSelectedMemberId,
    getClientUnconfirmedAchievementsList,
    showActivityHistoryDetail,
    clearHistoryData,
    handleNoticesPanel,
    handleUserWorkflowPanel,
    handleShowClientDashboard,
    postClientMuteNotification,
    showAssignActivityIFramePanel,
    logoutCallbackForAuth0Expired,
    setUserSelected,
    handleHideActivityDetailsPanel,
    showClientPanel,
    getGroupList,
    getAllGroupMembers,
    groupMembersRealtimeListenerOff,
    handleShowFallbackScreen
} from '../../../actions';
import { IconButton } from '@mui/material';
import { FilterAlt } from '@mui/icons-material';
import ActivityConfirmForm from '../../../components/ActivityForm/ActivityConfirmForm'
import ActivitiesFilterPopup from '../../../components/Widgets/ActivitiesFilterPopup';
import { Label } from 'reactstrap';
import CustomScrollbars from '../../../components/CustomScrollbars';
import {scrollMaincontainerToTop, isMobile, reauthenticateIfTokenExpireWithIn30Min } from '../../../helpers/helpers';
import WidgetServices from "../../../services/widgetServices";
import config from '../../../config/config';
import _ from 'lodash';

class ClientsList extends Component {

    /**
     * Swicth Chat With User
     * @param {*object} user
     */
    state = {
        openPopup: false,
        openForm: false,
        openOrgFilter: false,
        search: '',
        localLoading: false
    }

    componentDidMount() {
        console.log("%c[ClientsList]%c Rendered all clients panel on Home Screen","color:black","color:green");
        this.props.clearUserOrgFilter({panel:'client'});
        this.props.getGroupList()
        // this.props.getAllGroupMembers();
        if(isMobile()) {
            this.props.networkActivity()
        }
    }

    componentWillUnmount() {
        this.props.groupMembersRealtimeListenerOff(this.props.groups);
    }

    handlePokeForm() {
        const {pokeFormId} = this.props
        this.props.setsavePokeForGroup(!this.state.openForm) 
        if (!this.state.openForm){
            this.setState({openForm: true})
            this.props.getGroupPokeForm(pokeFormId)
        } else {
            this.setState({openForm: false})
        }
    }

    

    getScrollHeight() { 
        if (localStorage.getItem("widgetApp")) {
            return "calc(100vh - 150px)"
        } else if(isMobile()) {
            return "calc(100vh - 150px)";
        } else {
            return '515px';
        }
    }

    handleOpenFilter(){
        const {openOrgFilter} = this.state
        this.setState({openOrgFilter: !openOrgFilter})
    }

    handelSearch = (e) => {
        // console.log(e);
        this.setState({...this.state, search:e.target.value});
    }

    handelClearSearch = () => {
        this.setState({...this.state,search:""});
    }

    showChatPanelForSelectedUser = (user) => {
        let {selectedChatUsers, contactList} = this.props;
        const {userId, organization, profileImageURL, name} = user;
        const widgetServices = new WidgetServices()
        if(widgetServices.isWidgetApp()) {
            const data = {clientId: userId};
            widgetServices.openClientChatHandler(data)
            return;
        }

        let chatUser = contactList ? contactList.find(userInfo => userInfo.userId == userId) : null;
        if(!chatUser) {
            chatUser = {userId, name, profileImageURL, organization};
        }
        console.log(" Chat opening for user ->", chatUser);
		if(isMobile()) {
			this.props.showFullChatPanelForSelectedUser(chatUser);
			localStorage.setItem('prevUrl', window.location.pathname);
			this.props.navigate("/app/lsdashboard/chat");
		} else {
			if(selectedChatUsers && selectedChatUsers.length > 0) {
				let index = selectedChatUsers.findIndex(user => user && user.userId == userId);
				if(index >= 0) {
					// selectedChatUsers.splice(index,1)
					// this.props.addSelectedChatUser(selectedChatUsers);
					// selectedChatUsers.push(chatUser);  
				} else {
					selectedChatUsers.push(chatUser);
				}
			} else {
				let usersList = [];
				usersList.push(chatUser);
				selectedChatUsers = usersList;
			}
	
			console.log(" Chat opened ->",selectedChatUsers);
			this.props.addSelectedChatUser(selectedChatUsers);
		}
        
    }

    handleOpenUnconfirmAchievements = (user) => {
        const {userId, organization: orgId} = user;
        this.props.clearHistoryData();
		const widgetServices = new WidgetServices()
		if(widgetServices.isWidgetApp()) {
			const data = {clientId: userId};
			widgetServices.openUnconfirmedAchievementsHandler(data);
            return;
		}
        const activityConfirmation = localStorage.getItem("activityConfirmation");
        if(activityConfirmation) {
            localStorage.removeItem("activityConfirmation");
        }
        this.props.setSelectedMemberId(userId);
        localStorage.setItem("uid",userId);
        localStorage.setItem("selectedOrgId", orgId)
        scrollMaincontainerToTop();
        this.props.getClientUnconfirmedAchievementsList(userId, null, !isMobile()); 
        this.props.showActivityHistoryDetail(true);
        localStorage.setItem('prevUrl', window.location.pathname);
        this.props.navigate("/app/lsdashboard/activities/history")
	}

    handleOpenNoticePanel = (user) => {
        const {userId, organization: orgId} = user;
        if(orgId) {
          localStorage.setItem("selectedOrgId", orgId)
        }
        this.props.handleNoticesPanel(true, userId)
    }

    handleOpenUserWorkflow = (user) => {
        const {userId, organization} = user;
        this.props.handleUserWorkflowPanel(true, userId, organization)
    }

    handleOpenDashboardPanel = (user) => {
        const {userId, organization} = user;
        localStorage.setItem("selectedOrgId", organization)
        this.props.handleShowClientDashboard(true, userId)
    }

    handleMuteNotification(user) {
        const {userId, organization} = user;
        const { notifications } = this.props;
        const notify = notifications && notifications[userId] !== undefined ? notifications[userId] : true
        const enabled = !notify
        const adminUserId = localStorage.getItem("auth_uid")
        this.props.postClientMuteNotification(adminUserId, userId, enabled)
    }

    async handleOpenAssignActivity(user){
        const {userId, organization} = user;
        const themeName = this.props.activeTheme && this.props.activeTheme.name ? this.props.activeTheme.name : "default"
        const theme = themeName.charAt(0).toUpperCase() + themeName.slice(1);
        let idToken = localStorage.getItem("idToken")
        const orgId = organization || localStorage.getItem("selectedOrgId");
        const widgetServices = new WidgetServices()
        if(widgetServices.isWidgetApp()) {
          const data = {clientId: userId};
          widgetServices.openAssignActivityHandler(data);
          return;
        } 
    
        const openAssignActivityAppUrl = `${config.assignActivityUrl}?userId=${userId}&idToken=${idToken}&orgId=${orgId}&mode=web&theme=${theme == "Danger" ? "Theme4":theme}`;
        const assingActivityInfo = {assignActivityUrl:openAssignActivityAppUrl};
        this.setState({localLoading:true});
        const reauthenticated = await reauthenticateIfTokenExpireWithIn30Min();
        if(reauthenticated) {
            this.setState({localLoading:false});
            this.props.showAssignActivityIFramePanel(assingActivityInfo);
            localStorage.setItem('prevUrl', window.location.pathname);
            this.props.navigate('/app/lsdashboard/client/activities/assign');
        } else {
            console.error("Token reauthentication failed",);
            this.setState({localLoading:false});
            logoutCallbackForAuth0Expired(); 
        }
    }

    // Function to trigger page rendering with debounce
    triggerClientDetailPage = _.debounce((user) => {
        const {userId, organization, groupId} = user;
		localStorage.setItem("selectedOrgId", organization)
		
        this.props.setUserSelected(user)
        localStorage.setItem('uid', userId);
        this.props.setSelectedMemberId(userId)
        this.props.changeRole({ role: 'client', id: userId })
        this.props.handleHideActivityDetailsPanel();
        localStorage.setItem('prevUrl', window.location.pathname)
        scrollMaincontainerToTop();
        this.props.showClientPanel(true);
        this.props.navigate("/app/lsdashboard/client");
        this.props.handleShowFallbackScreen(false);
    }, 500);

    openClientDetail(user) {
        const widgetServices = new WidgetServices()
		if(widgetServices.isWidgetApp()) {
			const data = {clientId: user.userId};
			widgetServices.openClientDetailsHandler(data);
            return;
		} 
        
        if(isMobile()) {
            this.props.handleShowFallbackScreen(true);
            this.triggerClientDetailPage(user);
        } else {
            const {userId, organization, groupId} = user;
            localStorage.setItem("selectedOrgId", organization)
            
            this.props.setUserSelected(user)
            localStorage.setItem('uid', userId);
            this.props.setSelectedMemberId(userId)
            this.props.changeRole({ role: 'client', id: userId })
            this.props.handleHideActivityDetailsPanel();
            localStorage.setItem('prevUrl', window.location.pathname)
            scrollMaincontainerToTop();
            this.props.showClientPanel(true);
            this.props.navigate("/app/lsdashboard/client");

        }
        
        // this.props.navigate("/app/lsdashboard/client");
    }

    getGroupMembersList(search) {
        let {groupMembersDetails} = this.props
        if(groupMembersDetails) {
            if (search && search.length > 2) {
                return groupMembersDetails.filter((member) => (member.name.toLowerCase().includes(search.toLowerCase())))
            } 
            return groupMembersDetails;
        }
        return [];
    }
    
    render() {
        const { groups, loading, selectedMember, groupMembersDetails, pokeFormId, groupPokeForm, groupPokeFormLoading, organizations, clientPanelOrgFiltered, contactList, selectedGroup, groupMembers } = this.props;
        const {openOrgFilter, search} = this.state

        const groupMembersList = this.getGroupMembersList(search);
        const filterApplied = (clientPanelOrgFiltered && clientPanelOrgFiltered.length > 0) || (selectedGroup && selectedGroup.length > 0) ? true : false
        return (
            <div data-testid="my-clients-list-panel">
                {
                    <React.Fragment>
                        <div className={`p-3  ${isMobile() ? 'bg-lifesherpa ' : 'list-card '}`}>
                            {
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className={`d-flex ${filterApplied ? "activity-filter-icon" : "activity-filter-icon-applied"} mr-2`}>
                                        <IconButton data-testid="orgId-filter-icon-button" title="Filter By Groups" className="text-white rounded-circle mr-2" onClick={() => this.handleOpenFilter()}>
                                            <FilterAlt data-testid="orgId-filter-enabled" className="filter-icon" fontSize="large"/>
                                        </IconButton>
                                    </div>
                                    <div className="mx-2 search-activity-field">
                                        <form noValidate autoComplete="off">
                                            <TextField data-testid="my-client-search-text-field" id="standard-search" 
                                                onChange={(e) => this.handelSearch(e)}
                                                label="Search Client" 
                                                type="search" 
                                                value={search} 
                                                InputProps={{
                                                startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon color="primary"/>
                                                </InputAdornment>
                                                ),
                                                endAdornment: ( search && search.length > 0 ?
                                                    <InputAdornment position="end">
                                                    <CloseIcon color="primary" onClick={() => this.handelClearSearch()} style={{cursor:"pointer"}}/>
                                                    </InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
                                                )
                                                }}
                                                variant="standard" 
                                            />
                                            <Label className='mb-0 mt-2' style={{fontWeight: 'bold'}}>{`${filterApplied ? "Selected" : "Total"} Clients (${groupMembersDetails && groupMembersDetails.length > 0 ? groupMembersDetails.length : 0})`}</Label>
                                        </form>
                                     </div>
                                    {pokeFormId ?
                                        <div className='activity-filter-icon-applied'>
                                            <IconButton onClick={() => this.handlePokeForm()} title='Poke group members' className='ml-2'>
                                                <TouchAppIcon fontSize='large'/>
                                            </IconButton>
                                        </div>
                                        : <></>
                                    }
                                </div>
                            }
                        </div>
                      
                        <div className="chat-list ">
                            <LifeSherpaLoading loading={this.props.muteNotificationLoading || this.state.localLoading || this.props.membersLoading} />
                            <CustomScrollbars
                                className="rct-scroll"
                                autoHide
                                style={{ height: this.getScrollHeight() }}
                                >
                                <List className="px-0 py-2 mb-0 mt-1">
                                {/* <div id="clients-list-container" className="px-0 py-2 mb-0 mt-1" style={{ minHeight: this.getScrollHeight(), height: this.getScrollHeight(), overflowX: "auto"  }}> */}
                                    { groupMembersList.length ?
                                        <RecentChatUsers
                                            groupMembersList={groupMembersList}
                                            groupMembers={groupMembers}
                                            showChatPanelForSelectedUser={this.showChatPanelForSelectedUser}
                                            handleOpenUnconfirmAchievements={this.handleOpenUnconfirmAchievements}
                                            handleOpenNoticePanel={this.handleOpenNoticePanel}
                                            handleOpenUserWorkflow={this.handleOpenUserWorkflow}
                                            handleOpenDashboardPanel={this.handleOpenDashboardPanel}
                                            handleMuteNotification={this.handleMuteNotification.bind(this)}
                                            handleOpenAssignActivity={this.handleOpenAssignActivity.bind(this)}
                                            openClientDetail={this.openClientDetail.bind(this)}
                                            notifications={this.props.notifications}
                                            networkActivities={this.props.networkActivities}
                                            organizationsObj={this.props.organizationsObj}
                                        />
                                        : !loading ?
                                        <div data-testid="no-group-members-found" className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found" style={{ height: '100%' }}>
                                            <h4>{this.props.membersLoading ? "Fetching Clients" : "Clients Not Found"}</h4>
                                        </div>
                                        : 
                                        <div data-testid="no-group-members-found" className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found" style={{ height: '100%' }}>
                                            <h4>Fetching Members...</h4>
                                        </div>
                                    }
                                {/* </div> */}
                                </List>
                      
                            </CustomScrollbars>
                        </div>
                        { (this.state.openForm || groupPokeFormLoading) ?
                            <ActivityConfirmForm
                                open={this.state.openForm || groupPokeFormLoading}
                                handleClose={this.handlePokeForm.bind(this)}
                                loading={groupPokeFormLoading}
                                pokeForm={groupPokeForm}
                                selectedGroup={this.props.selectedGroup}
                                isPokeForGroup={true}
                                formId={pokeFormId}
                                title={this.props.pokeForm && this.props.pokeForm.formName ? this.props.pokeForm.formName : null}
                                description={this.props.pokeForm && this.props.pokeForm.description ? this.props.pokeForm.description : null}
                            />
                            : <></>
                        }
                        {openOrgFilter ?
                            <ActivitiesFilterPopup
                                title={"Clients Filter"}
                                showFilter={openOrgFilter}
                                handleCloseFilter={this.handleOpenFilter.bind(this)}
                                panel="client"
                            />
                            : <></>
                        }
                    </React.Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ GroupList, chatAppReducer, Contacts, PokeForm, authUser, clientsList, settings, activitiesList}) => {
    let { groups, selectedGroup, loading, groupMembers, groupMembersDetails, membersLoading } = GroupList
    const {  selectedChatUsers } = chatAppReducer;
    const { pokeForm, groupPokeForm, groupPokeFormLoading } = PokeForm
    let pokeFormLoading = PokeForm.loading
    const {pokeFormId, notifications} = authUser

    const {clientPanelOrgFiltered, organizationsObj, muteNotificationLoading} = clientsList
    const {contactList} = Contacts;
    const {activeTheme} = settings;
    const { realtimeUpdate, networkActivities } = activitiesList;
    return { groupPokeForm, groupPokeFormLoading, selectedGroup, loading, groupMembers, groupMembersDetails, selectedChatUsers, pokeFormLoading, pokeForm, pokeFormId, clientPanelOrgFiltered, contactList, notifications, activeTheme, groups, realtimeUpdate, networkActivities, organizationsObj, membersLoading, muteNotificationLoading };
};

export default withRouter(connect(mapStateToProps, {
    changeRole,
    addSelectedChatUser,
    getGroupPokeForm,
    setsavePokeForGroup,
    clearUserOrgFilter,
    networkActivity,
    showFullChatPanelForSelectedUser,
    setSelectedMemberId,
    getClientUnconfirmedAchievementsList,
    showActivityHistoryDetail,
    clearHistoryData,
    handleNoticesPanel,
    handleUserWorkflowPanel,
    handleShowClientDashboard,
    postClientMuteNotification,
    showAssignActivityIFramePanel,
    setUserSelected,
    handleHideActivityDetailsPanel,
    showClientPanel,
    getGroupList,
    getAllGroupMembers,
    groupMembersRealtimeListenerOff,
    handleShowFallbackScreen
})(ClientsList));
;
