/**
 * Chat App Reducers
 */
import update from 'react-addons-update';

// actions types
import {
    CHAT_WITH_SELECTED_CLIENT,
    SEND_MESSAGE_TO_CLIENT,
    UPDATE_CLIENTS_SEARCH,
    GET_RECENT_CHAT_CLIENTS,
    SEARCH_CLIENTS,
    GET_CLIENTS_LIST,
    GET_SUCCESS_CLIENTS_LIST,
    SHOW_LOADING_INDICATOR_CLIENT_LIST,
    HIDE_LOADING_INDICATOR_CLIENT_LIST,
    SHOW_CLIENT_PANEL,
    MUTE_CLIENT_NOTIFICATIONS,
    MUTE_CLIENT_NOTIFICATIONS_SUCCESS,
    MUTE_CLIENT_NOTIFICATIONS_FAILED,
    GET_CLIENT_MUTE_NOTIFICATION,
    GET_CLIENT_MUTE_NOTIFICATION_SUCCESS,
    GET_CLIENT_DETAIL_BY_ID,
    GET_SUCCESS_CLIENT_DETAIL_BY_ID,
    HANDLE_SHOW_CLIENT_DASHBOARD,
    GET_CLIENT_NETWORK_ACTIVITY,
    GET_CLIENT_NETWORK_ACTIVITY_SUCCESS,
    GET_CLIENT_NETWORK_ACTIVITY_FAILED,
    CLEAR_CLIENT_NETWORK_ACTIVITIES_DATA,
    FETCH_USER_ORGANIZATIONS,
    FETCH_USER_ORGANIZATIONS_SUCCESS,
    APPLY_ORGANIZATIONS_FILTER_AT_CLIENT,
    APPLY_ORGANIZATIONS_FILTER_AT_ACTIVITIES,
    APPLY_ORGANIZATIONS_FILTER_AT_COURSES,
    APPLY_ORGANIZATIONS_FILTER_AT_RESOURCES,
    CLEAR_ORGANIZATIONS_FILTER_FROM_CLIENT,
    CLEAR_ORGANIZATIONS_FILTER_FROM_ACTIVITIES,
    CLEAR_ORGANIZATIONS_FILTER_FROM_COURSES,
    CLEAR_ORGANIZATIONS_FILTER_FROM_RESOURCES,
    CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW,
    APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW,
    APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW_SUMMARY,
    CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW_SUMMARY
} from '../actions/types';

// chat users
// import recentClients from 'Assets/data/chat-app/users';

const INITIAL_STATE = {
    allClients: null,
    recentClients: null,
    allRecentClients: null,
    selectedClient: null,
    searchClients: '',
    loading: false,
    clientPanel: false,
    muteNotifications: true,
    clientUserDetail: null,
    showClientDashBaord: false,
    selectedUserId: null,
    clientNetworkActivities:null,
    clientNetworkActivitiesLoading: false,
    organizations: null,
    orgFilterOptions: null,
    clientPanelOrgFiltered: null, 
    activitiesPanelOrgFiltered: null, 
    coursePanelOrgFiltered: null, 
    resourcePanelOrgFiltered: null,
    workflowPanelOrgFiltered: null,
    workflowSummaryPanelOrgFiltered: null,
    organizationsObj: {},
    muteNotificationLoading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        // get recent chat user
        case GET_RECENT_CHAT_CLIENTS:
            return { ...state, recentClients: null };
        // get recent chat user
        case GET_CLIENTS_LIST:
            return { ...state, loading: true };
        case SHOW_CLIENT_PANEL:
            return { ...state, clientPanel:action.payload};
        // get recent chat user
        case GET_SUCCESS_CLIENTS_LIST:
            return { ...state, allClients: action.payload, recentClients: action.payload, allRecentClients: action.payload, loading: false };
        case HANDLE_SHOW_CLIENT_DASHBOARD:
            return { ...state, selectedUserId: action.payload.userId, showClientDashBaord: action.payload.show}; 
            // chat with selected user
        case CHAT_WITH_SELECTED_CLIENT:
            let indexOfSelectedClient;
            indexOfSelectedClient = state.recentClients.indexOf(action.payload);
            return update(state, {
                selectedClient: { $set: action.payload },
                recentClients: {
                    [indexOfSelectedClient]: {
                        isSelectedChat: { $set: true },
                        new_message_count: { $set: 0 }
                    }
                }
            });

        // send message to user
        case SEND_MESSAGE_TO_CLIENT:
            let adminReplyData = {
                isAdmin: action.payload.isAdmin,
                message: action.payload.message,
                sent: action.payload.time
            };
            let pos = state.selectedClient.previousChats.length;
            return update(state, {
                selectedClient: { previousChats: { $splice: [[pos, 0, adminReplyData]] } }
            })

        // update search
        case UPDATE_CLIENTS_SEARCH:
            return { ...state, searchClients: action.payload };

        // search user
        case SEARCH_CLIENTS:
            if (action.payload === '') {
                return { ...state, recentClients: state.allClients};
            } else {
                const searchClients = state.allRecentClients.filter((user) =>
                    user.first_name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
                return { ...state, recentClients: searchClients }
            }
        // show loading 
        case SHOW_LOADING_INDICATOR_CLIENT_LIST:
            return { ...state, loading: true };
        // hide loading 
        case HIDE_LOADING_INDICATOR_CLIENT_LIST:
            return { ...state, loading: false };
        
        case MUTE_CLIENT_NOTIFICATIONS:
            return { ...state, muteNotificationLoading: true };
        case MUTE_CLIENT_NOTIFICATIONS_SUCCESS:
            return { ...state, muteNotificationLoading: false, muteNotifications: action.payload.enabled };
        case MUTE_CLIENT_NOTIFICATIONS_FAILED:
            return { ...state, muteNotificationLoading: false };
        case GET_CLIENT_MUTE_NOTIFICATION:
            return { ...state, loading: true };
        case GET_CLIENT_MUTE_NOTIFICATION_SUCCESS: 
            const {enabled} = action.payload
            return { ...state, loading: false, muteNotifications: enabled };
        case GET_CLIENT_DETAIL_BY_ID:
            return { ...state, loading: true, clientUserDetail: null};
        case GET_SUCCESS_CLIENT_DETAIL_BY_ID:
            return { ...state, loading: false, clientUserDetail: action.payload };
        case GET_CLIENT_NETWORK_ACTIVITY:
            return { ...state, clientNetworkActivitiesLoading: true, clientNetworkActivities: null};
        case GET_CLIENT_NETWORK_ACTIVITY_SUCCESS:
            return { ...state, clientNetworkActivitiesLoading: false, clientNetworkActivities: action.payload };
        case GET_CLIENT_NETWORK_ACTIVITY_FAILED:
            return { ...state, clientNetworkActivitiesLoading: false};
        case CLEAR_CLIENT_NETWORK_ACTIVITIES_DATA:
            return { ...state, clientNetworkActivities: null};
        case FETCH_USER_ORGANIZATIONS:
            return { ...state, loading: true};
        case FETCH_USER_ORGANIZATIONS_SUCCESS:
            const organizationsObj = {};
            if(action.payload) {
                for(const org of action.payload) {
                    organizationsObj[org.id] = org;
                }
            }
            return { ...state, loading: false, organizations: action.payload, organizationsObj};
        case APPLY_ORGANIZATIONS_FILTER_AT_CLIENT:
            return { ...state, clientPanelOrgFiltered: action.payload};
        case CLEAR_ORGANIZATIONS_FILTER_FROM_CLIENT:
            return { ...state, clientPanelOrgFiltered: null}
        case APPLY_ORGANIZATIONS_FILTER_AT_ACTIVITIES:
            return { ...state, activitiesPanelOrgFiltered: action.payload};
        case CLEAR_ORGANIZATIONS_FILTER_FROM_ACTIVITIES:
            return { ...state, activitiesPanelOrgFiltered: null}
        case APPLY_ORGANIZATIONS_FILTER_AT_COURSES:
            return { ...state, coursePanelOrgFiltered: action.payload};
        case CLEAR_ORGANIZATIONS_FILTER_FROM_COURSES:
            return { ...state, coursePanelOrgFiltered: null}
        case APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW:
            return { ...state, workflowPanelOrgFiltered: action.payload};
        case CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW:
            return {...state, workflowPanelOrgFiltered: null}
        case APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW_SUMMARY:
            return { ...state, workflowSummaryPanelOrgFiltered: action.payload};
        case CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW_SUMMARY:
            return { ...state, workflowSummaryPanelOrgFiltered: null};
        case APPLY_ORGANIZATIONS_FILTER_AT_RESOURCES:
            return { ...state, resourcePanelOrgFiltered: action.payload};
        case CLEAR_ORGANIZATIONS_FILTER_FROM_RESOURCES:
            return { ...state, resourcePanelOrgFiltered: null}
        default: return { ...state };
    }
}
