
import {
    SHOW_PRIVATE_ACTIVITY_LOADING,
    HIDE_PRIVATE_ACTIVITY_LOADING,
    CLEAR_ACTIVITY_DATA,
    REMOVE_API_ERROR_MESSAGE,
    SHOW_ACTIVITY_CRETAED_MESSAGE,
    OPEN_EDITOR,
    SELECT_ACTIVTY,
    HANDEL_EXECUTE_ACTIVITY_CONFIRMATION,
    SET_ACTIVITY_FILTER,
    SET_CLIENT_ACTIVITY_FILTER
 } from './types';


export const showActivityLoading = () => (dispatch) =>{
    dispatch({type: SHOW_PRIVATE_ACTIVITY_LOADING})
}

export const hideActivityLoading = () => (dispatch) =>{
    dispatch({type: HIDE_PRIVATE_ACTIVITY_LOADING})
}


export const clearActivityData = () => (dispatch) => {
    dispatch({type: CLEAR_ACTIVITY_DATA})
}

export const removeAPIErrorMessage = () => (dispatch) => {
    dispatch({type: REMOVE_API_ERROR_MESSAGE})
}

export const showActivitySuccess = (message) => (dispatch) => {
    dispatch({type: SHOW_ACTIVITY_CRETAED_MESSAGE, payload: message})
}

export const handlEditor=(value)=>(dispatch)=>{
    dispatch({type:OPEN_EDITOR,payload: value})
}

export const selectActivity=(value)=>(dispatch)=>{
    dispatch({type:SELECT_ACTIVTY,payload: value})
}

export const handleActivityCompletionConfirmation = (value)=>(dispatch)=>{
    dispatch({type:HANDEL_EXECUTE_ACTIVITY_CONFIRMATION , payload: value})
}

export const setActivitiesFilter = (filterOptions)=>(dispatch)=>{
    dispatch({type:SET_ACTIVITY_FILTER , payload: filterOptions})
}

export const setClientActivitiesFilter = (filterOptions)=>(dispatch)=>{
    dispatch({type:SET_CLIENT_ACTIVITY_FILTER , payload: filterOptions})
}