/**
 * Chat Sidebar
 */
import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import withRouter from '../../../router';

// components
import ActivityHistoryList from './ActivityHistoryList';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import LegendComponent from './LegendComponent';
import RctCollapsibleCard from '../../RctCollapsibleCard/RctCollapsibleCard';

//Actions
import { getHistoryList, clearHistoryData, getClientUnconfirmedAchievementsList , setHistoryListPageCount} from 'Actions'

class ActivityHistoryListPanel extends Component {
	constructor(props) {
		super(props)
		this.interval = null;
		this.state = {
			showLegend: false
		}
	}
	handleloadmore = () => {
		const { showUnconfirmedAcheivemts, selectedActivity , fullScreen} = this.props;
		let {pageCount} = this.props;
		//this.props.getHistoryList(this.props.selectedMemberId, this.props.selectedActivity.id, this.props.pageCount)
		const userId = localStorage.getItem("uid");
		//const userId = this.props.selectedMemberId
		//console.log("selectedActivity ------->", this.props.selectedActivity)
		const activityConfirmation = localStorage.getItem("activityConfirmation");
		if(activityConfirmation && pageCount === 1 && fullScreen) {
			pageCount = 2
			this.props.setHistoryListPageCount(pageCount)
		}
		if (showUnconfirmedAcheivemts) {
			this.props.getClientUnconfirmedAchievementsList(userId, pageCount)
		} else {
			this.props.getHistoryList(userId, selectedActivity.id, pageCount);
		}
	}
	componentWillUnmount() {
		//this.interval = null
		//this.props.clearHistoryData()
	}
	handleLengendInfo() {
		this.setState({ showLegend: !this.state.showLegend })
	}
	handleOpenActivityDetails() {
		const {fullScreen} = this.props;
		console.log("/app/lsdashboard/activities/history/details", !fullScreen)
		if(!fullScreen) {
			this.props.navigate('/app/lsdashboard/activities/history/details');
		} 
	}

	render() {
		const { showUnconfirmedAcheivemts, displayName, selectedActivity, mobileView, loading, fullScreen } = this.props;
		//console.log(" showUnconfirmedAcheivemts ---->", showUnconfirmedAcheivemts);
		let titleText = "";
		let subText = "";
		if (showUnconfirmedAcheivemts) {
			titleText = "Unconfirmed Activities";
		} else if (selectedActivity) {
			titleText = selectedActivity.title;
			subText = "Activity History";
		}
		const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
		return (
			<RctCollapsibleCard
				colClasses=""
				heading={<div className="d-flex align-items-center justify-content-between text-white">
							<div className="d-flex align-items-center">
								<IconButton title="Back To Home" onClick={() => this.props.onCloseActivitiesHistoryPanel()}><ArrowBackIcon /></IconButton>
							</div>
							<div className="mx-3" data-testid="history-client-title">{displayName}</div>
							<IconButton title="Legend Icons" className='text-white' onClick={() => this.handleLengendInfo()}><InfoOutlinedIcon /></IconButton>
						</div>
						}
				fullBlock
				customClasses="overflow-hidden theme-background activities-history-list-panel"
				headingCustomClasses="bg-lifesherpa "
			>
				<div className="px-3 py-2 list-card sub-header-list-card screen-content-background-color">
					<div className="align-items-center">
						<div className=" mt-1">
							<h3 className="mb-0 heading-text">{titleText}</h3>
							{subText && <p className="mb-0 sub-text">{subText}</p>}
						</div>
					</div>
				</div>
				<div className={`activity-history-list ${showUnconfirmedAcheivemts ? "activity-history-list-full-screen" :""}`} id="scrollable1">
					{!this.props.allActivitiesHistory && <LifeSherpaLoading loading={loading} />}

					{<InfiniteScroll
						dataLength={this.props.allActivitiesHistory && this.props.allActivitiesHistory.length}
						next={() => this.handleloadmore()}
						hasMore={this.props.hasMoreData}
						style={{ overflow: "none" }}
						scrollableTarget='scrollable1'
					>
						<ActivityHistoryList
							handleOpenActivityDetails={this.handleOpenActivityDetails.bind(this)}
						/>
						<LifeSherpaLoading loading={this.props.allActivitiesHistory && this.props.allActivitiesHistory.length > 0 && loading} onlyLoading={true}/>
					</InfiniteScroll>}
				</div>

				<LegendComponent showLegend={this.state.showLegend} handleClose={this.handleLengendInfo.bind(this)} popupThemeClass={this.props.popupThemeClass} />

			</RctCollapsibleCard>
		);
	}
}

const mapStateToProps = ({ activitiesList, activitiesHistoryList, chatAppReducer, settings }) => {
	const { selectedActivity } = activitiesList
	const { allActivitiesHistory, loading, hasMoreData, pageCount, showUnconfirmedAcheivemts } = activitiesHistoryList
	const { selectedMemberId } = chatAppReducer;
	const { popupThemeClass } = settings;
	return { selectedActivity, loading, selectedMemberId, allActivitiesHistory, hasMoreData, pageCount, showUnconfirmedAcheivemts, popupThemeClass };
};

export default withRouter(connect(mapStateToProps, {
	getHistoryList,
	clearHistoryData,
	getClientUnconfirmedAchievementsList,
	setHistoryListPageCount
})(ActivityHistoryListPanel));
