/**
 * LandingPageAllActivity
 */
import React from "react";

import { connect } from 'react-redux';
import LifeSherpaLoading from '../LifeSherpaLoading';
// actions
import {    
    getActivitiesList, 
    handleListStyle,
    removeFilteredActivitiesList,
    getFilteredActivitiesList,
    setActivitiesFilter,
    getActivityGroupsList,
    getActivitiesCalendar
} from '../../actions';

// components
import LandingPageAllList from './LandingPageAllList';
import "../../assets/scss/custom/app.scss"
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close';
import ActivitiesFilterPopup from "../Widgets/ActivitiesFilterPopup";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// import 'firebase/auth';
// import firebase from 'firebase/app';
import {IconButton} from '@mui/material';


import AddActivity from "./AddActivity";
import { isMobile } from "../../helpers/helpers";
import moment from 'moment'
import zIndex from "@mui/material/styles/zIndex";

const drawerWidth = 280;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 'auto',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '620px',
        overflowY: 'scroll'
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
        backgroundColor: 'transparent',
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
});

class LandingPageAllActivity extends React.Component {

    state = {
        open: false,
        selectedRoutine: null,
        select: "activity",
        search: '',
        filter: '',
        activityFilter: [],
        update: '',
        loading: true,
        filterOptions: [],
        showFilter: false,
        applyedFilterOptions: [],
        applyFiltersOnActivities: false,
    };

    componentDidMount() {
        // console.log("%c[AllActivities]%c Rendered all activities panel","color:black","color:green");
        const auth_uid = localStorage.getItem('auth_uid');
		const idToken = localStorage.getItem('idToken');
        const orgId = localStorage.getItem("orgId");
        const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : null
        const {appliedActivityFilter} = this.props
        console.log("applied activity filter: ", appliedActivityFilter)
		if (idToken && auth_uid) {
            localStorage.setItem('uid', auth_uid);
            localStorage.setItem("selectedOrgId", orgId)
            // this.props.clearUserOrgFilter({panel:'activities'});
            if(appliedActivityFilter) {
                this.props.getFilteredActivitiesList(auth_uid, appliedActivityFilter, null, null)
            } else {
                this.props.getActivitiesList(auth_uid, '', true)
            }
			this.props.getActivityGroupsList(auth_uid, orgIds);
            this.props.getActivitiesCalendar(auth_uid);
		}
        
    }

    clearFilter = () => {
        this.setState({ ...this.state, 
            showFilter: false, 
            applyFiltersOnActivities: false, 
            filterOptions: [], 
            applyedFilterOptions: [], 
            showInactiveActivities: false  
        }, () => {
            this.props.setActivitiesFilter()
            this.props.removeFilteredActivitiesList();
        });
    }

    applyFilter = (orgIds) => {
        let { filterOptions } = this.state;
        let selectedOrgIds = orgIds
        // const {activityGroups} = this.props
        const auth_uid = localStorage.getItem('auth_uid');
        // console.log("activity filter in state: ", filterOptions)
        if(filterOptions.length > 0 || (orgIds && orgIds.length > 0)) {
            this.setState({ ...this.state, 
                showFilter: false, 
                applyFiltersOnActivities: true, 
                applyedFilterOptions: [...filterOptions], 
                showInactiveActivities: false
            }, () => {
                this.props.setActivitiesFilter(filterOptions)
                this.props.getFilteredActivitiesList(auth_uid, filterOptions, null, selectedOrgIds);
            });
        } else {
            this.setState({ ...this.state, 
                showFilter: false, 
                applyFiltersOnActivities: false, 
                filterOptions: [], 
                applyedFilterOptions: [], 
                showInactiveActivities: false 
            }, () => {
                this.props.setActivitiesFilter()
                this.props.removeFilteredActivitiesList();
            });
        }
    }

    handleCloseFilter = () => {
        this.setState({ ...this.state, showFilter: false });
    }

    handelShowFilter = () => {
        let { applyedFilterOptions } = this.state;
        const {appliedActivityFilter} = this.props
        this.setState({ ...this.state, showFilter: true, filterOptions: [...appliedActivityFilter||[]] });
    }
   
    handelFilterOpitonsChange = (id) => {
        let tempState = { ...this.state };
        let filterObject  = null
        const {activityGroups} = this.props
    
        const index = activityGroups.findIndex(ele => ele.id === id)
        const selectedActivityGroup = activityGroups[index]
        if (selectedActivityGroup.name === "Private Activities") {
            filterObject = { activityGroupId: selectedActivityGroup.id, organization: "Private Activities" }
        } else {
            filterObject = { activityGroupId: selectedActivityGroup.id, organization: selectedActivityGroup.orgId }
        }

        let indexOfGroupInState = tempState.filterOptions.findIndex(group => group.activityGroupId === id);
        if (indexOfGroupInState >= 0) {
            tempState.filterOptions.splice(indexOfGroupInState, 1);
        } else {
            tempState.filterOptions.push(filterObject);
        }
        
        this.setState(tempState);
    }


    handelSearch = (e) => {
       // // console.log(e);
        this.setState({...this.state,search:e.target.value});
    }

    handelClearSearch = () => {
        this.setState({...this.state,search:""});
    }

    getScrollHeight() {
        if (localStorage.getItem("widgetApp")) {
            return 'calc(100vh - 160px)';
        } else if(this.props.isSidebarEnable) {
            return "calc(100vh - 221px)";
        } else {
            return '540px';
        }
        
    }

    handleOpenObjectEditor = (type) => {
		console.log("add icon clicked -->", type);
        this.clearFilter();
		this.setState({openEditor: true, activityId: null, editorType:type})
	}

	handleCloseObjectEditor = () => {
        const {appliedActivityFilter} = this.props
        const auth_uid = localStorage.getItem('auth_uid');
		this.setState({openEditor: false, activityId: null, editorType:null}, () => {
            appliedActivityFilter ? this.props.getFilteredActivitiesList(auth_uid, appliedActivityFilter, null, null) : this.props.getActivitiesList(auth_uid, '');
        })
	}

    handleListType = (type) => {
        this.props.handleListStyle(type);
    }

    visibleLoading = () => {
        const { allActivities, summaryLoading, loading, listStyle, activitiesPanelOrgFiltered, activitiesLoading } = this.props;
        let {applyFiltersOnActivities} = this.state;
        if(summaryLoading && listStyle == 'summary') {
            return true;
        }
        if(activitiesLoading) {
            return true;
        }
        if(loading) {
            if(applyFiltersOnActivities || (activitiesPanelOrgFiltered && activitiesPanelOrgFiltered.length > 0)) {
                return true;
            }
            if(!allActivities || !allActivities.length) {
                return true;
            }
        }
        return false;
    }

    getFilterActivitiesList = (list) => {
        const {filteredActivities} = this.props;
        let filteredList = [];
        if(filteredActivities && filteredActivities.length > 0 && list && list.length > 0) {
            filteredList =  list.filter(activity => filteredActivities.findIndex(routine => routine.id === activity.id) >= 0);
        } else {
            filteredList = list ? [...list] : [];
        }
        return [...filteredList];
    }

    applySearchFilter = (list) => {
        const {search} = this.state;
        if(search && search.length > 2 && list && list.length > 0) {
            return list.filter((li) => (li.title && li.title.toLowerCase().includes(search.toLowerCase())) || (li.detailedDescription && li.detailedDescription.toLowerCase().includes(search.toLowerCase())))
        }
        return list;
    }


    render() {

        const {  activityGroups, activitiesPanelOrgFiltered, allActivities } = this.props;
        let {search, showFilter, filterOptions, applyFiltersOnActivities, openEditor, activityId, editorType} = this.state;
        let activitiesList = this.getFilterActivitiesList(allActivities);
        activitiesList =  this.applySearchFilter(activitiesList);

        activitiesList && activitiesList.map((act, index) => {
            if (act.state === 'paused' || act.state === 'inProgress') {
                activitiesList.splice(index, 1)
                activitiesList.unshift(act)
            }
        });

        return (
            <div data-testid="my-activities-panel">
                <div className="rct-mail-wrapper">
                    <div >
                        <div className="d-flex p-3 list-card align-items-center">
                            {
                                this.props.listStyle === 'summary' ? 
                                    <div className="d-flex align-items-center">
                                        <h4>{moment().format('dddd, MMMM DD, YYYY')}</h4>
                                    </div>
                                : ""
                            }
                           
                            { this.props.listStyle !== 'summary' ? 
                                <div className={`d-flex ${(applyFiltersOnActivities || activitiesPanelOrgFiltered && activitiesPanelOrgFiltered.length > 0) ? "activity-filter-icon" : "activity-filter-icon-applied"}`}>
                                    <div>
                                        <IconButton data-testid="activities-filter-icon-button" disabled={this.props.listStyle == 'summary' || this.props.listStyle === 'workflows'}   title="Filter By Activity Group" className="text-white rounded-circle mr-2" style={{background:'#134266'}} onClick={()=>this.handelShowFilter()}>
                                            {(this.props.listStyle == 'summary' || this.props.listStyle === 'workflows') ? <FilterAltOffIcon data-testid="activities-filter-disabled" className="filter-icon" fontSize="large"/> : <FilterAltIcon data-testid="activities-filter-enabled" className="filter-icon" fontSize="large"/>}
                                        </IconButton>
                                    </div>
                                </div>
                                : <></>
                             }
                            {this.props.listStyle !== 'summary' ?
                                <div className="mx-2 search-activity-field">
                                    <form noValidate autoComplete="off">
                                        <TextField data-testid={this.props.listStyle === 'summary' ? "my-activity-search-text-field-disabled" : "my-activity-search-text-field"} id="standard-search" 
                                            onChange={(e) => this.handelSearch(e)}
                                            label={this.props.listStyle === 'workflows' ? "Search Workflow" :  "Search Activity"}
                                            type="search" 
                                            value={search} 
                                            disabled={this.props.listStyle == 'summary'}
                                            InputProps={{
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon color="primary"/>
                                            </InputAdornment>
                                            ),
                                            endAdornment: ( search && search.length > 0 ?
                                                <InputAdornment position="end">
                                                <CloseIcon color="primary" onClick={() => this.handelClearSearch()} style={{cursor:"pointer"}}/>
                                                </InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
                                            )
                                            }}
                                            variant="standard" 
                                        />
                                    </form>
                                </div>
                                : <></>
                            } 
                            <div className="d-flex ml-auto align-items-center justify-content-center ls-btn-tab-container">
                                <div data-testid="common-activity-tab" className={this.props.listStyle !='summary' && this.props.listStyle !='workflows' ? 'selected-btn' : 'unselected-btn'}>
                                    <IconButton data-testid="common-activity-tab-btn" title="Common Activities" onClick={()=>this.handleListType('common')}> 
                                        <ViewAgendaOutlinedIcon/>
                                    </IconButton>
                                </div>
                                <div data-testid="summary-activity-tab" className={this.props.listStyle == 'summary' ? 'selected-btn' : 'unselected-btn'}>
                                    <IconButton data-testid="summary-activity-tab-btn" title="Activities Summary"  onClick={()=>this.handleListType('summary')}>
                                        <CalendarTodayIcon/>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <LifeSherpaLoading customClasses={isMobile() ? "home-panel-spinner-container-height" : ""} loading={this.visibleLoading()} zindex={2}/>

                        
                        {showFilter &&
                            <ActivitiesFilterPopup
                                activityGroups={activityGroups}
                                showFilter={showFilter}
                                filterOptions={filterOptions && filterOptions.length > 0 ? filterOptions.map(group => {return group.activityGroupId}) : []}
                                clearFilter={this.clearFilter}
                                applyFilter={this.applyFilter}
                                handleCloseFilter={this.handleCloseFilter}
                                handelFilterOpitonsChange={this.handelFilterOpitonsChange}
                                panel="activities"
                            />
                        }
                        <LandingPageAllList
                            listStyle={this.props.listStyle}
                            activitiesList={activitiesList}
                            summaryLoading={this.props.summaryLoading}
                            activitiesLoading={this.props.activitiesLoading}
                            clearFilter={this.clearFilter}
                        />

                    </div>
                </div>
            
                { (this.props.listStyle !== 'workflows' && this.props.listStyle !== 'summary') ?  
                    <AddActivity
                        handleOpenObjectEditor={this.handleOpenObjectEditor}
                        handleCloseObjectEditor={this.handleCloseObjectEditor}
                        openEditor={openEditor}
                        editorType={editorType}
                        activityId={activityId}  
                    />
                    : <></>
                }
            </div>
        )
    }
}

// map state to props
const mapStateToProps = ({ activitiesList, GroupList, ActivityReducer, clientsList}) => {
    const { allActivities, loading, listStyle, activityGroups, filteredActivities, summaryLoading, realtimeUpdate, activitiesLoading } = activitiesList
    const { appliedActivityFilter } = ActivityReducer
    const { selectedGroup } = GroupList
    const {activitiesPanelOrgFiltered} = clientsList
    return {  listStyle, allActivities, loading, selectedGroup, activityGroups, filteredActivities, summaryLoading, activitiesPanelOrgFiltered, realtimeUpdate, appliedActivityFilter, activitiesLoading};
}

export default connect(mapStateToProps, {
    getActivitiesList,
    handleListStyle,
    removeFilteredActivitiesList,
    getFilteredActivitiesList,
    setActivitiesFilter,
    getActivityGroupsList,
    getActivitiesCalendar
})(LandingPageAllActivity);