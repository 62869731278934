import React, {Component} from "react";

import { Scrollbars } from 'react-custom-scrollbars';

class CustomScrollbars extends Component {

    render() {
        const { children, className, autoHide = true, style = {}, hideTracksWhenNotNeeded = false, id='' } = this.props
        if(hideTracksWhenNotNeeded) {
            return (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )
        }
        return (
            <Scrollbars
                className={className}
                autoHide={autoHide}
                style={style}
                id={id}
            >
                {children}
            </Scrollbars>
        );
    }
};


export default CustomScrollbars;

