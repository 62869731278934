import {
    GET_HELP_CONTACTS,
    GET_HELP_CONTACTS_SUCCESS,
    GET_VIDEO_GROUPS,
    GET_VIDEO_GROUPS_SUCCESS,
    SELECT_VIDEO_GROUPS,
    SELECTED_VIDEO_GROUPS,
    GET_CHAT_TOKEN,
    GET_VIDEO_TOKEN, GET_CHANNEL_INFO, GET_CHAT_LOADING,
    HIDE_CHAT_LOADING,
    GET_HELP_CONTACTS_FAILED,
    UPDATE_CONTACTS_LIST,
    SET_TWILIO_CLIENT,
    SHOW_VIDEO_ROOM,
    HIDE_VIDEO_ROOM,
    GET_VIDEO_CALL_DETAILS_SUCCESS,
    SHOW_VIDEO_CALL_CONFIRMATION,
    HIDE_VIDEO_CALL_CONFIRMATION,
    CONNECT_TWILIO_VIDEO_CALL,
    CONNECT_TWILIO_VIDEO_CALL_SUCCESS,
    GET_VIDEO_TOKEN_LOADING,
    HIDE_VIDEO_TOKEN_LOADING,
    GET_SELECTED_VIDEO_CAHT,
    SHOW_MISSED_CALL_CONFIRMATION,
    HIDE_MISSED_CALL_CONFIRMATION,
    ADD_NEW_VIDEO_GROUP_DETAILS,
    ADD_NEW_VIDEO_GROUP_DETAILS_SUCCESS,
    ADD_NEW_VIDEO_GROUP_DETAILS_FAILED,
    SET_SELECTED_GROUP_VIDEO_CALL_DETAILS,
    GET_VIDEO_GROUPS_FAILED,
    RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST,
    GET_CLIENT_CONTACTS,
    GET_CLIENT_CONTACTS_SUCCESS,
    GET_CLIENT_CONTACTS_FAILED,
    SHOW_CONTACT_CHAT_PAEG,
    UPDATE_CONTACTS_DETAILS
} from '../actions/types';

const INIT_STATE = {
    loading: false,
    contactList: [],
    videoGroupList: [],
    selectedVideoGroup: null,
    chatToken: null,
    videoToken: null,
    channel: null,
    twilioClient: null,
    selectedVideoChat: null,
    showVideoRoom: false,
    videoCallDetails: null,
    showVideoCallConfirmationPanel: false,
    videoCallMessage: null,
    videoConnectLoading: false,
    videoTokenLoading: false,
    showMissedCallConfirmationPanel: false,
    selectedGroupDetails: null,
    groupDetailsLoading: false,
    clientContactsLoading: false,
    clientContacts: null,
    showContactChatScreen: false,
    contactsObj: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CHAT_LOADING:
            return { ...state, loading: true };
        case HIDE_CHAT_LOADING:
            return { ...state, loading: false };
        case GET_HELP_CONTACTS:
            return { ...state, loading: true };
        case GET_HELP_CONTACTS_SUCCESS:
            return { ...state, contactList: action.payload, loading: false };
        case UPDATE_CONTACTS_LIST:
            return { ...state, contactList: action.payload };
        case UPDATE_CONTACTS_DETAILS:
            return { ...state, contactsObj: action.payload };
        case GET_HELP_CONTACTS_FAILED:
            return { ...state, loading: false };
        case GET_VIDEO_GROUPS:
            const {initLoad = false} = action.payload || {};
            let loading = true;
            if(initLoad) {
                if(state.videoGroupList && state.videoGroupList.length) {
                    loading = false;
                } 
            }
            return { ...state, loading };
        case GET_VIDEO_GROUPS_SUCCESS:
            return { ...state, videoGroupList: action.payload, loading: false };
        case GET_VIDEO_GROUPS_FAILED:
            return { ...state, loading: false };
        case SELECT_VIDEO_GROUPS:
            return { ...state, loading: true };
        case SELECTED_VIDEO_GROUPS:
            return { ...state, selectedVideoGroup: action.payload, loading: false };
        case GET_CHAT_TOKEN:
            return { ...state, chatToken: action.payload }
        case GET_VIDEO_TOKEN:
            return { ...state, videoToken: action.payload, videoTokenLoading: false }
        case GET_CHANNEL_INFO:
            return { ...state, channel: action.payload }
        case SET_TWILIO_CLIENT:
            return { ...state, twilioClient: action.payload }
        case SHOW_VIDEO_ROOM:
            return { ...state, selectedVideoChat: action.payload, showVideoRoom: true }
        case HIDE_VIDEO_ROOM:
            return { ...state, showVideoRoom: false, videoConnectLoading: false, showVideoCallConfirmationPanel: false, videoCallMessage: null, videoCallDetails: null }
        case GET_VIDEO_CALL_DETAILS_SUCCESS:
            return { ...state, videoCallDetails: action.payload, loading: false }
        case SHOW_VIDEO_CALL_CONFIRMATION:
            return { ...state, showVideoCallConfirmationPanel: true, videoCallMessage: action.payload }
        case HIDE_VIDEO_CALL_CONFIRMATION:
            return { ...state, showVideoCallConfirmationPanel: false }
        case CONNECT_TWILIO_VIDEO_CALL:
            return { ...state, videoConnectLoading: true }
        case CONNECT_TWILIO_VIDEO_CALL_SUCCESS:
            return { ...state, videoConnectLoading: false }
        case GET_VIDEO_TOKEN_LOADING:
            return { ...state, videoTokenLoading: true, videoToken: null }
        case HIDE_VIDEO_TOKEN_LOADING:
            return { ...state, videoTokenLoading: false }
        case GET_SELECTED_VIDEO_CAHT:
            return { ...state, selectedVideoChat: action.payload }
        case SHOW_MISSED_CALL_CONFIRMATION:
            return { ...state, showMissedCallConfirmationPanel: true, videoCallMessage: action.payload }
        case HIDE_MISSED_CALL_CONFIRMATION:
            return { ...state, showMissedCallConfirmationPanel: false }
        case ADD_NEW_VIDEO_GROUP_DETAILS:
            return { ...state, groupDetailsLoading: true }
        case ADD_NEW_VIDEO_GROUP_DETAILS_SUCCESS:
            return { ...state, groupDetailsLoading: false, selectedGroupDetails: action.payload }
        case ADD_NEW_VIDEO_GROUP_DETAILS_FAILED:
            return { ...state, groupDetailsLoading: false }
        case SET_SELECTED_GROUP_VIDEO_CALL_DETAILS:
            return { ...state, selectedGroupDetails: action.payload }
        case GET_CLIENT_CONTACTS:
            return { ...state, clientContactsLoading: true, clientContacts: null }
        case GET_CLIENT_CONTACTS_SUCCESS:
            return { ...state, clientContactsLoading: false, clientContacts: action.payload }
        case GET_CLIENT_CONTACTS_FAILED:
            return { ...state,clientContactsLoading: false}
        case RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST:
            return INIT_STATE
        case SHOW_CONTACT_CHAT_PAEG: 
            return { ...state, showContactChatScreen: action.payload}
        default:
            return { ...state };
    }
}