/**
 * Assign Activity Component
 */
import React, { Component } from 'react';
import  {AsyncAssingActivitiesIFrame} from '../AsyncComponent/AsyncComponent'
import { connect } from 'react-redux';
import { isMobile } from '../../helpers/helpers';
import {showAssignActivityIFramePanel, handleChangeBottomTab, handleShowFallbackScreen} from "../../actions";
import withRouter from '../../router';
import _ from 'lodash';

class AssignActivityComponent extends Component {

    handleCloseAssignActivity(){
        console.log("%c[AssignActivityiFrame]%c Closed assign activity iframe","color:black","color:red");
        console.groupEnd();
        this.props.showAssignActivityIFramePanel(null);
		const {clientPanel} = this.props;
        const prevUrl = localStorage.getItem('prevUrl');
        if(prevUrl) {
			localStorage.removeItem('prevUrl');
			this.props.navigate(prevUrl);
		} else {
			if (isMobile()) {
				this.props.handleChangeBottomTab({tabMode: "My Clients", selectedClientMenuItem: "/app/lsdashboard/client",  showFallbackScreen: true });
                this.triggerRenderPage('/app/lsdashboard/clients');
			} else {
				if(clientPanel) {
					this.props.navigate('/app/lsdashboard/client');
				} else {
					this.props.navigate('/app/lsdashboard');
				}
			}
		} 	
    }
    // Function to trigger page rendering with debounce
	triggerRenderPage = _.debounce((path) => {
		this.props.navigate(path);
		this.props.handleShowFallbackScreen(false);
	}, 500);
 
     render() {
        const {assignActivityUrl} = this.props;
        return (
        <div data-testid="open-assign-activity-component-iframe" className={`row ${isMobile() ? 'mobile-activity-container' : 'assign-activity-container-layout mb-4'}`}>
            <div data-testid="open-my-course-iframe" className="w-100 col-12">
            <AsyncAssingActivitiesIFrame handleCloseAssignActivity={this.handleCloseAssignActivity.bind(this)} assignActivityUrl={assignActivityUrl}/>
            </div>
        </div> 
        );
     }
 }


const mapStateToProps = ({ settings, clientsList }) => {
    const {  assingnActivityPanel } = settings;
    const { clientPanel } = clientsList;
    const {assignActivityUrl} = assingnActivityPanel || { assignActivityUrl: null};
    return { clientPanel, assignActivityUrl };
}

export default withRouter(connect(mapStateToProps,{showAssignActivityIFramePanel, handleChangeBottomTab, handleShowFallbackScreen})(AssignActivityComponent));

 