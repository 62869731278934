/**
 * Client Alerts
 */

 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 
 //Actions
 import {
     clearNoticesData,
     setSelectedNotice
 } from '../../actions'
 import NoticesList from '../../components/Notices/NoticesList'

 class NoticesListPage extends Component {
 
     constructor(props) {
         super(props)
         this.state = {
             role: null
         }
     }

     componentDidMount() {
        this.props.handleOpenURL('list');
        console.log("Notices List Rendered")
     }

     handleOpenNoticeForm = (notice) => {
        const {userId, idToken, orgId, theme, widget} = this.props.params;
        let redirctURL = `/widget/${widget}/form?userId=${userId}&orgId=${orgId}&theme=${theme}&formId=${notice.formId}&noticeId=${notice.noticeId}&idToken=${idToken}`;
        this.props.setSelectedNotice(notice);
        this.props.handleOpenURL('form', redirctURL);
    }

     render() {
         const {widgetApp, title, selectedUserId } = this.props;
         return (
             <div className='notices-list-page'>
                 {selectedUserId ? 
                    <NoticesList
                        handleOpenNoticeForm={this.handleOpenNoticeForm}
                        widgetApp={widgetApp}
                    />
                   :
                   <div className='d-flex align-items-center my-4 py-4 mx-auto justify-content-between'>
                        <div className='text-center w-100 mt-4 pt-4'>
                        </div>
                    </div>
                 } 
             </div>
         )
     }
 }
 
 const mapStateToProps = ({ authUser, noticesReducer }) => {
     const { themeLoading, userLoading } = authUser;
     const { selectedUserId } = noticesReducer;
     return { themeLoading, userLoading, selectedUserId }
 }
 
 export default connect(mapStateToProps, {
     clearNoticesData,
     setSelectedNotice
 })(NoticesListPage)
 