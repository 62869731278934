import React, { Component } from 'react';

import LifeSherpaLoading from '../../LifeSherpaLoading';

import { connect } from 'react-redux';
import {database} from '../../../firebase';
import { ref, onValue, off} from "firebase/database";

import {
    resetUserCourse
} from '../../../actions';

class CourseViewPage extends Component {

    state = {
        iframeLoading: true,
        progressListener: null,
        listenerOn: false,
    }

    componentDidMount() {
        const { userId, courseId} = this.props;
        const orgId = localStorage.getItem("orgId");
        let that = this;
        this.props.resetUserCourse(userId, courseId);
    
        const refValue = ref(database, `Realtime/${orgId}/Users/${userId}/lms/courses/${courseId}/status`)
        const listenerValue = onValue(refValue, (snapshot) => {
            const realtimeCourseStatus = snapshot.val();
            console.log("previous course completion status: ", that.props.course_completion_status)
            console.log("Real-time course completion status: " ,realtimeCourseStatus)
            that.props.handlePreviousCourseStatus(realtimeCourseStatus)
            if(realtimeCourseStatus === "completed" && that.props.course_completion_status === "not_completed") {
                that.props.handleClose()
            }
            
        })

        this.setState({listenerValue:listenerValue})
        
    }

    componentWillUnmount() {
        const { userId, courseId } = this.props;
        const orgId = localStorage.getItem("orgId");
        const removeListener = ref(database, `Realtime/${orgId}/Users/${userId}/lms/courses/${courseId}/status`)
        if (removeListener) {
            off(removeListener)
        }
    }

    render() {
        const { courseLoading, courseGotoURL, courseProgressLoading } = this.props;
        const { iframeLoading} = this.state;

        return (
            <div>
                <div className='row' >
                    <div className={`col-12`}>
                        <LifeSherpaLoading loading={courseLoading || iframeLoading || (courseProgressLoading)} />
                        <div>
                            {courseGotoURL && courseGotoURL.url ?
                                <div className='p-3 '>
                                    <iframe width="100%" src={courseGotoURL.url} onError={() => this.setState({ iframeLoading: false })} onLoad={() => this.setState({ iframeLoading: false })} loading={<LifeSherpaLoading />} />
                                </div>
                                : <div className='px-3 empty-container'>
                                </div>
                            }

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = ({ MyCourses }) => {
    const { courseLoading, courseProgress, courseGotoURL, showResetCourseAlert, courseProgressLoading } = MyCourses;
    return { courseLoading, courseProgress, courseGotoURL, showResetCourseAlert, courseProgressLoading };
}

export default connect(mapStateToProps,{
    resetUserCourse
})(CourseViewPage);
