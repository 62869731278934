import React, { Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import WidgetServices from "../../services/widgetServices";
import { Capacitor } from '@capacitor/core';

class LifeSherpaLoading extends Component {
    state = {}

    render() {
        const widgetServices = new WidgetServices();
        let { loading, fullLoader = false, onlyLoading = false, zindex, customClasses = "" } = this.props;
        if (loading) {
            if (widgetServices.isWidgetOnIOSApp() || Capacitor.getPlatform() == "ios") {
                return (
                    <div data-testid="lifesherpa-loading" style={zindex ? {zIndex: zindex}: {}} className={`d-flex  align-items-center ${customClasses} justify-content-center ${onlyLoading ? "" : fullLoader ? 'complete-loader-overlay' : 'loader-overlay'} `}>
                        <div className="spinner">
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                            <div className="bar3"></div>
                            <div className="bar4"></div>
                            <div className="bar5"></div>
                            <div className="bar6"></div>
                            <div className="bar7"></div>
                            <div className="bar8"></div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div data-testid="lifesherpa-loading" style={zindex ? {zIndex: zindex}: {}} className={`d-flex align-items-center ${customClasses} justify-content-center ${onlyLoading ? "" : fullLoader ? 'complete-loader-overlay' : 'loader-overlay'} `}>
                        <CircularProgress color="progress" disableShrink />
                    </div>
                )
            }

        }
        else return <></>;
    }
}

export default LifeSherpaLoading;
