import React, { Component } from "react";
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import { withRouter } from 'react-router-dom';
import withRouter from "../../../router";
import {setInActivityTimerUpdate, clearInActiviyIdleData , storeInActiveSetIntervalData} from '../../../actions'
import { Label } from 'reactstrap';
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from '@mui/styles';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#008C95',
    },
  }))(LinearProgress);


class TimerPopup extends Component {
    
    state = {
  
    }

    // componentDidMount = () => {
    //     const { timer , inactivityWarningPeriod, inAvtivityValue , showUnActiveAlert, setIntervalData} = this.props;
    //     console.log("timer componet did mount---> ", timer,showUnActiveAlert, inAvtivityValue, setIntervalData);
    //     clearInterval(setIntervalData);
    //     this.props.setInActivityTimerUpdate(inactivityWarningPeriod)
    //     let timerInterval = setInterval(this.handelTimer, 1000);
    //     this.props.storeInActiveSetIntervalData(timerInterval)
    // }

    // componentWillUnmount() {
    //     const { setIntervalData} = this.props;
    //     clearInterval(setIntervalData);
    // }

  
    handelContinue = () => {
        const { timer,setIntervalData} = this.props;
        clearInterval(setIntervalData);
        this.props.handelContinue();
    }
    handleLogout = () => {
        const { timer,setIntervalData} = this.props;
        clearInterval(setIntervalData);
        this.props.handleLogout()
    }

    render() {
        const { timer, showUnActiveAlert, factor, inactivityWarningPeriod, inAvtivityValue } = this.props;
        return (
            <Dialog
                fullScreen={false}
                open={showUnActiveAlert}
                // disableBackdropClick={true}
                fullWidth={true}
                maxWidth="sm"
                className="resource-filter"
                aria-labelledby="responsive-dialog-title"
            >
                
                <div className="template-header">
                    <Label data-testid="scheduleHeading" className=" mb-0 text-white"><h2>Session Logout Warning</h2></Label>
                </div>
                <DialogContent className="p-4">
                    <div className="fs-14 mb-2 fw-semi-bold">You are about to be automatically logged-out in {timer} second(s) due to inactivity. Click Continue to stay in your session.</div>
                    <BorderLinearProgress variant="determinate" value={timer * factor} />
                </DialogContent>
                <DialogActions className="p-4">
                    <div className="d-flex">
                        <div className="d-flex w-50">
                        </div>
                        <div className="d-flex w-50 justify-content-end">
                            <button onClick={() => this.handelContinue()} className="mr-3 btn btn-primary ">
                                Continue
                            </button>
                            <button onClick={() => this.handleLogout()} className="btn  btn-danger" >
                                Logout
                            </button>

                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        )
    }
}
const mapStateToProps = ({ screenTimer }) => {
    const { timer, showUnActiveAlert, factor, inactivityWarningPeriod, inAvtivityValue, setIntervalData } = screenTimer;
    return {timer, showUnActiveAlert, factor, inactivityWarningPeriod, inAvtivityValue, setIntervalData }
}
export default withRouter(connect(mapStateToProps, {
    setInActivityTimerUpdate,
    clearInActiviyIdleData,
    storeInActiveSetIntervalData
})(TimerPopup));
