/**
 * Landing Page Activity List Item
 */
import React, { useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListIcon from '@mui/icons-material/List';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Label, Badge } from 'reactstrap';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
// import { Badge } from '@mui/material';

const LandingPageAllItem = ({ hideRewards, activity, handleStartActivityPreview, handleResumeActivity, itemKey, handleCalenderButton, anchorEl, handleClickMenu, handleCloseMenu, handleExecuteActivity, handleOpenHistory, EditActivity, handleDeletePop, selectedMenuActivity, showStartOrResumeButton, organization }) => {
    let ref = useRef()
    return (
        <div  data-testid={`landing-page-my-activities-list-item${itemKey}`} className="mb-2" key={itemKey}>
            {activity.state === "paused" || activity.state === "inProgress" ?
                <div className="d-flex justify-content-between align-items-center ml-1">
                    <div className="col-4 col-md-4  col-lg-4 text-center activities-list-inprogress-title" >In Progress</div>
                </div>
                : ''
            }
            {hideRewards ?
                <div data-testid={"activity-reward-diamand-" + itemKey} className="row mx-auto align-items-center justify-content-center mb-auto text-secondary position-relative font-lg" style={{ width: 50 + 'px', height: "46px" }}></div>
                : <div data-testid={"activity-reward-diamand-" + itemKey} className="row mx-auto align-items-center justify-content-center  mb-auto text-secondary position-relative   font-lg" style={{ width: 50 + 'px' }}>
                    <img className="mt-1 mx-auto d-block" style={{ zIndex: "1" }} src={require("Assets/img/diamond_icon.png")} alt="project logo" width="43" height="43" />
                    {activity.rewards && activity.rewards > 99 ?
                        <h2 className="text-white reward-style " >{activity.rewards && activity.confirmationRewards ? activity.rewards + activity.confirmationRewards : activity.rewards ? activity.rewards : activity.confirmationRewards ? activity.confirmationRewards : 0}</h2>
                        : <h1 className="text-white reward-style " >{activity.rewards && activity.confirmationRewards ? activity.rewards + activity.confirmationRewards : activity.rewards ? activity.rewards : activity.confirmationRewards ? activity.confirmationRewards : 0}</h1>
                    }
                </div>
            }
            <li
                key={itemKey}
                className={"d-flex  list-card p-3 justify-content-between align-items-center list-item " + `${activity.state === "paused" || activity.state === "inProgress" ? "activities-list-item-border-inprogress" : "activities-list-item-border"}`}
                style={{ backgroundImage: `url(${activity.backgroundImageUrl})`, backgroundRepeat: "no-repeat", backgroundSize: 'contain', backgroundPosition:"center" }}
            >
                <div className="w-100">
                    <div className="scheduleButton px-1 d-flex">
                        {activity.type === "QT-Step" || activity.type === "QT-Form" ?
                            <IconButton title={activity.type} /*disabled={true}*/>
                                <FlashOnIcon color="primary" />
                            </IconButton>
                            : activity.type === "QT-Reminder" ?
                                <IconButton title="Notification" /*disabled={true}*/>
                                    <NotificationsIcon color="primary" />
                                </IconButton>
                                :
                                <IconButton title="Routine" data-testid={"list-activity-type-icon-" + itemKey}  /*disabled={true}*/>
                                    <ListIcon fontSize="large" color="primary" />
                                </IconButton>
                        }
                        {/* {activity.sortedSchedules && activity.sortedSchedules.length > 0 &&
                            <IconButton data-testid={"activity-calender-" + itemKey} title="Calendar" onClick={activity.sortedSchedules && activity.sortedSchedules.length > 0 ? handleCalenderButton : null}>
                                <CalendarTodayIcon color="primary" />
                            </IconButton>
                        } */}
                        {activity.isPrivateActivity === true &&
                            <IconButton data-testid={"private-activity-" + itemKey} title="Private Activity">
                                <LockOutlinedIcon color="primary" />
                            </IconButton>
                        }
                        {organization && activity.isPrivateActivity !== true && <Label title={organization.name+' '+activity.title} className='mb-0 d-flex align-items-center justfy-content-center'><CorporateFareIcon className='mr-1'/>{organization.name}</Label>}
                    </div>
                    {activity.isPrivateActivity === true && (((activity.type === "Routine") || !(activity.type) || (activity.type === "QT-Step") || (activity.type === "QT-Form") || (activity.type === "QT-Set-Status") || (activity.type === 'QT-Reminder'))) ?
                        <div className="moreButton">
                            <div className="w-4 d-flex justify-content-end align-items-center px-1 ml-1" style={{ borderRadius: 12 + "px" }}>
                                <div className='d-flex align-items-center justfy-content-between'>
                                {/* {organization && <Label className='mb-0 org-label d-flex align-items-center justfy-content-center'><CorporateFareIcon className='mr-1'/>{organization.name}</Label>} */}
                                {activity.sortedSchedules && activity.sortedSchedules.length > 0 &&
                                        <IconButton data-testid={"activity-calender-" + itemKey} title="Calendar" onClick={activity.sortedSchedules && activity.sortedSchedules.length > 0 ? handleCalenderButton : null}>
                                            <CalendarTodayIcon color="primary" />
                                        </IconButton>
                                    }
                                    <IconButton ref={ref} onClick={(e) => handleClickMenu(e, itemKey, activity, ref)} data-testid={"vertical-menu-icon-" + itemKey} title="Activity More Actions">
                                        <MoreVertIcon color="primary" aria-label="More" aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" />
                                    </IconButton>
                                </div>
                                
                            </div>
                            {selectedMenuActivity && selectedMenuActivity.id === activity.id &&
                                <Menu id="simple-menu-home" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}
                                // PaperProps={
                                //     { style: { boxShadow: "1px 2px 1px gray" } }
                                // }
                                >
                                    {activity.type !== 'QT-Reminder' && <MenuItem className="menuEditItem" onClick={handleStartActivityPreview}>Preview Activity</MenuItem>}
                                    <MenuItem className="menuEditItem" onClick={handleOpenHistory}>Activity History</MenuItem>
                                    <MenuItem className="menuEditItem" onClick={EditActivity}> Edit</MenuItem>
                                    <MenuItem className="menuEditItem" onClick={handleDeletePop}>Delete</MenuItem>
                                    <MenuItem className="menuEditItem" onClick={handleCloseMenu}>Cancel</MenuItem>
                                </Menu>
                            }
                        </div>
                        : ((activity.type === "Routine") || !(activity.type) || (activity.type === "QT-Step") || (activity.type === "QT-Form") || (activity.type === "QT-Set-Status")) ?
                            <div className="moreButton">
                                <div className="w-4 d-flex justify-content-end align-items-center  px-1 ml-1" style={{ borderRadius: 12 + "px" }}>
                                    <div className='d-flex align-items-center justfy-content-between'>
                                    {/* {organization && <Label className='mb-0 org-label d-flex align-items-center justfy-content-center'><CorporateFareIcon className='mr-1'/>{organization.name}</Label>} */}
                                    {activity.sortedSchedules && activity.sortedSchedules.length > 0 &&
                                            <IconButton data-testid={"activity-calender-" + itemKey} title="Calendar" onClick={activity.sortedSchedules && activity.sortedSchedules.length > 0 ? handleCalenderButton : null}>
                                                <CalendarTodayIcon color="primary" />
                                            </IconButton>
                                        }
                                        <IconButton ref={ref} data-testid={"vertical-menu-icon-" + itemKey} onClick={(e) => handleClickMenu(e, itemKey, activity, ref)} title="Activity-More-Actions">
                                            <MoreVertIcon color="primary" aria-label="More" aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" />
                                        </IconButton>
                                    </div>
                                </div>
                                {selectedMenuActivity && selectedMenuActivity.id === activity.id &&
                                    <Menu id="simple-menu-home" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}
                                    // PaperProps={
                                    //     { style: { boxShadow: "1px 2px 1px gray" } }
                                    // }
                                    >
                                        <MenuItem className="menuEditItem" onClick={handleStartActivityPreview}>Preview Activity</MenuItem>
                                        {<MenuItem className="menuEditItem" onClick={handleOpenHistory}>Activity History</MenuItem>}
                                        {/*<MenuItem><div className="menuEditItem" onClick={handleShowDetails}>Show Details</div></MenuItem> 
                                    {allActivities[editScheduleKey]&&allActivities[editScheduleKey].doNotNotifyOnRoutineStart&&<MenuItem><div className="menuEditItem" onClick={handleReminderButton}>Notify to Start</div></MenuItem>}*/}
                                        <MenuItem className="menuEditItem" onClick={handleCloseMenu}>Cancel</MenuItem>
                                    </Menu>
                                }
                            </div>
                            :
                            <></>
                    }
                    <div className="row">
                        <div  className={showStartOrResumeButton ? "col-12 pt-4" : "col-12 pt-4 pb-3"}>
                            <div className=" text-center pt-4 pb-2" >
                                <h4 data-testid={"activity-title-" + itemKey} className="mb-1" >{activity && activity.title ? activity.title.toUpperCase() : ""}</h4>
                                <div data-testid={"activity-description-" + itemKey} className="font-sm d-block">{activity && activity.detailedDescription ? activity.detailedDescription : ""}</div>
                            </div>
                        </div>
                        {showStartOrResumeButton &&
                            <div className={activity.state === "paused" || activity.state === "inProgress" ? "col-12 pb-3" : "col-12 pb-3"}>
                                {activity.state === "paused" || activity.state === "inProgress" ?
                                    <button title="Resume" data-testid={"resume-activity-" + itemKey}  className="start-button-border-radius MuiButtonBase-root MuiButton-root MuiButton-contained primary-button MuiButton-containedPrimary cps" onClick={handleResumeActivity}>RESUME</button>
                                    : activity.showStartButton ?
                                        <button title="Start" data-testid={"start-activity-" + itemKey}  className="start-button-border-radius MuiButtonBase-root MuiButton-root MuiButton-contained primary-button MuiButton-containedPrimary cps" onClick={handleExecuteActivity}>START</button>
                                        : <></>
                                }
                                {activity.state === "paused" || activity.state === "inProgress" ?
                                    <div className="d-flex w-100 align-items-center mt-4 font-sm" style={{ whiteSpace: 'nowrap' }}>
                                        <div className="align-items-center"> <div className={"rounded-circle  mr-1 " + (activity.state === "paused" ? "activity-icon-progress" : "activity-icon-progressa")}>
                                            {activity.state === "paused" ? "P" : "A"}</div></div>
                                        <div className="activity-step">{activity.nextStep ? activity.nextStep : 'Step : Start'}</div>
                                    </div>
                                    :
                                    <></>
                                }
                                {activity.state === "paused" || activity.state === "inProgress" ?
                                    <div className="row m-2">
                                        <div style={{ height: 5 + 'px', borderRadius: 2 + 'px' }} className={`activity-completed w-${Math.round(Math.round(activity.completed / activity.count * 100) / 10) * 10}`}></div>
                                        <div className={`w-${100 - Math.round(Math.round(activity.completed / activity.count * 100) / 10) * 10}`} style={{ background: 'lightgray', height: 5 + 'px', borderBottomRightRadius: 2 + 'px', borderTopRightRadius: 2 + 'px' }}></div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        }
                    </div>
                    <div className="d-none w-4 justify-content-end align-items-center text-right"><ArrowRightIcon /></div>
                </div>
            </li>
        </div>
    )
}

export default LandingPageAllItem;