import React, { Component } from 'react';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { getWPQ, logoutUserFromFirebase, removePinMsg, checkPIN, getCourseFromConfigurator, collapsedSidebarAction, updateStatus, setStatusColor, getUserStatusByIdFromConfigurator, getPokeFormFromConfigurator } from '../../../actions';
import SweetAlert from 'react-bootstrap-sweetalert'
import { FormGroup, Input, Label } from 'reactstrap';
import '../../../assets/scss/custom/startActivityDetails.scss'
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SetCurrentStatus from '../../SetCurrentStatus';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import { withAuth0 } from "@auth0/auth0-react";
import { openURL, capTextSpeech } from '../../../helpers/helpers';

class StartActivityListViewDetails extends Component {
	state = {
		speak: false,
		StatusPopup: false,
		statusList: [],
		maxStepTimeout: null,
		showCheckIn: false,
		pin: '',
		pinErr: null,
		totalTry: 4,
		showTimer: false,
		hour: '',
		min: '',
		sec: '',
		showEvent: false,
		openedItem: "",
		warningPopup:"",
		isSpeechSupported:true,
		openSpeechWarningPopup:false,
		countDownStart: false,
		countDownInterval: 0,
	}

	componentDidMount() {
		//	console.log("StartActivityDetailss",this.props.step)
		const { checkList, stepIndex, executeActivity } = this.props;
		if (this.props.step.cardType === 'Course' && executeActivity) {
			this.props.getCourseFromConfigurator(this.props.step.courseId)
		}
		if (this.props.step.cardFormId) {
			if (!checkList.completeForm) {
				this.props.updateCheckList("completeForm");
				this.props.setStepDisabled(this.props.stepIndex)
			}
		}
		if (this.props.step.cardType === 'wpq' && executeActivity) {
			this.props.getWPQ()
		} 
		let idToken = localStorage.getItem('idToken')
		if (!idToken) this.props.navigate('/success')
		let { maxStepTimeout } = this.state
		let countDownInterval = 0
		maxStepTimeout = this.props.step.countDownInterval ? this.props.step.countDownInterval : 0
		let { step } = this.props;
		if ((step.cardType === "countDown" || step.cardType === "countDownEnforced" || step.cardType === "countDownPermissive") && step.countDownInterval > 0) {
			countDownInterval = step.countDownInterval
		}
		this.setState({ maxStepTimeout, countDownInterval })
	}

	handlecountDownInterval = () => {
		this.setState({ countDownStart: true })
		if (this.state.countDownInterval > 0) {
		  this.intervalID1 = setInterval(
			() => {
			  this.setState({
				countDownInterval: this.state.countDownInterval - 1
			  })
			  if (this.props.step.cardType === "countDownEnforced" && this.state.countDownInterval <= 1) {
				this.props.handleNextStep(this.props.step.stepId, this.props.stepIndex)
					clearInterval(this.intervalID1);
				} else if(this.state.countDownInterval <= 1) {
					clearInterval(this.intervalID1);
			  }
			},
			1000
		  );
		} else {
			if (this.props.step.cardType === "countDownEnforced") {
				this.props.handleNextStep(this.props.step.stepId, this.props.stepIndex)
			}
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalID1);
	}

	componentDidUpdate(prevProps, prevState) {
		let {openedStep,step} = this.props;
		let {isSpeechSupported} = this.state;
         if(isSpeechSupported && openedStep == step.position) {
			this.isSpeechIconValid();
			// console.log(openedStep,prevProps.openedStep ,step);
		 }
	}

	isSpeechIconValid = () => {
		let { step } = this.props;
		let speechElement = document.getElementsByClassName("rs-text");
		//console.log("speechElement ---->",speechElement);
		if(speechElement && document.getElementsByClassName("rs-text")[0]) { 
            let innnerText = document.getElementsByClassName("rs-text")[0].innerText;
		//	console.log("innnerText ---->",innnerText);
			if(innnerText && typeof innnerText == "string" && innnerText == step.title) {
                console.log("Browser not supported speech component ");
				this.setState({...this.state,isSpeechSupported:false });
			} else {
				//console.log("Browser supported speech component ")
			}
		}	
	}

	handleOpenSpeechWarningPopup = () => {
		this.setState({...this.state,openSpeechWarningPopup:true });
	}

	handleCloseSpeechWarningPopup = () => {
		this.setState({...this.state,openSpeechWarningPopup:false });
	}

	updateStatus = (statusList) => {
		if(this.props.executeActivity) {
			console.log(" Clicked on submit set status form");	
			let data = this.props.Status.status
			data && data.sections ?
				Object.keys(data.sections).map((obj, key) => {
					data.sections[obj] = statusList[key]
				}) : ''
			let uid = localStorage.getItem('puid')
			this.props.updateStatus(uid, data);
		}
		this.handelStatusEditClose();
	}
	updateStatusPopup = () => {
		let idToken = localStorage.getItem('idToken')
		let uid = localStorage.getItem('puid')
		this.props.getUserStatusByIdFromConfigurator(uid, idToken)
		this.setState({ StatusPopup: true })
	}
	handleStepFrom = (formId) => {
		this.props.getPokeFormFromConfigurator(formId)
		this.props.setActiveStep(this.props.stepIndex);
		this.props.showForm(this.props.step.stepId, formId,  this.props.stepIndex)
	}
	handleStart = () => {
		this.handlecountDownInterval()

	}
	handlePIN = (n) => {
		let { pin, totalTry } = this.state
		pin = pin + n
		if (pin.length === 4) {
			this.props.checkPIN(pin)
			totalTry = totalTry - 1
			this.setState({ totalTry, pin })
		}
		else this.setState({ pin })
	}
	handleBack = () => {
		let { pin } = this.state
		pin = pin.slice(0, -1)
		//console.log('pin',pin)
		this.setState({ pin })
	}
	handleClose = () => {
		this.props.removePinMsg()
		this.setState({ pin: '', showCheckIn: false, totalTry: 4 })
		this.props.handleNextStep(this.props.step.stepId, this.props.stepIndex)
	}
	logoutUser() {
		const {logout} = this.props.auth0
		this.props.logoutUserFromFirebase(this.props.history, logout);
	}
	handleHour = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 25) {
			//console.log("handleHour",i)
			this.setState({ hour: +e.currentTarget.value })
		}
	}
	handleMin = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 60) {
			this.setState({ min: +e.currentTarget.value })
		}
	}
	handleSec = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 60) {
			this.setState({ sec: +e.currentTarget.value })
		}
	}
	updateTimer = () => {
		let { hour, min, sec } = this.state
		let totalhrs = hour ? hour * 3600 : 0;
		let totalmin = min ? min * 60 : 0;
		let total = totalhrs + totalmin + sec
		this.setState({ showTimer: false, countDownInterval: total});
	}
	handleEvent = () => {
		this.props.handleEvent()
	}

	handleCourseOpenAlert = () => {
		const url = this.props.coures ? this.props.coures.url : null
		this.props.setStepEnabled(this.props.stepIndex);
		this.props.showOpenCourseAlert(this.props.step.stepId, this.props.stepIndex, url, 'List');
	}

	handelStatusEditClose = () => {
		this.setState({ StatusPopup: false, statusList: [] })
		this.props.handleNextStep(this.props.step.stepId, this.props.stepIndex)
	}
	setOpenSteps = (p) => {
		if (this.props.step.cardType === 'Course' && this.props.executeActivity) {
			this.props.getCourseFromConfigurator(this.props.step.courseId);
		} else if(this.props.step.cardType === 'wpq' && this.props.executeActivity) {
			this.props.getWPQ()
		}
		this.props.setOpenSteps(p);
	}
	handleDoButton(step, stepIndex){
		const stepActions = ["changeStatus", "Course", "Form","event","wpq", "countDownEnforced"]
		if (step.cardType && stepActions.indexOf(step.cardType) !== -1){
			this.setOpenSteps(step.position);
		} else if(step.actionButtonType && stepActions.indexOf(step.actionButtonType) !== -1) {
			this.setOpenSteps(step.position)
		} else {
			this.props.handleNextStep(step.stepId, stepIndex)
		}
	}
	handleSkipStep = (stepId, stepIndex, status) => {
		if(status == "Done" || status == "Skipped") {
			console.log("step completed")
		} else {
			this.props.handleSlipStep(stepId, stepIndex);
		}
	}
	handleDoneStep = (step, stepIndex, status) => {
		const stepActions = ["changeStatus", "Course", "Form","event","wpq", "countDownEnforced"]
        if(status == "Done" || status == "Skipped") {
			console.log(" Step completed")
		} else {
			if (step.cardType && stepActions.indexOf(step.cardType) >= 0){
				this.setOpenSteps(step.position)
			} else if(step.actionButtonType && stepActions.indexOf(step.actionButtonType) >= 0) {
				this.setOpenSteps(step.position)
			} else {
				this.props.handleNextStep(step.stepId, stepIndex)
			}
		}
	}
	handelShowWarningPopup = (warning) => {
		let {step, stepIndex, executeActivity, wpqLink} = this.props;
		if(executeActivity && step.cardType === 'wpq') {
			if(wpqLink) {
				openURL(wpqLink)
			}
			console.log(" wpqLink -->", wpqLink);
			this.props.handleNextStep(step.stepId, stepIndex);
		} else if(executeActivity && step.cardType === 'event') {
			this.setState({...this.state,warningPopup:"Not Supported"});
		} else if(step.cardType === "checkIn") {
			this.setState({ showCheckIn: true });
		}  else {
			this.setState({...this.state,warningPopup:warning});
		}	
	}
	handleCloseWarningPopup =() => {
		let {step, stepIndex} = this.props;
		this.props.handleNextStep(step.stepId, stepIndex);
		this.setState({...this.state,warningPopup:""});
	}
	render() {
		let { openedStep, stepsCompletionList, step, stepIndex, activity, stepslist } = this.props;
		let stepCompletionDetail = stepsCompletionList ? stepsCompletionList.find(ele => ele.step.id == step.stepId) : {};
		let status = stepCompletionDetail ? stepCompletionDetail.status : "";
		const {totalTry, isSpeechSupported } = this.state;
		const stepActions = ["changeStatus", "Course", "Form","event","wpq", "countDownEnforced"]
		var timeleft = this.state.countDownInterval * 1000;
		let seconds = ("0" + (Math.floor(timeleft / 1000) % 60)).slice(-2);
		let minutes = ("0" + (Math.floor(timeleft / 60000) % 60)).slice(-2);
		let hours = ("0" + Math.floor(timeleft / 3600000)).slice(-2)
		let num = [1, 2, 3, 4, 5, 6, 7, 8, 9, "", 0, 'back']
		if (totalTry === 0 && this.props.pinMsg && this.props.pinMsg != "PIN verified successfully") {
			this.logoutUser()
		}
		let show = false
		if (this.props.pinMsg === "PIN verified successfully") {
			show = true
			setTimeout(() => {
				show = false
				this.handleClose()
			}, 2000);
		}
		return (
			<div key={this.props.key}>
				{this.props.pinMsg === "PIN verified successfully" && show &&
					<div className="col-12 p-4">
						<SweetAlert
							success
							btnSize="sm"
							customClass="successText"
							confirmBtnText="YES"
							cancelBtnText="NO"
							confirmBtnBsStyle="success"
							title="Success"
							onConfirm={() => this.handleClose()}
						>
							<div className="sweet-alert-text">{this.props.pinMsg}</div>
						</SweetAlert>
					</div>
				}
				{!this.state.showCheckIn && step.title &&
					<div className="list-card my-2 mx-0">
						<div className="d-flex mx-0  py-2 px-1 justify-content-between  align-items-center">
							<div className="d-flex pl-1 align-items-left text-left w-90 " >
								{status && status == "Skipped" && <CloseIcon className="step-skipped-icon" style={{ fontSize: "18", marginRight: "8px", color: "#fff", marginTop: "3px", borderRadius: "2px" }} />}
								{status && status == "Done" && <CheckBoxIcon style={{ fontSize: "24", marginRight: "5px", color: "rgb(31, 179, 31)" }} />}
								{!status && <CheckBoxOutlineBlankIcon className="theme-icon-colo" style={{ fontSize: "24", marginRight: "5px", color: "rgb(165, 163, 163)" }} />}
								<div className="activity-title  fw-semi-bold d-block ">
									{step.referenceURL ?
										<a className="text-black" style={{ textDecoration: 'underline' }} onClick={() => this.props.openLinkInIframe(step.referenceURL)}  >{step.title}</a>
										:
										<h4 className="mb-0 mt-1">{step.title}</h4>
									}
								</div>
							</div>
							<div className="d-flex align-items-right text-right w-10" >
								<div className="ml-auto mr-0 pt-1">
									{
										openedStep == step.position ?
											<KeyboardArrowUpIcon style={{ margin: "0px", padding: "0px", cursor: "pointer", marginLeft: "0px" }}
												className="m-0 p-0" onClick={() => this.setOpenSteps(step.position)} /> :
											<KeyboardArrowDownIcon style={{ margin: "0px", padding: "0px", cursor: "pointer" }} className="m-0 p-0"
												onClick={() => this.setOpenSteps(step.position)} />
									}
								</div>
							</div>
						</div>

						{openedStep == step.position &&
							<div data-testid={`details-panel-for-step-${stepIndex+1}`} className="text-center border-top  pb-2 pt-1  px-3">
								<div className="row">
									<div className="col-3">
									</div>
									<div className="col-6"></div>
									<div data-testid={`speech-icon-for-step-${stepIndex+1}`} className="col-3 text-right theame-text on-focus">
										<div>
											<IconButton className='activity-speech-icon-button' onClick={() => capTextSpeech(step.title)}>
												<VolumeUpOutlinedIcon  className="" />
											</IconButton>
										</div>
									</div>
								</div>
								{step.cardType ?
									<>
										{step.imageURL && step.cardType !== 'Course' &&
											<div className="text-center font-18 text-gray">
												<img className="img-fluid mt-3 img-height-330" src={step.imageURL} alt="" />
											</div>
										}
										{step.cardType === 'Course' && this.props.coures ?
											<div className='mx-auto mt-2'>

												{this.props.courseLoading ?
													<LifeSherpaLoading loading={this.props.courseLoading}/>
													:
													<>
														<div className="text-center font-18 text-gray">{this.props.coures.name}</div>
														<div className="text-center font-18 text-gray">
															<img className="img-fluid mt-3 img-height-330" src={this.props.coures.big_avatar} alt="" />
														</div>
														<Button color="primary" variant="contained" disabled={status ? true : false} className="mt-4 primary-button" onClick={() => this.handleCourseOpenAlert()}>Open Course </Button>

													</>
												}
											</div>
											: step.cardType === 'Course' ? 
												<div className='mx-auto mt-2'>
													<Button color="primary" variant="contained" disabled={status ? true : false} className="mt-4 primary-button" onClick={() => this.handleCourseOpenAlert()}>Open Course </Button>
												</div>
											:
											<></>
										}
										{step.cardType === 'wpq' &&
											<div className="mx-auto mt-2">
												{this.props.wpqLoading ? 
												  <LifeSherpaLoading loading={this.props.wpqLoading} />
												  :
												  <Button color="primary" variant="contained" disabled={status ? true : false} className="mt-4 primary-button" onClick={()=> this.handelShowWarningPopup("Cannot open WPQ in activity preview")}>Open Questionnaire</Button>
												  }
											</div>
										}
										{step.cardType === 'event' &&
											<div className="mx-auto mt-2">
												 <Button color="primary" variant="contained" disabled={status ? true : false} className="mt-4 primary-button" onClick={()=> this.handelShowWarningPopup("Cannot open event in activity preview")} /*onClick={() => this.handleEvent()}*/>Check Event </Button>
											</div>
										}
										{step.cardFormId &&
											<div className='mx-auto mt-2'>
												 <Button color="primary" variant="contained" disabled={status ? true : false} className="primary-button" onClick={() => this.handleStepFrom(step.cardFormId)}>Complete Form </Button>
											</div>}
										{step.cardType === "changeStatus" &&
											<div className='mx-auto mt-2'>
												 <Button color="primary" variant="contained" disabled={status ? true : false} className="primary-button" onClick={() => this.updateStatusPopup()}>Change Status </Button>
											</div>
										}
										{step.cardType === "checkIn" &&
											<div className='mx-auto mt-2'>
											</div>
										}
										{((step.cardType === "countDown" || step.cardType === "countDownPermissive" || step.cardType === "countDownEnforced") && status != 'Done') &&
											<div className='countdown-height'>
												<div className='mx-auto mt-2'>
													<div className="text-center countdown-time my-50 text-danger">{hours + ':' + minutes + ':' + seconds}</div>
												</div>
												{!this.state.countDownStart && <div className='d-flex mx-auto mt-2'>
													 {(step.cardType === "countDown" || step.cardType === "countDownPermissive") && <Button color="primary" variant="outlined" disabled={status ? true : false} className=" countdown-edit-button mx-auto" onClick={() => this.setState({ showTimer: true, hour: hours, min: minutes, sec: seconds })}>EDIT </Button>}
													 <Button color="primary" variant="contained" disabled={status ? true : false} className="mx-auto primary-button" onClick={() => this.handleStart()}>START </Button>
												</div>}
											</div>
										}
									</>
									:
									<>
										{step.imageURL &&
											<div className="text-center font-18 text-gray">
												<img className="img-fluid mt-3 img-height-330" src={step.imageURL} alt="" />
											</div>
										}
										{step.actionButtonType === "changeStatus" &&
											<div className='mx-auto mt-2'>
												 <Button color="primary" variant="contained" disabled={status ? true : false} className="primary-button" onClick={() => this.updateStatusPopup()}>Change Status </Button>
											</div>
										}
										{step.actionButtonType === "checkIn" &&
											<div className='mx-auto mt-2'>
												 <Button color="primary" variant="contained" disabled={status ? true : false} className="primary-button" onClick={() => this.setState({ showCheckIn: true })}>CheckIn </Button>
											</div>
										}
										{((step.actionButtonType === "countDown" || step.actionButtonType === "countDownPermissive" || step.actionButtonType === "countDownEnforced") && status != 'Done') &&
											<div className='countdown-height'>
												<div className='mx-auto mt-2'>
													<div className="text-center countdown-time my-50 text-danger">{hours + ':' + minutes + ':' + seconds}</div>
												</div>
												{!this.state.countDownStart && <div className='d-flex mx-auto mt-2'>
													 {(step.actionButtonType === "countDown" || step.actionButtonType === "countDownPermissive") && <Button color="primary" variant="outlined" disabled={status ? true : false} className=" countdown-edit-button  mx-auto" onClick={() => this.setState({ showTimer: true, hour: hours, min: minutes, sec: seconds })}>Edit </Button>}
													 <Button color="primary" variant="contained" disabled={status ? true : false} className="mx-auto primary-button" onClick={() => this.handleStart()}>Start </Button>
												</div>}
											</div>
										}
									</>
								}
								{step.contentUrl &&
									<div className="text-center font-18 text-gray">
										<img className="img-fluid mt-3 img-height-330" src={step.contentUrl} alt="" />
									</div>
								}
							</div>
							}
						<div className="d-flex mx-0 border-top py-2 px-1 justify-content-between  align-items-center">
								<div style={{ opacity: status && (status == "Skipped" || status == "Done") ? "0.5" : "1" }} className="d-flex mx-0 w-100 justify-content-end  align-items-center ">
									{activity && activity.showSkipButton && !step.isMandatory ?
										<div className={"d-flex pl-1 justify-content-center align-items-center " + (status && (status == "Skipped" || status == "Done") ? "" : "cps")} onClick={() => this.handleSkipStep(stepslist[stepIndex].stepId, stepIndex, status)} >
											<div className="d-flex ">
												<ThumbDownRoundedIcon className="mr-1 d-inline-block" style={{ color: "rgb(243, 62, 62)", fontSize: "14px" }} />
											</div>
											<div className="d-flex">
												<span className={"fw-semi-bold d-inline-block "} style={{ fontSize: "16px", color: status && (status == "Skipped" || status == "Done") ? "rgb(165, 163, 163)" : "rgb(243, 62, 62)" }}>I Skipped It</span>
											</div>
										</div> : <div className="d-flex pl-1 justify-content-center w-50 "></div>
									}
									<div className={"d-flex justify-content-center w-30 align-items-center " + (status && (status == "Skipped" || status == "Done") ? "" : "cps")} onClick={() => this.handleDoneStep(stepslist[stepIndex], stepIndex, status)}  >
										<div className='d-flex '>
											<ThumbUpAltRoundedIcon className="mr-1 d-inline-block done-button-thumb-icon" style={{ fontSize: "14px" }} />
										</div>
										<div className="d-flex">
											<span style={{ fontSize: "16px" }} className={" fw-semi-bold d-inline-block "+(status && (status == "Skipped" || status == "Done") ? "stepDone" : /*stepsDisabled.findIndex(li => li == stepIndex) >= 0 ? "stepDone" : */ "stepDo" )} > 
												{stepslist && stepslist[stepIndex].isStartButtonEnabled && !status ? 'Start' : status == "Done" ? "Done" : step.cardType && stepActions.indexOf(step.cardType) !== -1 ? "Do" : step.actionButtonType && stepActions.indexOf(step.actionButtonType) !== -1 ? "Do" : 'Done'}
											</span>
										</div>
									</div>

								</div>
						</div>
					</div>
				}
				{this.state.StatusPopup &&
					<SetCurrentStatus
						showStatus={this.state.StatusPopup}
						image={this.props.userImage}
						name={this.props.userName}
						handelStatusEditClose={this.handelStatusEditClose}
						updateStatus={this.updateStatus}
						configuratorPreview={true}
					/>
				}
				{this.state.showTimer &&
					<Dialog
						fullScreen={false}
						open={this.state.showTimer}
						onClose={() => this.setState({ showTimer: false })}
						fullWidth={true}
						maxWidth="xl"
						aria-labelledby="responsive-dialog-title"
						className={this.props.popupThemeClass}
						PaperProps={
							{ style: { maxWidth: "500px", borderRadius: 8, margin: 'auto' } }
						}
					>
						<div>
							<div className="row p-3 bg-lifesherpa">
								<div className="col">
									<h2 className="text-white">{step.title}</h2>
									<h4 className="text-white mb-0 pb-0">Edit Timer</h4>
								</div>
								<IconButton className="ml-auto my-auto text-white" onClick={() => this.setState({ showTimer: false })}><CloseIcon /></IconButton>
							</div>
							<div className="row p-3 mt-3">
								<FormGroup className="col text-center">
									<Label>HH</Label>
									<Input className="msg-inpu text-center" value={this.state.hour}  min={0} max={25} type='number' placeholder='00' name='timer' onChange={(e) => this.handleHour(e)} />
								</FormGroup>
								<h1 className="mx-1 my-auto">:</h1>
								<FormGroup className="col text-center">
									<Label>MM</Label>
									<Input className="msg-inpu text-center" value={this.state.min} min={0} max={60} type='number' placeholder='00' name='timer' onChange={(e) => this.handleMin(e)} />
								</FormGroup>
								<h1 className="mx-1 my-auto">:</h1>
								<FormGroup className="col text-center">
									<Label>SS</Label>
									<Input className="msg-inpu text-center" value={this.state.sec} min={0} max={60} type='number' placeholder='00' name='timer' onChange={(e) => this.handleSec(e)} />
								</FormGroup>
							</div>
							<div className="row d-flex align-items-center justify-content-center mt-4 update-time-buttons">
								 <Button color="primary" variant="outlined"  disabled={status} className="  mb-3 mr-2 form-cancel-button " onClick={() => this.setState({ showTimer: false })}>Cancel </Button>
								 <Button color="primary" variant="contained" disabled={status} className="  mb-3 ml-2 primary-button" onClick={() => this.updateTimer()}>Update Timer </Button>
							</div>
						</div>
					</Dialog>
				}
				 {this.state.openSpeechWarningPopup &&
					<div className="col-12 p-4">
						<SweetAlert
						warning
						btnSize="sm"
						customClass="warningText"
						confirmBtnText="OK"
						confirmBtnBsStyle="warning"
						title={"Your browser doesn’t support speech content"}
						onConfirm={() => this.handleCloseSpeechWarningPopup()}
						>
						<div className="sweet-alert-text"></div>
						</SweetAlert>
					</div>
				}
				 {this.state.warningPopup &&
					<div className="col-12 p-4">
						<SweetAlert
						warning
						btnSize="sm"
						customClass="warningText"
						confirmBtnText="OK"
						confirmBtnBsStyle="warning"
						title={this.state.warningPopup}
						onConfirm={() => this.handleCloseWarningPopup()}
						>
						<div className="sweet-alert-text"></div>
						</SweetAlert>
					</div>
					}
			</div>);
	}
}
const mapStateToProps = ({ settings, authUser, Status, activitiesList }) => {
	const { coures, pinMsg, wpqLink, courseLoading ,wpqLoading } = activitiesList
	//console.log("mapStateToProps",coures)
	const { popupThemeClass } = settings; 
	const { user, credit, profile } = authUser
	return {popupThemeClass, courseLoading, settings, user, credit, profile, Status, coures, pinMsg, wpqLink, wpqLoading };
};

export default withAuth0(withRouter(connect(mapStateToProps, {
	getWPQ, logoutUserFromFirebase, removePinMsg, checkPIN, getCourseFromConfigurator, collapsedSidebarAction, updateStatus, setStatusColor, getUserStatusByIdFromConfigurator, getPokeFormFromConfigurator
})(StartActivityListViewDetails)));
