import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import StartActivityListView from "./StartActivityListView";

class EditorPopup extends Component {
    
    getScrollbarsHeight=()=>{
        return "800px";
    }
    render() { 
        let{previewId}=this.props;
        return ( 
            <Scrollbars
                 className="rct-scroll"
                 autoHide 
                 style={{height: this.getScrollbarsHeight() }}
                 >
                <StartActivityListView  id={previewId} handleClose1={()=>this.props.handleClose1()} handleClose={()=>this.props.handleClose()} handleRewards={()=>this.props.handleRewards()} />
            </Scrollbars>
         );
    }
}
 
export default EditorPopup;