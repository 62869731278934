/**
* Main App
*/
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import UserProvider from "../src/providers/UserProvider"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// css
import './lib/reactifyCss';
// app component
import App from './container/App';
// import Clientslider from "./routes/about-us/components/client-slider"
import { configureStore } from './store';

const MainApp = () => (
	<Provider store={configureStore()}>
		<UserProvider>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Routes>
					<Route path="/*" element={<App/>} />
				</Routes>
			</LocalizationProvider>
		</UserProvider>
	</Provider>
);

export default MainApp;