/**
 * Clients List Actions
 */

 import {axiosClient} from '../sherpaApi'
 import moment from 'moment'
 import { NotificationManager } from 'react-notifications';
 
import {
    GET_Resource_List,
    GET_categories,
    GET_KEYWORDS,
    GET_RESOURCES_DATA_LOADING,
    HIDE_RESOURCES_DATA_LOADING,
    SET_NEW_REPORT_NOT_FOUND,
    SET_RESOURCES_SELECTED_LIST,
    CLEAR_RESOURCES_REPORTS_DATA,
    GET_RESOURCES_FILTERS,
    HANDLE_CLOSE_POST_VIEWER,
    HANDLE_SHOW_POST_VIEWER,
    SHOW_POST_LOADING,
    HIDE_POST_LOADING,
    SET_RESOURCES_FILTER_ITEMS_COUNT
} from './types';

/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout  */ 
export const getResourceList= (sort, categoryId, keywords, orgIds, isFilterApplied = false, initLoad = false) => (dispatch) => {
   
    let uid = localStorage.getItem('uid');
    let idToken = localStorage.getItem('idToken');
    console.log(" Fetching user resources list for categoryId:"+categoryId+"......");
    let url = ""
    if (orgIds && orgIds.length > 0) {
        url = `/bulletin/posts?userId=${uid}&sort=${sort}&categoryId=${categoryId}&keywords=${keywords?keywords:''}&organizations=${encodeURIComponent(JSON.stringify(orgIds))}` //bulletin/posts`
    } else {
        url = `/bulletin/posts?userId=${uid}&sort=${sort}&categoryId=${categoryId}&keywords=${keywords?keywords:''}` //bulletin/posts`
    }
  //  console.log(url);
    dispatch({type:GET_RESOURCES_DATA_LOADING, payload: {initLoad}});
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched resources list for categoryId:"+categoryId,"color:green");
        // if(response.data && response.data.length > 0) {
        //     let consoleList = [];
        //     response.data.map(resource => [
        //         consoleList.push({Id:resource.id, title:resource.title,description:resource.description,read:resource.read})
        //     ]);
        //     console.table(consoleList);
        // }
        dispatch({type:GET_Resource_List,payload:response.data});
        if(isFilterApplied) {
            const filterItem = {name: "Filtered", totalCount: response.data.length};
            dispatch(setResorcesSelectedList(filterItem));
            dispatch(setResourcesFilterItemsCount(response.data.length));
            
        }
        if(categoryId == "new" && response.data.length == 0) {
            dispatch({type:SET_NEW_REPORT_NOT_FOUND,payload:{found: false, newReportCount: 0}});
            dispatch(getcategoriesForDefaultList(orgIds, initLoad));
        } else if(categoryId == "new" && response.data.length > 0) {
            dispatch({type:SET_NEW_REPORT_NOT_FOUND,payload:{found: true, newReportCount: response.data.length}});
        }
       // console.log("resource list data --->", response.data);
    }).catch(async(error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user resources list for categoryId:"+categoryId,"color:red", errorMsg);
        dispatch({type:HIDE_RESOURCES_DATA_LOADING});        
     })
}
export const setResourcesFilterItemsCount = (count) => (dispatch) => {
    dispatch({type:SET_RESOURCES_FILTER_ITEMS_COUNT, payload: count});
}
export const getcategoriesForDefaultList = (orgIds, initLoad) => (dispatch) => {
    let uid = localStorage.getItem('uid');
    let idToken = localStorage.getItem('idToken')
    let url = ""
    if (orgIds && orgIds.length) {
        url = `/bulletin/categories?userId=${uid}&organizations=${encodeURIComponent(JSON.stringify(orgIds))}` ///bulletin/categories`
    } else {
        url = `/bulletin/categories?userId=${uid}` ///bulletin/categories`
    }
    console.log(" Fetching default user resources category list for userId:"+uid+"......");
    dispatch({ type: GET_RESOURCES_DATA_LOADING, payload: {initLoad}});
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched default user resources category list for userId:"+uid,"color:green");
        dispatch({type:GET_categories,payload:response.data})
        if(response.data && response.data.length > 0) {
            let list = response.data[0]
            dispatch(getResourceList("",list.id, '', orgIds, false, initLoad))
            dispatch(setResorcesSelectedList(list))
        }
    }).catch((error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching default user resources category list for userId:"+uid,"color:red", errorMsg);
        dispatch({type:HIDE_RESOURCES_DATA_LOADING});        
     })
 }

 export const getResouceFilters= () => (dispatch) => {
    let uid = localStorage.getItem('uid');
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("selectedOrgId")
    let url = `/bulletin/filters?userId=${uid}&organization=${orgId}` ///bulletin/filters`
    console.log(" Fetching default user resources filters list for userId:"+uid+"......");
    dispatch({type:GET_RESOURCES_DATA_LOADING});
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched default user resources filters list for userId:"+uid,"color:green");
        dispatch({type:GET_categories,payload:response.data})
        if(response.data && response.data.length > 0) {
            dispatch({type: GET_RESOURCES_FILTERS, payload: response.data})
        }
    }).catch((error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching default user resources category list for userId:"+uid,"color:red", errorMsg);
        dispatch({type:HIDE_RESOURCES_DATA_LOADING});        
     })
 }

 export const getcategories = (filteredOrgIds, initLoad) => (dispatch) => {
    let uid = localStorage.getItem('uid');
   
    let idToken = localStorage.getItem('idToken')
    let url = `/bulletin/categories?userId=${uid}` ///bulletin/categories`
    if(filteredOrgIds) {
        url = `/bulletin/categories?userId=${uid}&organizations=${encodeURIComponent(JSON.stringify(filteredOrgIds))}` ///bulletin/categories`
    }
    console.log(" Fetching user resources category list for userId:"+uid+"......");
    dispatch({type:GET_RESOURCES_DATA_LOADING, payload: {initLoad}});
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched resources category list for user:"+uid,"color:green");
        dispatch({type:GET_categories,payload:response.data})
    }).catch((error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching resources category list for userId:"+uid,"color:red", errorMsg);
        dispatch({type:HIDE_RESOURCES_DATA_LOADING});        
     })
 }

 export const getKeywords= (organizations) => (dispatch) => {
    let uid = localStorage.getItem('uid')
    //dispatch({type:GET_KEYWORDS,payload:null})
    //console.log("client uid->",uid);
    let idToken = localStorage.getItem('idToken');
    let url = `/bulletin/keywords?userId=${uid}` ///bulletin/keywords`
    if(organizations) {
        url = `/bulletin/keywords?userId=${uid}&organizations=${encodeURIComponent(JSON.stringify(organizations))}` ///bulletin/keywords`
    }
    
   // dispatch({type:GET_RESOURCES_DATA_LOADING});
   console.log(" Fetching user resources keyword list by userId:"+uid+"......");
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        let data=[];
        console.log("%c Fetched resources keyword list for user:"+uid,"color:green");
        response.data.map((da, key)=>{
            let value = null
            if (!da.name) {
                value = {id: key+1, name: da, selected:false, organization: orgId}
            } else {
                value = {id: key+1, selected:false, ...da}
            }
            data.push(value)
        });
        dispatch({type:GET_KEYWORDS,payload:data})
    }).catch(async(error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching resources keyword list for userId:"+uid,"color:red", errorMsg);
     })
 }
 export const setReportAsRead= (categoryId, postId, orgId, initLoad = false) => (dispatch) => {
   
    let uid = localStorage.getItem('uid');
    let idToken = localStorage.getItem('idToken');
  //  console.log(`/bulletin/post/${postId}?userId=${uid}&categoryId=${categoryId}&read=true`,idToken);
    let url = `/bulletin/post/${postId}?userId=${uid}&categoryId=${categoryId}&read=true&organization=${orgId}` //bulletin/posts`
  //  console.log(url);
     dispatch({type:GET_RESOURCES_DATA_LOADING, payload: {initLoad}});
     console.log(" Updating report as read :"+postId+"......");
    axiosClient.post(url,null,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type:HIDE_RESOURCES_DATA_LOADING});
        console.log("%c Updated report as read successfuly","color:green");
    }).catch(async(error) => {
        console.log("%c Error Occured while updating report as read","color:red", error);
        dispatch({type:HIDE_RESOURCES_DATA_LOADING});
    })
 }

 export const setPostOpenHistory= (categoryName, postId, postTitle, orgId) => (dispatch) => {
    const uid = localStorage.getItem('uid');
    const auth_uid = localStorage.getItem("auth_uid");
    const idToken = localStorage.getItem("idToken")
    let timestamp = moment().format('YYYY-MM-DD HH:mm:ss Z')
    const payloadData = {
        type: auth_uid === uid ? "Resources" : "Client Resources",
        context: categoryName,
        recordType: "Resource Post",
        timeStamp: timestamp,
        title: postTitle,
        postId: postId
    }
    let url = `/users/${auth_uid}/metrics?organization=${orgId}`;
    console.log(" post metrics data : ", payloadData, "URL : ", url);
    dispatch({type:GET_RESOURCES_DATA_LOADING});
    axiosClient.post(url, payloadData, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type:HIDE_RESOURCES_DATA_LOADING});
        console.log("Sent post open metrics data: ", response.data)
    }).catch(async(error) => {
        console.log("%c Error Occured while updating report as read","color:red", error);
        dispatch({type:HIDE_RESOURCES_DATA_LOADING});
    })
 }

export const setResorcesAndCategories = (resourcesList,categories) => (dispatch) => {
    dispatch({type:GET_Resource_List,payload:resourcesList});
    dispatch({type:GET_categories,payload:categories})
}
export const setResorcesSelectedList = (sellist) => (dispatch) => {
    dispatch({type:SET_RESOURCES_SELECTED_LIST,payload:sellist});
}
export const clearResourcesData = () => (dispatch) => {
    dispatch({type:CLEAR_RESOURCES_REPORTS_DATA});
   // console.log("Resources data cleared--------->")
}


export const handleClosePostViewer = () => (dispatch) => {
    dispatch({type:HANDLE_CLOSE_POST_VIEWER});
}


export const getResourcePost= (categoryId,postId,orgId) => (dispatch) => {
   
    let uid = localStorage.getItem('auth_uid');
    let idToken = localStorage.getItem('idToken');
    let url = `/bulletin/posts?userId=${uid}&categoryId=${categoryId}&organization=${orgId}`;
    dispatch({type:SHOW_POST_LOADING});
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        let post = response.data && response.data.length > 0 ? response.data.find(resource => resource.id == postId) : null;
        if(post) {
            dispatch({type:HANDLE_SHOW_POST_VIEWER, payload: post});
            if(!post.read) {
                dispatch(setReportAsRead(categoryId, postId, orgId));
            }
        } else {
            dispatch({type:HIDE_POST_LOADING});  
        }
        
    }).catch(async(error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.error("Failed to get post : ", errorMsg);
        dispatch({type:HIDE_POST_LOADING});        
     })
 }


 
export const getPostById = ({postId, categoryId}) => (dispatch) => {
    let orgId = localStorage.getItem('selectedOrgId');
    const idToken = localStorage.getItem('idToken');
    let url = `/bulletin/post/${postId}?categoryId=${categoryId}&organization=${orgId}`;
    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("Post set as opened : ", response.data);
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("Failed to set post as opened : ", errorMsg);
        NotificationManager.error(errorMsg, "Failed to set post as opened");
    })
}


 