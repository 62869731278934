/**
 * Ecommerce Dashboard
 */

 import React, { Component } from 'react'
 import { connect } from 'react-redux';
 import Webcam from "react-webcam";
 import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
 //Actions
 import {handleShowPhotoCamera} from 'Actions'
 import Dialog from '@mui/material/Dialog';
 import DialogContent from '@mui/material/DialogContent';
 import DialogActions from '@mui/material/DialogActions';
 import SweetAlert from 'react-bootstrap-sweetalert'
 import LifeSherpaLoading from '../LifeSherpaLoading';
 import {isMobile} from '../../helpers/helpers';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import { IconButton } from '@mui/material';
import pictureClickSound from '../../assets/audio/picture-sound.mp3';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

class CameraComponent extends Component {
    constructor(props) {
        super(props);
        let facingMode = "user"
        if(this.props.IsExecutingActivity && isMobile()) {
            facingMode = { exact: "environment" };
        }
        
        this.state = {
            dataUri:"",
            showWarning: false,
            cameraLoading:true,
            facingMode,
            stream:null,
            showCamera: true,
        }
        this.webcamRef = React.createRef();
        this.audioRingtone = new Audio(pictureClickSound)
    }
    
    handleTakePhoto = ()  => {
        // Do stuff with the photo...
        if (!this.state.cameraLoading) {
            this.audioRingtone.play()
            const imageSrc = this.webcamRef.current.getScreenshot();
            this.props.handleTakePhoto(imageSrc);
        }
    }

    componentDidMount() {
        // setTimeout(() => {
        //     this.setState({cameraLoading:false});
        // },4000)
    }
      
    handleCameraStart = (stream) => {
        console.log(" Camera Started --> ", stream);
        this.setState({stream: stream}, () => {
            setTimeout(() => {
                this.setState({cameraLoading:false});
            },500)
        });
        
    }

    handleClose = () => {
        console.log(" handleClose --> ",this.state.showWarning);
        this.setState({showWarning:""})
    }

    switchCamera() {
        let {facingMode} = this.state
        if (facingMode === 'user') {
            this.setState({facingMode: { exact: "environment" }})
        } else {
            this.setState({facingMode: "user"})
        }
    }

    handleCameraSupportError(e) {
        this.setState({cameraLoading:false});
        this.props.handleCameraSupportError(e);
    }

    render() {
        let {showWarning, cameraLoading, facingMode} = this.state;
        const {takePictureMessage} = this.props;
        const videoConstraints = {
            facingMode
        };
        console.log("IsExecutingActivity :", this.props.IsExecutingActivity)
         return (
             <div className={this.props.screenThemeClass}>
                 <Dialog
					open={true}
                    fullScreen={isMobile()}
					className={`camera-popup-dialog p-0 ${this.props.popupThemeClass}`}
                    onClose={() => this.handleClose()}
					>
                    <DialogContent className={`m-0 p-0 camera-popup-dialog-content fix-min-style-content`}>
                        <LifeSherpaLoading loading={cameraLoading} />
                        <div className={cameraLoading ? "hide-camara" : "webcam-container"}>
                            <Webcam
                                audio={false}
                                height={720}
                                ref={this.webcamRef}
                                screenshotFormat="image/jpeg"
                                width={1280}
                                videoConstraints={videoConstraints}
                                onUserMedia={(stream) =>this.handleCameraStart(stream)}
                                onUserMediaError={(e) => this.handleCameraSupportError(e)}
                            />
                        </div>
                        {
                            cameraLoading ? 
                            <div className='d-flex align-items-end fix-min-style-content justify-content-center'>
                                <PhotoCameraIcon className='take-picture-camera-icon'/>
                            </div>
                            : <></>
                        }
                       
                         {showWarning &&
                            <div className="col-12 p-4">
                                <SweetAlert
                                    warning
                                    btnSize="sm"
                                    customClass="warningText"
                                    confirmBtnText="OK"
                                    confirmBtnBsStyle="warning"
                                    title={"Please take a photo to continue"}
                                    onConfirm={() => this.handleClose()}
                                    >
                                <div className="sweet-alert-text"></div>
                                </SweetAlert>
                            </div>
					    }
                    </DialogContent>
                   <DialogActions className="d-flex justify-content-center camera-popup-dialog-actions">
                       <div>
                           {takePictureMessage && <div className="take-picture-message-camera">{takePictureMessage}</div>}
                            <div className="d-flex align-items-center justify-content-center mt-1">
                                {/* <div className="outer-circle" onClick={() => this.handleTakePhoto()}>
                                    <div className="inner-circle"></div>
                                </div> */}
                                <IconButton size="large" onClick={() => this.handleTakePhoto()}><CameraAltRoundedIcon fontSize="large"/></IconButton>
                                {isMobile() ? <div className='camera-switch'><IconButton size="large" onClick={() => this.switchCamera()}><SwitchCameraIcon fontSize="large"/></IconButton></div> : <></>}
                            </div>
                       </div> 
                    </DialogActions>
                 </Dialog>
            </div>
        )
    }
}
 
const mapStateToProps = ({ activitiesList, settings}) => {
    const { selectedId, showCamera } = activitiesList;
    const { screenThemeClass, popupThemeClass } = settings; 
    return { screenThemeClass, popupThemeClass, selectedId}
}
 
export default connect(mapStateToProps, {handleShowPhotoCamera})(CameraComponent)
 