/**
 * User Block
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    activitiesOpen,
    chatOpen,
    getUnConfirmtActivitiesList,
    clearUnconfirmData,
    addSelectedChatUser,
    clearHistoryData,
    showActivityHistoryDetail,
    getClientUnconfirmedAchievementsList,
    postClientMuteNotification,
    handleNoticesPanel,
    handleShowClientDashboard,
    handleUserWorkflowPanel
} from '../../../actions'

import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatSharpIcon from '@mui/icons-material/TrendingFlatSharp';

import config from '../../../config/config'
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { reauthenticateIfTokenExpireWithIn30Min } from 'Helpers/helpers';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import { logoutCallbackForAuth0Expired } from 'Actions'
import { scrollMaincontainerToTop } from "../../../helpers/helpers";
import withRouter from '../../../router';

class UserBlock extends Component {

    state = {
        windowWidth: window.innerWidth,
        mobileOpen: false,
        open: false,
        selectedRoutine: null,
        select: "activity",
        search: '',
        addDilogBox: false,
        showFilter: false,
        filter: '',
        activityFilter: [],
        listStyle: 'normal',
        update: '',
        loading: false,
        anchorEl: null,


    }

    componentDidMount() {
        console.log("%c[UserBlock]%c Rendered selected user panel block", "color:black", "color:green");
        window.addEventListener("resize", this.updateDimensions);
        document.addEventListener('nativeBackClick', this.handleNativeBack);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        document.removeEventListener('nativeBackClick', this.handleNativeBack);
    }

    handleNativeBack = () => {
        this.setState({ anchorEl: null })
    }

    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }

    handleOpenChat() {
        this.props.chatOpen(!this.props.isChatOpen)
    }

    handleLogout() {

    }

    handleClose = (id) => {
        this.props.clearUnconfirmData()
        this.props.getUnConfirmtActivitiesList(this.props.selectedMemberId, 1);
        this.props.handleClose(this.props.selectedMemberId)
    }

    handleClose1 = () => {
        // this.props.clearUnconfirmData()
        // this.props.handleClose(this.props.selectedMemberId)
    }

    handleActivity = (value) => {
        //	this.props.hideRewards()
        //	this.setState({select:value})
        let idToken = localStorage.getItem('idToken')
        let uid = localStorage.getItem('uid')
        if (!idToken) this.props.navigate('/launchscreen')
        if (value === "myactivity") {
            let userId = localStorage.getItem('uid')
        }
        this.props.setSelect(value);
    }

    handleRewards = () => {
        let idToken = localStorage.getItem('idToken')
        let uid = localStorage.getItem('uid')
        if (!idToken) this.props.navigate('/launchscreen')
        //this.setState({select:'rewards'});
        this.props.setSelect('rewards');
    }

    handleResources = () => {
        let idToken = localStorage.getItem('idToken')
        let uid = localStorage.getItem('uid')
        if (!idToken) this.props.navigate('/launchscreen')
        //this.setState({select:'Resources'});
        this.props.setSelect('Resources');
    }

    handleClickMoreIcon(event) {
        this.setState({ anchorEl: event.currentTarget });
    };

    showChatPanelForSelectedUser = (chatUser) => {
        let { selectedChatUsers } = this.props;
        console.log(" Chat opening for user ->", chatUser);
        if (chatUser) {
            if (selectedChatUsers && selectedChatUsers.length > 0) {
                let index = selectedChatUsers.findIndex(user => user && user.userId == chatUser.userId);
                if (index >= 0) {
                    // selectedChatUsers.splice(index,1)
                    // this.props.addSelectedChatUser(selectedChatUsers);
                    // selectedChatUsers.push(chatUser);  
                } else {
                    selectedChatUsers.push(chatUser);
                }
            } else {
                let usersList = [];
                usersList.push(chatUser);
                selectedChatUsers = usersList;
            }

            console.log(" Chat opened ->", selectedChatUsers);
            this.props.addSelectedChatUser(selectedChatUsers);
            this.setState({ ...this.state, anchorEl: null });
        }

    }

    handleOpenNoticePanel = (userId) => {
        this.props.handleNoticesPanel(true, userId)
        this.setState({ ...this.state, anchorEl: null });
    }

    handleOpenUserWorkflowPanel = (userId) => {
        this.props.handleUserWorkflowPanel(true, userId)
        this.setState({ ...this.state, anchorEl: null });
    }

    handleOpenDashboardPanel = (userId) => {
        this.props.handleShowClientDashboard(true, userId)
        this.setState({ ...this.state, anchorEl: null });
    }


    handleOpenUnconfirmAchievements = (userId) => {
        this.props.clearHistoryData();
        const activityConfirmation = localStorage.getItem("activityConfirmation");
        if (activityConfirmation) {
            localStorage.removeItem("activityConfirmation");
        }
        this.setState({ anchorEl: null });
        this.props.getClientUnconfirmedAchievementsList(userId, null, !isMobile());
        scrollMaincontainerToTop()
        this.props.showActivityHistoryDetail(true);
        this.props.navigate("/app/lsdashboard/activities/history")
    }

    getScrollHeight() {
        return "235px";
    }

    handleMuteNotification() {
        const { muteNotifications } = this.props
        const enabled = !muteNotifications
        const adminUserId = localStorage.getItem("auth_uid")
        const clientUserId = localStorage.getItem("uid")
        this.setState({ anchorEl: null });
        this.props.postClientMuteNotification(adminUserId, clientUserId, enabled)
    }

    render() {
        const { networkActivities, clientUserDetail, selectedMember, enableWorkflows, selectedMemberId, contactList, groupMembers, muteLoading, muteNotifications, userWorkflows, clientPanel } = this.props
        let { anchorEl } = this.state;
        let statusList = [];
        if(groupMembers && selectedMemberId && selectedMember) {
            let  selectedMemberDetails = groupMembers[selectedMember.groupId] && groupMembers[selectedMember.groupId][selectedMemberId];
            if (selectedMemberDetails && selectedMemberDetails.lastUserStatusSets) {
                Object.keys(selectedMemberDetails.lastUserStatusSets).map((status, key) => {
                    let obj = { color: selectedMemberDetails.lastUserStatusSets[status].color, status: selectedMemberDetails.lastUserStatusSets[status].status, direction: selectedMemberDetails.lastUserStatusSets[status].direction };
                    statusList.push(obj);
                })
            }
        }

        let chatUser = contactList ? contactList.find(userInfo => userInfo.userId == selectedMemberId) : null;
        let networkData = networkActivities ? networkActivities.find(ele => ele.userId == selectedMemberId) || {} : {};
        const totalNetworkActivityCount = (networkData.unconfirmedAchievements || 0) + (networkData.unreadMessages || 0) + (networkData.unreadClientAlerts || 0) + (userWorkflows && userWorkflows.count || 0);
        let profileImageURL = (selectedMember && selectedMember.profileImageURL) || (clientUserDetail && clientUserDetail.profileImageURL);
        let displayName = (selectedMember && selectedMember.name) || (clientUserDetail && clientUserDetail.displayName);

        return (
            <div >
                <LifeSherpaLoading loading={this.state.loading || muteLoading} />
                {this.props.selectedRole == 'admin' && clientPanel ?
                    <div className="row user-block-height" >
                        <div className="col-12">
                            <div className="media">
                                <h1 className="mt-50 mx-auto ">No Data Available</h1>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row ">
                        <div className="col-12  " data-testid="details-panel-client-block">
                            <div className="media ">
                                <div className="media-left pl-3 position-relative ">
                                    <img data-testid="details-panel-client-block-image" src={profileImageURL && displayName !== "Test User" ? profileImageURL : require('Assets/avatars/user-15.jpg')} alt="user profile" className="cps rounded-circle" width="140" height="140"  /*onClick={() => this.handleOpenDashboardPanel(selectedMemberId)}*/ />
                                    {totalNetworkActivityCount ? <div title="Unconfirmed Activities" style={{ cursor: 'pointer' }} onClick={() => this.handleOpenDashboardPanel(selectedMemberId)} className="badge-extension badge-danger rounded-circle">{totalNetworkActivityCount}</div> :""}
                                    {muteNotifications ? 
                                        <></>
                                        : 
                                        <div data-testid={`my-client-detail-mute-icon`} title="Muted" className="badge-extension-mute ">
                                            <VolumeOffOutlinedIcon fontSize='large'/>
                                        </div>
                                    }
                                </div>
                                <div className="media-body mr-3" >
                                    <div className="user-block-body">
                                        <div className="d-flex align-items-center  ">
                                            <h1 data-testid="details-panel-client-title" className="user-name-font">{displayName || "Display Name"}</h1>
                                            <IconButton className="p-1 user-more-icon-button" title="Client More Actions" onClick={(e) => this.handleClickMoreIcon(e)}>
                                                <MoreVertIcon aria-label="More" aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" />
                                            </IconButton>    
                                        </div>
                                        <Menu id="simple-menu-user-block" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => this.setState({ anchorEl: null })}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            getcontentanchorel={null}
                                        >
                                            {/* <MenuItem className="menuEditItem" onClick={() => this.handleOpenDashboardPanel(selectedMemberId)}>Dashboard</MenuItem> */}
                                            {chatUser ? <MenuItem className="menuEditItem" onClick={() => this.showChatPanelForSelectedUser(chatUser)}>Chat</MenuItem> : ""}
                                            <MenuItem className="menuEditItem" onClick={() => this.handleMuteNotification()}>{muteNotifications ? "Mute Notifications" : "Unmute Notifications"}</MenuItem>
                                            <MenuItem className="menuEditItem" onClick={() => this.handleOpenNoticePanel(selectedMemberId)}>Alerts</MenuItem>
                                            {enableWorkflows ? <MenuItem className="menuEditItem" onClick={() => this.handleOpenUserWorkflowPanel(selectedMemberId)}>Workflows</MenuItem> : ""}
                                        </Menu>
                                        <div className="row user-block-status ">
                                            <div className="col-12  mt-1">
                                                <ul className="list-inline mb-0">
                                                    <li className="text-white text-right" >
                                                        <div className="row d-none d-md-flex">
                                                            {statusList.map((info, key) =>
                                                                <div data-testid={`details-panel-client-status-list${key}responsive`} key={key + "statuslist"} className="d-flex w-auto mt-1 py-1  mr-1 px-3" style={{ borderRadius: 20 + "px", background: info.color }}>
                                                                    {info.direction == "neutral" ? <TrendingFlatSharpIcon className="my-auto" />
                                                                        : info.direction == "positive" ? <TrendingUpIcon className="my-auto" />
                                                                            : info.direction == "negative" ? <TrendingDownIcon className="my-auto" /> : ""}
                                                                    <div className="my-auto">{info.status}</div>
                                                                </div>
                                                            )}

                                                        </div>
                                                        <div className="row mr-1 d-block d-md-none">
                                                            {statusList.map((info, key) =>
                                                                <div data-testid={`details-panel-client-status-list${key}small`} key={key + "statuslist"} className="d-flex w-auto mt-1 py-1  mr-1 px-3 justify-content-between" style={{ borderRadius: "6px", background: info.color, width: "auto" }}>
                                                                    {info.direction == "neutral" ? <TrendingFlatSharpIcon className="my-auto" />
                                                                        : info.direction == "positive" ? <TrendingUpIcon className="my-auto" />
                                                                            : info.direction == "negative" ? <TrendingDownIcon className="my-auto" /> : <div style={{ width: "1.5rem" }}></div>}
                                                                    <div className="my-auto">{info.status}</div>
                                                                </div>
                                                            )}

                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ chatAppReducer, activitiesList, GroupList, settings, Contacts, clientsList, workflowData, authUser }) => {
    const { isActivitiesOpen, displayName, profileImage, status, checkIn, isChatOpen, selectedMemberId, selectedRole, email, selectedMember, selectedChatUsers } = chatAppReducer
    const { activeTheme } = settings;
    const { allInprogressActivities, loading, userActivities, networkActivities } = activitiesList
    const { groupMembers, groupMembersDetails } = GroupList
    let { contactList } = Contacts;
    const {muteNotifications, clientUserDetail, clientPanel} = clientsList
    const muteLoading = clientsList ? clientsList.loading : null
    const {userWorkflows} = workflowData
    return { networkActivities, selectedMember, userActivities, isActivitiesOpen, displayName, profileImage, selectedMemberId, status, checkIn, isChatOpen, selectedRole, allInprogressActivities, loading, email, groupMembers, groupMembersDetails, activeTheme, contactList, selectedChatUsers, muteNotifications, muteLoading, clientUserDetail, userWorkflows, clientPanel};
}

export default withRouter(connect(mapStateToProps, {
    activitiesOpen,
    chatOpen, getUnConfirmtActivitiesList, clearUnconfirmData,
    addSelectedChatUser,
    clearHistoryData,
    showActivityHistoryDetail,
    getClientUnconfirmedAchievementsList,
    postClientMuteNotification,
    handleNoticesPanel,
    handleShowClientDashboard,
    handleUserWorkflowPanel
})(UserBlock));
