import React, { useState, useEffect, useRef } from "react";
import MicOffRoundedIcon from '@mui/icons-material/MicOffRounded';
import MicIcon from '@mui/icons-material/Mic';
import Avatar from '@mui/material/Avatar';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import IconButton from '@mui/material/IconButton';
//import PushPinIcon from '@mui/icons-material/PushPin';
 import MaximizeIcon from '@mui/icons-material/Maximize';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';

const Participant = ({ participant, 
  videoMuteLocal,
  muteLocal, 
  getDisableVideoAvatarStyle, 
  keyIndex, 
  realTimeData,
  handlePingParticipant, 
  totalRemoteParticipant, 
  isLocalHostOfGroup,
  pingParticipantIdentitiy, 
  handleRemoteParticipantRemove,
  handleRemoteParticipantMute,
  handleRemoteParticipantUnMute,
  dominantSpeakerIdentity,
  setLocalMediaRefs,
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [mute, setMute]= useState('')
  const [videoMute, setVideoMute]= useState('')
  const [showScreenOptions, setShowScreenOptions]= useState(false);
  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));
    if(participant.localUser) {
      setLocalMediaRefs(videoRef, audioRef);
    }
    const trackSubscribed = (track) => {
      console.log(" participant ---->",participant);
      console.log(" track subscribed -->",track);
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      console.log(" participant ---->",participant);
      console.log(" track unsubscribed -->",track);
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    if(!participant.localUser) {
      participant.on("trackSubscribed", trackSubscribed);
      participant.on("trackUnsubscribed", trackUnsubscribed);  
    }
    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.on('enabled', (track) => {
        console.log(" video enabled ------------>");
        setVideoMute('')

      });
      videoTrack.on('disabled', (track) => {
        console.log(" video disabled ------------>");
        setVideoMute('mute')
      });
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
        audioTrack.on('enabled', (track) => {
        console.log(" audio enabled ------------>");
        setMute('');
      });
        audioTrack.on('disabled', (track) => {
        console.log(" audio disabled ------------>")
        setMute('mute');
      });
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);
  const vidoeStatus = videoTracks[0] && videoTracks[0].isEnabled ? videoTracks[0].isEnabled : false;
  const audioStatus = audioTracks[0] && audioTracks[0].isEnabled ? audioTracks[0].isEnabled : false;
  const participantName = participant.localUser ? "yourself" :  participant.name ?  participant.name :  "participant";
  let pinTitle = "Pin "+participantName+" to your main screen";
  if(participant.identity == pingParticipantIdentitiy ) {
      pinTitle = "Unpin "+participantName+" from your main screen";
  }

  return (
    <div className={`participant-container mx-auto ${dominantSpeakerIdentity == participant.identity ? "dominantSpeakerStyle":"" }`} id={participant.identity+keyIndex} onMouseEnter={() => setShowScreenOptions(true)} onMouseLeave={() => setShowScreenOptions(false)}> 
      <div className="mic-icon-on-video" >
        <IconButton  className="mic-icon-button-onvideo">
          {audioStatus ? <MicIcon/> :  <MicOffRoundedIcon/> }
        </IconButton>
      </div>
      {!vidoeStatus && 
        <div className="disabled-video-avatar"> 
          {participant.profileImageURL ?
            <Avatar
              alt="Remy Sharp"
              src={participant.profileImageURL}
              variant="circular"
              overlap="circular"
              style={getDisableVideoAvatarStyle(participant.identity+keyIndex)}
            />
            :
            <Avatar style={getDisableVideoAvatarStyle(participant.identity+keyIndex)}>{participant.name ? participant.name.charAt(0):"A"}</Avatar>
          }
        </div>
      }
      <video 
        ref={videoRef} 
        id={participant.identity+"-"+keyIndex}
        autoPlay={true} 
        className={`${vidoeStatus ? "" : "video-invisible" }`} 
        />
      <audio 
        ref={audioRef} 
        autoPlay={true} 
        />

      {realTimeData && realTimeData.group && 
        <div className="video-screen-options">
          <div className="options-list" > 
            {totalRemoteParticipant > 1 && <Tooltip title={pinTitle} arrow><IconButton onClick={handlePingParticipant}>{participant.identity == pingParticipantIdentitiy ?  <FullscreenExitIcon/> : <FullscreenIcon/> }</IconButton></Tooltip>}
            {!participant.localUser && isLocalHostOfGroup && <Tooltip title={`${audioStatus ? "Mute":"Unmute"} ${participantName}`} arrow><IconButton onClick={audioStatus ? handleRemoteParticipantMute : handleRemoteParticipantUnMute}>{audioStatus ? <MicIcon/> :  <MicOffRoundedIcon/> }</IconButton></Tooltip>}
            {!participant.localUser && isLocalHostOfGroup && <Tooltip title={`Remove ${participantName} from the call`} arrow><IconButton onClick={handleRemoteParticipantRemove}> <RemoveCircleOutlineIcon/> </IconButton></Tooltip>} 
          </div>
        </div>
      }
      {participant.name ? 
        <div className="client-name-on-video">
          <h3>{participant.localUser ? " You" :  participant.name}</h3>
        </div>
          :
         ""
      }
    </div>
  );
};

export default Participant;
