import axios from 'axios';
import config from '../config/config'
import {logoutCallbackForAuth0Expired} from '../actions'
import { reauthenticateIfTokenWillExpire, getFibonacciBackoff, retryableRequestError, getRetryDelay, onRetry} from '../helpers/helpers';
// const axiosRetry = require("axios-retry");
import axiosRetry from 'axios-retry';


import WidgetServices from "../services/widgetServices";


let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};
let postMessageSend = false
 
const retryWrapper = (APIKit) => {
    APIKit.interceptors.response.use(
        response => {
            return response;
        },
        err => {
            const originalRequest = err.config;
            const errorCodes = ["E1103", "E1054", "E1055", "E1056", "E1057", "E1097", "E1098", "E1099", "E1100", "E1101", "E1102"];
            const errorCode = err && err.response && err.response.data && err.response.data.errorCode;
            const widgetApp = localStorage.getItem("widgetApp");
            if(widgetApp) {
                const widgetServices = new WidgetServices()
                if(widgetServices.isWidgetApp() && !postMessageSend && originalRequest && errorCodes.findIndex(rcode => rcode == errorCode) >= 0) {
                    console.log("postMessageSend : ", postMessageSend)
                    widgetServices.idTokenExpiredHandler();
                    postMessageSend = true
                } 
                return Promise.reject(err);
            } else {
                if (originalRequest && errorCodes.findIndex(rcode => rcode == errorCode) >= 0 && !originalRequest._retry) {
                    console.log("isRefreshing --->", isRefreshing)
                    console.log("failedQueue --->", failedQueue)
                    if((window.location.pathname && (window.location.pathname == "/launchscreen" || window.location.pathname == "/signinEmail"))) {
                            return Promise.reject(err); 
                    } 
                    if (isRefreshing) {
                        return new Promise(function (resolve, reject) {
                            failedQueue.push({ resolve, reject });
                        })
                            .then(token => {
                                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                return APIKit(originalRequest);
                            })
                            .catch(err => {
                                return Promise.reject(err);
                            });
                    }
                    originalRequest._retry = true;
                    isRefreshing = true;
                    return new Promise(function(resolve, reject) {
                        reauthenticateIfTokenWillExpire()
                            .then((reAuthenticated) => {
                                console.log("reAuthenticated --->", reAuthenticated)
                                if(reAuthenticated) {
                                    APIKit.defaults.headers.common['Authorization'] = 'Bearer ' + reAuthenticated;
                                    originalRequest.headers['Authorization'] = 'Bearer ' + reAuthenticated;
                                    processQueue(null, reAuthenticated);
                                    resolve(APIKit(originalRequest));
                                } else {
                                    if (window.location.pathname && window.location.pathname != "/activity/preview") {
                                        logoutCallbackForAuth0Expired();
                                    }
                                    processQueue(err, null);
                                    reject(err);
                                }  
                            })
                            .catch(err => {
                                if(window.location.pathname && window.location.pathname != "/activity/preview") {
                                    logoutCallbackForAuth0Expired();
                                }
                                processQueue(err, null);
                                reject(err);
                            })
                            .then(() => {
                                isRefreshing = false;
                            });
                    });
                } 
                return Promise.reject(err);
            }
        }
    );
    return APIKit;
}
const axiosClientWithRetryLimit = (axiosClient) => {
    return retryWrapper(axiosClient);
}


const axiosClientWithOutRetry = axios.create({
    baseURL: config.baseURL,
    timeout: 30000
 });

const axiosAuthClientWithoutRetry = axios.create({
    baseURL: config.authAPIURL
});


const axiosClient = axiosClientWithRetryLimit(axiosClientWithOutRetry);

const axiosAuthClient = axiosClientWithRetryLimit(axiosAuthClientWithoutRetry);

axiosRetry(axiosClient, {
    retries: 3,
    retryCondition: retryableRequestError,
    retryDelay: getRetryDelay,
    shouldResetTimeout: true,
    onRetry: onRetry
});

axiosRetry(axiosAuthClient, {
    retries: 3,
    retryCondition: retryableRequestError ,
    retryDelay: getRetryDelay,
    onRetry: onRetry
});




const userProfileAPI = axios.create({
   baseURL: 'https://en.gravatar.com/',
   timeout: 30000
})

const locationAPI = axios.create({
   baseURL: "https://ipapi.co/json/",
   timeout: 30000
})

const geoCoding = axios.create({
   baseURL: "https://maps.googleapis.com/maps/api/geocode"
})

const lifesherpaAuthBaseAPI = axios.create({
   baseURL: config.authBaseURL
})

axiosRetry(lifesherpaAuthBaseAPI, {
    retries: 3,
    retryCondition: retryableRequestError ,
    retryDelay: getRetryDelay
});

export {
   axiosClient,
   userProfileAPI,
   locationAPI,
   geoCoding,
   lifesherpaAuthBaseAPI,
   axiosAuthClient
}
