import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainApp from './App';
import AppWidget from './AppWidget';
import ExternalPages from './externalPages/App';

/**
 * Main App Navigator
 */
const AppNavigator = () => (
    <Router>
        <Routes>
            <Route path="/widget/v3/eastbay/:widgetApp" component={AppWidget} />
            <Route path="/widget/v3/:widgetApp" element={<AppWidget />} />
            <Route path="/widget/v2/:widgetApp" element={<AppWidget />} />
            <Route path="/widget/:widgetApp" element={<AppWidget />} />
            <Route path="/external-links" element={<ExternalPages />} />
            <Route path="/*" element={<MainApp />} />
        </Routes>
    </Router>
);

export default AppNavigator;