/**
 * Client Dashboard
 */

 import React, { Component } from 'react'
 import { connect } from 'react-redux'
import Dashboard from '../../../components/UserDetails/components/Dashboard';

 //Actions
 import {
     getUserById,
     handleShowClientDashboard
 } from '../../../actions'
  
 class ClientDashboard extends Component {
 
     constructor(props) {
         super(props)
         this.state = {
             role: null
         }
     }
    
 
    componentDidMount() {
         const {widgetApp} = this.props;
         const uid = localStorage.getItem('uid');
         const idToken = localStorage.getItem('idToken')
        this.props.getUserById(uid, idToken, widgetApp)
    }
 
    render() {
        const {themeLoading, userLoading} = this.props;
        return (
            <div className="w-100 " data-testid="ls-dashboard">
            <div className="row">
                <div
                    className="col-12 w-xs-full"
                >
                    <Dashboard widgetApp={this.props.widgetApp} widgetVersion={this.props.widgetVersion}/>
                </div>
            </div>
        </div>
        )
    }
 }
 
 const mapStateToProps = ({ authUser}) => {
     const { themeLoading, userLoading } = authUser
     return { themeLoading, userLoading }
 }
 
 export default connect(mapStateToProps, {
    getUserById,
    handleShowClientDashboard
 })(ClientDashboard)
 