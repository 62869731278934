/**
 * Client Alerts
 */

 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 
import Notices from '../../../components/Notices';

 //Actions
 import {
     getUserById,
     handleNoticesPanel,
     clearNoticesData
 } from '../../../actions'
  
 class ClientAlerts extends Component {
 
     constructor(props) {
         super(props)
         this.state = {
             role: null
         }
     }
    
 
    componentDidMount() {

         const { widgetApp } = this.props;
         this.props.clearNoticesData();
         const uid = localStorage.getItem('uid');
         const idToken = localStorage.getItem('idToken')
        this.props.getUserById(uid, idToken, widgetApp)
        this.props.handleNoticesPanel(true, uid);
    }
 
    render() {
        const {themeLoading, userLoading} = this.props;
        return (
            <div>
                {!(themeLoading || userLoading) && <Notices widgetApp={this.props.widgetApp} params={this.props.params}/>} 
            </div>
        )
    }
 }
 
 const mapStateToProps = ({ authUser}) => {
     const { themeLoading, userLoading } = authUser
     return { themeLoading, userLoading }
 }
 
 export default connect(mapStateToProps, {
     getUserById,
     handleNoticesPanel,
     clearNoticesData
 })(ClientAlerts)
 