import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid2';
import { Label } from 'reactstrap'
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DialogTitle from "@mui/material/DialogTitle";

import { applyUserOrgFilter, clearUserOrgFilter, networkActivity } from "../../actions"

class ActivitiesFilterPopup extends Component {

    state = {
        collection: '',
        filterOrgOptions: [],
        filteredGroupOptions: []
    }

    componentDidMount(){
        const {panel, selectedGroup, groups} = this.props
        const filterOrgOptions = this.getOrganizationFilterData(panel);
        console.log("selectedGroup: ", selectedGroup, filterOrgOptions)
        let filteredGroupOptions = [];
        if(selectedGroup) {
            if(typeof selectedGroup === 'string') {
                const index = groups ? groups.findIndex(group => group.id === selectedGroup) : -1;
                if(index !== -1) {
                    filteredGroupOptions.push({...groups[index]});
                }
            } else {
                filteredGroupOptions = selectedGroup;
            }
        }
        this.setState({filterOrgOptions, filteredGroupOptions});
        document.addEventListener('nativeBackClick', this.handleNativeBack);
    }

    getOrganizationFilterData(panel){
        let filterOrgs = null
        const {clientPanelOrgFiltered, activitiesPanelOrgFiltered, coursePanelOrgFiltered, resourcePanelOrgFiltered, workflowPanelOrgFiltered, workflowSummaryPanelOrgFiltered} = this.props
        switch(panel) {
            case "client":
                filterOrgs = clientPanelOrgFiltered
                break;
            case "activities":
                filterOrgs = activitiesPanelOrgFiltered
                break;
            case "courses":
                filterOrgs = coursePanelOrgFiltered
                break;
            case "resources":
                filterOrgs = resourcePanelOrgFiltered
                break;
            case "workflows":
                filterOrgs = workflowPanelOrgFiltered
                break;
            case "workflow_summary_report":
                filterOrgs = workflowSummaryPanelOrgFiltered
                break;
            default: 
                filterOrgs = clientPanelOrgFiltered
                break;
        }
        return filterOrgs
    }

    handleNativeBack = () => {
        console.log("native back clicked for actiivty filter popup ........");
        this.props.handleCloseFilter()
    }

    componentWillUnmount() {
        document.removeEventListener('nativeBackClick', this.handleNativeBack);
    }

    handleFilter = (id) => {
        this.props.handelFilterOpitonsChange(id);
        this.setState({ collection: id });
    }

    handelOrgIdFilter(id) {
        let {filterOrgOptions} = this.state
        if(filterOrgOptions && filterOrgOptions.length > 0) {
            const index = filterOrgOptions.findIndex(ele => ele === id)
            if (index === -1) {
                filterOrgOptions.push(id)
            } else {
                filterOrgOptions.splice(index, 1)
            }
        } else {
            const orgIds = [id]
            filterOrgOptions = orgIds
        }
        this.setState({filterOrgOptions})
    }

    applyFilter(){
        const {filterOrgOptions, filteredGroupOptions} = this.state;
        const {selectedGroup} = this.props
        console.log("filterOrgOptions: ",filterOrgOptions)
        const groups = this.getFilteredGroup()
        const {title, panel} = this.props
        if (title && (title  === "Clients Filter" || title === "Organization Filter") ) {
            if(panel === "workflows" || panel === "workflow_summary_report") {
                this.props.applyFilter(filterOrgOptions)
            } else {
                this.props.applyUserOrgFilter({panel, orgIds: filterOrgOptions, groups, selectedGroup});
                // this.props.networkActivity(filterOrgOptions, "hideLoading")
            }
            this.props.handleCloseFilter();
            
        } else {
            this.props.applyUserOrgFilter({panel, orgIds: filterOrgOptions})
            this.props.applyFilter(filterOrgOptions)
        }

        if (panel == "workflow_summary_report") {
            this.props.handleFilterWorkflowChart(filterOrgOptions)
        }
    }

    clearFilter(){
        const {title, panel} = this.props
        if (title && (title  === "Clients Filter" || title === "Organization Filter") ) {
            if(panel === "workflows" || panel === "workflow_summary_report") {
                this.props.clearFilter()
            } else {
                this.props.clearUserOrgFilter({panel});
            }
            this.props.handleCloseFilter()
        } else {
            this.props.clearUserOrgFilter({panel})
            this.props.clearFilter()
        }

        if (panel == "workflow_summary_report") {
            this.props.handleFilterWorkflowChart()
        }
    }

    handleOrgGroupChange(group) {
        let {filteredGroupOptions} = this.state
        if(filteredGroupOptions && filteredGroupOptions.length > 0) {
            const index = filteredGroupOptions.findIndex(gele => gele.id === group.id)
            if (index === -1) {
                filteredGroupOptions.push(group)
            } else {
                filteredGroupOptions.splice(index, 1)
            }
        } else {
            filteredGroupOptions.push(group)
        }  

        this.setState({filteredGroupOptions})
    }

    getFilteredGroup() {
        const { groups } = this.props
        const {filterOrgOptions, filteredGroupOptions} = this.state
        let filteredGroups = []
        if (filteredGroupOptions && filteredGroupOptions.length > 0) {
            return filteredGroupOptions
        } else if (filterOrgOptions && groups && groups.length > 0 && filterOrgOptions.length > 0) {
            for (let index = 0; index < filterOrgOptions.length; index++) {
                const id = filterOrgOptions[index];
                const orgGroups = groups.filter(group => group.orgId === id)
                console.log("org filter Groups: ", orgGroups)
                filteredGroups = filteredGroups.concat(orgGroups)
                console.log("org concat Groups: ", filteredGroups)
            }
            return filteredGroups
        } else {
            return groups
        } 
    }

    render() {
        let { activityGroups, filterOptions, popupThemeClass, title="Activities Filter", subTitle="Groups", organizations, groups, selectedGroup, panel } = this.props;
        let orgsArray = organizations && organizations.length > 0 ? [...organizations] : null;
        if((panel === "workflows" || panel === "workflow_summary_report") && orgsArray) {
            orgsArray = orgsArray.filter(org => org.enableWorkflows);
        }
        const {filterOrgOptions, filteredGroupOptions} = this.state
        const loggedInUser = localStorage.getItem("auth_uid")
        const clientUserId = localStorage.getItem("uid")
        const showOrgFilter = loggedInUser === clientUserId ? true : false
        let filteredActivityGroups = [];
        if (filterOrgOptions && activityGroups && activityGroups.length > 0 && filterOrgOptions.length > 0) {
            for (let index = 0; index < filterOrgOptions.length; index++) {
                const id = filterOrgOptions[index];
                const orgActivityGroups = activityGroups.filter(actgroup => actgroup.name === "Private Activities" || actgroup.orgId === id)
                filteredActivityGroups = filteredActivityGroups.concat(orgActivityGroups)
            }
        } else {
            filteredActivityGroups = activityGroups
        }
        let filteredGroups = []
        if (filterOrgOptions && groups && groups.length > 0 && filterOrgOptions.length > 0) {
            for (let index = 0; index < filterOrgOptions.length; index++) {
                const id = filterOrgOptions[index];
                const orgGroups = groups.filter(group => group.orgId === id)
                console.log("org filter Groups: ", orgGroups)
                filteredGroups = filteredGroups.concat(orgGroups)
                console.log("org concat Groups: ", filteredGroups)
            }
        } else {
            filteredGroups = groups
        }

        if (panel === "activities" && orgsArray && orgsArray.length > 0 && showOrgFilter) {
            const searchPrivateActivity = orgsArray.findIndex(org => org.id === "Private Activities")
            searchPrivateActivity === -1 ? orgsArray.push({id: "Private Activities", name: "Private Activities"}) : ""/*orgsArray.splice(searchPrivateActivity, 1)*/
        }
       
        const privateGroup = filteredActivityGroups && filteredActivityGroups.find(group => group.name === "Private Activities");
        // filteredActivityGroups = filteredActivityGroups && filteredActivityGroups.filter(group => group.name !== "Private Activities");
        return (
            <Dialog
                fullScreen={false}
                open={this.props.showFilter}
                onClose={() => this.props.handleCloseFilter()}
                fullWidth={true}
                maxWidth="sm"
                className={`${popupThemeClass}`}
                aria-labelledby="responsive-dialog-title"
                id='actiivties-filter-popup'
                >
                <DialogTitle>
                    <div data-testid="resource-filter-dialog" className="d-flex justify-content-between align-items-center py-2 px-3 bg-lifesherpa">
                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">{title}</Label>
                        <IconButton data-testid="close-icon-for-filter-dialog" title='Close Filter Popup' className="text-white" onClick={() => this.props.handleCloseFilter()}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="pl-4 pr-4 activities-filter-dialog-content">
                    {showOrgFilter && (panel !== "client" && title !== "Activities Filter") ?
                        <>
                            <div className="text-secondary filter-heading  fw-semi-bold my-2">Organization</div>
                            <Grid
                                container
                                direction="row"
                                justifycontent="flex-start"
                                alignItems="flex-start"
                                >
                                {orgsArray && orgsArray.map((list, key) => (
                                    <Grid item key={key}>
                                        {filterOrgOptions && filterOrgOptions.length > 0 && filterOrgOptions.find(gid => gid == list.id) ?
                                            <Chip
                                                key={key}
                                                className={" mr-1 my-1 chipSelected "}
                                                avatar={<CheckCircleOutlinedIcon  />}
                                                label={list.name}
                                                style={{ fontSize: "16px" }}
                                                onClick={() => this.handelOrgIdFilter(list.id)} 
                                                />
                                            :
                                            <Chip
                                                key={key}
                                                className={" mr-1 my-1  chipStyle"}
                                                label={list.name}
                                                style={{ fontSize: "16px" }}
                                                onClick={() => this.handelOrgIdFilter(list.id)} 
                                                />
                                        }
                                    </Grid>
                                ))}
                                 {title === "Activities Filter" && privateGroup &&
                                    <Grid item >
                                        {filterOptions.find(gid => gid == privateGroup.id) ?
                                            <Chip
                                                className={" mr-1 my-1 chipSelected "}
                                                avatar={<CheckCircleOutlinedIcon  />}
                                                label={privateGroup.name}
                                                style={{ fontSize: "16px" }}
                                                onClick={() => this.handleFilter(privateGroup.id)} 
                                                />
                                            :
                                            <Chip
                                                className={" mr-1 my-1  chipStyle"}
                                                label={privateGroup.name}
                                                style={{ fontSize: "16px" }}
                                                onClick={() => this.handleFilter(privateGroup.id)} 
                                                />
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </>
                        : <></>
                    }
                    {panel === "client" && orgsArray && orgsArray.length > 0 ? orgsArray.map((list, key) => 
                        <div key={key}>
                            <div className="text-secondary filter-heading  fw-semi-bold my-2">{`Groups of ${list.name} Organization`}</div>
                            <Grid
                                container
                                direction="row"
                                justifycontent="flex-start"
                                alignItems="flex-start"
                                >
                                {filteredGroups && filteredGroups.length > 0 ? filteredGroups.map((group, key) => (
                                    <Grid item key={key}>
                                        {filteredGroupOptions && filteredGroupOptions.length > 0 && filteredGroupOptions.find(gr  => gr.id === group.id && list.id === group.orgId) ?
                                            <Chip
                                                key={key}
                                                className={" mr-1 my-1 chipSelected "}
                                                data-testid={`Selected-${group.name}`}
                                                avatar={<CheckCircleOutlinedIcon  />}
                                                label={group.name}
                                                style={{ fontSize: "16px" }}
                                                onClick={() => this.handleOrgGroupChange(group)} 
                                                />
                                            : list.id === group.orgId ?
                                            <Chip
                                                key={key}
                                                className={" mr-1 my-1  chipStyle"}
                                                data-testid={`Unselected-${group.name}`}
                                                label={group.name}
                                                style={{ fontSize: "16px" }}
                                                onClick={() => this.handleOrgGroupChange(group)} 
                                                />
                                               : <></>
                                        }
                                    </Grid>
                                )) : <Grid item key={0}>No group found!</Grid>}
                            </Grid> 
                        </div>) : <></>
                    }
                    {title === "Activities Filter" && orgsArray && orgsArray.length > 0 ? orgsArray.map((orglist, key) =>
                        <div key={key} className={showOrgFilter ? "" : "mt-3"}>
                            {(orglist.name !== 'Private Activities' || (orglist.name === 'Private Activities' && privateGroup)) && showOrgFilter ? <div className="text-secondary filter-heading  fw-semi-bold my-2">{orglist.name !== 'Private Activities' ? `${subTitle} of ${orglist.name} Organization` : `${subTitle} of ${orglist.name}`}</div> : <></>}
                            <Grid
                                container
                                direction="row"
                                justifycontent="flex-start"
                                alignItems="flex-start"
                                >
                                {filteredActivityGroups &&  filteredActivityGroups.length > 0 ? filteredActivityGroups.map((list, key) => (
                                    <Grid item key={key}>
                                        { list.organization === orglist.id && filterOptions.find(gid => gid == list.id) ?
                                            <Chip
                                                key={key}
                                                className={" mr-1 my-1 chipSelected "}
                                                data-testid={`Selected-${list.name}`}
                                                avatar={<CheckCircleOutlinedIcon  />}
                                                label={list.name}
                                                style={{ fontSize: "16px" }}
                                                onClick={() => this.handleFilter(list.id)} 
                                                />
                                            : list.organization === orglist.id ?
                                            <Chip
                                                key={key}
                                                className={" mr-1 my-1  chipStyle"}
                                                data-testid={`Unselected-${list.name}`}
                                                label={list.name}
                                                style={{ fontSize: "16px" }}
                                                onClick={() => this.handleFilter(list.id)} 
                                                />
                                            :<></>
                                        }
                                    </Grid>
                                )) : <Grid item key={0}>No activity group found!</Grid>
                                }
                            </Grid>
                        </div>)
                        : <></>
                    }
                </DialogContent>
                <DialogActions className="p-4 filter-buttons-styles">
                     <Button color="primary" variant="outlined" title='Clear Filter' data-testid="cancel-filter-button" className="  w-50 filter-button-cancel"  onClick={() => this.clearFilter()}>
                        CLEAR FILTERS
                     </Button>
                     <Button color="primary" variant="contained" title='Apply Filter' data-testid="apply-filter-button" className=" w-50 filter-button-apply"  onClick={() => this.applyFilter()}>
                        APPLY
                     </Button>
                </DialogActions>
            </Dialog>

        );
    }
}

const mapStateToProps = ({settings, clientsList, GroupList}) => { 
    const { groups, selectedGroup} = GroupList
    const { screenThemeClass, popupThemeClass } = settings;
    const {organizations, clientPanelOrgFiltered, activitiesPanelOrgFiltered, coursePanelOrgFiltered, resourcePanelOrgFiltered} = clientsList 
    return {screenThemeClass, popupThemeClass, organizations, clientPanelOrgFiltered, activitiesPanelOrgFiltered, coursePanelOrgFiltered, resourcePanelOrgFiltered, groups, selectedGroup};
}

export default connect(mapStateToProps, { 
    applyUserOrgFilter, clearUserOrgFilter, networkActivity
})(ActivitiesFilterPopup);