/**
 * Reactify - A Material Design Admin Template
 * Copyright 2020-21 All Rights Reserved
 * Made With Love
 * Created By The Iron Network, LLC
 */
// import React from 'react';
// import ReactDOM from 'react-dom';

// // Save a reference to the root element for reuse
// const rootEl = document.getElementById("root");

// // Create a reusable render method that we can call more than once
// const AppNavigator = require("./AppNavigator").default;

// let render = () => {
//   // Dynamically import our main App component, and render it
//   const MainApp = require('./App').default;
//   ReactDOM.render(
//       <AppNavigator/>
//     ,
//     rootEl
//   );
// };

// if (module.hot) {
//   module.hot.accept('./App', () => {
//     const NextApp = require("./AppNavigator").default; // require('./App').default;
//     if(rootEl) {
//       render(
//         <React.StrictMode><NextApp /></React.StrictMode>,
//         rootEl
//       );
//     }
//   })
// }

// render();

import React from 'react';
import { createRoot } from 'react-dom/client';

import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import reportWebVitals from "../reportWebVitals";

// Create a reusable render method that we can call more than once
const AppNavigator = require("./AppNavigator").default;

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<AppNavigator />);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require("./AppNavigator").default; // require('./App').default;
    if(container) {
      root.render(<NextApp />)
    }
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();