let config = null;
if (process.env.SERVER_MODE === 'production') {
    config = {
       //Production:
        baseURL: 'https://us-central1-project-5436648267978138178.cloudfunctions.net/api/v5',
        authBaseURL: 'https://us-central1-project-5436648267978138178.cloudfunctions.net/api/V1',
        appUrl: 'http://firebase-registration-app.s3-website-us-east-1.amazonaws.com',
        API_KEY: 'AIzaSyCD-Tq45VrpVcNRbmIe__AmHVg_lS77VGQ',
        authAPIURL: "https://us-central1-project-5436648267978138178.cloudfunctions.net/api",
        configurator: "https://configurator.3rbehavioralsolutions.com",
        zohoUrl: `https://accounts.zoho.in/signin?servicename=ZohoReports&signupurl=https://www.zoho.in/analytics/signup.html`,
        notificationURL: "https://us-central1-project-5436648267978138178.cloudfunctions.net/api/V3",
        localStorageBucket: 'lifesherpaProdBucket',
        registraionappurl: 'https://registration.3rbehavioralsolutions.com/login?mode=web',
        tokenRegistrationURL: 'https://tokenregistration.lifesherpapp.com/login?mode=web&theme=Theme4',
        assignActivityUrl: "https://objeditor.3rbehavioralsolutions.com/activity/assign",
        objectEditorUrl: "https://objeditor.3rbehavioralsolutions.com",
        defaultAuth0DataForForUSA : {
            audience: "https://configurator.lifesherpapp.com/",
            clientId: {
                "autism@workMobileApp":"GswNASX6lGkkyiInKTad57eWSLrIMUNx",
                "autism@workRegistrationApp":"qrdSKb0A990KXV4AgxSP7hcNHrxqjw0f",
                "capacitorApp":"Zc9QffCQm2kh38yauwfcRacywNB0AUXX",
                "configurator":"qrdSKb0A990KXV4AgxSP7hcNHrxqjw0f",
                "lifesherpaMobileApp":"Qs3MsvqdS1cKXeQIZUNHxpNE1l23lHB9",
                "lifesherpaRegistrationApp":"qrdSKb0A990KXV4AgxSP7hcNHrxqjw0f",
                "lsPortal":"qrc2jzsk3vCpoFuvT4i1tQ8IbYW5CEkZ"
            },
            domain:"login.3rbehavioralsolutions.com",
            loginAudience:"https://configurator.lifesherpapp.com/",
            region: "us",
        },
        analyticsUrl: 'https://analytics.3rbehavioralsolutions.com/ZDBCustomDomainLogin.ma?ZDBACTION=samllogout',
        lsPortalUrl:"https://lsportal.3rbehavioralsolutions.com",
        zohoReportsUrl: "https://analytics.3rbehavioralsolutions.com/open-view/1618883000011971116?ZDB_THEME_NAME=blue&FS=OS&NEW_ENCRYPTION=true&RSCONFIG=4038fac9aedea8b3783f5a5bb08a0cc2a94c047e253fe37ea599f69241a8fe45e3fc1fbfde3cea1893b08a424780030201b023561a02d3500365645be77932f053b7b948a0688a4d508972a49cd59ede&CRITERIA=d6aca09d8246534a20ae14c9a643461cffb9fd069b39b0a047aeb9f51e14d011375c266661cc43b1a2cafd265c593bffac6751bd34f76ff8c4eb5160d37bc88c88c6b3f7a03254219d2297e714ffba3bad7b73944531bdffbb1a01694fec8fcb3c6a036fd5d76481dfdb36fcd105af80ef9c7814468d9837acc4abe3641c2335ef8e78613a57ca73314edfe09c94b2cc5671f9721da5267a50bc5887de7be26eb2fbb4ef43eb5fdd43ae13d1e51c2d9eb1d76ad1e6ea88b093562571f130e79bda3f457fcc5bfa3255c3d152d2985a2b6bab3da8471834c028589c75c9c7f15f32d5162cb3c77ba3b345e62f2f979d456283c1f1067656885264977f2e7afaf6",
        serverURL: 'https://us-central1-project-5436648267978138178.cloudfunctions.net',
    };
} else if (process.env.SERVER_MODE === 'development') {
    config = {
        // Migration:
        baseURL: 'https://us-central1-ls-config-migration.cloudfunctions.net/api/v5',
        authBaseURL: 'https://us-central1-ls-config-migration.cloudfunctions.net/api/V1',
        appUrl: 'http://firebase-registration-app-dev.s3-website-us-east-1.amazonaws.com',
        API_KEY: 'AIzaSyCD-Tq45VrpVcNRbmIe__AmHVg_lS77VGQ',
        authAPIURL: "https://us-central1-ls-config-migration.cloudfunctions.net/api",
        configurator: "https://dev.configurator.3rbehavioralsolutions.com",
        zohoUrl: `https://accounts.zoho.in/signin?servicename=ZohoReports&signupurl=https://www.zoho.in/analytics/signup.html`,
        notificationURL: "https://us-central1-ls-config-migration.cloudfunctions.net/api/V3",
        localStorageBucket: 'lifesherpaDevBucket',
        registraionappurl: 'https://dev.registration.3rbehavioralsolutions.com/login?mode=web',
        tokenRegistrationURL: 'https://dev.tokenregistration.3rbehavioralsolutions.com/login?mode=web&theme=Theme4',
        assignActivityUrl: "https://dev.objeditor.3rbehavioralsolutions.com/activity/assign",
        objectEditorUrl: "https://dev.objeditor.3rbehavioralsolutions.com",
        defaultAuth0DataForForUSA : { 
            audience: "http://lifesherpa-ls-config.s3-website-us-east-1.amazonaws.com",
            clientId: {
                "autism@workMobileApp": "u6GSf410NGS6VddlbtkKloEVB4o3HmPS",
                "autism@workRegistrationApp": "cwpBg8M2fDRv9XBQ11V1bOU1Axvhcda5",
                "capacitorApp":"Zc9QffCQm2kh38yauwfcRacywNB0AUXX",
                "configurator": "cwpBg8M2fDRv9XBQ11V1bOU1Axvhcda5",
                "lifesherpaMobileApp": "Uom6sk7lyXaZ8vwcMT3QlJTp1xkTjKlI",
                "lifesherpaRegistrationApp": "cwpBg8M2fDRv9XBQ11V1bOU1Axvhcda5",
                "lsPortal":"uwFs7KZchQfCmVmN6DqXXS8IsmIltdEm"
            },
            domain: "dev.login.3rbehavioralsolutions.com",
            loginAudience: "https://dev.configurator.3rbehavioralsolutions.com",
            // connection: "firebase-migration-database",
            region: "us",
        },
        analyticsUrl: 'https://analytics.3rbehavioralsolutions.com/ZDBCustomDomainLogin.ma?ZDBACTION=samllogout',
        //lsPortalUrl:"https://dev.lsportal.3rbehavioralsolutions.com"
        lsPortalUrl:"http://localhost:8000",
        zohoReportsUrl: "https://analytics.3rbehavioralsolutions.com/open-view/1618883000011971116?ZDB_THEME_NAME=blue&FS=OS&NEW_ENCRYPTION=true&RSCONFIG=4038fac9aedea8b3783f5a5bb08a0cc2a94c047e253fe37ea599f69241a8fe45e3fc1fbfde3cea1893b08a424780030201b023561a02d3500365645be77932f053b7b948a0688a4d508972a49cd59ede&CRITERIA=d6aca09d8246534a20ae14c9a643461cffb9fd069b39b0a047aeb9f51e14d011375c266661cc43b1a2cafd265c593bffac6751bd34f76ff8c4eb5160d37bc88c88c6b3f7a03254219d2297e714ffba3bad7b73944531bdffbb1a01694fec8fcb3c6a036fd5d76481dfdb36fcd105af80ef9c7814468d9837acc4abe3641c2335ef8e78613a57ca73314edfe09c94b2cc5671f9721da5267a50bc5887de7be26eb2fbb4ef43eb5fdd43ae13d1e51c2d9eb1d76ad1e6ea88b093562571f130e79bda3f457fcc5bfa3255c3d152d2985a2b6bab3da8471834c028589c75c9c7f15f32d5162cb3c77ba3b345e62f2f979d456283c1f1067656885264977f2e7afaf6",
        serverURL: 'https://us-central1-ls-config-migration.cloudfunctions.net',

    }; 
} else {
    config = {
        // Migration:
        baseURL: 'https://us-central1-ls-config-migration.cloudfunctions.net/api/v5',
        authBaseURL: 'https://us-central1-ls-config-migration.cloudfunctions.net/api/V1',
        appUrl: 'http://firebase-registration-app-dev.s3-website-us-east-1.amazonaws.com',
        API_KEY: 'AIzaSyCD-Tq45VrpVcNRbmIe__AmHVg_lS77VGQ',
        authAPIURL: "https://us-central1-ls-config-migration.cloudfunctions.net/api",
        configurator: "https://dev.configurator.3rbehavioralsolutions.com",
        zohoUrl: `https://accounts.zoho.in/signin?servicename=ZohoReports&signupurl=https://www.zoho.in/analytics/signup.html`,
        notificationURL: "https://us-central1-ls-config-migration.cloudfunctions.net/api/V3",
        localStorageBucket: 'lifesherpaDevBucket',
        registraionappurl: 'https://dev.registration.3rbehavioralsolutions.com/login?mode=web',
        tokenRegistrationURL: 'https://dev.tokenregistration.3rbehavioralsolutions.com/login?mode=web&theme=Theme4',
        assignActivityUrl: "https://dev.objeditor.3rbehavioralsolutions.com/activity/assign",
        objectEditorUrl: "https://dev.objeditor.3rbehavioralsolutions.com",
        defaultAuth0DataForForUSA : { 
            audience: "http://lifesherpa-ls-config.s3-website-us-east-1.amazonaws.com",
            clientId: {
                "autism@workMobileApp": "u6GSf410NGS6VddlbtkKloEVB4o3HmPS",
                "autism@workRegistrationApp": "cwpBg8M2fDRv9XBQ11V1bOU1Axvhcda5",
                "capacitorApp":"Zc9QffCQm2kh38yauwfcRacywNB0AUXX",
                "configurator": "cwpBg8M2fDRv9XBQ11V1bOU1Axvhcda5",
                "lifesherpaMobileApp": "Uom6sk7lyXaZ8vwcMT3QlJTp1xkTjKlI",
                "lifesherpaRegistrationApp": "cwpBg8M2fDRv9XBQ11V1bOU1Axvhcda5",
                "lsPortal":"uwFs7KZchQfCmVmN6DqXXS8IsmIltdEm"
            },
            domain: "dev.login.3rbehavioralsolutions.com",
            loginAudience: "https://dev.configurator.3rbehavioralsolutions.com",
           // connection: "firebase-migration-database",
            region: "us",
        },
        analyticsUrl: 'https://analytics.3rbehavioralsolutions.com/ZDBCustomDomainLogin.ma?ZDBACTION=samllogout',
        lsPortalUrl:"https://dev.lsportal.3rbehavioralsolutions.com",
        zohoReportsUrl: "https://analytics.3rbehavioralsolutions.com/open-view/1618883000011971116?ZDB_THEME_NAME=blue&FS=OS&NEW_ENCRYPTION=true&RSCONFIG=4038fac9aedea8b3783f5a5bb08a0cc2a94c047e253fe37ea599f69241a8fe45e3fc1fbfde3cea1893b08a424780030201b023561a02d3500365645be77932f053b7b948a0688a4d508972a49cd59ede&CRITERIA=d6aca09d8246534a20ae14c9a643461cffb9fd069b39b0a047aeb9f51e14d011375c266661cc43b1a2cafd265c593bffac6751bd34f76ff8c4eb5160d37bc88c88c6b3f7a03254219d2297e714ffba3bad7b73944531bdffbb1a01694fec8fcb3c6a036fd5d76481dfdb36fcd105af80ef9c7814468d9837acc4abe3641c2335ef8e78613a57ca73314edfe09c94b2cc5671f9721da5267a50bc5887de7be26eb2fbb4ef43eb5fdd43ae13d1e51c2d9eb1d76ad1e6ea88b093562571f130e79bda3f457fcc5bfa3255c3d152d2985a2b6bab3da8471834c028589c75c9c7f15f32d5162cb3c77ba3b345e62f2f979d456283c1f1067656885264977f2e7afaf6",
        serverURL: 'https://us-central1-ls-config-migration.cloudfunctions.net',
     };

    
}

export default config;