
import React, { Component } from 'react';
import { connect } from 'react-redux'

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import LayersIcon from '@mui/icons-material/Layers';
import StarsIcon from '@mui/icons-material/Stars';
import AppConfig from '../../constants/AppConfig';
import SpeedIcon from '@mui/icons-material/Speed';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';

import { getMobileTabsConfig } from '../../helpers/helpers';
import {
    selectClientMenuItem,
    getFilteredActivitiesList,
    getActivitiesList,
    getResourceList,
    getcategories,
    getKeywords,
    getRewards,
    getPendingRewards,
    getreceivedRewards,
    clearUserOrgFilter,
    clientNetworkActivity,
    getMyCourses,
    getClientActivitiesList
} from "../../actions"

const iconsList = {
    'Activities': TuneIcon,
    'Resources': AssignmentTurnedInOutlinedIcon,
    'Rewards': StarsIcon,
    'Courses': LayersIcon,
    'Dashbaord': SpeedIcon,
    'Workflows': Diversity3RoundedIcon,
    'Workflows Dashbaord': GridViewRoundedIcon,
    'Workflows Summary Report': AssessmentRoundedIcon,
};

// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';

class TabsHeader extends Component {

    state = {
        value: ''
    }

    
    handleChange(event, newValue) {
        this.props.selectClientMenuItem(newValue)
        // this.setState({value: newValue}, () => {
        //     this.props.selectClientMenuItem(newValue)
        // });
        // Trigger Fetch data for tab containers
        const auth_uid = localStorage.getItem('auth_uid');
        const uid = localStorage.getItem("uid");
		const idToken = localStorage.getItem('idToken');
        const orgId = localStorage.getItem("orgId");
        if(newValue === "/app/lsdashboard/activities") {
            if(this.props.appliedActivityFilter) {
                this.props.getFilteredActivitiesList(auth_uid, appliedActivityFilter, null, null)
            } else {
                this.props.getActivitiesList(auth_uid, '')
            }
        }
        if(newValue === "/app/lsdashboard/resources") {
            this.props.getResourceList('','new', '', null, false)
            this.props.getcategories(null)
            this.props.getKeywords()
        }

        if(newValue === "/app/lsdashboard/rewards") {
            this.props.getRewards()
            this.props.getPendingRewards()
            this.props.getreceivedRewards()
        } 
        if(newValue === "/app/lsdashboard/courses") {
            this.props.clearUserOrgFilter({panel:'courses'});
        } 

        if(newValue === "/app/lsdashboard/client") {
            this.props.clientNetworkActivity(uid);
        } 

        if(newValue === "/app/lsdashboard/client/activities") {
            const { selectedMember, clientActivitiesType } = this.props;
            let {organization: orgId, groupId} = selectedMember || {};
            const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null;
            this.props.getClientActivitiesList({userId: uid, orgId, groupId, filter:'', activityType});
        } 

        if(newValue === "/app/lsdashboard/client/resources") {
            const orgIds = [localStorage.getItem("selectedOrgId")]
            this.props.getResourceList('','new', '', orgIds, false)
            this.props.getcategories(orgIds)
            this.props.getKeywords(orgIds);
        } 

        if(newValue === "/app/lsdashboard/client/rewards") {
            this.props.getRewards()
            this.props.getPendingRewards()
            this.props.getreceivedRewards()
        } 

        if(newValue === "/app/lsdashboard/client/courses") {
            this.props.getMyCourses();
        } 

    };

    render() {
        const {selectedMenu, showLMSWidget, allowedModules, selectedClientMenuItem, enableWorkflowsStatus, enableWorkflows, location} = this.props;
        let value =  selectedClientMenuItem;
        let {mode, tabsList} = getMobileTabsConfig();
        
        // if(showLMSWidget && allowedModules && allowedModules.LMSInstructor) {
        //     console.log("Courses avaialable for current user")
        // } else {
        //     tabsList = tabsList.filter(item => item.title !== "Courses");
        // }

        // if (tabsList.length > 0 && hideRewards) {
        //     tabsList = tabsList.filter(item => item.title !== "Rewards");
        // }
        
        // if (tabsList.length > 0 && !enableWorkflowsStatus) {
        //     tabsList = tabsList.filter(item => item.title !== "Workflows");
        // }

        // if(!tabsList.length) {
        //     return '';
        // }

        // const index = tabsList.findIndex(tab => tab.path === selectedClientMenuItem);
        // if(index === -1 || !value) {
        //     value = tabsList[0].path;
        // }
        // return (
        //     <Box className="tabs-header" sx={{ width: '100%' }}> 
        //         <Tabs value={value} onChange={this.handleChange.bind(this)} aria-label="nav tabs" variant="scrollable">
        //             {tabsList.map(tab => {
        //                 const Icon = iconsList[tab.title];
        //                 return (
        //                     <Tab key={tab.title} value={tab.path} label={tab.title} icon={<Icon/>} iconPosition="start" />
        //                 )
        //             } )}
        //         </Tabs>
        //     </Box>
        // );

        if(mode === "Home") {
            const {hideRewards} = this.props || {};
            if(!value || value.includes("app/lsdashboard/client")) {
                value = "/app/lsdashboard/activities";
            }
            return (
                <Box className="tabs-header" sx={{ width: '100%' }}> 
                    <Tabs value={value} onChange={this.handleChange.bind(this)} aria-label="nav tabs" variant="scrollable">
                        <Tab key="Activities" label="Activities" value="/app/lsdashboard/activities" icon={<TuneIcon/>} iconPosition="start" />
                        { enableWorkflows ? <Tab key="Workflows" label="Workflows" value="/app/lsdashboard/workflows" icon={<Diversity3RoundedIcon/>} iconPosition="start" />: ""}
                        <Tab key="Resources" label="Resources" value="/app/lsdashboard/resources" icon={<AssignmentTurnedInOutlinedIcon/>} iconPosition="start" /> 
                        {hideRewards ? "" : <Tab key="Rewards" label="Rewards" value="/app/lsdashboard/rewards" icon={<StarsIcon/>} iconPosition="start" /> }
                        { showLMSWidget && allowedModules && allowedModules.LMSInstructor ? <Tab key="Courses" label="Courses" value="/app/lsdashboard/courses" icon={<LayersIcon/>} iconPosition="start" /> : ""}  
                    </Tabs>
                </Box>
            );
        }

        if(mode === "Client") {
            const {hideRewards} = this.props.clientUserDetail || {};
            if(!value || !value.includes("app/lsdashboard/client")) {
                value = "/app/lsdashboard/client";
            }
            return (
                <Box className="tabs-header" sx={{ width: '100%' }}> 
                    <Tabs value={value} onChange={this.handleChange.bind(this)} aria-label="nav tabs" variant="scrollable">
                        <Tab key="Dashbaord" label="Dashbaord" value="/app/lsdashboard/client" icon={<SpeedIcon/>} iconPosition="start" />
                        <Tab key="Activities" label="Activities" value="/app/lsdashboard/client/activities" icon={<TuneIcon/>} iconPosition="start" />
                        {enableWorkflows ? <Tab key="Workflows" label="Workflows" value="/app/lsdashboard/client/workflows" icon={<Diversity3RoundedIcon/>} iconPosition="start" /> : ""}
                        <Tab key="Resources" label="Resources" value="/app/lsdashboard/client/resources" icon={<AssignmentTurnedInOutlinedIcon/>} iconPosition="start" />
                        {hideRewards ? "" : <Tab key="Rewards" label="Rewards" value="/app/lsdashboard/client/rewards" icon={<StarsIcon/>} iconPosition="start" />}
                        {showLMSWidget && allowedModules && allowedModules.LMSInstructor ? <Tab key="Courses" label="Courses" value="/app/lsdashboard/client/courses" icon={<LayersIcon/>} iconPosition="start" /> : ""}  
                    </Tabs>
                </Box>
            );
        }

        if(mode === "Workflows" && enableWorkflows) {
            if(!value || tabsList.findIndex(tab => tab.path === value) === -1) {
                value = "/app/workflows/dashboard";
            }
            return (
                <Box className="tabs-header" sx={{ width: '100%' }}> 
                    <Tabs value={value} onChange={this.handleChange.bind(this)} aria-label="nav tabs" variant="scrollable">
                        <Tab key="Dashbaord" label="Dashbaord" value="/app/workflows/dashboard" icon={<GridViewRoundedIcon/>} iconPosition="start" />
                        <Tab key="Workflows" label="Workflows" value="/app/workflows/list" icon={<Diversity3RoundedIcon/>} iconPosition="start" />
                        <Tab key="Reports Summary" label="Reports Summary" value="/app/workflows/workflow_summary_report" icon={<AssessmentRoundedIcon/>} iconPosition="start" />
                    </Tabs>
                </Box>
            );
        }
        return <></>;
    }
}



const mapStateToProps = ({ settings, authUser, MyCourses, clientsList, ActivityReducer, chatAppReducer, activitiesList }) => {
    const {selectedMenu, selectedClientMenuItem} = settings;
    const { allowedModules, hideRewards, enableWorkflowsStatus, enableWorkflows } = authUser;
    const { clientUserDetail } = clientsList
    const { showLMSWidget} = MyCourses;
    const { appliedActivityFilter } = ActivityReducer;
    const { selectedMember } = chatAppReducer;
    const {  clientActivitiesType } = activitiesList;

    return {selectedMenu, allowedModules, showLMSWidget, selectedClientMenuItem, hideRewards, clientUserDetail,  enableWorkflowsStatus, appliedActivityFilter, selectedMember, clientActivitiesType, enableWorkflows };
};

export default withRouter(connect(mapStateToProps, {
    selectClientMenuItem,
    getFilteredActivitiesList,
    getActivitiesList,
    getResourceList,
    getcategories,
    getKeywords,
    getRewards,
    getPendingRewards,
    getreceivedRewards,
    clearUserOrgFilter,
    clientNetworkActivity,
    getMyCourses,
    getClientActivitiesList
})(TabsHeader));

