/**
 * Acivity Detail Reducers
 */
import {
    GET_ACTIVITIES_LIST_DETAIL,
    GET_SUCCESS_ACTIVITIES_LIST_DETAIL,
    SHOW_PRIVATE_ACTIVITY_LOADING,
    HIDE_PRIVATE_ACTIVITY_LOADING,
    CLEAR_ACTIVITY_DATA,
    REMOVE_API_ERROR_MESSAGE,
    SHOW_ACTIVITY_CRETAED_MESSAGE,
    OPEN_EDITOR,SELECT_ACTIVTY,
    SET_ACTIVITY_FILTER,
    SET_CLIENT_ACTIVITY_FILTER
 } from '../actions/types';
 
 /**
  * initial auth user
  */
 const INIT_STATE = {
    activity: null,
    schedules: [],
    steps: [],
    loading: false,
    allSteps: null,
    activityExist: false,
    activityTitle: '',
    open:false,
    errorMessage: '',
    activityState: null,
    successMessage: null,
    errorTitle: null,
    selected:null,
    appliedActivityFilter: null,
    appliedClientActivityFilter: null
 };
 
 export default (state = INIT_STATE, action) => {
     switch (action.type) {
 
        case GET_ACTIVITIES_LIST_DETAIL:
            return { ...state, loading: true };
 
        case GET_SUCCESS_ACTIVITIES_LIST_DETAIL:
            return { ...state, activity: action.payload, loading: false };

        case CLEAR_ACTIVITY_DATA: 
            return{...state, loading: false, errorMessage: '', errorTitle: '', activity: null, schedules: [], steps: [], activityExist: false, activityTitle: '', activityState: null}
        
        case REMOVE_API_ERROR_MESSAGE:
            return { ...state, errorMessage: '', errorTitle: '' };

        case SHOW_PRIVATE_ACTIVITY_LOADING: 
            return{...state, loading: true}

        case HIDE_PRIVATE_ACTIVITY_LOADING:
            return{...state, loading: false}
        
        case SHOW_ACTIVITY_CRETAED_MESSAGE: 
            return{...state, successMessage: action.payload}

        case OPEN_EDITOR:
            return {...state,open: action.payload}

        case SELECT_ACTIVTY:
            return {...state,selected:action.payload} 

        case SET_ACTIVITY_FILTER:
            return {...state, appliedActivityFilter: action.payload}

        case SET_CLIENT_ACTIVITY_FILTER:
            return {...state, appliedClientActivityFilter: action.payload}

        default: 
            return { ...state };
     }
 }
 