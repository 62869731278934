import React,  { Component } from 'react';
import { connect } from 'react-redux'
import withRouter from '../../router';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import classNames from 'classnames';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import LayersIcon from '@mui/icons-material/Layers';
import { ref, onValue, off} from "firebase/database";
import {database} from '../../firebase';


import {
    selectClientMenuItem, 
    handleChangeMode, 
    handleShowFallbackScreen, 
    handleChangeBottomTab, 
    showClientPanel, 
    networkActivity
} from "../../actions"
import _ from 'lodash';


class BottomTabContainer extends Component {

    state = {
        value: ''
    }
    // Function to trigger page rendering with debounce
    triggerRenderPage = _.debounce((path) => {
        if(path === "/app/lsdashboard/clients") {
            if (this.props.clientPanel) {
                this.props.showClientPanel(false);
            }
        }
        // this.props.navigate(path);
        this.props.handleShowFallbackScreen(false);
    }, 500);

    componentDidMount() {
        if(["/app/lsdashboard", "/app/lsdashboard/activities"].includes(window.location.pathname)) {
            this.props.handleChangeBottomTab({tabMode: "Home Page", selectedClientMenuItem: "/app/lsdashboard/activities",  showFallbackScreen: false });
        } 
        const organization = localStorage.getItem('orgId')
        const uid = localStorage.getItem('auth_uid');
        const refValue = ref(database, `Realtime/${organization}/Users/${uid}/networkActivity`)
        let self = this;
        onValue(refValue, (snapshot) => {
            if(snapshot.val()) {
                self.props.networkActivity();
            }
        })
    }
    componentWillUnmount() {
        const organization = localStorage.getItem('orgId')
        const uid = localStorage.getItem('auth_uid');
        const removeListener = ref(database, `Realtime/${organization}/Users/${uid}/networkActivity`)
        if (removeListener) {
            off(removeListener)
        }
    }


    handleTabClick(path, title) {
        this.props.handleChangeBottomTab({tabMode: title, selectedClientMenuItem: path,  showFallbackScreen: true })
        // this.props.selectClientMenuItem(path);
        // this.props.handleChangeMode(title);
        // this.props.handleShowFallbackScreen(true);
        this.props.navigate(path);
        this.triggerRenderPage(path)
    }

    render() {
        const { show, tabMode, userRole, enableWorkflowsStatus, networkActivities, touchpointActivitiesSummary, activityCalender, enableWorkflows} = this.props;
        if (!show) {
            return <></>;
        }
        let isDashboardUpdate = false;
        if(networkActivities && networkActivities.length) {
            isDashboardUpdate = true;
        }
        if(touchpointActivitiesSummary && touchpointActivitiesSummary.length) {
            isDashboardUpdate = true;
        }
        if(activityCalender && (activityCalender.expiredActivity || activityCalender.startedActivity)) {
            isDashboardUpdate = true;
        }
        console.log("tabMode :", tabMode);
        return (
            <div className={window.location.href.includes("/chat") ? "d-none bottom-panel bottom-tab-container" : 'bottom-panel bottom-tab-container'}>
                <div to='/app/lsdashboard/activities'
                    className={`text-center tab-item ${classNames({ 'tab-item-active': !tabMode || tabMode === 'Home Page' })}`}
                    onClick={() => this.handleTabClick('/app/lsdashboard/activities', 'Home Page')}
                >
                    <HomeRoundedIcon className="text-white" fontSize='large' />
                    <span className="tab-label">Home</span>
                </div>
                {userRole && userRole !== 'Client' ?
                    <div to='/app/lsdashboard/clients'
                        className={`text-center tab-item ${classNames({ 'tab-item-active': tabMode === 'My Clients' })}`}
                        onClick={() => this.handleTabClick('/app/lsdashboard/clients', 'My Clients')}
                    >
                        <PeopleOutlineRoundedIcon className="text-white" fontSize='large' />
                        <span className="tab-label">Clients</span>
                    </div>
                    : <div className='empty-clients-tab'></div>
                }
                {userRole && userRole !== 'Client' && enableWorkflows ?
                    <div to='/app/workflows/dashboard'
                        className={`text-center tab-item ${classNames({ 'tab-item-active': tabMode === 'Workflows' })}`}
                        onClick={() => this.handleTabClick('/app/workflows/dashboard', 'Workflows')}
                    >
                        <Diversity3RoundedIcon className="text-white" fontSize='large' />
                        <span className="tab-label">Workflows</span>
                    </div>
                    : null
                }
                {
                    isDashboardUpdate ? 
                    <Badge color="primary" badgeContent=" "> 
                        <div to='/app/lsdashboard/dashboard' onClick={() => this.handleTabClick('/app/lsdashboard/dashboard', 'Dashboard Summary')}>
                            <div className='dashboard-image-container'>
                                <img className={tabMode === 'Dashboard Summary' ? "dashboard-image dashboard-image-selected" : "dashboard-image"} src={require("../../assets/img/help-logo.png")} alt="user profile" />
                            </div>
                        </div>
                    </Badge>
                    : 
                    <div to='/app/lsdashboard/dashboard' onClick={() => this.handleTabClick('/app/lsdashboard/dashboard', 'Dashboard Summary')}>
                        <div className='dashboard-image-container'>
                            <img className={tabMode === 'Dashboard Summary' ? "dashboard-image dashboard-image-selected" : "dashboard-image"} src={require("../../assets/img/help-logo.png")} alt="user profile" />
                        </div>
                    </div>
                    
                }
                
                <div to='/app/contacts'
                    className={`text-center tab-item ${classNames({ 'tab-item-active': tabMode === 'Contacts' })}`}
                    onClick={() => this.handleTabClick('/app/contacts', 'Contacts')}
                >
                    <ChatRoundedIcon className="text-white" fontSize='large' />
                    <span className="tab-label">Contacts</span>
                </div>
        
                <div to='/app/lsdashboard/more'
                    className={`text-center tab-item ${classNames({ 'tab-item-active': tabMode === 'More' })}`}
                    onClick={() => this.handleTabClick('/app/lsdashboard/more', 'More')}
                >
                    <MenuRoundedIcon className="text-white" fontSize='large' />
                    <span className="tab-label">More</span>
                </div>
            </div>
        );
    }
}



const mapStateToProps = ({ settings, authUser, clientsList, activitiesList }) => {
    const {  tabMode } = settings;
    const {  userRole, enableWorkflowsStatus, enableWorkflows } = authUser;
    const {clientPanel} = clientsList;
    let { networkActivities, touchpointActivitiesSummary, activityCalender } = activitiesList;
    return {  enableWorkflowsStatus, tabMode, userRole, clientPanel, networkActivities, touchpointActivitiesSummary, activityCalender, enableWorkflows};
};

export default withRouter(connect(mapStateToProps, {
    selectClientMenuItem, handleChangeMode, handleShowFallbackScreen,
    handleChangeBottomTab,
    showClientPanel,
    networkActivity
})(BottomTabContainer));


