import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
// reactstrap components
import { Label } from 'reactstrap';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    clearCourseData,
} from '../../../actions';
import CourseViewPage from './CourseViewPage';

class ActivityCourse extends Component {


    handleClosePanel = () => {
        this.props.clearCourseData();
        // this.props.handleclosePanel()
    }

    render() {

        const {seletedCourseId, title, course_completion_status, handlePreviousCourseStatus} = this.props;
        const userId = localStorage.getItem("uid");

        return (
            <div>
                <Dialog
                    open={true}
                    fullScreen={true}
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} activity-course-viewer`}
                >
                    <DialogTitle>
                        <div className={`d-flex justify-content-between align-items-center border-bottom bg-lifesherpa pl-2 py-2`}>
                            <IconButton title='Back To Activity Page' className="text-white" onClick={() => this.handleClosePanel()}><ArrowBackIcon /></IconButton>
                            <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">{title || "LMS Course"}</Label>
                            <div>
                            {/* <IconButton className="text-white mr-2" onClick={() => this.handleClosePanel()}><CloseIcon /></IconButton> */}
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent className="m-0 p-0 activity-details-height">
                        <CourseViewPage handlePreviousCourseStatus={handlePreviousCourseStatus} course_completion_status={course_completion_status} courseId={seletedCourseId} userId={userId} handleClose={this.handleClosePanel}/>
                    </DialogContent>
                </Dialog>
            </div>


        );
    }
}

const mapStateToProps = ({ settings, MyCourses }) => {
    const { popupThemeClass } = settings;
    const { seletedCourseId } = MyCourses;
    return { popupThemeClass, seletedCourseId };
}

export default connect(mapStateToProps, { clearCourseData })(ActivityCourse);
