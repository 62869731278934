/**
 * User List Item
 */
import React from 'react';
import classnames from 'classnames';
import CommentIcon from '@mui/icons-material/Comment';
import Avatar from '@mui/material/Avatar';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import moment from 'moment'

// helpers
import { textTruncate, getActivityStatusClass, getActivityStatusText } from 'Helpers/helpers';

const ActivityHistoryListItem = ({ activity, selectedActivity, onClickListItem, alternativeColor, isClientUser, showUnconfirmedAcheivemts }) => (
    <div
        onClick={onClickListItem}
        key={activity.id}
        className={classnames('d-flex align-items-center list-card list-item  my-2 border-bottom cps  justify-content-between',
            { 'item-active': selectedActivity && selectedActivity.id === activity.id },
            { 'alternative-user-list-item': alternativeColor }
        )}
    >
        <div className="media align-items-center " /*onClick={onClickListItem}*/>
            <div className="media-left position-relative mr-10">
                <Avatar
                    width="40"
                    height="40"
                    title={getActivityStatusText(activity.status)}
                    className={`align-self-center  ${getActivityStatusClass(activity.status)}`}
                >
                    {activity.status === 'confirmed' || activity.status === 'confirmedSkippedSteps' || activity.status === 'confirmedNegative' || activity.status === 'completedNoConfirmationNeeded' ?
                        <DoneRoundedIcon style={{ fontSize: "2rem" }} />
                        : activity.status === 'cancelled' || activity.status === 'incomplete' ?
                            <ClearRoundedIcon style={{ fontSize: "2rem" }} />
                            : activity.status === 'inProgress' || activity.status === 'paused' ?
                                <MoreHorizRoundedIcon style={{ fontSize: "2rem" }} />
                                : activity.status === 'expired' || activity.status === 'notStarted' ?
                                    <AccessAlarmRoundedIcon style={{ fontSize: "2rem" }} />
                                    : activity.status === 'completedSkippedStepsNoConfirmationNeeded' ?
                                        <div style={{ fontSize: "2rem" }}>{'S'}</div>
                                        : activity.status === 'completedConfirmationNeeded' ?
                                            <div style={{ fontSize: "2rem" }}>{'?'}</div>
                                            :
                                            <div style={{ fontSize: "2rem" }}>{'A'}</div>
                    }
                </Avatar>
            </div>
            <div className="media-body">
                {showUnconfirmedAcheivemts && <h4 className="heading-text mb-0 mt-0">{activity.title}</h4>}
                <div className="heading-text fs-14">{activity.dateName}</div>
                <div className="sub-text">{moment(activity.date, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY hh:mm A')}</div>
            </div>
        </div>
        <div className="sub-text ml-auto  activity-time-duration">
            {activity.duration !== '-' ? activity.duration : "-:-"}
        </div>
        <div className="text-right msg-count ml-1 d-flex align-items-center">
            <div>
                {activity.hasEvaluationForm && isClientUser &&
                    <div style={{height:"22px"}}><AssignmentRoundedIcon style={activity.hasEvaluationForm && activity.hasEvaluationResponse ? { color: 'orange' } : { color: '#A5A7B2' }} /></div>
                }
                {activity.hasStepNotesForm && isClientUser &&
                    <div style={{height:"22px"}}><CommentIcon style={activity.hasStepNotesForm && activity.hasStepNotesResponse ? { color: 'orange' } : { color: '#A5A7B2' }} /></div>
                }
            </div>
           
        </div>
    </div>
);

export default ActivityHistoryListItem;
