import React, { useEffect } from 'react';
import LifeSherpaLoading from '../LifeSherpaLoading';

const FallbackPage = () => {
    return (
        <div>
            <LifeSherpaLoading loading={true} />
            <div
                style={{ height: "calc(100vh - 150px)", overflowX: 'hidden' }}
                className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found"
            ></div>
        </div>
    );
};


export default FallbackPage;
