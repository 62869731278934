/**
 * Chat Area Component
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
import MiniChatSection from "./MiniChatSection";
// actions
import {activitiesOpen, chatOpen, sendMessageToUser, savePokrFormForClients, clearUnconfirmData, removeSelectedChatUser} from 'Actions'
 
class ChatSections extends Component {
    state ={
        update:false,
    }

    render() {
        const { selectedUser, admin_photo_url, isChatOpen, selectedMember, isActivitiesOpen, selectedChatUsers, userUpdatedTolist, windowWidth } = this.props;
        console.log("chat sections -----> ",selectedChatUsers );
        
        if(!selectedChatUsers) {
            return "";
        } else {
            return (
                <React.Fragment>
                {selectedChatUsers && selectedChatUsers.length > 0 && 
                selectedChatUsers.map((user,key)=> {
                    let panelWidth = 339;
                    let right = key * panelWidth;
                    let availableWidth = windowWidth - right;
                    if(availableWidth >= panelWidth && user) {
                        if(availableWidth < (panelWidth * 2)) {
                            let lastIndex = selectedChatUsers.length - 1;
                                user =   selectedChatUsers[lastIndex] ? selectedChatUsers[lastIndex] : user;
                        }
                        return (
                            <div key={user.userId} className="mini-chat-panel-sections" style={{right: right+"px"}}>
                                <div className="chat-sections" >
                                    <MiniChatSection
                                        selectedUser= {user}
                                        key={user.userId}
                                    />
                                </div>
                            </div>
                        )
                    } else {
                        return ""
                    }  
                }     
                )}
            </React.Fragment> 
            )
        }
        
    }
}
 
const mapStateToProps = ({ chatAppReducer,GroupList, settings}) => {	
    const { selectedUser, admin_photo_url, selectedMember, selectedChatUsers , userUpdatedTolist} = chatAppReducer;
    const { screenThemeClass, popupThemeClass } = settings; 

    return { selectedUser, admin_photo_url, selectedMember, screenThemeClass, selectedChatUsers, userUpdatedTolist }
}
 
export default withRouter(connect(mapStateToProps, {
    sendMessageToUser, activitiesOpen, chatOpen, savePokrFormForClients, clearUnconfirmData, removeSelectedChatUser
})(ChatSections));
 