
import React, { Component } from 'react';
import { Label } from 'reactstrap';
import ListItem from '@mui/material/ListItem';
import classnames from 'classnames';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import LandingPageList from "./LandingPageList";
import MainLandingPageItem from './MainLandingPageItem';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatSharpIcon from '@mui/icons-material/TrendingFlatSharp';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge'
import { IconButton } from '@mui/material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { isMobile } from '../../../helpers/helpers';

class NewUserListItem extends Component {

  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      totalActivities: 10,
      activitiesInProgress: 6,
      showActivitiesList: [],
      anchorEl: null,
      loading:false,
  
    }
    this.handleCancel = this.handleCancel.bind(this)
  }

  componentDidMount() {
    document.addEventListener('nativeBackClick', this.handleCancel);
  }

  componentWillUnmount() {
    document.removeEventListener('nativeBackClick', this.handleCancel);
  }

  handleShowActivitiesList = (userId) => {
    let s = { ...this.state };
    let index = s.showActivitiesList.findIndex(ele => ele == userId);
    if (index >= 0) {
      s.showActivitiesList.splice(index, 1);
    } else {
      s.showActivitiesList.push(userId);
    }
    this.setState(s);
  }

  handleClickMoreIcon(event, organization) {  
    event.stopPropagation()
    console.log("handleClickMoreIcon click")  
    localStorage.setItem("selectedOrgId", organization)
    this.setState({ anchorEl: this.ref.current});
  };
  
  async handleOpenAssignActivity(user){
    this.setState({anchorEl: null});
    this.props.handleOpenAssignActivity(user);
  }


  handleOpenNoticePanel = (user) => {
    this.setState({ ...this.state, anchorEl: null });
    this.props.handleOpenNoticePanel(user);
  }

  handleOpenUserWorkflow = (user) => {
    this.setState({ ...this.state, anchorEl: null });
    this.props.handleOpenUserWorkflow(user)
    
  }

  handleOpenDashboardPanel = (user) => {
    this.setState({ ...this.state, anchorEl: null });
    this.props.handleOpenDashboardPanel(user)
  }

  handleMuteNotification(user) {
    this.setState({ anchorEl: null });
    this.props.handleMuteNotification(user)
  }

  handleCancel(){
    const orgId = localStorage.getItem("orgId")
    localStorage.setItem("selectedOrgId", orgId)
    this.setState({anchorEl: null})
  }

  handleClickItem(e, user, totalNetworkActivityCount, organization, item) {
    e.stopPropagation();
    if (item == "badge" && totalNetworkActivityCount) {
      this.handleOpenDashboardPanel(user)
    } else {
      this.props.onClickListItem(organization)
    }
  }

  render() {

    let { user, itemIndex, allActivities, networkActivities, notifications, organizationsObj } = this.props;
    const {userId} = user;
    let { showActivitiesList, anchorEl } = this.state;
    let statusList = user.statusList || [];
    let notify = notifications && notifications[userId] !== undefined ? notifications[userId] : true
    // if (user.lastUserStatusSets) {
    //   Object.keys(user.lastUserStatusSets).map((status) => {
    //     let obj = { color: user.lastUserStatusSets[status].color, status: user.lastUserStatusSets[status].status, direction: user.lastUserStatusSets[status].direction };
    //     statusList.push(obj);
    //   })
    // }
    let networkData = networkActivities ? networkActivities.find(ele => ele.userId == userId) || {} : {};
    let unconfirmedAchievements = networkData.unconfirmedAchievements || 0;
    let unreadMessages = networkData.unreadMessages || 0;
    const totalNetworkActivityCount = (networkData.unconfirmedAchievements || 0) + (networkData.unreadMessages || 0) + (networkData.unreadClientAlerts || 0);
    const inProgressActivities = user.userActivities || [];

    const organization = (organizationsObj && organizationsObj[user.organization]) || null;
    const enableWorkflows = isMobile() ? false : (organization && organization.enableWorkflows);
    
    return (
      <ListItem
        className={classnames('user-list-item list-card mb-3 pt-0')}
        data-testid={`client-${itemIndex+1}`}       
        >
        {/* <LifeSherpaLoading loading={muteLoading} /> */}
          <div className="d-flex mx-0   my-0 py-0  row justify-content-between w-100 align-items-center">
              <div className='col-12 m-0 p-0'>
                <div className="moreButtonI">
                  <div className=" d-flex justify-content-between align-items-center pb-1 ml-auto mr-0" style={{ borderRadius: 12 + "px" }}>
                      <div className='d-flex align-items-center'>
                        {organization && <Label className='mb-0 d-flex align-items-center justify-content-center'><CorporateFareIcon className='mr-1'/>{organization.name}</Label>}
                        <Label className='mb-0 ml-2 d-flex align-items-center justify-content-center'><PeopleOutlineIcon className='mr-1'/>{user && user.groupsName &&  user.groupsName.length  > 0 ? user.groupsName.join(", "): ""}</Label>
                      </div>
                      <div className='d-flex align-items-center justfy-content-end'>
                        <IconButton ref={this.ref} onClick={(e) => this.handleClickMoreIcon(e, user.organization)}><MoreVertIcon color="primary" aria-label="More" aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" style={{cursor:"pointer"}} data-testid="client-more-actions"/></IconButton>
                      </div>
                  </div>
                  <Menu id="simple-menu-clients-panel" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => this.handleCancel()}
                      slotProps={{
                        paper: {
                          style: { boxShadow: "1px 2px 1px gray" },
                        },
                      }}
                  >
                      {/* <MenuItem key="dashabord-item" className="menuEditItem" onClick={() => this.handleOpenDashboardPanel(user)}>Dashboard</MenuItem> */}
                      <MenuItem key="assign-activity-item" className="menuEditItem" onClick={() => this.handleOpenAssignActivity(user)}>Assign | Unassign Activity</MenuItem>
                      {/* {<MenuItem key="chat-item" className="menuEditItem" onClick={() => this.props.showChatPanelForSelectedUser(user)}>Chat</MenuItem>} */}
                      {<MenuItem key="notification-item" className="menuEditItem" onClick={() => this.handleMuteNotification(user)}>{notify ? "Mute Notifications" : "Unmute Notifications"}</MenuItem>}
                      {<MenuItem key="client-alert-item" className="menuEditItem" onClick={() => this.handleOpenNoticePanel(user)}>Alerts</MenuItem>}
                      {enableWorkflows && <MenuItem key="pending-workflow-item" className="menuEditItem" onClick={() => this.handleOpenUserWorkflow(user)}>Workflows</MenuItem>}
                      <MenuItem key="cancel-item" className="menuEditItem" onClick={() => this.handleCancel()}>Cancel</MenuItem>
                  </Menu>
                </div>
              </div>
              <div className="col-12  m-0  p-0 cps" onClick={(e) => this.handleClickItem(e, user, totalNetworkActivityCount, organization, "list")}>
                  <div className='row align-items-center'>
                    <div className='col-xl-3 col-lg-3 col-md-2 col-sm-3 col-3'>
                        <div className="media-left mt-2 ml-0 pl-0 pb-1 position-relative ">
                              <Badge className="badgeon-chatlist cps" onClick={(e) => this.handleClickItem(e, user, totalNetworkActivityCount, organization, "badge")} max={999} badgeContent={totalNetworkActivityCount} color="primary" 
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                              >
                                <img data-testid={`my-clients-client-image`} src={user.profileImageURL && user.name !== "Test User" ? user.profileImageURL : require('Assets/avatars/user-16.jpg')} className="img-fluid rounded-circle " alt="" width="60" height="60" />
                                {notify ? "" :
                                    <div data-testid={`my-clients-client-mute-icon`} title="Muted" className="list-badge-extension-mute ">
                                      <VolumeOffOutlinedIcon fontSize='large'/>
                                    </div>
                                  }
                              </Badge>
                        </div>
                    </div>
                    <div className='col-xl-9 col-lg-9 col-md-10 col-sm-9 col-9'>
                      <div className="row align-items-center">
                        <div className="col-lg-12 col-md-6 col-sm-12">
                          <div data-testid="my-clients-client-title" className="media-body pl-2 pt-5">
                            <h4 style={{ fontWeight: "550", cursor: 'pointer' }} className="mb-0 ml-1 mb-1">
                              {user.name || "Display Name"}
                              {inProgressActivities.length > 1 ? <span> ({inProgressActivities.length})</span> : ""}
                            </h4>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-12 pl-4">
                            {statusList.map((info, key) =>
                                <div data-testid={`my-clients-status-list-item${key}`} key={key + "statuslabel"} className="status-label " style={{ backgroundColor: info.color }}>
                                  <div className="d-flex m-0 p-0  justify-content-between w-100 align-items-center">
                                    <div style={{ height: "20px" }} className="m-0 p-0   text-left  align-items-left w-30">
                                      {info.direction == "neutral" ?
                                        <TrendingFlatSharpIcon />
                                        : info.direction == "positive" ?
                                          <TrendingUpIcon />
                                          : info.direction == "negative" ?
                                            <TrendingDownIcon />
                                            : ""
                                      }
                                    </div>
                                    <div className="m-0 p-0 text-right align-items-right w-70">{info.status}</div>
                                  </div>
                                </div>)
                            }
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className={"col-12 w-100 m-0 p-0 " + (inProgressActivities.length > 0 ? "border-top " : "")}>
              {inProgressActivities.length > 0 ? (
                <MainLandingPageItem
                  key={"firstactivity"}
                  allActivities={inProgressActivities}
                  itemKey={"firstactivity"}
                  activity={inProgressActivities[0]}
                  userId={userId}
                  handleShowActivitiesList={() => this.handleShowActivitiesList(userId)}
                  showActivitiesList={showActivitiesList}
                  inProgressActivitiesCount= {inProgressActivities.length}
                />
              )
                :
                ""
              }
              {
                inProgressActivities.length > 1 ?
                  <div className="d-flex m-0 p-0 row justify-content-between w-100 align-items-center">

                  {
                    showActivitiesList.findIndex((ele) => ele == userId) >= 0 ?
                      <LandingPageList inProgressActivities={inProgressActivities} userId={userId} /> : ""
                  }
                </div>
                :""
              }
            
              <div className={"d-flex col-12 mx-0 mt-2 user-list-extra-info  px-0 justify-content-between w-100 align-items-center m-0 "+(unreadMessages || unconfirmedAchievements ? "border-top":"")}>
                {unreadMessages ?
                  <div data-testid="my-clients-unread-messages" className="d-flex align-items-center " onClick={() => this.props.showChatPanelForSelectedUser(user)} style={{cursor:'pointer'}}>
                    <ChatOutlinedIcon className="reply-messages-icon"/>
                    <span className=" ml-1">{unreadMessages}</span>
                    <span className="unread-messages" >Reply</span>
                  </div>
                  : <div className="d-flex align-items-left"></div>
                }
                {unconfirmedAchievements ?
                  <div data-testid="my-clients-unconfirmedAchiements" onClick={() => this.props.handleOpenUnconfirmAchievements(user)} className="d-flex align-items-right justify-content-center mb-1 mt-2 mr-0 pr-0" style={{cursor:'pointer'}}>
                    <AssignmentTurnedInOutlinedIcon className="unconfirmed-activity-icon" />
                    <span className="">{unconfirmedAchievements}</span>
                    <span className="unconfirmed-activities" >Unconfirmed Activities</span>
                  </div>
                  : ""}
              </div>
            </div>
          </div>
      </ListItem>
    )
  }
}


export default NewUserListItem;
