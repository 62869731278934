/**
 * Chat App Reducers
 */

// actions types
import {
    GET_RECENT_ACTIVITIES,
    GET_SELECTED_ACTIVITIES,
    UPDATE_ACTIVITIES_SEARCH,
    SEARCH_ACTIVITIES,
    GET_ACTIVITIES_LIST,    
    GET_SUCCESS_ACTIVITIES_LIST,
    GET_ACTIVITIES_LIST_DETAIL,
    GET_SUCCESS_ACTIVITIES_LIST_DETAIL,
    SET_ACTIVITIES_INPROGESS,
    SET_ACTIVITIES_RESET,
    SET_SUCCESS_ACTIVITIES_RESET,
    SET_ACTIVITIES_REMINDER,
    SET_SUCCESS_ACTIVITIES_REMINDER,
    GET_REALTIME_ACTIVITY_DETAIL,
    GET_SUCCESS_REALTIME_ACTIVITY_DETAIL,
    SHOW_LOADING_INDICATOR_ACTIVITIES_LIST,
    HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,
    GET_ACTIVITY_FILTERS,
    GET_START_ACTIVITY,
    GET_START_ACTIVITY_DETAILS,
    GET_RESUME_ACTIVITY,
    GET_PAUSE_ACTIVITY,
    GET_END_ACTIVITY,START_ACTIVITY,
    GET_CANCEL_ACTIVITY,
    GET_RESUME_ACTIVITY_DETAILS,
    GET_LOADING,
    GET_ENDSTEP,EDITOR_LOADING,
    STOP_LOADING,
    GET_NETWORK_ACTIVITY,
    GET_NETWORK_ACTIVITY_SUCCESS,
    GET_LAUNCHPAD,
    GET_ACTIVITY_CALENDER,
    GET_LIST_STYLE,
    SET_ACTIVITY_TYPE,
    REMOVE_SUMMERY_ACTIVITY,
    GET_SUMMERY_ACTIVITY,
    ACTIVITY_START_TIME,
    REMOVE_RESUME_ACTIVITY,
    REMOVE_TIMER_ACTIVITY,SELECT_ACTIVITY_ID,
    GET_REALTIME_ACTIVITY_DETAIL1,GET_PRIVATE_LIST,
    GET_SUCCESS_ALL_ACTIVITIES_LIST,
    GET_COURES,
    REMOVE_COURES,
    GET_PINRESPONSE,
    REMOVE_PINRESPONSE,
    GET_WPQLINK,
    GET_REALTIME_USER_ACTIVITIES,
    CLEAR_START_ACTIVITY_DETAILS,
    GET_LOADING_FOR_COURSE,
    START_STEP_SLIDER_LOADING,
    STOP_STEP_SLIDER_LOADING,
    GET_ACTIVITY_GROUPS_LIST_SUCCESS,
    GET_ACTIVITY_GROUPS_LIST,
    GET_ACTIVITY_GROUPS_LIST_FAILED,
    EXECUTE_ACTIVITY,
    SET_ACTIVITIES_ACHIEVEMENTS,
    SET_USER_ACTIVITIES_ACHIEVEMENTS,
    GET_WPQ_LOADING,
    HIDE_WPQ_LOADING,
    HANDLE_SHOW_PHOTO_CAMERA,
    CATCH_API_ERROR,
    SET_FILTERED_ACTIVITIES_DATA,
    CLEAR_FILTERED_ACTIVITIES_DATA,
    SHOW_EXECUTE_ACTIVITY_CONFIRMATION_PANEL,
    HIDE_EXECUTE_ACTIVITY_CONFIRMATION_PANEL,
    HANDEL_EXECUTE_ACTIVITY_CONFIRMATION,
    ACTIVITY_PREVIEW_LOADING,
    SHOW_ACTIVITY_DETAILS_PANEL,
    HIDE_ACTIVITY_DETAILS_PANEL,
    ADJUST_ACHIEVEMENT_TIMER,
    ADJUST_ACHIEVEMENT_TIMER_SUCCESS,
    ADJUST_ACHIEVEMENT_TIMER_FAILED,
    GET_USERS_ECOUNTER_TYPES_ACTIVITIES,
    GET_USERS_ECOUNTER_TYPES_ACTIVITIES_SUCCESS,
    MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA,
    MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA_SUCCESS,
    HANDLE_ENCOUNTER_ACTIVITY_HISTORY,
    HANDLE_ENCOUNTER_ACTIVITY_DETAILS,
    HANDLE_ENCOUNTER_ACTIVITY_EXECUTION,
    SET_CLIENT_ACTIVITIES_TYPE,
    GET_ECOUNTER_ACTIVITIES_SUMMARY,
    GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS,
    GET_ECOUNTER_ACTIVITIES_SUMMARY_FAILED,
    GET_SUMMARY_LOADING,
    REALTIME_UPDATE_ACTIVITIES,
    RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST,
    SUCCESS_CHECK_GENERAL_ACTIVITY_PERMISSION,
    CHECK_GENERAL_ACTIVITY_PERMISSION,
    FAILED_CHECK_GENERAL_ACTIVITY_PERMISSION,
    UNASSIGN_CLIENT_COMMON_ACTIVITY,
    UNASSIGN_CLIENT_COMMON_ACTIVITY_SUCCESS,
    UNASSIGN_ACTIVITY_TO_CLIENT_FAILED,
    HIDE_UNASSIGN_ACTIVITY_LOADING,
    SELECT_ACTIVITY_FILTER_GROUP,
    RESET_ACTIVITIES_LIST,
    UPDATE_ACTIVITEIS_LIST,
    SHOW_START_ACTIVITY_PANEL,
    HIDE_START_ACTIVITY_PANEL,
    SHOW_ACTIVITY_EDITOR,
    HANDLE_SHOW_ADD_ACTIVITY_OPTIONS,
    CLEAR_USER_ACTIVITIES_DATA,
    GET_ALL_ACTIVITIES_LIST,
    GET_ALL_ACTIVITIES_LIST_SUCCESS,
    GET_ALL_ACTIVITIES_LIST_FAILED,
    GET_ALL_USER_ACTIVITIES_LIST,
    GET_ALL_USER_ACTIVITIES_LIST_SUCCESS,
    GET_ALL_USER_ACTIVITIES_LIST_FAILED
} from '../actions/types';

// chat users
// import recentActivities from 'Assets/data/chat-app/users';

const INITIAL_STATE = {    
    activityTimer:[],
    allActivities: null,
    privateActivities:null,
    recentActivities: null,
    allRecentActivities: null,
    selectedActivity: null,
    activityDetail: null,
    activityRealTimeDetail: null,
    allInprogressActivities: null,
    searchActivities: '',
    loading: false,
    loading1:false,
    networkActivityLoading: false,
    activityFilter:[],
    startActivity:null,
    startActivityDetails:null,
    pauseActivity:[],
    networkActivities:[],
    launchpad:[],
    activityCalender:null,
    listStyle:'common',
    activityType:'',
    activitySummery:[],
    selectedId:null,
    allUserActivities:null,
    userActivities:null,
    scheduled:[],
    unscheduled:[],
    coures:null,
    pinMsg:null,
    wpqLink:null,
    courseLoading:false,
    stepSliderLoading:false,
    activityGroups:null,
    activityGroupsLoading:false,
    executeActivity: false,
    activitiesAchievementsList:[],
    wpqLoading:false,
    showCamera:false,
    apiError: null,
    filteredActivities:null,
    executeActivityNotification: null,
    activityCompletionMessage: null,
    showActivityDetailsPanel:false,
    selectedActivityDetails:null,
    encounterLoading: false,
    encounterActivities: null,
    selectedEncounterActivityDetails: null,
    showEncounterActivityHistory: false,
    showEncounterActivityDetails:false,
    executeEncounterActivity:false,
    clientActivitiesType: "Normal",
    touchpointActivitiesSummary:null,
    summaryLoading:false,
    realtimeUpdate: 0,
    allowCreateGeneralActivity: false,
    unassignActivity: null,
    selectedActivityGroups: null,
    startActivityPanel:null,
    activityEditorConfig: null,
    showAddActivityOptions: false,
    inProgressAchievementsByOrg: {},
    inProgressUserAchievements: {},
    activitiesLoading: false,
    userActivitiesLoading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        // get recent chat user
        case GET_RECENT_ACTIVITIES:
            return { ...state, recentActivities: null };
        case GET_REALTIME_ACTIVITY_DETAIL:
            return {...state, loading: false}
        case GET_PRIVATE_LIST:
            return {...state,privateActivities:null,loading:true}    
        case GET_SUCCESS_REALTIME_ACTIVITY_DETAIL:
            return {...state, activityRealTimeDetail: action.payload, loading: false}
        case SET_ACTIVITIES_REMINDER:
            return { ...state, loading: false  };
        case SET_SUCCESS_ACTIVITIES_REMINDER:
            return { ...state, loading: false  };
        case SET_ACTIVITIES_RESET:
            return { ...state, loading: true  };
        case SET_SUCCESS_ACTIVITIES_RESET:
            return { ...state, loading: false  };
        case EXECUTE_ACTIVITY:
            console.log('Called EXECUTE_ACTIVITY------>')
            return { ...state, /*loading: true,*/ loading1:true, executeActivity: true, apiError: null, startActivityDetails: null, startActivity: null};
        // get recent chat user
        case GET_SELECTED_ACTIVITIES:
            return { ...state, selectedActivity: action.payload };
        case GET_REALTIME_USER_ACTIVITIES:
            let selectedActivityDetails = state.selectedActivityDetails;
            if(selectedActivityDetails) {
                let inprogressAchievements = action.payload;
                selectedActivityDetails.state = null;
                selectedActivityDetails.stepsCompletionList = [];
                inprogressAchievements && inprogressAchievements.length > 0 && inprogressAchievements.map(activity => {
                    if(activity.id === selectedActivityDetails.id) {
                        selectedActivityDetails = activity;
                    }
                })

            }
            return { ...state,userActivities:action.payload, selectedActivityDetails: selectedActivityDetails, loading: false};
        // get recent chat user 
        case GET_ACTIVITIES_LIST:
            return { ...state, loading: true };
        case GET_REALTIME_ACTIVITY_DETAIL1:
            return {...state,loading:false}       
        // get recent chat user
        case GET_SUCCESS_ACTIVITIES_LIST: 
            return { ...state, allActivities: action.payload, loading: false };
        case UPDATE_ACTIVITEIS_LIST: 
            const existingActivities = state.allActivities || []
            const orgActivitiesObj = action.payload || {}
            for(const index in existingActivities) {
                const activityId = existingActivities[index].id;
                if(orgActivitiesObj[activityId]) {
                    existingActivities[index] = orgActivitiesObj[activityId];
                }
            }

            return { ...state, allActivities: existingActivities, loading: false };
        case RESET_ACTIVITIES_LIST: 
            return { ...state, allActivities: [], loading: false, filteredActivities: null };
        case GET_SUCCESS_ALL_ACTIVITIES_LIST:
            return { ...state, allUserActivities: action.payload};
            
            // get recent chat user
        case GET_ACTIVITIES_LIST_DETAIL:
            return { ...state, loading: false };
        // get recent chat user
        case GET_SUCCESS_ACTIVITIES_LIST_DETAIL:
            return { ...state, activityDetail: action.payload, loading: false };
        // update search
        case UPDATE_ACTIVITIES_SEARCH:
            return { ...state, searchActivities: action.payload };

        // search user
        case SEARCH_ACTIVITIES:
            if (action.payload === '') {
                return { ...state, recentActivities: state.allRecentActivities};
            } else {
                const searchActivities = state.allRecentActivities.filter((user) =>
                    user.first_name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
                return { ...state, recentActivities: searchActivities }
            }
        // show loading 
        case SHOW_LOADING_INDICATOR_ACTIVITIES_LIST:
            return { ...state, loading: true };
        // hide loading 
        case HIDE_LOADING_INDICATOR_ACTIVITIES_LIST:
            return { ...state, loading: false };
        case SET_FILTERED_ACTIVITIES_DATA:
            return { ...state, loading: false, filteredActivities: action.payload };
        case CLEAR_FILTERED_ACTIVITIES_DATA:
                return { ...state, filteredActivities: null  };
        case GET_ACTIVITY_FILTERS:
            return {...state,activityFilter:action.payload} 
        case GET_LOADING:
            return {...state,loading:true/*,loading1:true*/}
        case EDITOR_LOADING:
            return {...state,loading1:true, apiError: null}    
        case GET_ENDSTEP:
            return {...state,loading1:false}    
        case GET_START_ACTIVITY:
            return {...state,startActivity:action.payload,loading: false,loading1:false}
        case ACTIVITY_PREVIEW_LOADING:
            return {...state, loading1:true, apiError: null, executeActivity: false, startActivityDetails: null, startActivity: null}
        case GET_START_ACTIVITY_DETAILS:
            return {...state,startActivityDetails:action.payload} 
        case GET_RESUME_ACTIVITY:
            return {...state,startActivity:action.payload,loading: false,loading1:false}
        case GET_RESUME_ACTIVITY_DETAILS: 
            return {...state,startActivityDetails:action.payload} 
        case GET_PAUSE_ACTIVITY:
            return {...state,pauseActivity:action.payload,loading: false,loading1:false, executeActivity: false} 
        case GET_END_ACTIVITY:
            return {...state,pauseActivity:[],startActivity: action.payload,loading: false,loading1:false}
        case GET_CANCEL_ACTIVITY:
            return {...state,pauseActivity:[],startActivity:{},loading: false,loading1:false, executeActivity: false}   
        case STOP_LOADING:
            return {...state,loading:false,loading1:false,courseLoading:false, networkActivityLoading: false}   

        case GET_NETWORK_ACTIVITY:
            return {...state, networkActivityLoading:true}
        case GET_NETWORK_ACTIVITY_SUCCESS:
            return {...state,networkActivities:action.payload,loading:false, networkActivityLoading:false}

        case GET_LAUNCHPAD:
            return {...state,launchpad:action.payload,loading:false} 

        case GET_ACTIVITY_CALENDER:
            return {...state,activityCalender:action.payload,loading:false} 

        case GET_LIST_STYLE:
            return {...state,listStyle:action.payload}

        case SET_ACTIVITY_TYPE:
            return {...state,activityType:action.payload}

        case REMOVE_SUMMERY_ACTIVITY:
            return {...state,activitySummery:[],loading:false}  

        case GET_SUMMERY_ACTIVITY:
            return {...state,activitySummery:action.payload,loading:false, summaryLoading:false} 
        case GET_SUMMARY_LOADING:
            return {...state, summaryLoading:true, activitySummery:null}
        case ACTIVITY_START_TIME:
            let index=state.activityTimer?state.activityTimer.findIndex((time)=>time.achievementId===action.payload.achievementId):-1
            if(index>-1) state.activityTimer[index].timer=action.payload.timer
            else state.activityTimer.push(action.payload)
            return {...state,activityTimer:state.activityTimer}

        case REMOVE_TIMER_ACTIVITY:
            let i=state.activityTimer?state.activityTimer.findIndex((time)=>time.achievementId===action.payload.achievementId):-1
            if(i>-1) state.activityTimer.splice(i,1)
            return {...state,activityTimer:state.activityTimer}

        case SELECT_ACTIVITY_ID:
                return {...state,selectedId:action.payload}

        case GET_COURES:
            return {...state,coures:action.payload,courseLoading:false,}

        case REMOVE_COURES:
            return {...state,coures:null}

        case GET_PINRESPONSE:
            return {...state,pinMsg:action.payload} 

        case REMOVE_PINRESPONSE:
            return {...state,pinMsg:null}

        case GET_WPQLINK:
            return {...state,wpqLink:action.payload.url, wpqLoading: false} 

        case CLEAR_START_ACTIVITY_DETAILS:
            return {...state, startActivityDetails: null, startActivity: null, executeActivity: false, executeActivityNotification: null} 
        case GET_LOADING_FOR_COURSE:
            return {...state, courseLoading:true, coures:null } 
        case START_STEP_SLIDER_LOADING:
            return {...state, stepSliderLoading:true} 
        case STOP_STEP_SLIDER_LOADING:
            return {...state, stepSliderLoading:false}
        case GET_ACTIVITY_GROUPS_LIST:
            return {...state, activityGroupsLoading: true}
        case GET_ACTIVITY_GROUPS_LIST_SUCCESS:
            return {...state, activityGroupsLoading: false, activityGroups:action.payload}
        case GET_ACTIVITY_GROUPS_LIST_FAILED:
            return {...state, activityGroupsLoading: false} 
        case SET_ACTIVITIES_ACHIEVEMENTS:
            const currentInProgress = state.inProgressAchievementsByOrg || {};
            const {orgAchievementsObj, orgId, callback} = action.payload;
            if(callback) {
                callback(state.allActivities, state.userActivities);
            }
            if(!currentInProgress[orgId]) {
                currentInProgress[orgId] = {};
            }
            currentInProgress[orgId] = orgAchievementsObj;
            return {...state, inProgressAchievementsByOrg: currentInProgress}
        case SET_USER_ACTIVITIES_ACHIEVEMENTS:
                const {achievementsObj} = action.payload;
                if(action.payload.callback) {
                    action.payload.callback(state.userActivities);
                }
                return {...state, inProgressUserAchievements: achievementsObj}
        case GET_WPQ_LOADING:
            return {...state, wpqLoading:true}
        case HIDE_WPQ_LOADING:
            return {...state, wpqLoading: false} 
        case HANDLE_SHOW_PHOTO_CAMERA:
            return {...state, showCamera: action.payload} 
        case CATCH_API_ERROR:
            return {...state, apiError: action.payload}
        case SHOW_EXECUTE_ACTIVITY_CONFIRMATION_PANEL:
            return {...state, executeActivityNotification: action.payload}
        case HIDE_EXECUTE_ACTIVITY_CONFIRMATION_PANEL:
            return {...state, executeActivityNotification: null}
        case HANDEL_EXECUTE_ACTIVITY_CONFIRMATION:
            return {...state, activityCompletionMessage: action.payload}
        case SHOW_ACTIVITY_DETAILS_PANEL:
            return {...state, showActivityDetailsPanel: true,selectedActivityDetails:action.payload}
        case HIDE_ACTIVITY_DETAILS_PANEL:
            return {...state, showActivityDetailsPanel: false,selectedActivityDetails:null}
        case ADJUST_ACHIEVEMENT_TIMER:
            return {...state, loading:true}
        case ADJUST_ACHIEVEMENT_TIMER_SUCCESS:
            let selectedActivity = state.selectedActivityDetails;
            if(action.payload && action.payload.achievement && selectedActivity) {
                let achievement = action.payload.achievement;
                if(action.payload.achievementId === selectedActivity.achievementId) {
                    selectedActivity.state = achievement.state;
                    if(achievement.routine) {
                        if(achievement.routine.steps && Object.keys(achievement.routine.steps).length > 0) {
                            selectedActivity.steps = achievement.routine.steps;
                        }
                        if(achievement.routine.expectedDuration) {
                            selectedActivity.expectedDuration = achievement.routine.expectedDuration
                        }
                    }
                    let stepsCompletion = achievement.StepsCompletion ? achievement.StepsCompletion : {};
                    let stepsCompletionList = [];
                    if (stepsCompletion) {
                        Object.keys(stepsCompletion).map((stepCompId, key) => {
                            stepsCompletionList.push(stepsCompletion[stepCompId]);
                        });
                        stepsCompletionList = stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped" )
                    }
                    selectedActivity.stepsCompletionList = stepsCompletionList;
                    selectedActivity.completed = stepsCompletionList.length;
                    if(selectedActivity.completed > 0 && stepsCompletionList[selectedActivity.completed - 1].step) {
                        selectedActivity.nextStep = stepsCompletionList[selectedActivity.completed - 1].step.title;
                    }
                    console.log("Achievemnet Expected Timer Updated -->")
                }

            }
            return {...state, loading:false, selectedActivityDetails: selectedActivity}
        case ADJUST_ACHIEVEMENT_TIMER_FAILED:
            return {...state, loading:false}

        case GET_USERS_ECOUNTER_TYPES_ACTIVITIES:
            return {...state, encounterLoading:true}

        case GET_USERS_ECOUNTER_TYPES_ACTIVITIES_SUCCESS:
            return {...state, encounterLoading:false, encounterActivities: action.payload}

        case MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA:
            return {...state, encounterLoading:true}
        case HANDLE_ENCOUNTER_ACTIVITY_HISTORY:
            return {...state, showEncounterActivityHistory: action.payload}
        case HANDLE_ENCOUNTER_ACTIVITY_DETAILS:
            return {...state, showEncounterActivityDetails: action.payload}
        case HANDLE_ENCOUNTER_ACTIVITY_EXECUTION:
            return {...state, executeEncounterActivity: action.payload}
        case SET_CLIENT_ACTIVITIES_TYPE:
            return {...state, clientActivitiesType: action.payload}
        case MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA_SUCCESS:
            let selectedAchievement= state.selectedActivityDetails;
            if(selectedAchievement) {
                let inprogressAchievements = action.payload;
                selectedAchievement.state = null;
                selectedAchievement.stepsCompletionList = [];
                inprogressAchievements && inprogressAchievements.length > 0 && inprogressAchievements.map(activity => {
                    if(activity.id === selectedAchievement.id) {
                        selectedAchievement = activity;
                    }
                })
            }
            return {...state, encounterLoading:false, encounterActivities: action.payload, selectedActivityDetails: selectedAchievement};
        case GET_ECOUNTER_ACTIVITIES_SUMMARY:
            return {...state, summaryLoading: true}
        case GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS:
            return {...state,summaryLoading: false,  touchpointActivitiesSummary: action.payload}
        case GET_ECOUNTER_ACTIVITIES_SUMMARY_FAILED:
            return {...state, summaryLoading: false}
        case REALTIME_UPDATE_ACTIVITIES:
            return {...state, realtimeUpdate: action.payload}
        case RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST:
            return INITIAL_STATE
        case CHECK_GENERAL_ACTIVITY_PERMISSION:
            return {...state, summaryLoading: true}
        case SUCCESS_CHECK_GENERAL_ACTIVITY_PERMISSION :
            const {addEditClientActivity} = action.payload
            return {...state, summaryLoading: false, allowCreateGeneralActivity: addEditClientActivity}
        case FAILED_CHECK_GENERAL_ACTIVITY_PERMISSION :
            return {...state, summaryLoading: false}

        case UNASSIGN_CLIENT_COMMON_ACTIVITY:
            return {...state, loading: true}
        case UNASSIGN_CLIENT_COMMON_ACTIVITY_SUCCESS:
            return {...state, loading: false, unassignActivity: action.payload}
        case UNASSIGN_ACTIVITY_TO_CLIENT_FAILED:
            return {...state, loading: false, unassignActivity: action.payload}
        case HIDE_UNASSIGN_ACTIVITY_LOADING:
            return {...state, loading: false}
        case SELECT_ACTIVITY_FILTER_GROUP:
            return {...state, selectedActivityGroups: action.payload}
        case SHOW_START_ACTIVITY_PANEL:
            return {...state, startActivityPanel: action.payload}
        case SHOW_ACTIVITY_EDITOR:
            return {...state, activityEditorConfig: action.payload}
        case HANDLE_SHOW_ADD_ACTIVITY_OPTIONS:
            return {...state, showAddActivityOptions: action.payload}
        case HIDE_START_ACTIVITY_PANEL:
            return {...state, startActivityPanel: null}
        case CLEAR_USER_ACTIVITIES_DATA: 
            return {...state, userActivities: null, inProgressUserAchievements: {}}
        case GET_ALL_ACTIVITIES_LIST: 
            if(action.payload && action.payload.callback) {
                action.payload.callback(state.inProgressAchievementsByOrg);
            }
            return {...state, activitiesLoading: true}
        case GET_ALL_ACTIVITIES_LIST_SUCCESS: 
            return {...state, activitiesLoading: false, allActivities: action.payload}
        case GET_ALL_ACTIVITIES_LIST_FAILED: 
            return {...state, activitiesLoading: false, allActivities: []}
        case GET_ALL_USER_ACTIVITIES_LIST: 
            if(action.payload && action.payload.callback) {
                action.payload.callback(state.inProgressUserAchievements);
            }
            if(action.payload && action.payload.clearData) {
                return { ...state, userActivitiesLoading: true, userActivities: []};
            }
            return {...state, userActivitiesLoading: true}
        case GET_ALL_USER_ACTIVITIES_LIST_SUCCESS: 
            return {...state, userActivitiesLoading: false, userActivities: action.payload}
        case GET_ALL_USER_ACTIVITIES_LIST_FAILED: 
            return {...state, userActivitiesLoading: false, userActivities: []}
        default: return { ...state };
      
    } 
}
