import React, { Suspense, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// app default layout
import RctAppLayout from '../components/RctAppLayout';
import LifeSherpaLoading from '../components/LifeSherpaLoading';

// lazy-loaded components
const AsyncLifesherpaDashboardComponent = lazy(() => import("Routes/dashboard/lifesherpa"));
// import AsyncLifesherpaDashboardComponent from "Routes/dashboard/lifesherpa";
const AsyncLifesherpaContacts = lazy(() => import("../components/LifesherpaContacts"));
const AsyncResourcesPage = lazy(() => import("../components/SherpaActivities/ResourceComponent"));
const AsyncUserModuleCharts = lazy(() => import("../components/Charts/UserModuleCharts"));
const AsyncLifesherpaiFrame = lazy(() => import("../components/LifesherpaiFrame/LifesherpaiFrame"));
const AsyncAnalyticsiFrame = lazy(() => import("../components/LifesherpaiFrame/AnalyticsiFrame"));
const AsyncLifesherpaWorkflow = lazy(() => import("../routes/lsworkflow"));

const DefaultLayout = () => {
  const location = useLocation(); // use location hook to get current location

  return (
    <RctAppLayout>
      <Suspense fallback={<LifeSherpaLoading loading={true} />}>
        <Routes location={location}>
          <Route
            path="/lsdashboard/*"
            element={<AsyncLifesherpaDashboardComponent />}
          />
          <Route
            path="/resources/*"
            element={<AsyncResourcesPage />}
          />
          <Route
            path="/usercharts/*"
            element={<AsyncUserModuleCharts />}
          />
          <Route
            path="/configurator"
            element={<AsyncLifesherpaiFrame />}
          />
          <Route
            path="/lms"
            element={<AsyncLifesherpaiFrame />}
          />
          <Route
            path="/lms_course"
            element={<AsyncLifesherpaiFrame />}
          />
          <Route
            path="/zoho_analytics"
            element={<AsyncAnalyticsiFrame />}
          />
          <Route
            path="/contacts/*"
            element={<AsyncLifesherpaContacts />}
          />
          <Route
            path="/workflows/*"
            element={<AsyncLifesherpaWorkflow />}
          />
        </Routes>
      </Suspense>
    </RctAppLayout>
  );
};

export default DefaultLayout;