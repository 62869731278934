/**
 * Landing Page List
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';

// redux action
import {
    showActivityHistoryDetail,
    getHistoryList,
    getSelectedActivity,
    executeActivity,
    clearHistoryData,
    showStartActivityPanel,
    showActivityEditor,
    deletePrivateActivity
} from '../../actions';
import { scrollMaincontainerToTop } from "../../helpers/helpers";

// component
import LandingPageAllItem from './LandingPageAllItem';

import LandingpageAgendaView from './LandingpageAgendaView'

import {database} from '../../firebase';
import { ref, query, orderByChild, equalTo, off} from "firebase/database"
import WidgetServices from "../../services/widgetServices";

import CustomScrollbars from "../CustomScrollbars";
import { isMobile } from "../../helpers/helpers";
import ActivityCalenderPopup from '../Widgets/ActivityCalenderPopup';
import SweetAlert from 'react-bootstrap-sweetalert';

class LandingPageAllList extends Component {
    
    state = {
        anchorEl: null,
        editScheduleKey: -1,
        deletePopup: false,
        previewPopup: false,
        stepNo: 0,
        calenderActivity: null,
        activity: '',
        notifyBox: false,
        selectedMenuActivity: {},
        openChart: false,
        chartParams: {},
        selectId: -1,
        showPopup: false,
        showCalenderPopup: false
    }

    componentDidMount() {
        document.addEventListener('nativeBackClick', this.handleCloseMenu);
    }

    componentWillUnmount() {
        let organization = localStorage.getItem('orgId')
        let uid = localStorage.getItem('auth_uid');
        const refValue = ref(database, `Realtime/${organization}/Users/${uid}/achievementsInProgress`)
        const topRef = query(refValue, orderByChild('preview') , equalTo(false))
        console.log("Removing Firebase listener--->", "achievementsInProgress")
        if (topRef) {
            off(topRef)
        }
        document.removeEventListener('nativeBackClick', this.handleCloseMenu);

    }

    handleCalenderButton(activity) {
        this.setState({ calenderActivity: activity, showCalenderPopup: true })
    }
    handleCloseCalenderPopup() {
        this.setState({ showCalenderPopup: false, calenderActivity: null })
    }


    handleClickMenu(event, key, activity, ref) {
        event.stopPropagation()
        // console.log('selected activity --->', activity)
        this.setState({ anchorEl: ref.current, editScheduleKey: key, selectedMenuActivity: activity });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null, editScheduleKey: -1, selectedMenuActivity: null });
    };

    handleDeletePop = () => {
        if (!this.state.deletePopup)
            this.setState({ deletePopup: true, anchorEl: null, });
        else this.setState({ deletePopup: false })
    }

    handleDeleteActivity = () => {
        let { selectedMenuActivity } = this.state;
        let userId = localStorage.getItem('auth_uid');
        let activityId = selectedMenuActivity.id
        this.props.deletePrivateActivity(userId, activityId);
        this.props.clearFilter();
        this.setState({ anchorEl: null, deletePopup: false, editScheduleKey: -1, selectedMenuActivity: null })
    }

    EditActivity = () => {
        let { selectedMenuActivity } = this.state;
        const activityId = selectedMenuActivity.id;
        const activityType = selectedMenuActivity.type === "QT-Reminder" ? "notification" : 'general';
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const typeOfActivity = activityType == "notification" ? "Notification" : "General";
            const data = { activityId: activityId, type: typeOfActivity };
            widgetServices.activityEditHandler(data)
        } else {
            this.props.showActivityEditor({activityId, editorType: activityType, organization: selectedMenuActivity.organization})
        }
        this.setState({ anchorEl1: null, anchorEl: null })
    }

    handleShowActivityHistoryList = () => {
        let { selectedMenuActivity } = this.state;
        let activity = selectedMenuActivity;
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const data = { activityId: activity.id };
            widgetServices.activityHistoryHandler(data)
        } else {
            this.props.clearHistoryData()
            const activityConfirmation = localStorage.getItem("activityConfirmation");
            if (activityConfirmation) {
                localStorage.removeItem("activityConfirmation");
            }
            if(activity.organization) {
                localStorage.setItem("selectedOrgId", activity.organization);
            }
            this.props.getSelectedActivity(activity);
            const userId = localStorage.getItem("uid");
            this.props.getHistoryList(userId, activity.id);
            scrollMaincontainerToTop();
            this.props.showActivityHistoryDetail(true);
            localStorage.setItem('prevUrl', window.location.pathname);
            this.props.navigate("/app/lsdashboard/activities/history");
        }
        this.setState({ anchorEl1: null, anchorEl: null })
    }

    /* Execute Activity*/
    handleExecuteActivity = (selectedActivity, scheduleKey) => {
        const widgetServices = new WidgetServices()

        if (widgetServices.isWidgetApp()) {
            const data = { activityId: selectedActivity.id };
            widgetServices.executeActivityHandler(data)
        } else {
            this.props.showStartActivityPanel({action:'execute', ...selectedActivity, scheduleKey});
        }
        this.setState({ anchorEl: null });
    }

    /* Preview Activity*/
    handleStartActivityPreview = () => {
        let { selectedMenuActivity } = this.state;
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            const data = { activityId: selectedMenuActivity.id };
            widgetServices.activityPreviewHandler(data)
        } else {
            this.props.showStartActivityPanel({action:'preview', ...selectedMenuActivity})
        }
        this.setState({ anchorEl: null })
    }


    handleResumeActivity = (selectedActivity, scheduleKey) => {
       
        const widgetServices = new WidgetServices()

        if (widgetServices.isWidgetApp()) {
            const data = { activityId: selectedActivity.id };
            widgetServices.resumeActivityHandler(data)
        } else {
            this.props.showStartActivityPanel({action:'execute', ...selectedActivity, scheduleKey})
        }

        this.setState({ anchorEl: null })
    }


    getScrollHeight() {
        if (localStorage.getItem("widgetApp")) {
            return 'calc(100vh - 160px)';
        } else if(this.props.isSidebarEnable) {
            return "calc(100vh - 221px)";
        } else {
            return '540px';
        }
        
    }

    render() {
        const { activitiesList, summaryLoading, isEdit, organizationsObj, enableWorkflows } = this.props;
        const {showCalenderPopup, calenderActivity} = this.state;

        const orgId = localStorage.getItem("orgId");
        
        const PopupsViews = () => (
            <React.Fragment>
                {showCalenderPopup ? <ActivityCalenderPopup showCalenderPopup={showCalenderPopup} activity={calenderActivity || {}} handleClose={this.handleCloseCalenderPopup.bind(this)}/> : <></>}
            </React.Fragment>
        );

        if(this.props.listStyle === 'common' && activitiesList && activitiesList.length) {
            return (
                <CustomScrollbars
                    className="rct-scroll list-sherpa-activity"
                    autoHide
                    style={{ height: this.getScrollHeight() }}
                    hideTracksWhenNotNeeded={isMobile()}
                >
                    <ul className="list-unstyled m-0 list-sherpa-activity mt-2 mb-4 pt-0 pl-2 pr-2" >
                        {activitiesList.map((activity, index) => (
                            <LandingPageAllItem
                                key={index}
                                allActivities={activitiesList}
                                activity={activity}
                                editScheduleKey={this.state.editScheduleKey}
                                selectedMenuActivity={this.state.selectedMenuActivity}
                                handleOpenHistory={() => this.handleShowActivityHistoryList()}
                                itemKey={index}
                                anchorEl={this.state.anchorEl}
                                handleClickMenu={this.handleClickMenu.bind(this)}
                                handleCloseMenu={() => this.handleCloseMenu()}
                                handleCalenderButton={() => this.handleCalenderButton(activity, index)}
                                handleDeletePop={() => this.handleDeletePop()}
                                EditActivity={(e) => this.EditActivity()}
                                handleStartActivityPreview={(e) => this.handleStartActivityPreview()}
                                handleResumeActivity={(e) => this.handleResumeActivity(activity)}
                                handleExecuteActivity={(e) => this.handleExecuteActivity(activity)}
                                hideRewards={this.props.hideRewards}
                                showStartOrResumeButton={true}
                                organization={ organizationsObj && organizationsObj[activity.organization || orgId]}
                            />
                        ))

                        }
                    </ul>
                    {PopupsViews()}
                    {this.state.deletePopup &&
                        <div className="col-12 p-4">
                            <SweetAlert
                                warning
                                showCancel
                                btnSize="sm"
                                customClass="warningText"
                                confirmBtnText="Delete"
                                confirmBtnBsStyle="warning"
                                cancelBtnBsStyle="default"
                                title="Delete"
                                onConfirm={() => this.handleDeleteActivity()}
                                onCancel={() => this.setState({ deletePopup: false, anchorEl: null })}
                            >
                                <div style={{ fontFamily: "'Lato'", fontWeight: 400 }}>Do you want to delete {this.state.selectedMenuActivity.title || "this"} Activity?</div>
                            </SweetAlert>
                        </div>
                    }
                </CustomScrollbars>
            )
        }
        
        return (
            <CustomScrollbars
                className="rct-scroll list-sherpa-activity"
                autoHide
                style={{ height: this.getScrollHeight() }}
                hideTracksWhenNotNeeded={isMobile()}
            >
                <ul className="list-unstyled m-0 list-sherpa-activity mt-2 mb-4 pt-0 pl-2 pr-2" >
                    {this.props.listStyle === 'summary' ?
                        summaryLoading ? 
                                <div data-testid="agendaview-loading-activity" className="d-flex justify-content-center align-items-center py-50">
                                <h4>Fetching activities Summary</h4>
                            </div> 
                            : 
                            <LandingpageAgendaView
                                editScheduleKey={this.state.editScheduleKey}
                                selected={this.props.selected}
                                selectedMenuActivity={this.state.selectedMenuActivity}
                                allActivities={activitiesList}
                                handleShowActivityHistoryList={this.handleShowActivityHistoryList.bind(this)}
                                anchorEl={this.state.anchorEl}
                                handleClickMenu={this.handleClickMenu.bind(this)}
                                handleCloseMenu={this.handleCloseMenu.bind(this)}
                                handleCalenderButton={this.handleCalenderButton.bind(this)}
                                handleDeletePop={this.handleDeletePop.bind(this)}
                                EditActivity={this.EditActivity.bind(this)}
                                handleStartActivityPreview={this.handleStartActivityPreview.bind(this)}
                                handleResumeActivity={this.handleResumeActivity.bind(this)}
                                handleExecuteActivity={this.handleExecuteActivity.bind(this)}
                                hideRewards={this.props.hideRewards}
                            />
                        :
                        <div data-testid="landing-page-no-inprogress-activity" className="d-flex justify-content-center align-items-center py-50 no-data-found">
                            <h4>{this.props.activitiesLoading ? "Fetching activities" : "Activities Not Found"}</h4>
                        </div>
                    }
                    {PopupsViews()}
                </ul>
            </CustomScrollbars>
        )
       
    }
}

// map state to props
const mapStateToProps = ({ authUser, clientsList }) => {
    const { hideRewards } = authUser;
    const {organizationsObj} = clientsList
    return { hideRewards, organizationsObj};
}

export default withRouter(connect(mapStateToProps, {
    showActivityHistoryDetail,
    getHistoryList,
    getSelectedActivity,
    executeActivity,
    clearHistoryData,
    showStartActivityPanel,
    showActivityEditor,
    deletePrivateActivity
})(LandingPageAllList));