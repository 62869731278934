/**
 * App Header
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import withRouter from '../../router';
// import { withRouter } from 'react-router-dom';
import SetCurrentStatus from '../SetCurrentStatus';
// actions
import { updateStatus, getUserStatusById, realTimeStatus, getWorkspaceDetails, isWorkspaceAdminStatus, getUserChangePassword, showSetCureentStatusPopup } from '../../actions';
import AppConfig from '../../constants/AppConfig';
import SherpaUserProfile from './SherpaUserProfile'
import {auth, database} from '../../firebase'
import { ref, onValue, off} from "firebase/database"
import {onIdTokenChanged} from 'firebase/auth'
import UserProfileBlock from './UserProfileBlock';
import { isMobile } from '../../helpers/helpers';

class Header extends Component {

	state = {
		showStatus: false,
	}

	componentDidMount() {
		let idToken = localStorage.getItem('idToken')
		let uid = localStorage.getItem('auth_uid')
		let organization = localStorage.getItem('orgId')
		this.props.getWorkspaceDetails(organization);

		if (idToken && uid && organization) {
			this.props.getUserStatusById(uid,idToken);
			this.props.isWorkspaceAdminStatus(uid);
			
			onValue(ref(database, `Realtime/${organization}/Users/${uid}/lastUserStatusSets`), (snapshot) => {
				const realtimeData = snapshot.val();
				this.props.realTimeStatus(realtimeData);
				this.setState({ ...this.state })
			});
		} else {
			this.props.navigate('/launchscreen')
		}

		if(this.props.profile && this.props.profile.email) {
			this.props.getUserChangePassword(this.props.profile.email);
		}
		
		onIdTokenChanged(auth, (userAuth) => {
			if(userAuth == null) {
				this.props.navigate('/logout');
			} 
		});
	}

	componentWillUnmount() {
		// Cleanup the listener
		const uid = localStorage.getItem('auth_uid')
		const organization = localStorage.getItem('orgId')
		const userStatusRef = ref(database, `Realtime/${organization}/Users/${uid}/lastUserStatusSets`);

		// Remove the listener
		if (userStatusRef) {
			off(userStatusRef);
		}
	}

	handleStatus = (userId) => {
		const idToken = localStorage.getItem("idToken");
		let uid = userId || localStorage.getItem('auth_uid');
		console.log(" Clicked on updated status button");	
		this.props.getUserStatusById(uid, idToken);
		this.props.showSetCureentStatusPopup(true);
	}

	updateStatus = (statusList) => {
		console.log(" Clicked on submit set status form");	
		let data = this.props.status
		if (data && data.sections) {
			Object.keys(data.sections).map((obj) => {
				const index = statusList.findIndex(item => obj.includes(item.userStatusSetId))
				data.sections[obj] = statusList[index]
			})

			let uid = localStorage.getItem('auth_uid')
			this.props.updateStatus(uid, data)
		}
		this.props.showSetCureentStatusPopup(false);
	}

	handelStatusEditClose = () => {
		this.props.showSetCureentStatusPopup(false);
	}
	
	render() {
		let { statusData, themeData, showStatus } = this.props
		let {themeLoading} = this.props;
		const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
		let activeThemeId = localStorage.getItem('activeTheme');
		let themeLogo= localStorage.getItem("loginLogoImage") || this.props.themeLogo;
		let localLogo = activeThemeId == 9 || activeThemeId == 8 || activeThemeId == 5 || activeThemeId == 10 ?  AppConfig.appLogoWhite : AppConfig.appLogo;
        let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};
		if(isMobile()) {
			return (
				<React.Fragment> 
					<UserProfileBlock handleStatus={this.handleStatus}/>
					{this.props.currentStatusPopup &&
						<SetCurrentStatus
							showStatus={this.props.currentStatusPopup}
							handelStatusEditClose={this.handelStatusEditClose}
							updateStatus={this.updateStatus}
							popupMode={auth_uid == uid ? "Admin": "Client"}
						/>
					}
				</React.Fragment>
				
			)
		}
		
		return (
			<AppBar data-testid="landing-page-header" position="static" id="ls-header-appbar" className="rct-header ls-portal-header">
				<Toolbar className="pl-0 pr-0">
					<div className="row header-content-box w-100 align-items-center">
						<div >
							<div className="d-inline-flex align-items-center">
								{activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default') ? 
									<Link to="/" className="logo-mini lifesherpa-logo">
										{!themeLoading && 
										<img data-testid="ls-logo"  src={themeLogo || localLogo} alt="site logo" width="auto" height="auto" className='auto-resize-img' />
										}
									</Link>
								: !themeLoading &&
								 <Link to="/" className={`lifesherpa-theme-logo text-center ${themeData && (themeData.logoBackgroundColor) ? "header-theme-logo-background" : ""}`} >
									<img data-testid="ls-logo"  src={ themeLogo || localLogo} alt="site logo" width="auto" height="auto" className='auto-resize-img'/>
									<div className="d-flex  justify-content-center lifesherpa-poweredby-logo">
									{themeData && themeData.loginLifeSherpaImage &&
										<img src={themeData.loginLifeSherpaImage} alt="session-logo" width="auto" height="30" />
									}
									</div>
								</Link>
								}					
							</div>
						</div>
						<div className={`d-flex align-items-center pl-3 ml-auto justify-content-end`}>
							<ul className="list-inline mb-0">
							    { showStatus ? statusData && statusData.length > 0 ? statusData.map((status , key) => {
									let {direction, value, color , score } = status;
										return (
											<li data-testid={`client-status-list${key}`}  key={key} className={"w-auto list-inline-item text-white p-2 px-3 my-1 "+(key < 1 ? "d-inline-block": key <= 2 ? "d-none d-md-inline-block":"d-none d-xl-inline-block")} style={{ borderRadius: 20 + "px", background: color ? color : '', color: color === '#ffff00' ? '#677080' : 'white' }}>
												<div className="d-flex">
													{direction && direction === 'positive' ?
														<div className='mr-1'>
															<i className="zmdi zmdi-trending-up zmdi-hc-lg"></i>
														</div>
														: direction && direction === 'neutral' ?
															<div className='mr-1'>
																<i className="zmdi zmdi-long-arrow-right zmdi-hc-lg"></i>
															</div>
															: direction && direction === 'negative' &&
															<div className='mr-1'>
																<i className="zmdi zmdi-trending-down zmdi-hc-lg"></i>
															</div>
													}
													{value}
												</div>
											</li>
										)
									}) : 
										<li data-testid={`client-status-list${0}`}  key={0} className={"w-auto list-inline-item p-2 px-3"} style={{ borderRadius: 20 + "px", background: themeData && themeData.tabBarButtonsColor ? themeData.tabBarButtonsColor : '#000', color: themeData && themeData.tabBarColor ? themeData.tabBarColor : "white" }}>
											No Status
										</li> 
								: <></>
								}
								<li  className="list-inline-item setting-icon">
								{showStatus &&
									<IconButton className="edit-status-button" title="Status" aria-label="settings" onClick={() => this.handleStatus()} >
										<BorderColorRoundedIcon data-testid="client-status-edit-button" />
									</IconButton>
								}
								</li>
								{ 
									<li data-testid="client-profile-image-and-dropdown-icon" title="Profile" className="list-inline-item">
										<SherpaUserProfile />
									</li>
								}
								
							</ul>
						</div>
					</div>
				</Toolbar>
			
				{this.props.currentStatusPopup &&
					<SetCurrentStatus
						showStatus={this.props.currentStatusPopup}
						handelStatusEditClose={this.handelStatusEditClose}
						updateStatus={this.updateStatus}
						popupMode={"Admin"}
					/>
				}
			</AppBar>
		);
	}
}

// map state to props
const mapStateToProps = ({ settings, authUser, Status }) => {
	let {status} = Status;
	const {statusData, profile,themeLoading , themeData, usersInfo, currentStatusPopup} = authUser;
	const { themeLogo } = settings; 
	const {showStatus} = usersInfo ? usersInfo : {showStatus: false}

	return {themeLoading ,statusData, status, profile, themeLogo, themeData, showStatus, currentStatusPopup };
};

export default withRouter(connect(mapStateToProps, {
 updateStatus, getUserStatusById, realTimeStatus, getWorkspaceDetails, isWorkspaceAdminStatus, getUserChangePassword, showSetCureentStatusPopup
})(Header));
