import React, { Component } from 'react';
import '../../../assets/scss/custom/startActivityDetails.scss';
import SetCurrentStatus from '../../SetCurrentStatus';

class SetCurrentStatusForm extends Component {
	state = {
		StatusPopup: true,
		statusList: [],

	}

	componentDidMount() {
		let { statusData } = this.props;
		let { statusList } = this.state;
		if (statusData) {
			Object.keys(statusData.sections).map((obj, key) => {
				statusList.push(statusData.sections[obj])
			})
			this.props.setFormStatusData( statusList)
			// this.setState({ StatusPopup: true, statusList })
		}
		
	}

	updateStatus = (statusList) => {
		if(this.props.executeActivity) {
			console.log(" Clicked on submit set status form");
			const uid = localStorage.getItem('auth_uid')
			const { statusData } = this.props;	
			let data = statusData;
	
			if (data && data.sections) {
				Object.keys(data.sections).map((obj) => {
					const index = statusList.findIndex(item => obj.includes(item.userStatusSetId))
					data.sections[obj] = statusList[index]
				})
	
				this.props.updateStatus(uid, data)
			}
			this.props.handleUpdateStatus();
		}
		this.handelStatusEditClose();
		
	}

	handelStatusEditClose = () => {
		// this.setState({ StatusPopup: false, statusList: [] })
		this.props.handelStatusFormClose();
	}

	render() {	
		const {showSetStatusPopup} = this.props
		return (
			<SetCurrentStatus
					showStatus={showSetStatusPopup}
					handelStatusEditClose={this.handelStatusEditClose}
					updateStatus={this.updateStatus}
					configuratorPreview={this.props.configuratorPreview}
					hideCancel={true}
					image={this.props.image}
					name={this.props.name}
					popupMode={this.props.popupMode}
                    preview={this.props.preview}
				/>
		);
	}
}

export default SetCurrentStatusForm