import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import ContactsList from './ContactsList';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { connect } from 'react-redux';
// reactstrap components
import UserChat from '../../LifesherpaContacts/UserChat';
import { v4 as uuidv4 } from 'uuid';
import config from '../../../config/config'

import {
    getClientContacts,
    handleShowHelpPanel,
    setSelectedChatUser,
    removeSelectedChatUser
} from '../../../actions';
import { openURL, isMobile } from '../../../helpers/helpers';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';

class HelpViewPage extends Component {
    state = {
        iframeLoading: true,
        openList: false,
        windowWidth: window.innerWidth,
        selectedChat: null
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.props.getClientContacts();
    }
    handleHideHelp = () => {
        this.props.handleShowHelpPanel({ show: false, url: null });
    }

    handleSelectClient = (value) => {
        console.log("selected chat: ", value);
        if (this.state.selectedChat) {
            if(this.state.selectedChat.userId !== value.userId) {
                this.setState({ selectedChat: null }, () => {
                    this.opneSelectedChat(value)
                    this.setState({ selectedChat: value })
                });
            }
        } else {
            this.opneSelectedChat(value)
            this.setState({ selectedChat: value });
        }

    }

    handleBack = () => {
        this.setState({ selectedChat: null });
    }

    handleVideo = (selectedChat) => {
        this.openVidoeRoomNewTab(selectedChat);
    }

    opneSelectedChat = (value) => {
        this.removeSelectedChatUser(value.userId);
        this.props.setSelectedChatUser(value);
    }

    removeSelectedChatUser = (userId) => {
        console.log(" Chat closed for user ->",userId);
        let {selectedChatUsers} = this.props;
        let index = selectedChatUsers.findIndex(user => user.userId == userId);
        if(index >= 0) {
            selectedChatUsers.splice(index,1);
            this.props.removeSelectedChatUser(selectedChatUsers);
        }
    }

    openVidoeRoomNewTab = (selectedChat) => {
        let selectedVideoChat = { ...selectedChat };
        const roomname = uuidv4();
        selectedVideoChat.name = roomname;
        let videoCallRoomDetails = { name: selectedVideoChat.name }
        videoCallRoomDetails.userId = selectedVideoChat.userId;
        videoCallRoomDetails = JSON.stringify(videoCallRoomDetails);
        localStorage.setItem("videoCallRoomDetails", videoCallRoomDetails)
        let openVideoCallRoom = `${config.lsPortalUrl}/lifesherpa/videocall?room=${selectedVideoChat.name}`;
        if (isMobile()) {
            this.props.navigate(`/lifesherpa/videocall?room=${selectedVideoChat.name}`)
        } else {
            openURL(openVideoCallRoom)
        }
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }

    scrollToTop = () => {
        var objDiv = document.getElementsByClassName('MuiDialogContent-root')[0] || document.getElementsByClassName('alert-view-page')[0];
        if (objDiv) {
            objDiv.scrollTop = 0
        }
    };

    handleOpenMemberList = () => {
        this.setState({ openList: !this.state.openList }, () => {
            this.scrollToTop();
        });
    }

    isVideoUrl = (url) => {
        const videoExtensionsList = ["MOV", "WEBM", "AVI", "MP4", "M4P", "M4V", "MPG", "MP2", "MPEG", "MPE", "MPV"];
        const index = videoExtensionsList.findIndex(extension => url.toUpperCase().includes(extension));
        return index >= 0;
    }

    // isAudioUrl = (url) => {
    //     const audioExtensionsList = ["MP3" ,"AAC", "M4A", "FLAC", "MP4", "WAV", "WMA", "FLAC", "AIFF", "DSD" ,"PCM"];
    //     const index = audioExtensionsList.findIndex(extension => url.toUpperCase().includes(extension));
    //     return index >= 0 ;
    // }

    isImageUrl = (url) => {
        const imageExtensionsList = ["JPEG", "PNG", "GIF", "TIFF", "PSD"];
        const index = imageExtensionsList.findIndex(extension => url.toUpperCase().includes(extension));
        return index >= 0;
    }



    render() {
        const { clientContacts, clientContactsLoading, currentStep } = this.props;
        const helpVideoUrl = (currentStep && currentStep.helpVideoURL) || "";
        const title = (currentStep && currentStep.title) || "Help";
        const { iframeLoading, windowWidth, selectedChat } = this.state;
        console.log("helpVideoUrl : ", helpVideoUrl);
        return (
            <div>
                <div className='row help-panel-container' data-testid="client-help-contact" >
                    <div className={`col-sm-12 col-md-8 clo-xl-9 col-lg-9 ${windowWidth > 700 ? "pr-0" : ""}`}>
                        <LifeSherpaLoading loading={this.isVideoUrl(helpVideoUrl) ? false : clientContactsLoading} />
                        <div>
                            <div className={`d-flex justify-content-between pl-3 pr-2  w-100 align-items-center  ${windowWidth < 700 ? 'py-2' : 'py-3'} `}>
                                <div className="d-flex">
                                    <h4 className="mb-0 mr-3">{title}</h4>
                                </div>
                            </div>
                            <div data-testid="client-contacts" className={`${windowWidth > 700 ? "d-none" : selectedChat ? "d-none" : "d-block"}`}>
                                <div className={"px-0"} >
                                    <div className='pl-3 pr-2 py-2'>
                                        <div className='d-flex align-items-center justify-content-between' onClick={() => this.handleOpenMemberList()}>
                                            <h4 className='my-2'>Contact someone for help</h4>
                                            <IconButton className=" ml-auto">
                                                {this.state.openList ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
                                            </IconButton>
                                        </div>
                                    </div>
                                    {
                                        this.state.openList ?
                                            <ContactsList windowWidth={windowWidth} clientContactsLoading={clientContactsLoading} handleSelectClient={this.handleSelectClient} members={clientContacts} />
                                            : ""
                                    }

                                </div>
                            </div>
                            <div className='px-3 notice-pdf-viewer'>
                                {selectedChat ?
                                    <div className='chat-container container-border'>
                                        <UserChat customBack={true} activityChatScreen={true}  handleVideo={(chat) => this.handleVideo(chat)} handleBack={() => this.handleBack()} />
                                    </div>
                                    : this.isImageUrl(helpVideoUrl) ?
                                        <div className='image-container' data-testid="help-contact-iframe">
                                            <img className="ing-fluid w-100" src={helpVideoUrl} alt="" />
                                        </div>
                                        : this.isVideoUrl(helpVideoUrl) ?
                                            <div data-testid="help-contact-iframe">
                                                <video  width="100%" controls autoPlay loop muted>
                                                    <source src={helpVideoUrl} type="video/mp4" />
                                                </video>
                                            </div>
                                            : helpVideoUrl ?
                                                <>
                                                    <iframe data-testid="help-contact-iframe" width="100%" src={helpVideoUrl} onError={() => this.setState({ iframeLoading: false })} onLoad={() => this.setState({ iframeLoading: false })} loading={<LifeSherpaLoading />} />
                                                </>
                                                :
                                                <div className='d-flex align-items-center my-0 py-4 mx-auto empty-container container-border justify-content-between'>
                                                    <div className='text-center w-100 mt-4 pt-4'>
                                                        <h3>Sorry, no help file specified</h3>
                                                    </div>
                                                </div>
                                }
                                <div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-testid="client-contacts" className={`col-sm-12 col-md-4 clo-xl-3 col-lg-3 ${windowWidth > 700 ? "pl-0" : "d-none"}`}>
                        <div>
                            <div className='pl-3 pr-1 py-2'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h4 className='my-2'>Contact someone for help</h4>
                                </div>
                            </div>
                            <ContactsList windowWidth={windowWidth} clientContactsLoading={clientContactsLoading} handleSelectClient={this.handleSelectClient} members={clientContacts} />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = ({ Contacts, chatAppReducer, activitiesList }) => {
    const { clientContacts, clientContactsLoading } = Contacts;
    const { currentStep, selectedChatUsers, selectedUser } = chatAppReducer;
    const {clientActivitiesType} = activitiesList;
    return { clientContacts, clientContactsLoading, currentStep, clientActivitiesType, selectedChatUsers, selectedUser };
}

export default withRouter(connect(mapStateToProps, { 
    getClientContacts, 
    handleShowHelpPanel,
    setSelectedChatUser,
    removeSelectedChatUser 
})(HelpViewPage));
