/**
 * Ecommerce Dashboard
 */

import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import WidgetServices from "../../../services/widgetServices";

// rct collapsible card
import RctCollapsibleCard from '../../../components/RctCollapsibleCard/RctCollapsibleCard'
//Actions
import {
    getMyCourses,
    getCourseLaunchURL,
    getUserById
} from '../../../actions'

import CoursesComponent from '../../../components/Courses';
import { withAuth0 } from "@auth0/auth0-react";
import ActivitySuportCourses from "../../../components/Widgets/ActivitySupportCourses"
import LifeSherpaLoading from '../../../components/LifeSherpaLoading';

class Courses extends Component {

    constructor(props) {
        super(props)
        this.state = {
            role: null,
            firebaseToken: null,
            selected: '',
            coursePanel: false,
            assingnActivityPanel: false,
        }
    }

    componentDidMount() {
        const { widgetApp } = this.props;
        const auth_uid = localStorage.getItem('auth_uid');
        const idToken = localStorage.getItem('idToken')
        if (idToken && auth_uid) {
            this.props.getMyCourses()
            this.props.getUserById(auth_uid, idToken, widgetApp)
        }
        this.setState({ firebaseToken: idToken });
    }

    getCourseLaunchURL = (course) => {
        console.group("%c[LMSCourse]%c Rendered LMS iFrame", "color:black", "color:green");
        const courseId = course.id;
        const widgetServices = new WidgetServices()
        if (widgetServices.isWidgetApp()) {
            this.props.getCourseLaunchURL(courseId, widgetServices);
        } else {
            this.props.getCourseLaunchURL(courseId, null, null, true);
            this.setState({ coursePanel: true, selectedCourse: course.name })
        }   
    }

    handleCloseCourse() {
        console.log("%c[LMSCourse]%c Closed LMS iFrame", "color:black", "color:red");
        console.groupEnd();
        this.setState({ coursePanel: false, assingnActivityPanel: false, selectedCourse: '' })
    }

    render() {
        window["auth0"] = this.props.auth0
        let { coursePanel, selectedCourse } = this.state;
        const { launchpad, myCourseLoading, myCourses, allowedModules, showLMSWidget} = this.props;
        return (
            <div className="w-100 " data-testid="ls-dashboard">
                { coursePanel ?
                    <CoursesComponent
                        handleCloseCourse={this.handleCloseCourse.bind(this)}
                        selectedCourse={selectedCourse}
                    />
                    :
                    <div className="row">
                        {(showLMSWidget && allowedModules && allowedModules.LMSInstructor) ?
                            <RctCollapsibleCard
                                colClasses="col-12 w-xs-full"
                                heading={<div className="d-flex align-items-center justify-content-between text-white">
                                    <div>My Courses</div>
                                    <div >
                                        {myCourses && myCourses.courses && myCourses.courses.length > 0 ? "(" + myCourses.courses.length + ")" : ""}
                                    </div>
                                </div>
                                }
                                fullBlock
                                customClasses="overflow-hidden theme-background "
                                headingCustomClasses="bg-lifesherpa"
                            >
                                <Suspense fallback={<LifeSherpaLoading loading={true} />}>
                                    <ActivitySuportCourses getCourseLaunchURL={this.getCourseLaunchURL} myCourseLoading={myCourseLoading} myCourses={myCourses && myCourses.courses ? myCourses.courses : null} launchpad={launchpad} />
                                </Suspense>
                            </RctCollapsibleCard>
                            : <></>
                        }
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ authUser, activitiesList, Charts, MyCourses}) => {
    const { allowedModules} = authUser
    const { launchpad } = activitiesList
    const { loading } = Charts
    const { myCourseLoading, myCourses, showLMSWidget } = MyCourses;
    return { launchpad, loading, myCourseLoading, myCourses, allowedModules, showLMSWidget }
}

export default withAuth0(connect(mapStateToProps, {
    getMyCourses,
    getCourseLaunchURL,
    getUserById,
})(Courses))
