import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment'
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddIcon from '@mui/icons-material/Add';
import NoticesFilter from './NoticesFilter';
import Scrollbars from "react-custom-scrollbars"
import LifeSherpaLoading from '../LifeSherpaLoading';
import {
    getNoticeHistory
} from '../../actions';
import {isMobile} from '../../helpers/helpers';

class NoticesHistory extends Component {
    state = {
        filterOptions: [],
        showFilter: false,
        applyedFilterOptions: [],
        applyFiltersOnActivities: false,
    }

    componentDidMount() {
        const { selectedUserId } = this.props;
        this.props.getNoticeHistory(selectedUserId);
    }

    handelFilterOpitonsChange = (id) => {
        //let tempState = { ...this.state };
        // let index = tempState.filterOptions.findIndex(groupId => groupId === id);
        // if (index >= 0) {
        //     tempState.filterOptions.splice(index, 1);
        // }
        // else {
        //     tempState.filterOptions.push(id);
        // }
        this.setState({ filterOptions: [id] });
    }

    handleCloseFilter = () => {
        this.setState({ ...this.state, showFilter: false });
    }

    handelShowFilter = () => {
        let { applyedFilterOptions } = this.state;
        this.setState({ ...this.state, showFilter: true, filterOptions: [...applyedFilterOptions] });
    }

    clearFilter = () => {
        this.props.getNoticeHistory(this.props.selectedUserId);
        this.setState({ ...this.state, showFilter: false, applyFiltersOnNotices: false, filterOptions: [], applyedFilterOptions: [], showInactiveActivities: false });
    }

    applyFilter = (inActiveActivities) => {
        let { filterOptions } = this.state;
        const auth_uid = localStorage.getItem('auth_uid');
        //const uid = localStorage.getItem('uid');
        if (filterOptions.length > 0) {
            this.props.getNoticeHistory(this.props.selectedUserId, filterOptions[0]);
            this.setState({ ...this.state, showFilter: false, applyFiltersOnNotices: true, applyedFilterOptions: [...filterOptions], showInactiveActivities: inActiveActivities });
        } else {
            this.setState({ ...this.state, showFilter: false, applyFiltersOnNotices: false, filterOptions: [], applyedFilterOptions: [], showInactiveActivities: inActiveActivities });
        }
    }

    getScrollHeight() {
        const { widgetApp } = this.props;
        if (widgetApp || isMobile()) {
            return 'calc(100% - 65px)';
        } else {
            return 'calc(100vh - 210px)';
        }
    }

    render() {
        const { noticesHistory, noticeHistoryLoading } = this.props;
        const { applyFiltersOnNotices, showFilter } = this.state;
        console.log("noticesHistory :", noticesHistory);
        return (

            <React.Fragment>
                <LifeSherpaLoading loading={noticeHistoryLoading} />
                {showFilter &&
                    <NoticesFilter
                        showFilter={showFilter}
                        filterOptions={this.state.filterOptions}
                        clearFilter={this.clearFilter}
                        applyFilter={this.applyFilter}
                        handleCloseFilter={this.handleCloseFilter}
                        handelFilterOpitonsChange={this.handelFilterOpitonsChange}
                    />
                }
                <div data-testid="client-notice-alert-history-header" className="notice-history-alerts-panel-header p-2 d-flex justify-content-between w-100 align-items-center">
                    <div className="notice-alert-nav-title d-flex justify-content-center w-100 align-items-center">
                        <h3 className="mb-0">Alerts</h3>
                    </div>
                    <div className={`d-flex ml-auto ${applyFiltersOnNotices ? "activity-filter-icon" : "activity-filter-icon-applied"}`}>
                        <IconButton data-testid="client-alert-filter-button" title="Filter By Notice Type  " className="rounded-circle mr-2" onClick={() => this.handelShowFilter()}>
                            <FilterAltIcon />
                        </IconButton>
                    </div>
                    <div className={`d-flex activity-filter-icon-applied`}>
                        <IconButton data-testid="create-client-alert-button" title="Create Notice " className="rounded-circle" onClick={() => this.props.showNoticesListPanel()}>
                            <AddIcon />
                        </IconButton>
                    </div>
                </div>

                <Scrollbars
                    className="rct-scroll "
                    autoHide
                    style={{ height: this.getScrollHeight() }}
                >
                    {noticesHistory && noticesHistory.length > 0 ?
                        <List data-testid="client-alert-history-list" className="list-unstyled px-0 pt-0 pb-3 notices-history">
                            {
                                noticesHistory.map((alert, key) => (
                                    <ListItem data-testid={`notice-post-${key}`} key={key} className={`${key % 2 === 0 ? "styleToListItem" : " defaultStyleToListItem"} px-15 py-2 cps d-flex justify-content-between align-content-center`} onClick={() => this.props.openAlertView(alert)}>

                                        <div className={`d-flex   w-100 align-items-center pt-2 pb-2 ${alert.openedByAdmin === false ? "error-list-item" : ""}`}>
                                            <div className="w-30">
                                                <h4 className="mb-0">{moment(new Date(alert.createdOn)).format('MMM DD, YYYY')}</h4>
                                            </div>
                                            <div className="media-body">
                                                <h4 className="mb-0">{alert.title}</h4>
                                            </div>

                                        </div>
                                    </ListItem>
                                ))
                            }
                        </List>
                        :
                        <div data-testid="client-alert-history-no-data" className='d-flex align-items-center my-4 py-4 mx-auto justify-content-between'>
                            <div className='text-center w-100 mt-4 pt-4'>
                                {noticeHistoryLoading ? "Fetching Alerts..." : "Alerts Not Found"}
                            </div>
                        </div>
                    }
                </Scrollbars>

            </React.Fragment>

        );
    }
}

const mapStateToProps = ({ noticesReducer }) => {
    const { noticesHistory, noticeHistoryLoading, selectedUserId } = noticesReducer;
    return { noticesHistory, noticeHistoryLoading, selectedUserId };
}

export default connect(mapStateToProps, { getNoticeHistory })(NoticesHistory);
