import React, { Component } from 'react';
import { connect } from 'react-redux';
import VideocamIcon from '@mui/icons-material/Videocam';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import IconButton from '@mui/material/IconButton';
import { removeVideoToken, setTwilioClient, addChannelToContactList, generateTwilioTokenForContacts, notifyUser , removeSelectedUser} from "Actions"
import ChatItem from "./ChatItem"
import LifeSherpaLoading from 'Components/LifeSherpaLoading';
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar';
import {
  List,
} from "@mui/material";
import Grid from '@mui/material/Grid2'
import IconButton from '@mui/material/IconButton'
import SendIcon from '@mui/icons-material/Send';

import config from '../../config/config'
import { v4 as uuidv4 } from 'uuid';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Client as ConversationsClient } from "@twilio/conversations";
import DotsLoader from "../DotsLoader";
import {getTwilioChatInfo, isMobile} from "../../helpers/helpers";
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
import classNames from 'classnames';
import {Capacitor} from '@capacitor/core'

const styles = {
  textField: {
    width: "100%",
    borderWidth: 0,
    borderColor: "transparent",
    height: 45,
    marginBottom: 3
  },
  textFieldContainer: {
    flex: 1,
    marginRight: 3,
    marginLeft: 2
  },
  gridItem: {
    paddingTop: 12,
    paddingBottom: 12
  },
  gridItemChatList: {
    overflow: "auto",
    height: "56vh"
  },
  gridItemChatList1: {
    overflow: "auto",
    height: "51vh"
  },
  gridItemMessage: {
    marginTop: 2,
    marginBottom: 2
  },
  sendButton: {
    backgroundColor: '#008C95',
    height: 45,
    marginLeft: 2
  },
  sendIcon: { color: "white" },
  mainGrid: { paddingTop: 100, borderWidth: 1 },
};

class UserChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      messages: [],
      loading: false,
      conversation: null,
      videoCallRoomName: null,
      textMenuOpen: null,
      bottomScroll: false,
      unReadMessagesCount: 0,
      showUnAvailabelAlert: false,
      alertMessage: "",
      isTyping: false
    }
    this.scrollDiv = React.createRef();
  }
  
  componentDidMount = async () => {
    document.addEventListener('nativeBackClick', this.handleNativeBack);
    this.props.removeVideoToken();
    const testing = localStorage.getItem("testing");
    if (!testing) {
      const { selectedUser } = this.props;
      if(selectedUser) {
        await this.handleChatChannelFetch();
      } else {
        this.props.navigate('/app/contacts');
      } 
    }
    const { selectedUser } = this.props;
    if (selectedUser && selectedUser.status == "Unavailable" && this.props.fromNotification) {
      this.setState({ showUnAvailabelAlert: true, alertMessage: "User is unavailable" })
    }
  }
  handleNativeBack = () => {
    console.log("native back clicked for Chat screen ........");
    this.handleBack()
  }


  componentWillUnmount() {
    document.removeEventListener('nativeBackClick', this.handleNativeBack);
    const { conversation } = this.state;
    if (conversation) {
      conversation.off("messageAdded", m => this.handleMessageAdded(m));
      conversation.off("typingStarted", () => this.handleTypingStarted());
      conversation.off("typingEnded", () => this.handleTypingEnded());
      this.setState({ text: "", messages: [], loading: false, conversation: null });
    }
  }

  handleChatChannelFetch = async () => {
    let { selectedUser, twilioClient } = this.props;
    console.log("[UserChat] selectedUser -->", selectedUser);
    if (selectedUser.conversation) {
      this.setState({ conversation: selectedUser.conversation, messages: selectedUser.messages || [], loading: true}, () => {
        this.scrollToBottom(true);
      });
      this.joinConversation(selectedUser.conversation);
    } else {
      this.setState({ loading: true });
      const details = await getTwilioChatInfo(selectedUser.userId);
      console.log("Chat Details : ", details);
      if(twilioClient && details && details.room) {
        this.createConversationWithUniqueName(twilioClient, details, true);
      } else if(details && details.token && details.room) {
        this.createTwilioClient(details);
      } else {
        this.setState({ loading: false });
      } 
    }
  }

  createTwilioClient = async (details) => {
    const {token, room} = details;
    try {
      const client = new ConversationsClient(token);
      console.log("[UserChat] conversation client created --> ");
      client.on('stateChanged', (state) => {
        if (state === 'initialized') {
          console.log(" Client state initialized --> ")
          this.props.setTwilioClient(client);
          this.createConversationWithUniqueName(client, details);
        }
      })
    } catch (e) {
      console.error("[UserChat] error occured while creating channel -->", e);
      this.setState({ loading: false });
    }
  }
  createConversationWithUniqueName = async (client, details, clientCreateRetry) => {
    const {token, room} = details;
    try {
      const conversation = await client.getConversationByUniqueName(room.uniqueName);
      console.log("[UserChat] fetched conversaton by unique name -->", conversation);
      this.updateConversation(conversation);
      
    } catch (err) {
      console.error("[UserChat] Unable to get conversation by unique name --> ", room, err);
      try {
        const conversation = await client.createConversation({
          friendlyName: room.friendlyName,
          uniqueName: room.uniqueName,
        })
        await conversation.add(this.props.selectedUser.userId)
        console.log("[UserChat] conversaton created -->", conversation);
        this.updateConversation(conversation);
      } catch (error) {
        console.error("[UserChat] Unable to create conversation, please reload this page --> ", error);
        if(token && clientCreateRetry) {
          this.createTwilioClient(details);
        } else {
          this.setState({ loading: false });
        }
      }
      // this.setState({ loading: false });
    }
  }

  updateConversation = (conversation) => {
    const { contactList, selectedUser } = this.props;
    this.setState({ conversation: conversation });
    this.joinConversation(conversation);
    this.props.addChannelToContactList(contactList, conversation, selectedUser.userId);
  }

  joinConversation = async (conversation) => {
    try {
      if (conversation._internalState.status !== "joined") {
        await conversation.join();
      }
      const messages = await conversation.getMessages(50);
      if (messages.items && messages.items.length > 0) {
        this.setState({ messages: messages.items, clientMessages: messages }, () => {
          this.scrollToBottom();
        });
      }
      conversation.on("messageAdded", m => this.handleMessageAdded(m));
      conversation.on("typingStarted", () => this.handleTypingStarted());
      conversation.on("typingEnded", () => this.handleTypingEnded());
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.log("[UserChat] error occured with conversation", e);
    }
  };


  handleMessageAdded = (message) => {
    let { messages, bottomScroll, unReadMessagesCount, conversation } = this.state;
    let uid = localStorage.getItem('auth_uid');
    var objDiv = document.getElementById('chatlists1');
    if (conversation && objDiv) {
      // console.log("[UserChat] conversation messages added --->", message);
      if (message.author === uid || !bottomScroll) {
        this.setState({ messages: messages && messages.length > 0 ? [...messages, message] : [message] }, () => {
          this.scrollToBottom();
        });
      } else {
        unReadMessagesCount = unReadMessagesCount + 1;
        this.setState({ messages: messages && messages.length > 0 ? [...messages, message] : [message], unReadMessagesCount: unReadMessagesCount });
      }
    }
  };

  scrollToBottom = async (onlyScroll) => {
    try {
      var objDiv = document.getElementById('chatlists1');
      if(onlyScroll) {
        if(objDiv) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
        return;
      }
      const { conversation } = this.state;
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
        if (conversation) {
          const status = await conversation.setAllMessagesRead();
          console.log("message unread successfully : ", status);
        } 
        this.setState({ bottomScroll: false, unReadMessagesCount: 0 });
      } 
    } catch(error) {
      console.error("scrollToBottom Failed :", error)
    }
   
  };


  handleScrollOnChat = (event) => {
    var objDiv = event.currentTarget;
    const { bottomScroll } = this.state;
    // console.log(" clientHeight --> ", objDiv.clientHeight);
    // console.log(" scrollTop --> ", objDiv.scrollTop);
    // console.log("scrollHeight ----> ", objDiv.scrollHeight);
    const clientHeight = objDiv.clientHeight;
    const scrollTop = objDiv.scrollTop + clientHeight;
    const scrollHeight = objDiv.scrollHeight;
    if (scrollTop < (scrollHeight - clientHeight)) {
      if (!bottomScroll) {
        this.setState({ bottomScroll: true })
      }
    } else if (bottomScroll) {
      const { conversation } = this.state;
      if (conversation) {
        conversation.setAllMessagesRead().then(status => {
          // console.log("[UserChat] messages Consumed -->", status);
        }).catch(e => {
          console.log("[UserChat] error occured while reading messages -->", e);
        })
      }
      this.setState({ bottomScroll: false, unReadMessagesCount: 0 })
    }
    if (objDiv && objDiv.scrollTop === 0) {
      this.handleFetchPreMessages();
    }
  }

  handleFetchPreMessages = () => {
    const { clientMessages, loading, messages } = this.state;
    if (clientMessages && clientMessages.hasPrevPage && !loading) {
      this.setState({ loading: true });
      clientMessages.prevPage().then(preMessages => {
        if (preMessages.items && preMessages.items.length > 0) {
          // console.log("[UserChat] Fetched previous page messages --> ");
          let updatedMessagesList = [...preMessages.items, ...messages];
          const objDiv = document.getElementById('chatlists1');
          const previousHeight = objDiv ? objDiv.scrollHeight : 0;
          this.setState({ messages: updatedMessagesList, clientMessages: preMessages, loading: false, bottomScroll: true }, () => {
            this.handleScrollFocusOnOldMessagesLoaded(previousHeight);
          });
          
        }
      }).catch(error => {
        this.setState({ loading: false });
        console.log("[UserChat] error occured while fetching previous messages -->", error)
      })
    }
  }

  handleScrollFocusOnOldMessagesLoaded = (previousHeight) => {
    var objDiv = document.getElementById('chatlists1');
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight - previousHeight;
    }
  }

  sendMessage = () => {
    const { text, conversation, videoCallRoomName } = this.state;
    let { selectedUser } = this.props;
    if (text && conversation) {
      if (text && String(text).trim()) {
        this.setState({ loading: true, text: "" });
        conversation.sendMessage(text).then(() => {
          this.setState({ loading: false });
        }).catch((error) => {
          console.log("[UserChat] error occured while sending message", error, error.message, error.data);
          this.setState({ loading: false, showUnAvailabelAlert: true, alertMessage: error.message });
        })
      }
      if (videoCallRoomName) {
        this.props.notifyUser(selectedUser.userId, videoCallRoomName);
        this.setState({ videoCallRoomName: null });
      }
    }
  };

  handleEnter = (evt) => {
    if (evt.keyCode == 13 && evt.shiftKey) {
      // evt.preventDefault();
      // this.setState({text: this.state.text+"\n"})
      //evt.preventDefault();
      this.activeTyping();
    } else if (evt.keyCode == 13) {
      evt.preventDefault();
      this.sendMessage(evt)
    } else {
      this.activeTyping();
    }
  }

  openTextMenuOptions = event => {
    this.setState({ textMenuOpen: event.currentTarget });
  }
  closeTextMenuOptions = () => {
    this.setState({ textMenuOpen: null });
  };
  sendVidoeLink = () => {
    let { selectedUser } = this.props;
    const selectedVideoChat = { ...selectedUser };
    const roomname = uuidv4() //`${Math.random().toString(36).substr(2, 6)}-${Math.random().toString(36).substr(2, 5)}-${Math.random().toString(36).substr(2, 3)}`;
    selectedVideoChat.name = roomname;
    let videoCallRoom = `Join Video Call: ${config.lsPortalUrl}/lifesherpa/videocall?room=${selectedVideoChat.name}`;
    //let videoCallRoom = `Join Video Call: http://localhost:8000/lifesherpa/videocall?room=${selectedVideoChat.name}`;
    this.setState({ text: videoCallRoom, textMenuOpen: null, videoCallRoomName: selectedVideoChat.name });
  }
  handleVideo = (chat) => {
    this.props.handleVideo(chat);
  }
  getFilteredMessages = (messages, conversation) => {
    if (conversation && conversation.uniqueName) {
      return messages && messages.length > 0 ? messages.filter(msg => {
        let uniqueName = msg && msg.conversation && msg.conversation.uniqueName ? msg.conversation.uniqueName : "";
        return uniqueName === conversation.uniqueName
      }) : [];
    } else {
      return messages;
    }
  }
  activeTyping = () => {
    const { conversation } = this.state;
    if (conversation) {
      conversation.typing().then((s) => {
        // console.log("[UserChat] typing enable", s)
      }).catch((error) => {
        console.log("[UserChat] error occured while enabling typing", error);
      })
    }
  }
  handleTypingStarted = () => {
    // console.log("[UserChat] typing started --> ")
    let { bottomScroll } = this.state;
    if (!bottomScroll) {
      this.setState({ isTyping: true }, () => {
        this.scrollBottomToShowTypingIndicator()
      })
    }
  }

  scrollBottomToShowTypingIndicator = () => {
    var objDiv = document.getElementById('chatlists1');
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
    // this.setState({ isTyping: true })
  }

  handleTypingEnded = () => {
    // console.log("[UserChat] typing ended --> ")
    this.setState({ isTyping: false })
  }

  handleBack = () => {
    this.props.removeSelectedUser();
    if(!this.props.customBack) {
      this.props.navigate("/app/contacts");
    } else {
      this.props.handleBack();
    }
  }

  render() {
    const { loading, text, messages, conversation, textMenuOpen, bottomScroll, unReadMessagesCount, showUnAvailabelAlert, alertMessage, isTyping } = this.state;
    let { selectedUser, activityChatScreen } = this.props;
    selectedUser = selectedUser || {};
    let uid = localStorage.getItem('auth_uid');
    let filteredMessages = [];
    filteredMessages = this.getFilteredMessages(messages, conversation);
    return (
      <div className="add-video-group-panel chat-panel-messges" id={"contact-user-chat-screen"} data-testid="selected-contact-chat-panel">
        <div className="d-flex add-group-header lifesherpa-chat-panel-header align-items-center justify-content-between py-1">
          <div className="media align-items-center">
            <IconButton className="text-white font-lg" onClick={() => this.handleBack()} data-testid="chat-panel-back-button"><ArrowBackIcon /></IconButton>
            <div className="mr-2" >
              {selectedUser && selectedUser.profileImageURL ?
                <img src={selectedUser.profileImageURL} className="rounded-circle align-self-center" width="40" height="40" />
                : <Avatar className="align-self-center user-later-avatar">{selectedUser.name ? selectedUser.name.charAt(0) : ""}</Avatar>
              }
            </div>
            <div className="media-body">
              <h2 className="m-0" data-testid="chat-panel-contact-name">{selectedUser.name}</h2>
              {isTyping && <div className="fs-12">Typing....</div>}
            </div>
          </div>
          {selectedUser.videoCall && <IconButton className=" mr-2" onClick={() => this.handleVideo(selectedUser)} disabled={selectedUser.status === "Unavailable"} data-testid="chat-panel-video-button"><VideocamIcon /></IconButton>}
        </div>
        <LifeSherpaLoading loading={loading} />
        {showUnAvailabelAlert ?
          <SweetAlert
            warning
            btnSize="sm"
            customClass="warningText"
            confirmBtnText="Close"
            confirmBtnBsStyle="warning"
            title={alertMessage}
            onConfirm={() => this.setState({ showUnAvailabelAlert: false })}
          >
            <div className="sweet-alert-text">Please try again later.</div>
          </SweetAlert> 
          :<></>
        }
        {bottomScroll && unReadMessagesCount ?
          <Badge className="scroll-to-bottom-badge" badgeContent={unReadMessagesCount} color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
            <IconButton className="scroll-to-bottom-icon" onClick={() => this.scrollToBottom()}><ArrowDownwardIcon /></IconButton>
          </Badge>
          : bottomScroll ?
            <div className="scroll-to-bottom-box">
              <IconButton className="scroll-to-bottom-icon" onClick={() => this.scrollToBottom()}><ArrowDownwardIcon /></IconButton>
            </div>
            : ""
        }
        <Grid className={classNames({'chat-item-list': !isMobile() , 'chat-panel-height': Capacitor.getPlatform() === 'android' && !activityChatScreen, 'chat-panel-height-ios': Capacitor.getPlatform() === 'ios', 'activity-chat-panel-height': Capacitor.getPlatform() === 'android' && activityChatScreen })} item style={styles.gridItemChatList} onScroll={(e) => this.handleScrollOnChat(e)} id="chatlists1">
          <List dense={true}>
            {filteredMessages &&
              filteredMessages.map((message, index) => (
                <ChatItem
                  key={message.state.timestamp + index}
                  message={message}
                  email={uid}
                />
              ))}
          </List>
          {isTyping &&
            <div className="d-flex align-items-center mx-3">
              <div style={{ width: "50px" }}>
                <DotsLoader />
              </div>
            </div>
          }
        </Grid>

        <Grid item style={styles.gridItemMessage}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="px-3 py-1 chat-footer"
          >
            <Grid item style={styles.textFieldContainer}>
              <div className="mr-3 w-100">
                <textarea
                  rows="2"
                  autoComplete="off"
                  className="chat-msg-input-field"
                  placeholder={selectedUser.status === "Unavailable" ? "User is unavailable" : selectedUser.chat != undefined && !selectedUser.chat ? "Chat is not supported" : "Type your message"}
                  onChange={(event) => this.setState({ text: event.target.value })}
                  onKeyPress={(e) => this.handleEnter(e)}
                  onKeyDown={(e) => this.handleEnter(e)}
                  value={this.state.text}
                  disabled={selectedUser.status === "Unavailable" || (selectedUser.chat != undefined && !selectedUser.chat)}
                />
              </div>
            </Grid>
            <Grid item>
              <IconButton
                onClick={(e) => this.sendMessage(e)}
                className="send-icon-button"
                disabled={selectedUser.status == "Unavailable" || (selectedUser.chat != undefined && !selectedUser.chat) ? true : text && String(text).trim() ? false : true}
              >
                <SendIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </div>);
  }
}

const mapStateToProps = ({ Contacts, chatAppReducer, settings}) => {
  const { loading, chatToken, videoToken, channel, selectedVideoGroup, contactList, twilioClient } = Contacts
  const { selectedUser } = chatAppReducer;
  return { loading, chatToken, videoToken, channel, selectedVideoGroup, contactList, twilioClient, selectedUser };
};
export default withRouter(connect(mapStateToProps, { removeVideoToken, setTwilioClient, addChannelToContactList, generateTwilioTokenForContacts, notifyUser, removeSelectedUser })(UserChat));
