import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import {openURL} from "../../../helpers/helpers"

export default function AlertDialog({open, handleCloseAlert, link, loading, handleIAgree}) {
  
  const handleOpenLink = () => {
      openURL(link)
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Read & Accept"}
      </DialogTitle>
      <DialogContent>
        <LifeSherpaLoading loading={loading}/>
        <DialogContentText id="alert-dialog-description">
         Before you can continue you must read and accept the LifeSherpa Data Privacy Policy.
        </DialogContentText>
        <DialogContentText id="alert-dialog-description" className='text-right'>
          <Button onClick={handleOpenLink}>READ DATA PRIVACY POLICY</Button>
        </DialogContentText>
        <DialogContentText id="alert-dialog-description" className='text-right'>
          <Button disabled={loading} onClick={handleIAgree}>I AGREE</Button>
        </DialogContentText>
        <DialogContentText id="alert-dialog-description" className='text-right'>
          <Button onClick={handleCloseAlert}>CANCEL</Button>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}