import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import AppConfig from "../../constants/AppConfig";

class NotificationAlert extends Component {
    state = {
        open: true,
        isActionable: false
    }
    componentDidMount() {
        const {notificationData} = this.props;
        if(notificationData && (((notificationData.twi_body || notificationData.twi_message_id) && notificationData.conversation_title) || notificationData.type == 9)) {
            this.setState({isActionable: true})
        } 
    }

    
    handleConfirm = () => {
        this.props.handleConfirm()
    }

    handleClose = () => {
        this.props.handleCancel()
    }

    render() {
        const {open, title, body} = this.props
        return (
            <div> 
                <Dialog
                    fullScreen={false}
                    open={open}
                    fullWidth={true}
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} warning-dialog-popup`}
                    id="notification_alert"
                >
                    <DialogContent>
                        <div className="d-flex align-items-center caller-image justify-content-left">
                            <Avatar
                                alt="Remy Sharp"
                                src={AppConfig.lsIconLogo}
                                variant="circular"
                                overlap="circular"
                                style={{border: "3px solid"}}
                            />
                            <div className='ml-2 execute-activity-alert'>
                                <h2 className="mb-0">{title}</h2>
                                <h2 className="subHeading mb-0">{body}</h2>
                            </div>
                            <span></span>
                        </div>
                    </DialogContent>
                    <DialogActions className='border-top'>
                        {this.state.isActionable ?
                            <div className="d-flex align-items-center justify-content-between">
                                <button className="btn btn-danger action_button" onClick={() => this.handleClose()}>CLOSE</button>
                                <button className="btn btn-success ml-2 action_button" onClick={() => this.handleConfirm()}>VIEW</button>
                            </div>
                        : 
                            <div className="d-flex align-items-center justify-content-between">
                                <button className="btn btn-success ml-2 action_button" onClick={() => this.handleClose()}>CLOSE</button>
                            </div>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
export default NotificationAlert
