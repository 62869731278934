/**
 * Clients List Actions
 */

import {getUserById, getAllGroupMembers, getMyCourses, getWorkflowList, clearResourcesData, clearCourseData, clearRewardsData, clearUserActivitiesData} from '../actions'
import {axiosClient} from '../sherpaApi'
import { NotificationManager } from 'react-notifications';

import {
    CHAT_WITH_SELECTED_CLIENT,
    SEND_MESSAGE_TO_CLIENT,
    GET_RECENT_CHAT_CLIENTS,
    UPDATE_CLIENTS_SEARCH,
    SEARCH_CLIENTS,
    SHOW_LOADING_INDICATOR_CLIENT_LIST,
    HIDE_LOADING_INDICATOR_CLIENT_LIST,
    SHOW_CLIENT_PANEL,
    MUTE_CLIENT_NOTIFICATIONS,
    MUTE_CLIENT_NOTIFICATIONS_SUCCESS,
    MUTE_CLIENT_NOTIFICATIONS_FAILED,
    GET_CLIENT_MUTE_NOTIFICATION,
    GET_CLIENT_MUTE_NOTIFICATION_SUCCESS,
    GET_CLIENT_DETAIL_BY_ID,
    GET_SUCCESS_CLIENT_DETAIL_BY_ID,
    HANDLE_SHOW_CLIENT_DASHBOARD,
    GET_CLIENT_NETWORK_ACTIVITY,
    GET_CLIENT_NETWORK_ACTIVITY_SUCCESS,
    GET_CLIENT_NETWORK_ACTIVITY_FAILED,
    CLEAR_CLIENT_NETWORK_ACTIVITIES_DATA,
    FETCH_USER_ORGANIZATIONS,
    FETCH_USER_ORGANIZATIONS_SUCCESS,
    APPLY_ORGANIZATIONS_FILTER_AT_CLIENT,
    APPLY_ORGANIZATIONS_FILTER_AT_ACTIVITIES,
    APPLY_ORGANIZATIONS_FILTER_AT_COURSES,
    APPLY_ORGANIZATIONS_FILTER_AT_RESOURCES,
    CLEAR_ORGANIZATIONS_FILTER_FROM_CLIENT,
    CLEAR_ORGANIZATIONS_FILTER_FROM_ACTIVITIES,
    CLEAR_ORGANIZATIONS_FILTER_FROM_COURSES,
    CLEAR_ORGANIZATIONS_FILTER_FROM_RESOURCES,
    GET_SELECTED_GROUP,
    CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW,
    APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW,
    APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW_SUMMARY,
    CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW_SUMMARY,
    SAVE_WORKFLOW_ENABLE_STATUS,
    SELECT_CLIENT_PAGE_MENU_ITEM
} from './types';
import { isMobile } from '../helpers/helpers';

/**
 * Redux Action To Emit Boxed Layout
 * @param {*boolean} isBoxLayout 
 */
export const showClientPanel = (bool) => (dispatch) => {
    dispatch(clearResourcesData())
    dispatch(clearCourseData())
    dispatch(clearRewardsData())
    dispatch(clearUserActivitiesData())
    if(bool) {
        dispatch({type: SELECT_CLIENT_PAGE_MENU_ITEM, payload: "/app/lsdashboard/client"});
    }
    if(!bool) {
        const auth_uid = localStorage.getItem('auth_uid');
        localStorage.setItem('uid', auth_uid);
    }
    dispatch({ type: SHOW_CLIENT_PANEL, payload: bool });    
};
export const chatWithSelectedClient = (client) => ({
    type: CHAT_WITH_SELECTED_CLIENT,
    payload: client
});
export const getRecentClients = (client) => ({
    type: GET_RECENT_CHAT_CLIENTS,
    payload: client
});

export const getClientDetailById = (userId, organization = null) => (dispatch) => {
    const orgId = organization || localStorage.getItem("selectedOrgId")
    const url = `/users/${userId}?organization=${orgId}`
    const idToken = localStorage.getItem("idToken")
    // console.log(" Fetching client details for userId:",userId,".....");
    dispatch({ type: GET_CLIENT_DETAIL_BY_ID });
    axiosClient.get(url, {
       headers: {
          'Authorization': `Bearer ${idToken}`
       }
    }) 
    .then((response) => {
        // console.log("%c Fetched client details for userId:"+userId,"color:green");
        dispatch({ type: GET_SUCCESS_CLIENT_DETAIL_BY_ID, payload: response.data});
    })
    .catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user details by userId","color:red", errorMsg);
        dispatch({ type: HIDE_LOADING_INDICATOR_CLIENT_LIST });
    })
 }

export const sendMessageToClient = (data) => ({
    type: SEND_MESSAGE_TO_CLIENT,
    payload: data
});

export const handleShowClientDashboard = (show, userId) => (dispatch) => {
    dispatch({ type: HANDLE_SHOW_CLIENT_DASHBOARD, payload: { userId, show } });
}

/**
 * Redux Action To Update Client Search
 */
export const updateClientsSearch = (value) => ({
    type: UPDATE_CLIENTS_SEARCH,
    payload: value
});

/**
 * export const to search Clients
 */
export const onSearchClients = (value) => ({
    type: SEARCH_CLIENTS,
    payload: value
});

/**
 * Redux Action To Update Client Search
 */
export const showClientListLoading = () => ({
    type: SHOW_LOADING_INDICATOR_CLIENT_LIST,
});

/**
 * export const to search Clients
 */
export const hideClientListLoading = () => ({
    type: HIDE_LOADING_INDICATOR_CLIENT_LIST,
});

/**
 * Get Client notification status
 */
 export const getClientMuteNotification = (adminUserId, clientUserId) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    const url = `/users/${adminUserId}/notifications/users/${clientUserId}`
    const idToken = localStorage.getItem("idToken")
    dispatch({ type: GET_CLIENT_MUTE_NOTIFICATION });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.status === 200){
            dispatch({ type: GET_CLIENT_MUTE_NOTIFICATION_SUCCESS, payload: response.data });
        } else {
            dispatch({ type: HIDE_LOADING_INDICATOR_CLIENT_LIST });
            NotificationManager.error(response.data)
        }
    }).catch((error) => {
        console.log("error occured ", error);
        dispatch({ type: HIDE_LOADING_INDICATOR_CLIENT_LIST });
     })
}

/**
 * Post Client notification status
 */
 export const postClientMuteNotification = (adminUserId, clientUserId, enabled) => (dispatch) => {
    const orgId = localStorage.getItem("selectedOrgId")
    let url = `/users/${adminUserId}/notifications/users/${clientUserId}`
    let data = {enabled}
    const idToken = localStorage.getItem("idToken")
    dispatch({ type: MUTE_CLIENT_NOTIFICATIONS });
    axiosClient.post(url, data, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.status === 200){
            NotificationManager.success(response.data.message);
            dispatch({ type: MUTE_CLIENT_NOTIFICATIONS_SUCCESS, payload: response.data });
            dispatch(getUserById(adminUserId, idToken, false, false))
        } else {
            dispatch({ type: MUTE_CLIENT_NOTIFICATIONS_FAILED });
            NotificationManager.error(response.data)
        }
    }).catch((error) => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while saving user notifications","color:red", errorMsg);
        NotificationManager.error(errorMsg);
        dispatch({ type: MUTE_CLIENT_NOTIFICATIONS_FAILED });
    })
}

export const clientNetworkActivity = (uid) => (dispatch) => {
    let idToken = localStorage.getItem('idToken');
    dispatch({type:GET_CLIENT_NETWORK_ACTIVITY})
    let url =`/users/${uid}/network-activity` //users/{userId}/network-activity
    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        // console.log("Fetched client network activities : ", response.data);
        const networkActivities = response.data && response.data.length ? response.data[0] : {};
        dispatch({type:GET_CLIENT_NETWORK_ACTIVITY_SUCCESS, payload: networkActivities})
    }).catch((error) => {
        // error handling
        console.log("error occured while fetching client network activities : ", error);
        const errorMsg = error.response ? error.response.data.message : error.message;
        NotificationManager.error(errorMsg, "Failed to get client network activities");
        dispatch({ type: GET_CLIENT_NETWORK_ACTIVITY_FAILED}); 
    })
}

export const clearClientNetworkActivities = () => (dispatch) => {
    dispatch({type: CLEAR_CLIENT_NETWORK_ACTIVITIES_DATA});
}

export const fetchUserOrganizations = (user) => (dispatch) => {
    const userId = localStorage.getItem("auth_uid")
    const url = `/users/${userId}/organizations`
    const idToken = localStorage.getItem("idToken")
    // console.log(" Fetching organizations for userId:",userId,".....");
    dispatch({ type: FETCH_USER_ORGANIZATIONS });
    // dispatch({ type: FETCH_USER_ORGANIZATIONS_SUCCESS, payload: [{name: "Test", id: "Test"}, {name: "Grapes", id: "SbqnT7qt9kXY4robcR7u"}]});
    axiosClient.get(url, {
       headers: {
          'Authorization': `Bearer ${idToken}`
       }
    }) 
    .then((response) => {
        // console.log("%c Fetched organizations for userId:"+userId,"color:green");
        dispatch({ type: FETCH_USER_ORGANIZATIONS_SUCCESS, payload: response.data});
        const orgDetails = response.data && response.data.length > 0 ? response.data : null
        let orgIds = []
        let enableWorkflowsStatus = false;
        if (orgDetails) {
            for(const org of orgDetails) {
                orgIds.push(org.id);
                if(org.enableWorkflows) {
                    enableWorkflowsStatus = true;
                }
            }
        }
        if (isMobile()) {
            dispatch({ type: SAVE_WORKFLOW_ENABLE_STATUS, payload: false});
        } else {
            dispatch({ type: SAVE_WORKFLOW_ENABLE_STATUS, payload: enableWorkflowsStatus});
        }
        localStorage.setItem("orgIds", orgIds);
        if(user && user.role === 'Parent') {
            //dispatch(clearUserOrgFilter({panel:'client'}));
        }
        
        // dispatch(getActivitiesList(userId, ''))
    })
    .catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching organizations by userId","color:red", errorMsg);
        dispatch({ type: HIDE_LOADING_INDICATOR_CLIENT_LIST });
        if(user && user.role === 'Parent') {
            //dispatch(clearUserOrgFilter({panel:'client'}));
        }
    })
}

export const applyUserOrgFilter = (orgdata) => (dispatch) => {
    const {panel, orgIds, groups, selectedGroup} = orgdata
    switch(panel) {
        case "client":
            console.log("%c[Client Panel]%c Organization filter applied","color:black","color:green");
            dispatch({type: APPLY_ORGANIZATIONS_FILTER_AT_CLIENT, payload: orgIds})
            if(groups) {
                dispatch({type: GET_SELECTED_GROUP, payload: groups});
            }
            dispatch(getAllGroupMembers(groups, orgIds))
            break;
        case "activities":
            console.log("%c[Activities Panel]%c  Organization filter applied","color:black","color:green");
            dispatch({type: APPLY_ORGANIZATIONS_FILTER_AT_ACTIVITIES, payload: orgIds})
            break;
        case "courses":
            console.log("%c[Course Panel]%c Organization filter applied","color:black","color:green");
            dispatch({type: APPLY_ORGANIZATIONS_FILTER_AT_COURSES, payload: orgIds})
            dispatch(getMyCourses(orgIds))
            break;
        case "resources":
            console.log("%c[Resources Panel]%c Organization filter applied","color:black","color:green");
            dispatch({type: APPLY_ORGANIZATIONS_FILTER_AT_RESOURCES, payload: orgIds})
            break;
        case "workflows":
            console.log("%c[Workflows Panel]%c Organization filter applied","color:black","color:green");
            dispatch({type: APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW, payload: orgIds})
            dispatch(getWorkflowList(orgIds))
            break;
        case "workflow_summary_report":
            console.log("%c[Workflows Summary report Panel]%c Organization filter applied","color:black","color:green");
            dispatch({type: APPLY_ORGANIZATIONS_FILTER_ON_WORKFLOW_SUMMARY, payload: orgIds})
            break;
        default: 
            console.log("%c[Client Panel]%c Organization filter applied","color:black","color:green");
            dispatch({type: APPLY_ORGANIZATIONS_FILTER_AT_CLIENT, payload: orgIds})
            break;
    }
    
}
    
export const clearUserOrgFilter = (orgdata) => (dispatch) => {
    const {panel} = orgdata
    const userId = localStorage.getItem("uid")
    const orgIds = (localStorage.getItem("orgIds") && localStorage.getItem("orgIds").split(","))
    switch(panel) {
        case "client":
            console.log("%c[Client Panel]%c Organization filter cleared","color:black","color:green");
            dispatch({type: CLEAR_ORGANIZATIONS_FILTER_FROM_CLIENT})
            dispatch({type: GET_SELECTED_GROUP, payload: []});
            dispatch(getAllGroupMembers())
            break;
        case "activities":
            console.log("%c[Activities Panel]%c  Organization filter cleared","color:black","color:green");
            dispatch({type: CLEAR_ORGANIZATIONS_FILTER_FROM_ACTIVITIES})
            // dispatch(getActivityGroupsList(userId, orgIds))
            break;
        case "courses":
            console.log("%c[Course Panel]%c Organization filter cleared","color:black","color:green");
            dispatch({type: CLEAR_ORGANIZATIONS_FILTER_FROM_COURSES})
            dispatch(getMyCourses())
            break;
        case "resources":
            console.log("%c[Resources Panel]%c Organization filter cleared","color:black","color:green");
            dispatch({type: CLEAR_ORGANIZATIONS_FILTER_FROM_RESOURCES})
            break;
        case "workflows":
            console.log("%c[Workflows Panel]%c Organization filter cleared","color:black","color:green");
            dispatch({type: CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW})
            dispatch(getWorkflowList())
        case "workflow_summary_report":
            console.log("%c[Workflows Summary Report Panel]%c Organization filter cleared","color:black","color:green");
            dispatch({type: CLEAR_ORGANIZATIONS_FILTER_FROM_WORKFLOW_SUMMARY})
            break;
        default: 
            console.log("%c[Client Panel]%c Organization filter cleared","color:black","color:green");
            dispatch({type: CLEAR_ORGANIZATIONS_FILTER_FROM_CLIENT})
            break;
    }
}