import React, { Component } from 'react';
// redux method to subscribe class
import { connect } from 'react-redux';
import ActivityForm from '../../ActivityForm';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import SherpaLogo from '../../../assets/img/life-sherpa-logo-retina.png';


// Actions
import {
  clearPokeFormData,
  getPokeForm
} from '../../../actions'
import { openURL } from '../../../helpers/helpers'

class FormPage extends Component {
  state = {
    showForm: false,
    evaluationData: null,
  }

  componentDidMount() {
    const { formId } = this.props;
    if(formId) {
      this.props.getPokeForm(formId);
    }
  }

  componentWillUnmount() {
    this.props.clearPokeFormData();
  }


  handlePlus = (obj, section, sectionObj) => {
    console.log(obj, section, sectionObj);
    let value = typeof obj.value === 'number' ? obj.value : obj.minValue || 0;
    if (value < obj.maxValue) {
      obj.value = value + 1;
    }
    this.updateEvaluationForm(obj, sectionObj);
  }

  handleMinus = (obj, section, sectionObj) => {
    console.log(obj, section, sectionObj);
    let value = typeof obj.value === 'number' ? obj.value : obj.minValue || 0;
    if (value > obj.minValue) {
      obj.value = value - 1;
    }
    this.updateEvaluationForm(obj, sectionObj);
  }

  handleThumbUp = (obj, sectionObj) => {
    obj.value = "up"
    console.log(" thumb down --> ", sectionObj)
    this.updateEvaluationForm(obj, sectionObj);
  }
  handleThumDown = (obj, sectionObj) => {
    obj.value = "down"
    console.log(" thumb down --> ", sectionObj);
    this.updateEvaluationForm(obj, sectionObj);
  }
  handleDateAndTimeSection = (obj, sectionObj) => {
    console.log(" date changed --> ", sectionObj, obj)
    this.updateEvaluationForm(obj, sectionObj);
  }

  handleImageInputSection = (obj, sectionObj) => {
    this.updateEvaluationForm(obj, sectionObj);
  }

  updateEvaluationForm = (obj, sectionObj) => {
    let { evaluationData } = this.state;
    if (!evaluationData) {
      console.log(" updating poke form data for changes ....")
      evaluationData = this.props.pokeForm
    }
    evaluationData.sections[sectionObj] = obj;
    this.setState({ evaluationData: evaluationData })
  }

  handleEvaluationForm = (e, obj, section) => {
    let evaluationSectionData = obj
    ////console.log('target value-->', e.target.value, e.target.label, e.target.id, e.target.name, section)
    if (obj.type === 'checklist' && !obj.isMultiValue) {
      evaluationSectionData.itemsList.map((lbl, key) => {
        if (e.target.id === `radio-${obj.heading}-${key}-${section}`) {
          lbl.value = e.target.checked
        } else {
          lbl.value = !e.target.checked
        }
      })

    } else if (obj.type === 'checklist') {
      evaluationSectionData.itemsList.map((lbl, key) => {
        if (e.target.id === `checkbox-${key}-${lbl.name}-${section}`) {
          lbl.value = e.target.checked
        }
      })

    } else if (obj.type === 'longText') {
      evaluationSectionData['text'] = e.target.value

    } else if (obj.type === 'text') {
      evaluationSectionData['text'] = e.target.value
    } else if (obj.type === 'spacer') {

    } else {

    }
    this.updateform(evaluationSectionData, section);
  }

  updateform = (obj, section) => {
    let { evaluationData } = this.state;
    if (!evaluationData) {
      console.log(" updating poke form data for changes ....")
      evaluationData = this.props.pokeForm
    }
    Object.keys(evaluationData.sections).sort((a, b) => evaluationData.sections[a].position - evaluationData.sections[b].position).map((evaluatioObj, key) => {
      if (section === key) {
        evaluationData.sections[evaluatioObj] = obj
      }
    })
    ////console.log('updated value-->', evaluationData)
    this.setState({ evaluationData: evaluationData })
  }

  handleSaveAndContinue = () => {
    let { evaluationData } = this.state;
    const { pokeForm } = this.props;
    const payloadData = evaluationData ? evaluationData : pokeForm;
    this.props.handleSaveAndContinue(payloadData);
  }


  handleOpenPokeFormHelpURL = (helpURL) => {
    if (helpURL == "lifesherpapp.com") {
      helpURL = "https://lifesherpapp.com"
    }
    // window.open(helpURL, '_blank');
    openURL(helpURL)
  }
  render() {
    let { evaluationData } = this.state
    let { image, name, pokeForm, disabled, executeActivity, loading, themeData, themeLogo } = this.props;
    themeLogo = localStorage.getItem("loginLogoImage") || themeLogo;
    return (
      <div>
        {loading ?
          <div className=" activity-details-height">
            <div className='text-center w-100 mt-4 d-flex align-items-center justify-content-center'>
              <div>
                Fetching Form...
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-center'>
              <div className="mx-auto container text-center mt-4 activity-preview-theme-logo">
                <img className="auto-resize-img" style={{ maxWidth: "100%", maxHeight: 120 }} height="auto" width="auto" src={themeLogo || SherpaLogo} />
                <div className="d-flex align-items-center justify-content-center lifesherpa-poweredby-logo">
                  {themeData && themeData.loginLifeSherpaImage &&
                    <img src={themeData.loginLifeSherpaImage} alt="session-logo" className="" width="auto" height="30" />
                  }
                </div>
              </div>
            </div>
            <LifeSherpaLoading loading={loading} />
          </div>
          : pokeForm ?
            <ActivityForm
              image={image}
              name={name}
              disabled={disabled}
              pokeForm={pokeForm}
              handleSaveAndContinue={this.handleSaveAndContinue}
              handleEvaluationForm={this.handleEvaluationForm}
              handleMinus={this.handleMinus}
              handlePlus={this.handlePlus}
              handleThumbUp={this.handleThumbUp}
              handleThumDown={this.handleThumDown}
              evaluationData={evaluationData}
              executeActivity={executeActivity}
              handleOpenPokeFormHelpURL={this.handleOpenPokeFormHelpURL}
              handleDateAndTimeSection={this.handleDateAndTimeSection}
              handleImageInputSection={this.handleImageInputSection}
            />
            :
            <div className=" activity-details-height">
              <div className='text-center w-100 mt-4 d-flex align-items-center justify-content-center'>
                <div>
                  Fetching Form...
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <div className="mx-auto container text-center mt-4 activity-preview-theme-logo">
                  <img className="auto-resize-img" style={{ maxWidth: "100%", maxHeight: 120 }} height="auto" width="auto" src={themeLogo || SherpaLogo} />
                  <div className="d-flex align-items-center justify-content-center lifesherpa-poweredby-logo">
                    {themeData && themeData.loginLifeSherpaImage &&
                      <img src={themeData.loginLifeSherpaImage} alt="session-logo" className="" width="auto" height="30" />
                    }
                  </div>
                </div>
              </div>
            </div>
        }

      </div>
    );
  }
}

const mapStateToProps = ({ PokeForm, authUser, settings }) => {
  const { popupThemeClass, themeLogo } = settings;
  const { pokeForm, isPokeForGroup, loading } = PokeForm
  const { themeData } = authUser;

  return { pokeForm, popupThemeClass, loading, themeData, themeLogo };
}

export default connect(mapStateToProps, {
  getPokeForm,
  clearPokeFormData
})(FormPage);
