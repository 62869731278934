import React, { Component } from "react";
import Idled from "react-idled";
import { connect } from 'react-redux';
import {setInActivityTimerValue, handelUnActiveAlertPopup, setInActivityLogout, setInActivityTimerUpdate, clearInActiviyIdleData, storeInActiveSetIntervalData } from '../../actions'
import TimerPopup from "./components/TimerPopup";
import { withAuth0 } from "@auth0/auth0-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Label } from 'reactstrap';
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from '@mui/styles';
import DialogTitle from "@mui/material/DialogTitle";
import withRouter from "../../router";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#008C95',
    },
  }))(LinearProgress);

class UnActiveScreenTimer extends Component {
    state = {
        show:true,
    }

    handleChange = ({ idle }) => {
       const {inactivityWarningPeriod, setIntervalData , showUnActiveAlert} = this.props;
        if(idle && !showUnActiveAlert){
            clearInterval(setIntervalData);
            this.props.handelUnActiveAlertPopup(true);
            this.props.setInActivityTimerUpdate(inactivityWarningPeriod)
            let timerInterval = setInterval(this.handelTimer, 1000);
            this.props.storeInActiveSetIntervalData(timerInterval)
        }
    };

    componentDidMount() {
        const {setIntervalData} = this.props;
        clearInterval(setIntervalData);
        this.props.setInActivityTimerValue();
    }

    componentWillUnmount() {
        const { setIntervalData} = this.props;
        clearInterval(setIntervalData);
    }
    handelContinue = () => {
        const { setIntervalData} = this.props;
        clearInterval(setIntervalData);
        this.props.setInActivityTimerUpdate(null);
        this.props.handelUnActiveAlertPopup(false);
    }
    handleLogout = () => {
        const { setIntervalData} = this.props;
        clearInterval(setIntervalData);
        const {logout} = this.props.auth0;
        this.props.clearInActiviyIdleData();
        this.props.navigate('/logout')
    }
    handelTimer = () => {
        const { timer,setIntervalData} = this.props;
        console.log("timer ---> ", timer);
        if (timer === 0) {
            clearInterval(setIntervalData);
            this.handleLogout()
        }
        else {
            this.props.setInActivityTimerUpdate(timer - 1)
        }
    }


    render() {
        const { timer , inAvtivityValue , showUnActiveAlert, factor, popupThemeClass} = this.props;
        window["auth0"] = this.props.auth0;
        return (
            <React.Fragment>
                {inAvtivityValue && 
                    <Idled
                        onChange={this.handleChange}
                        timeout={inAvtivityValue || 500000 }
                        render={({ idle }) => <></>}
                    /> 
                }
                {showUnActiveAlert &&          
                  <Dialog
                        fullScreen={false}
                        open={showUnActiveAlert}
                        // disableBackdropClick={true}
                        fullWidth={true}
                        maxWidth="sm"
                        className={popupThemeClass}
                        aria-labelledby="responsive-dialog-title"
                    >
                     <DialogTitle>
					    <div className="row px-3 py-3 border-bottom bg-lifesherpa">
							<h2 className="text-white pl-2  my-auto">Session Logout Warning</h2>
						</div>
					</DialogTitle>
                        <DialogContent className="p-4">
                            <div className="fs-14 mb-2 fw-semi-bold">You are about to be automatically logged-out in {timer} second(s) due to inactivity. Click Continue to stay in your session.</div>
                            <BorderLinearProgress variant="determinate" value={timer * factor} />
                        </DialogContent>
                        <DialogActions className="p-4">
                            <div className="d-flex">
                                <div className="d-flex w-50">
                                </div>
                                <div className="d-flex w-50 justify-content-end">
                                    <button onClick={() => this.handelContinue()} className="mr-3 btn btn-continue-idle ">
                                        Continue
                                    </button>
                                    <button onClick={() => this.handleLogout()} className="btn  btn-danger" >
                                        Logout
                                    </button>

                                </div>
                            </div>
                        </DialogActions>
                    </Dialog> }
            </React.Fragment>
        )
    }
}
const mapStateToProps = ({ screenTimer, settings }) => {
    const { popupThemeClass } = settings; 
    const { timer, showUnActiveAlert, factor, inactivityWarningPeriod, inAvtivityValue, setIntervalData } = screenTimer;
    return {timer, showUnActiveAlert, factor, inactivityWarningPeriod, inAvtivityValue, setIntervalData , popupThemeClass}
}
export default withRouter(withAuth0(connect(mapStateToProps, {
    setInActivityLogout, 
    setInActivityTimerUpdate, 
    handelUnActiveAlertPopup,
    setInActivityTimerValue,
    clearInActiviyIdleData,
    storeInActiveSetIntervalData
})(UnActiveScreenTimer)))
