import React, { Component } from 'react';

//Redux subscriber
import { connect } from 'react-redux';
//WithRouter
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
//reacstrap components
import { FormGroup, Input, Label } from 'reactstrap';
//SCSS file
//Material UI Components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
//Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
//Actions
 import {setActivityType} from '../../actions';


class ActivityTimerUpdate extends Component {
	state = {
		hour: 0,
		min: 0,
		sec: 0,
	}

	componentDidMount() {
        let { details, duration } = this.props;
        // if(details.maxStepTimeout) {
        //     var timeleft = details.maxStepTimeout * 1000;
        //     let seconds = ("0" + (Math.floor(timeleft / 1000) % 60)).slice(-2);
        //     let minutes = ("0" + (Math.floor(timeleft / 60000) % 60)).slice(-2);
        //     let hours = ("0" + Math.floor(timeleft / 3600000)).slice(-2)
        //     this.setState({hour: Number(hours), min: Number(minutes), sec: Number(seconds) })
        // }
		if(duration) {
			const dateArray = duration.split(":");
			let hr = dateArray[0];
            let min = dateArray[1];
            let sec = dateArray[2];
            if(sec || min) {
                // if(min) {
                //     sec = min;
                //     min = hr;
                //     hr = 0
                // } else {
                //    sec = hr;
				//    min = 0
                //    hr = 0
                // }
				this.setState({hour: Number(hr), min: Number(min), sec: Number(sec)})
            } 
		}
        // console.log("maxStepTimeout ----> ", details.maxStepTimeout);
	}

	updateTimer = () => {
		let { hour, min, sec } = this.state
		// let totalhrs = hour ? hour * 3600 : 0;
		// let totalmin = min ? min * 60 : 0;
		// let total = totalhrs + totalmin + sec;
        let totalhrs = hour < 10 ?  "0"+hour : hour;
        let totalmin = min < 10 ?  "0"+min : min;
        let totalsec = sec < 10 ?  "0"+sec : sec;
		this.props.handleUpdateTimer(totalhrs, totalmin, totalsec)
	}


	handleHour = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 25) {
			//console.log("handleHour",i)
			this.setState({ hour: +e.currentTarget.value })
		}
	}

	handleMin = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 60) {
			//console.log("handleHour",i)
			this.setState({ min: +e.currentTarget.value })
		}
	}

	handleSec = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 60) {
			//console.log("handleHour",i)
			this.setState({ sec: +e.currentTarget.value })
		}
	}

	render() {
		let { showTimer, details, step } = this.props;
		return (
			<div>
					<Dialog
						fullScreen={false}
						open={showTimer}
						onClose={() => this.props.handleCloseTimerUpdate()}
						fullWidth={true}
						maxWidth="xl"
						aria-labelledby="responsive-dialog-title"
                        className={this.props.popupThemeClass}
						PaperProps={
							{ style: { maxWidth: "500px", borderRadius: 8, margin: 'auto' } }
						}
					>
						<div>
							<div className="row bg-lifesherpa p-3">
								<div className="col">
									<h2 className="text-white">{ step && step.title ? `[${step.position}] ${step.title}` : details.title || ""}</h2>
									<h4 className="text-white mb-0 pb-0">Adjust Timer</h4>
								</div>
								<IconButton className="ml-auto my-auto text-white" onClick={() => this.props.handleCloseTimerUpdate()}><CloseIcon /></IconButton>
							</div>
							<div className="row p-3 mt-3">
								<FormGroup className="col text-center">
									<Label>HH</Label>
									<Input className="msg-inpu text-center" value={this.state.hour} /*pattern="^[0-9]*$"*/ min={0} max={25} type='number' placeholder='00' name='timer' onChange={(e) => this.handleHour(e)} />
								</FormGroup>
								<h1 className="mx-1 my-auto">:</h1>
								<FormGroup className="col text-center">
									<Label>MM</Label>
									<Input className="msg-inpu text-center" value={this.state.min} min={0} max={60} type='number' placeholder='00' name='timer' onChange={(e) => this.handleMin(e)} />
								</FormGroup>
								<h1 className="mx-1 my-auto">:</h1>
								<FormGroup className="col text-center">
									<Label>SS</Label>
									<Input className="msg-inpu text-center" value={this.state.sec} min={0} max={60} type='number' placeholder='00' name='timer' onChange={(e) => this.handleSec(e)} />
								</FormGroup>
							</div>
							<div className="d-flex mx-4 align-items-center justify-content-between mt-4 update-time-buttons">
								<Button color="primary" variant="outlined" className="  mb-3 mr-2 form-cancel-button " onClick={() => this.props.handleCloseTimerUpdate()}>Cancel</Button>
								<Button color="primary" variant="contained" className="  mb-3 ml-2 primary-button" onClick={() => this.updateTimer()}>Save</Button>
							</div>
						</div>
					</Dialog>
				
			</div>);
	}
}
const mapStateToProps = ({ settings }) => {
	const { popupThemeClass } = settings; 
	return {popupThemeClass };
};

export default withRouter(connect(mapStateToProps, {	setActivityType})(ActivityTimerUpdate));
