import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
import {  getVideoToken, getSelectedVideoChat} from "Actions"
import { connect } from 'react-redux';
import queryString from 'query-string';
import VideoChat from './VideoChat';
import LoadingVideoUI from "./LoadingVideoUI";

class LifesherpaVideoCall extends Component {
    state = { 
        windowWidth:window.innerWidth,
        groupId: null,
        roomLeft: false,
        abortController: new AbortController() // Initialize AbortController here
    }

    componentDidMount() {
        // window.addEventListener("resize", this.updateDimensions);
        this.handleVidoeCallContainer();
        // Clean up the event listener
        document.addEventListener('nativeBackClick', this.handleBackButton);
    }

    handleVidoeCallContainer = () => {
		const params = this.getParams();
		console.log( " params -->", params);
		if(params && params.room) {
			let openRoom = {name:params.room};
			this.props.getVideoToken(this.state.abortController);
			this.props.getSelectedVideoChat(openRoom);
            if(params.groupId) {
                this.setState({groupId:params.groupId})
            }
		} 
	}

    componentWillUnmount() {
        // Abort the request if it's in progress when the component unmounts
        this.state.abortController.abort();

        // Clean up the event listener
        document.removeEventListener('nativeBackClick', this.handleBackButton);
    }

    handleBackButton = () => {
        // Abort the Axios request on back button press
        this.state.abortController.abort();
    }


    handleMessageForRoom = (roomLeft) => {
        this.setState({roomLeft: roomLeft})
    }

    updateDimensions = () => {
		this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
	}

    getParams = () => {
		let params = null
        if (this.props.location && this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } if (window.location && window.location.search) {
            params = queryString.parse(window.location.search)
        }
        return params
	}

    render() { 
		const {  loading, selectedVideoChat, videoToken, videoTokenLoading } = this.props;
        let {groupId, roomLeft, windowWidth} = this.state;
        return (
            <div className="vidio-commuinication-panel">
            {!videoTokenLoading && videoToken && selectedVideoChat ?
                    <VideoChat windowWidth={windowWidth} groupId={groupId} handleMessageForRoom={this.handleMessageForRoom}/>
                :<LoadingVideoUI text={roomLeft ? "Disconnecting from video room..." : "Connecting to video room ..."}/>
            }	
            </div> 
        );
    }
}

const mapStateToProps = ({Contacts,authUser, chatAppReducer}) => {
	const {userRole}=authUser
	const {videoToken, loading, videoTokenLoading, selectedVideoChat}=Contacts
	const { selectedChatUsers, selectedUser } = chatAppReducer;
	return {videoToken,userRole, loading, selectedUser, videoTokenLoading, selectedVideoChat};
};

export default withRouter(connect(mapStateToProps, {getVideoToken, getSelectedVideoChat})(LifesherpaVideoCall));
