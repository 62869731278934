//import { IconButton } from '@mui/material/';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActivityPreviewItem from "./ActivityPreviewItem"
import CloseIcon from '@mui/icons-material/Close';
import { clearPokeFormData, clearActivityDataOnClose, pauseAcivityForConfigurator, EndActivity, StartStep, selectActivityId, EndStep, SkipStep, cancelActivityForConfigurator, getPokeFormFromConfigurator, setPokeFormForGroup, savePokrFormForClients, postEvaluationForm, handleShowPhotoCamera, removeAPIError, setFormStatusData, updateStatus } from '../../../actions'
import Moment from 'moment'
import SweetAlert from 'react-bootstrap-sweetalert'
import IconButton from '@mui/material/IconButton';
import SherpaLogo from '../../../assets/img/life-sherpa-logo-retina.png'
import StartActivityListViewDetails from "./StartActivityListViewDetails";
import ActivityForm from '../../ActivityForm';
import Slide from '@mui/material/Slide';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import SetCurrentStatusForm from "../../AppMyActivity/components/SetCurrentStatusForm";
import PreviewLinksIframe from "./PreviewLinksIframe";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import {validURL, openURL} from '../../../helpers/helpers'
import SherpaCamera from "../../SherpaCamera"

class Preview extends Component {
  state = {
    stepNo: 0,
    closeAlert: false,
    stepslist: [],
    stepsId: [],
    activity: {},
    open: false,
    showForm: false,
    evaluationData: null,
    stepsNotesData: null,
    labelValue: 0,
    timerTime: null,
    timerStart: null,
    timeOutAlert: false,
    showRewards: false,
    nextStep: false,
    countDownInterval: 0,
    countDownStart: false,
    showEvent: false,
    eventDate: null,
    addEvent: false,
    title: '',
    note: '',
    nextdisable: false,
    openedItem: [],
    selectedThumb: "up",
    openCourseAlert: false,
    showSelfEvaluationform: true,
    stepsDisabled: [],
    activeStep: -1,
    checkList: {},
    StatusForm: true,
    statusList: [],
    statusData: null,
    openedStep:-1,
    linkUrl:"",
    showPictureCamera:false
  }

  componentDidMount() {
    let index = this.props.startActivity && this.props.startActivity.achievement ? this.props.activityTimer.findIndex((time) => time.routineId === this.props.startActivity.achievement.routineId) : -1
    if (index > -1) {
      this.setState({
        timerOn: true,
        timerTime: this.state.timerTime,
        timerStart: Date.now() - this.state.timerTime - +this.props.activityTimer[index].timer
      });
    } else {
      this.setState({
        timerOn: true,
        timerTime: this.state.timerTime,
        timerStart: Date.now() - this.state.timerTime
      });
    }
    this.intervalID = setInterval(
      () => this.setState({
        timerTime: Date.now() - this.state.timerStart
      }),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      sec: this.state.sec + 1
    });
  }
  handlecloseAlert = () => {
    let { stepslist, stepsCompletionList, activityMessage} = this.props;
    if (this.props.executeActivity) {
      if(stepsCompletionList.length == stepslist.length && activityMessage == "Achievement ended successfully") {
        this.closeWithClearAllData()
        if(this.props.executeActivity) {
           window.location.href = "/success";
         }
      } else {
        this.setState({ closeAlert: true })
      } 
    } else {
      this.handleCancel()
    }
  }
  updateCheckList = (checkName) => {
    let s = { ...this.state };
    s.checkList[checkName] = true;
    this.setState(s);
  }
  setActiveStep = (stepNo) => {
    this.setState({ ...this.state, activeStep: stepNo })
  }
  setStepDisabled = (stepNo) => {
    let s = { ...this.state };
    let index = s.stepsDisabled.findIndex(li => li == stepNo);
    if (index == -1) {
      s.stepsDisabled.push(stepNo);
      this.setState(s);
    }
  }
  setStepEnabled = (stepNo) => {
    let s = { ...this.state };
    let index = s.stepsDisabled.findIndex(li => li == stepNo);
    if (index >= 0) {
      s.stepsDisabled.splice(index, 1);
      this.setState(s);
    }
  }

  setOpenSteps = (p) => {
    let {stepslist} = this.props;
    let lstate = { ...this.state };
    if (p == lstate.openedStep) {
      lstate.openedStep=-1;
    }
    else {
      lstate.openedStep=p;
    }
    if(stepslist) {
      let step =  stepslist.find(step => step.position == p);
     // console.log("step ---> ", step);
      if(step && step.countDownInterval) {
        lstate.countDownInterval = step.countDownInterval;
      }
    }
    this.setState(lstate);
   

  }
  handleClose = () => {
    const {executeActivity} = this.props
    let achievementId = this.props.startActivity.achievementId
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let routineId = this.props.startActivity.achievement && this.props.startActivity.achievement && this.props.startActivity.achievement.routineId ? this.props.startActivity.achievement.routineId : ''
    let uid = localStorage.getItem('puid');
    if (this.props.startActivity && this.props.startActivity.achievement && this.props.startActivity.achievement.routine.isTimerEnabled)  {
      this.props.pauseAcivityForConfigurator(achievementId, date, uid, routineId, this.state.timerTime, executeActivity)
    } else {
      this.props.pauseAcivityForConfigurator(achievementId, date, uid, '', null, executeActivity)
    }
    //  this.props.handleClose()
    this.setState({ closeAlert: false })
    this.closeWithClearAllData();
  }

  handleCancel = () => {
    const {executeActivity} = this.props
    let id = this.props.startActivity ? this.props.startActivity.achievementId : ''
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let routineId = this.props.startActivity && this.props.startActivity.achievement && this.props.startActivity.achievement.routineId ? this.props.startActivity.achievement.routineId : ''
    let uid = localStorage.getItem('puid')
    if (this.props.startActivity.achievement.routine && this.props.startActivity.achievement.routine.isTimerEnabled) {
      this.props.cancelActivityForConfigurator(id, date, uid, routineId, this.state.timerTime, executeActivity)
    } else {
      this.props.cancelActivityForConfigurator(id, date, uid, null, null, executeActivity)
    }
    this.setState({ closeAlert: false, timeOutAlert: false })
    this.closeWithClearAllData();
  }
  handleEndActivity = () => {
    let achievementId = this.props.startActivity.achievementId ? this.props.startActivity.achievementId : ""
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let routineId = this.props.startActivity && this.props.startActivity.achievement && this.props.startActivity.achievement.routineId ? this.props.startActivity.achievement.routineId : ''
    let uid = localStorage.getItem('puid')
    const orgId = localStorage.getItem("porgId")
    if (this.props.startActivity && this.props.startActivity.achievement && this.props.startActivity.achievement.routine.isTimerEnabled) this.props.EndActivity(achievementId, date, uid, routineId, this.state.timerTime, orgId)
    else this.props.EndActivity(achievementId, date, uid, '', null, orgId)
    // this.props.handleClose()
    this.closeWithClearAllData();

  }
  handleCompletionActivity = () => {
    const {activity} = this.props
    if (activity && activity.routineCompletionRewardUrl && validURL(activity.routineCompletionRewardUrl)) {
      this.openLinkInIframe(activity.routineCompletionRewardUrl)
    } else {
      this.closeWithClearAllData();
      window.location.href = "/success";
    }
  }
  closeWithClearAllData = () => {
    this.props.selectActivityId(null);
    this.props.selectActivityId(null);
    this.props.clearPokeFormData()
    this.props.clearActivityDataOnClose()
    localStorage.removeItem('idToken')
    localStorage.removeItem('puid')
    localStorage.removeItem("porgId")
  }
  handleRewards = () => {
    let achievementId = this.props.startActivity.achievementId
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let uid = localStorage.getItem('puid')
    const orgId = localStorage.getItem("porgId")
    this.props.EndActivity(achievementId, date, uid, null, null, orgId)
    this.props.selectActivityId(null)
    this.props.handleRewards()
  }
  handleListDoneStep = (stepId, stepNo, stepResponseData) => {
    const {stepslist, executeActivity, stepsCompletionList} = this.props;
    const currentStep = stepslist ?  stepslist.find(step => step.stepId == stepId) : {};
    let currentCompletedStep  = stepsCompletionList.length > 0 && stepsCompletionList.find(ele => ele.step.id == stepId) ? stepsCompletionList.find(ele => ele.step.id == stepId) : {};
    console.log(" currentStep --> ", currentStep);
    const takePictureMessage = currentStep && currentStep.takePictureMessage ? currentStep.takePictureMessage : "";
    if(currentStep && currentStep.isStartButtonEnabled && currentCompletedStep.status != "Started") {
      this.handleStarStep(stepId, stepNo);
    } else {
        if(executeActivity && currentStep.shouldTakePicture) {
            if(stepResponseData && stepResponseData.photoUrl) {
              this.handleEndStep(stepId, stepNo,  stepResponseData);
            } else {
              this.setState({ showPictureCamera: true, stepId: stepId, stepResponseData: stepResponseData, takePictureMessage: takePictureMessage});
            }
        } else {
          this.handleEndStep(stepId, stepNo, stepResponseData);
        }
    }
  }

  handleNextStep = (stepId, stepNo, stepResponseData) => {  
    const {stepslist, executeActivity, stepsCompletionList} = this.props;
    const currentStep = stepslist ?  stepslist.find(step => step.stepId == stepId) : {};
    let currentCompletedStep  = stepsCompletionList.length > 0 && stepsCompletionList.find(ele => ele.step.id == stepId) ? stepsCompletionList.find(ele => ele.step.id == stepId) : {};
    console.log(" currentStep --> ", currentStep);
    const takePictureMessage = currentStep && currentStep.takePictureMessage ? currentStep.takePictureMessage : "";
    if(currentStep && currentStep.isStartButtonEnabled && currentCompletedStep.status != "Started") {
      this.handleStarStep(stepId, stepNo);
    } else {
        if(executeActivity && currentStep.shouldTakePicture) {
            if(stepResponseData && stepResponseData.photoUrl) {
              this.handleEndStep(stepId, stepNo,  stepResponseData);
            } else {
              this.setState({ showPictureCamera: true, stepId: stepId, stepResponseData: stepResponseData, takePictureMessage: takePictureMessage});
            }
        } else {
          this.handleEndStep(stepId, stepNo, stepResponseData);
        }
    }
  }
  handleEndStep = (stepId, stepNo, stepResponseData) => {
    const {startActivity, selfEvaluationFormId,  stepslist, stepsCompletionList, viewType, shouldTakePicture, executeActivity, takePictureMessage} = this.props;
    let id = startActivity.achievementId;
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let uid = localStorage.getItem('puid');
    const orgId = localStorage.getItem("porgId")
    if(viewType === "List") {
      stepNo = this.state.stepNo;
    }
    const stepsCompletedList = stepsCompletionList.length > 0 ? stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped" ) : [];
    const stepsCompleted = stepsCompletedList.length + 1;
    console.log(" stepsCompletionList --> ", stepsCompletionList);
    let endActivity = false;
    if(stepslist.length == stepsCompleted) {
      if(shouldTakePicture && executeActivity) {
        this.setState({ showPictureCamera: true, stepFormId: null , stepId: null, takePictureMessage: takePictureMessage});
      } else if(selfEvaluationFormId) {
        console.log(" Opening Activity poke form...");
        this.props.getPokeFormFromConfigurator(selfEvaluationFormId)
        this.setState({ countDownInterval: 0, showForm: true, stepFormId: null , stepId: null})
      } else {
        endActivity = true;
      }
    } 
    if(stepResponseData && stepResponseData.photoUrl && stepResponseData.photoUrl === "Camera Not Supported") {
      delete stepResponseData.photoUrl;
    }
    this.props.EndStep(id, date, stepId, uid, stepResponseData, endActivity, orgId);
    this.setState({ countDownInterval: 0, countDownStart: false, stepNo: stepNo + 1, responseSubmitted: false, stepResponseData: null})
  }
  handleStarStep = (stepId) => {
    const {startActivity} = this.props;
    let id = startActivity.achievementId;
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let uid = localStorage.getItem('puid');
    const orgId = localStorage.getItem("porgId");
    this.props.StartStep(id, date, stepId, uid, orgId);
  }
  handleEndAchievement = (evaluationFormData, photoUrl) => {
    const {startActivity, achievement} = this.props;
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    //let routineId = achievement && achievement.routineId ? achievement.routineId : '';
    let uid = localStorage.getItem('puid')
    const orgId = localStorage.getItem("porgId")
    let id = startActivity.achievementId;
    let endActivityResponse = {};
    if(evaluationFormData) {
      endActivityResponse.formResponse = evaluationFormData;
    }
    if(photoUrl && photoUrl !== "Camera Not Supported") {
      endActivityResponse.confirmationPictureUrl = photoUrl;
    }
    if (achievement && achievement.routine.isTimerEnabled) {
      this.props.EndActivity(id, date, uid, endActivityResponse, this.state.timerTime, orgId)
    } else {
      this.props.EndActivity(id, date, uid, endActivityResponse, null, orgId)
    }
  }
  handleClosePictureCamera = (photoUrl) => {
    let {stepId, stepNo, stepResponseData} = this.state;
    const {selfEvaluationFormId, shouldTakePicture} = this.props;
    if(stepId) {
      if(stepResponseData) {
        stepResponseData.photoUrl = photoUrl;
      } else {
        stepResponseData = {photoUrl : photoUrl}
      }
      this.setState({showPictureCamera: false});
      this.handleNextStep(stepId, stepNo, stepResponseData);
    } else {
        if(selfEvaluationFormId) {
          this.props.getPokeFormFromConfigurator(selfEvaluationFormId)
          this.setState({ showPictureCamera: false, showForm: true, stepFormId: null , stepId: null, photoUrl: photoUrl, takePictureMessage:null})
        } else {
          this.handleEndAchievement(null, photoUrl);
          this.setState({showPictureCamera: false, photoUrl: photoUrl, takePictureMessage: null});
        }
    }  
  }
  handleSlipStep = (stepId, stepNo) => {
    const {stepslist, selfEvaluationFormId, stepsCompletionList, startActivity, viewType, shouldTakePicture, executeActivity, takePictureMessage} = this.props;
    let id = startActivity.achievementId;
    let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
    let uid = localStorage.getItem('puid');
    const orgId = localStorage.getItem("porgId")
    if(viewType === "List") {
      stepNo = this.state.stepNo;
    }
    const currentStep = stepslist ?  stepslist.find(step => step.stepId == stepId) : {};
    console.log(" currentStep --> ", currentStep);
    console.log(" stepsCompletionList --> ", stepsCompletionList);
    let endActivity = false;
    const stepsCompleted = stepsCompletionList.length + 1;
    if(stepslist.length == stepsCompleted) {
      if(shouldTakePicture && executeActivity) {
        this.setState({countDownInterval: 0, showPictureCamera: true, stepFormId: null , stepId: null, takePictureMessage: takePictureMessage});
      } else if(selfEvaluationFormId) {
        console.log(" Opening Activity evaluation form...");
        this.props.getPokeFormFromConfigurator(selfEvaluationFormId)
        this.setState({ showForm: true, stepFormId: null , stepId: null })
      } else {
        endActivity = true;
      }
    } 
    this.props.SkipStep(id, date, stepId, uid, endActivity, orgId);
    this.setState({ countDownInterval: 0, countDownStart: false, stepNo: stepNo + 1, responseSubmitted: false, stepResponseData: null });
  }

  handlePlus = (obj, section, sectionObj) => {
    console.log(obj, section, sectionObj);
    let value = typeof obj.value === 'number' ? obj.value : obj.minValue || 0;
    if(value < obj.maxValue) {
      obj.value = value + 1;
    }
    this.updateEvaluationForm(obj, sectionObj);
  }

  handleMinus = (obj, section, sectionObj) => {
    console.log(obj, section, sectionObj);
    let value = typeof obj.value === 'number' ? obj.value : obj.minValue || 0;
    if(value > obj.minValue) {
      obj.value = value - 1;
    }
    this.updateEvaluationForm(obj, sectionObj);
  }

  handleThumbUp = (obj, sectionObj) => {
    obj.value = "up"
    console.log(" thumb down --> ", sectionObj)
    this.updateEvaluationForm(obj, sectionObj);
  }
  handleThumDown = (obj, sectionObj) => {
    obj.value = "down"
    console.log(" thumb down --> ", sectionObj);
    this.updateEvaluationForm(obj, sectionObj);
  }
  
  handleDateAndTimeSection = (obj, sectionObj) => {
    this.updateEvaluationForm(obj, sectionObj);
  }

  handleImageInputSection = (obj, sectionObj) => {
    this.updateEvaluationForm(obj, sectionObj);
  }

  updateEvaluationForm = (obj, sectionObj) => {
    let {evaluationData} = this.state;
    if(!evaluationData) {
      console.log(" updating poke form data for changes ....")
      evaluationData = this.props.pokeForm
    }
    evaluationData.sections[sectionObj] = obj;
    this.setState({evaluationData: evaluationData})
  }

  handleEvaluationForm = (e, obj, section) => {
    let evaluationSectionData = obj
    if (obj.type === 'checklist' && !obj.isMultiValue) {
      evaluationSectionData.itemsList.map((lbl, key) => {
        if (e.target.id === `radio-${obj.heading}-${key}-${section}`) {
          lbl.value = e.target.checked
        } else {
          lbl.value = !e.target.checked
        }
      })

    } else if (obj.type === 'checklist') {
      evaluationSectionData.itemsList.map((lbl, key) => {
        if (e.target.id === `checkbox-${key}-${lbl.name}-${section}`) {
          lbl.value = e.target.checked
        }
      })

    } else if (obj.type === 'longText') {
      evaluationSectionData['text'] = e.target.value

    } else if (obj.type === 'text') {
      evaluationSectionData['text'] = e.target.value
    } else if (obj.type === 'spacer') {

    } else {

    }
    this.updateform(evaluationSectionData, section)
  }

  updateform = (obj, section) => {
    let {evaluationData} = this.state;
    if(!evaluationData) {
      console.log(" updating poke form data for changes ....")
      evaluationData = this.props.pokeForm
    }
    Object.keys(evaluationData.sections).sort((a, b) => evaluationData.sections[a].position - evaluationData.sections[b].position).map((evaluatioObj, key) => {
      if (section === key) {
        evaluationData.sections[evaluatioObj] = obj
      }
    })
    this.setState({ evaluationData })
  }

  handleSaveAndContinue = () => {
    let {evaluationData, stepId, stepNo, nextStep, stepFormId, stepResponseData, photoUrl} = this.state;
    let {pokeForm , executeActivity, stepslist, viewType, startActivity, groups} = this.props;
    let uid = localStorage.getItem('puid');
    const payloadData = evaluationData ? evaluationData : pokeForm;
    if(!stepResponseData) {
      stepResponseData = {}
    }
    const currentStep = stepslist ?  stepslist.find(step => step.stepId == stepId) : {};
    groups = this.props.selectedGroup && this.props.selectedGroup.length > 0 ? this.props.selectedGroup : groups
    if (pokeForm && pokeForm.formName === 'Poke') {
      // if (this.props.isPokeForGroup) {
      //   this.props.setPokeFormForGroup(groups, payloadData)    
      // } else {
      //   this.props.savePokrFormForClients(uid, payloadData, this.props.selectedActivitiesHistory && this.props.selectedActivitiesHistory)    
      // }
    } else if(stepFormId) {
        stepResponseData.cardFormResponse = payloadData;  
    } else {
        this.handleEndAchievement(payloadData, photoUrl);  
    }
    console.log(" stepResponseData --> ", stepResponseData);
    this.props.clearPokeFormData();
    if (viewType == "List") {
      this.setFormStepEnabled();
      this.setState({ showForm: false, nextStep: false, evaluationData: null, showSelfEvaluationform: false })  
      if(stepId && stepslist[this.state.stepIndex].cardType !== "Course") {
        if(stepResponseData && (stepResponseData.cardFormResponse || stepResponseData.postStepFormResponse )) {
          this.handleListDoneStep(stepId, this.state.stepIndex, stepResponseData)
        }
      }
    } else {
      this.setState({ showForm: false, nextStep: false, evaluationData: null, showSelfEvaluationform: false, responseSubmitted: true ,nextdisable: false })  
      if(stepResponseData && (stepResponseData.cardFormResponse || stepResponseData.postStepFormResponse )){
        this.handleNextStep(stepId, stepNo, stepResponseData);
      }
    }
  }

  handleUpdateStatus = () => {
    this.handleEndAchievement();
  }

  setFormStepEnabled = () => {
    let s = { ...this.state };
    let index = s.stepsDisabled.findIndex(li => li == s.activeStep);
    if (index >= 0) {
      s.stepsDisabled.splice(index, 1);

    }
    s.showForm = false;
    s.activeStep = -1;
    s.nextStep = false;
    s.stepId = null
    this.setState(s);
  }
  handleTimeOut() {
    clearInterval(this.intervalID);
    this.setState({ timeOutAlert: !this.state.timeOutAlert })
  }
  handleShowForm = (stepId,stepFormId, stepIndex) => {
    console.log(" Opening Activity poke form --> ", stepId);
    this.setState({ showForm: true , stepIndex:stepIndex, responseSubmitted: false, stepId: stepId, stepFormId: stepFormId, nextStep: false})
  }

  handlecountDownInterval = (cardType) => {
    console.log("cardType: ", cardType)
    this.setState({ countDownStart: true })
    if (this.state.countDownInterval && this.state.countDownInterval > 0) {
      this.intervalID1 = setInterval(
        () => {
          this.setState({
            countDownInterval: this.state.countDownInterval - 1
          })
          if (cardType === "countDownEnforced" && this.state.countDownInterval <= 1) {
            this.setState({nextdisable: false})
            clearInterval(this.intervalID1);
          } else if (this.state.countDownInterval <= 1) {
            clearInterval(this.intervalID1);
          }
        },
        1000
      );
    } else {
      if (cardType === "countDownEnforced") {
        this.setState({nextdisable: false})
      }
    }
  }

  handleShowRewards = (activity) => {
    this.handleEndActivity()
  }

  updateTimer = (value) => {
    this.setState({ countDownInterval: value, countDownStart: false })
  }
  
  handleEvent = () => {
    let { eventDate } = this.state
    eventDate = Moment().format('MMM DD, YYYY')
    this.setState({ showEvent: !this.state.showEvent, eventDate: eventDate })
  }
  handleCloseEvent = () => {
    this.setState({ showEvent: false, responseSubmitted: true})
  }
  nextDate = () => {
    let { eventDate } = this.state
    let next = Moment(eventDate).add(1, 'days').format('MMM DD, YYYY')
    this.setState({ eventDate: next })
  }
  backDate = () => {
    let { eventDate } = this.state
    let next = Moment(eventDate).add(-1, 'days').format('MMM DD, YYYY')
    this.setState({ eventDate: next })
  }
  handleStartDateChange(date) {
    let event = Moment(date).format('MMM DD, YYYY')
    this.setState({ eventDate: event })
  }
  disableNext = () => {
    this.setState({ nextdisable: !this.state.nextdisable })
  }
  handleNextEnable = () => {
    this.setState({ nextdisable: false, responseSubmitted: true});
  }
  handleNextDisable = () => {
    this.setState({ nextdisable: true })
  }
  handleUp() {

    this.setState({ ...this.state, selectedThumb: "up" })
  }
  handleDown() {
    this.setState({ ...this.state, selectedThumb: "down" })
  }
  showOpenCourseAlert = (stepId, stepIndex, url, viewType) => {
    if (this.props.executeActivity) {
      this.setState({ ...this.state, stepId, stepIndex, nextdisable: false, responseSubmitted: true})
      if (viewType == "List") {
        this.handleListDoneStep(stepId, stepIndex)
      }
      this.openLinkInIframe(url);

      // window.open(url, '_blank')
    } else {
      this.setState({ ...this.state, openCourseAlert: true, stepId:stepId, stepIndex:stepIndex})
    }
  }

  openSelfEvaluationForm(selfEvaluationFormId) {
    this.props.getPokeFormFromConfigurator(selfEvaluationFormId)
    this.setState({ showForm: true, nextStep: true, showSelfEvaluationform: false })
  }
  handelStatusFormClose = () => {
    this.setState({ StatusForm: false })
  }
  componentDidUpdate(previousProps, previousState) {
    const {activityMessage, startActivity, selfEvaluationFormId, stepsCompletionList, shouldTakePicture, executeActivity, takePictureMessage} = this.props;
    const {showPictureCamera, photoUrl } = this.state;
    const stepsCount = this.props.stepslist && this.props.stepslist && this.props.stepslist ? this.props.stepslist.length : null;
    const stepsCompletedList = stepsCompletionList.length > 0 ? stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped" ) : [];
    if(shouldTakePicture && stepsCompletedList.length == stepsCount && executeActivity && !showPictureCamera && activityMessage !== "Achievement ended successfully" && !photoUrl) {
      this.setState({ showPictureCamera: true, stepFormId: null , stepId: null, takePictureMessage: takePictureMessage});
    } else if (selfEvaluationFormId && (stepsCount === 0) && this.state.showSelfEvaluationform) {
      console.log('called self evaluation form')
      this.openSelfEvaluationForm(selfEvaluationFormId)
    } else if(stepsCount > 0 && stepsCompletedList.length == stepsCount && selfEvaluationFormId && activityMessage !== "Achievement ended successfully" && this.state.showSelfEvaluationform) {
      this.openSelfEvaluationForm(selfEvaluationFormId);
    } 
  }

  handleCloseCoursePopup(){
    const { viewType } = this.props;
    if (viewType == "List") {
      this.setState({openCourseAlert: false});
      this.handleListDoneStep(this.state.stepId, this.state.stepIndex)
    } else {
      this.setState({openCourseAlert: false, nextdisable: false, responseSubmitted: true})
    }
    this.props.removeAPIError(null);
  }
  
  handleApiErrorAlertClose = () => {
    let {apiError, executeActivity} = this.props;
    console.log(" api error in method -->", apiError);
    if(apiError && apiError.type != "wpq") {
      this.closeWithClearAllData();
      if(executeActivity) {
        window.location.href = "/success";
      }
    }
    this.props.removeAPIError(null);
  }
  openLinkInIframe = (url) => {
		let width = window.innerWidth;
		if(width <= 450) {
            this.setState({linkUrl:url})
		}
		else {
			// window.open(url,"_blank");
      openURL(url)
		}
	}
	goBackToActivityPreviw = () => {
		this.setState({linkUrl:""});
	}
  handleOpenPokeFormHelpURL = (helpURL) => {
    if(helpURL == "lifesherpapp.com") {
      helpURL = "https://lifesherpapp.com"
    }
    this.openLinkInIframe(helpURL);
  }
  render() {
    let { stepNo, showForm, timerTime, timeOutAlert, showRewards, openCourseAlert, StatusForm ,linkUrl, showPictureCamera} = this.state
    let { startActivityDetails, startActivity , activityMode} = this.props
    let { stepsId, activity, stepCount, achievement, selfEvaluationFormId, stepslist, stepsCompletionList, statusData, viewType,stepSliderLoading , themeData, usersInfo, executeActivity, apiError, setFormStatusData, updateStatus} = this.props;
    if (this.state.countDownInterval <= 0) clearInterval(this.intervalID1);
    let maxStepTimeout = startActivityDetails && startActivityDetails.activity && startActivityDetails.activity.maxStepTimeout ? startActivityDetails.activity.maxStepTimeout * 1000 : 0
    // Finding position of current Step --
    let stepsCompletedList = stepsCompletionList.length > 0 ? stepsCompletionList.filter(step => step.status == "Done" || step.status == "Skipped" ) : [];
    let position = stepsCompletedList.length;
    stepNo = position && stepNo < position ? position : stepNo;
    // Getting flow view current Step completion details
    let currentCompletedStep  = stepslist && stepslist[stepNo] && stepsCompletionList && stepsCompletionList.find(ele => ele.step.id == stepslist[stepNo].stepId) ? stepsCompletionList.find(ele => ele.step.id == stepslist[stepNo].stepId) : {};
    //
    let stepWidth = stepslist ? 200 / stepslist.length : 200;
    let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
    let hours = ("0" + Math.floor(timerTime / 3600000)).slice(-2);
   // console.log('this.state.linkUrl',this.state.linkUrl);
    let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};
    //console.log("usersInfo --->",this.props.usersInfo);
    //console.log("user-->",this.props.user);
    const userName =  usersInfo ? usersInfo.displayName  ||  `${usersInfo.firstName || "Display"}  ${usersInfo.lastName || "Name"}` : this.props.user && this.props.user.displayName;
    const userImage = usersInfo && usersInfo.profileImageURL ? usersInfo.profileImageURL : this.props.user && this.props.user.thumbnailUrl;
   // console.log("userName --->",userName);
   // console.log("userImage --->",userImage);
   executeActivity = activityMode && activityMode=="execute" ? true : false;
    return (
      <Dialog
            fullScreen={true}
            open={true}
            fullWidth={true}
            maxWidth="xl"
            aria-labelledby="responsive-dialog-title"
            className={`${this.props.popupThemeClass} configurator-activity-preview`}
          >
           <DialogTitle>
            <div className={`d-flex justify-content-between border-bottom bg-lifesherpa ${executeActivity ? "pl-3" : "p-3"}`}>
                <h2 className="text-white my-auto pl-1 ">{`${activity && activity.title ? activity.title + " " : ''}${executeActivity ? "" : "(Preview)"}`}</h2>
                {executeActivity && <IconButton className="text-white mr-2" onClick={() => this.handlecloseAlert()}><CloseIcon /></IconButton>}
              </div>
          </DialogTitle>
      <DialogContent className={"m-0 p-0 " +(viewType == "List" && (stepsCompletionList.length == 0 || stepsCompletionList.length !== stepslist.length) && !showForm ? "list-view-container":"")}>
       <LifeSherpaLoading loading={this.props.loading1} />
        
        {this.state.linkUrl && <PreviewLinksIframe url={this.state.linkUrl} goBackToActivityPreviw={this.goBackToActivityPreviw}/> }
				
        {this.props.startActivity && this.props.startActivity.errorCode && this.props.startActivity.message &&
          <SweetAlert
            warning
            btnSize="sm"
            customClass="warningText"
            confirmBtnText="OK"
            confirmBtnBsStyle="warning"
            title="Alert!"
            onConfirm={() => this.props.clearActivityDataOnClose()}
          >
            <div className="sweet-alert-text">{this.props.startActivity && this.props.startActivity.message ? this.props.startActivity.message : ''}</div>
          </SweetAlert>
        }
        {showForm && this.props.pokeForm && !linkUrl &&
          <div className="mb-4">
            <ActivityForm
              image={userImage}
              name={userName}
              title={this.props.user && this.props.user.title ? this.props.user.title : 'Routine'}
              pokeForm={this.props.pokeForm}
              disabled={this.props.disabled}
              labelValue={this.state.labelValue}
              handleSaveAndContinue={this.handleSaveAndContinue}
              handleEvaluationForm={this.handleEvaluationForm}
              handleMinus={this.handleMinus}
              handlePlus={this.handlePlus}
              handleThumbUp={this.handleThumbUp}
              handleThumDown={this.handleThumDown}
              evaluationData = {this.state.evaluationData}
              executeActivity={executeActivity}
              handleOpenPokeFormHelpURL={this.handleOpenPokeFormHelpURL}
              handleDateAndTimeSection={this.handleDateAndTimeSection}
              handleImageInputSection={this.handleImageInputSection}
            />
          </div>
        }

        {!this.state.showEvent && !this.state.addEvent && (!showForm || !this.props.pokeForm) && !linkUrl &&
          <div  className={"container "}>
            {activity && activity.isTimerEnabled && !showRewards && stepNo < stepslist.length && <div className="row p-2 border-bottom bg-lifesherpa">
              <h2 className="text-white my-auto mx-auto">{'+' + hours + ':' + minutes + ':' + seconds}</h2>
            </div>}
            {activity && activity.isTimerEnabled && !showRewards && stepNo < stepslist.length && <div className="row p-2 border-bottom bg-lifesherpa">
              <p className="text-white my-auto ml-1">{'Elapsed Time ' + hours + ':' + minutes + ':' + seconds}</p>
            </div>}

            <div className=" my-5 py-5 mx-3">
              {viewType == "List" && (stepsCompletedList.length == 0 || stepsCompletedList.length !== stepslist.length) &&
                stepslist.map((step, key) =>
                  <div key={key} className="row">
                    <div className="w-100 ">
                      <StartActivityListViewDetails
                        step={step}
                        key={key + "activitydetails"}
                        stepIndex={key}
                        stepKey={stepCount[stepNo]}
                        countDownStart={this.state.countDownStart}
                        countDownInterval={this.state.countDownInterval}
                        activity={activity}
                        stepNo={stepNo}
                        stepsCompletionList={stepsCompletionList}
                        stepslist={stepslist}
                        checkList={this.state.checkList}
                        stepsDisabled={this.state.stepsDisabled}
                        updateCheckList={this.updateCheckList}
                        setActiveStep={this.setActiveStep}
                        setStepDisabled={this.setStepDisabled}
                        setStepEnabled={this.setStepEnabled}
                        nextdisable={this.state.nextdisable}
                        handleNextStep={this.handleListDoneStep}
                        handleSlipStep={this.handleSlipStep}
                        disableNext={() => this.disableNext()}
                        handleEvent={() => this.handleEvent()}
                        showForm={this.handleShowForm}
                        handlecountDownInterval={() => this.handlecountDownInterval()}
                        updateTimer={(value) => this.updateTimer(value)}
                        openedItem={this.state.openedItem}
                        setOpenSteps={this.setOpenSteps}
                        showOpenCourseAlert={this.showOpenCourseAlert}
                        openedStep={this.state.openedStep}
                        openLinkInIframe={this.openLinkInIframe}
                        userImage={userImage}
                        userName={userName}
                        executeActivity={executeActivity}
                        stepsCompletedList={stepsCompletedList}
                      />  </div></div>)
              }

              {stepslist && stepNo < stepslist.length && viewType == "Flow" && !stepSliderLoading?
                <Slide direction="left" in={!stepSliderLoading} timeout={1000}>
                  <div key={stepNo} className="">
                    <ActivityPreviewItem
                      step={stepslist[stepNo]}
                      stepKey={stepCount[stepNo]}
                      countDownStart={this.state.countDownStart}
                      countDownInterval={this.state.countDownInterval}
                      disableNext={() => this.disableNext()}
                      handleEvent={() => this.handleEvent()}
                      showForm={this.handleShowForm}
                      handlecountDownInterval={this.handlecountDownInterval}
                      updateTimer={(value) => this.updateTimer(value)}
                      showOpenCourseAlert={this.showOpenCourseAlert}
                      openLinkInIframe={this.openLinkInIframe}
                      userImage={userImage}
                      userName={userName}
                      executeActivity={executeActivity}
                      handleNextEnable = {this.handleNextEnable}
                      handleNextDisable = {this.handleNextDisable}
                      stepsCompletionList={stepsCompletionList}
                      responseSubmitted={this.state.responseSubmitted}
                      stepsCompletedList={stepsCompletedList}
                    />
                  </div>
                </Slide> : stepslist && stepNo < stepslist.length && viewType == "Flow" ?
                  <div className="activity-details-height">
                   {/*<LifeSherpaLoading loading={this.props.loading1} />*/}
                  </div> : ""
              }

              {!stepslist.length && !selfEvaluationFormId && statusData != null && StatusForm &&
                <SetCurrentStatusForm
                  image={userImage}
                  name={userName}
                  statusData={statusData}
                  handelStatusFormClose={this.handelStatusFormClose} 
                  configuratorPreview={true}
                  executeActivity={executeActivity}
                  handleUpdateStatus={this.handleUpdateStatus}
                  setFormStatusData={setFormStatusData}
                  updateStatus={updateStatus}
                  showSetStatusPopup={StatusForm}
                  />
              }

              {(stepslist.length && stepNo >= stepslist.length) || (stepsCompletedList.length == stepslist.length) || (stepslist.length === 0 && !selfEvaluationFormId && ((statusData != null && !StatusForm) || (statusData == null && StatusForm))) ?
                <div className="col-12 complete-activity-page-height mt-60 mx-auto">
                  {!this.props.loading1 && executeActivity && activity && activity.rewards  ? 
                        <>
                          <h2 data-testid="activity-steps-not-found" className="text-center text-dark mt-5 margin-t30">
                            {`Great job! You have added ${activity.rewards} gem(s) to your collection.`}
                            {activity && activity.confirmationRewards ? ` You will receive additional ${activity.confirmationRewards} gem(s) when your accomplishment is confirmed` : ""}
                          </h2>
                        </>
                        : !this.props.loading1 &&
                        <h2 data-testid="activity-steps-not-found" className="text-center text-dark mt-5 margin-t30">
                          {`Congratulations! for completing routine ${activity && activity.title ? activity.title.toUpperCase() : ''}.`}
                          {activity && activity.confirmationRewards ? ` You will receive ${activity.confirmationRewards} gem(s) when your accomplishment is confirmed` : ''}
                        </h2>
                    }  
                    {activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default') ? 
                          <div className="mx-auto d-flex mt-50">
                            <img className="mx-auto auto-resize-img" style={{maxHeight: 120, maxWidth: "100%"}} height="auto" width="auto" src={this.props.themeLogo || SherpaLogo} />
                          </div>
                            : 
                            <div className="mx-auto container text-center mt-50 activity-preview-theme-logo">
                            <img className="mx-auto auto-resize-img" style={{maxHeight: 120, maxWidth: "100%"}} height="auto" width="auto" src={this.props.themeLogo || SherpaLogo}/>
                              <div className="d-flex align-items-center justify-content-center lifesherpa-poweredby-logo">
                                {themeData && themeData.loginLifeSherpaImage &&
                                  <img src={themeData.loginLifeSherpaImage} alt="session-logo" className="" width="auto" height="30" />
                                }
                              </div>
                           </div>

                      }
                      {executeActivity && 
                      <div className="d-flex mt-auto"> 
                         <Button className="primary-button mx-auto" onClick={()=>this.handleCompletionActivity()}>OK </Button> 
                      </div>}
                     
                </div>
                : ""
              }
            </div>
          </div>
        }
        {showPictureCamera && <SherpaCamera userName={name} takePictureMessage={this.state.takePictureMessage}  handleClosePictureCamera={this.handleClosePictureCamera}/>}
        {this.state.closeAlert &&
          <div className="col-12 p-4">
            <SweetAlert
              warning
              showCancel
              btnSize="sm"
              customClass="warningText"
              confirmBtnText="YES"
              cancelBtnText="NO"
              confirmBtnBsStyle="warning"
              cancelBtnBsStyle="default"
              title="Resume"
              onConfirm={() => this.handleClose()}
              onCancel={() => this.handleCancel()}
            >
              <div className="sweet-alert-text">Would you like to resume later?</div>
            </SweetAlert>
          </div>
        }
        {timeOutAlert &&
          <div className="col-12 p-4">
            <SweetAlert
              warning
              btnSize="sm"
              customClass="warningText"
              confirmBtnText="OK"
              confirmBtnBsStyle="warning"
              title="TimeOut"
              onConfirm={() => this.handleCancel()}
              onCancel={() => this.handleCancel()}
            >
              <div className="sweet-alert-text">Activity Timeout</div>
            </SweetAlert>
          </div>
        }
        {(openCourseAlert || apiError) &&
              <div className="col-12 p-4">
                <SweetAlert
                  warning
                  btnSize="sm"
                  customClass="warningText"
                  confirmBtnText="OK"
                  confirmBtnBsStyle="warning"
                  title={!executeActivity && openCourseAlert ? "Course cannot be opened in activity preview" : apiError ? apiError.response ? apiError.response.data.message : apiError.message  :  "Course cannot be opened in activity preview"}
                  onConfirm={() => !executeActivity && openCourseAlert ? this.handleCloseCoursePopup() : apiError ?  this.handleApiErrorAlertClose() : this.handleCloseCoursePopup()}
                >
                  <div className="sweet-alert-text"></div>
                </SweetAlert>
              </div>
            }
       </DialogContent>
         {  !this.state.showEvent && !this.state.addEvent && (!showForm || !this.props.pokeForm) && !linkUrl && stepslist && stepNo < stepslist.length && viewType == "Flow" && 
           <DialogActions className="activity-preview-dialog-actions">
          <div className="activity-preveiw-skip-buttons">
            {stepslist && stepNo < stepslist.length && viewType == "Flow" && <div className="d-flex step-complete-bar mx-auto align-items-center my-3">
              <div className="activity-completed bg-lifesherpa" style={{ width: (stepWidth * (stepNo + 1)) + 'px' }}></div>
              <div className="activity-remain" style={{ width: (200 - stepWidth * (stepNo + 1)) + 'px' }}></div>
            </div>}
            {stepslist && stepNo < stepslist.length && viewType == "Flow" && <div className="row d-flex align-items-center justify-content-center mt-auto pb-2">
              <div className="col-6 ">{activity && activity.showSkipButton && !stepslist[stepNo].isMandatory ?  <Button color="primary" variant="outlined" className=" mx-auto skip-button" disabled={stepNo >= stepslist.length} onClick={() => this.handleSlipStep(stepslist[stepNo].stepId, stepNo)}>SKIP IT </Button> : ''}</div>
              <div className="col-6 "> <Button color="primary" variant="contained" className="  mx-auto next-button" disabled={this.state.nextdisable} onClick={() => this.handleNextStep(stepslist[stepNo].stepId, stepNo)}>{stepslist && stepslist[stepNo].isStartButtonEnabled && currentCompletedStep.status != "Started" ? 'START' : 'NEXT'} </Button></div>
            </div>}

          </div> </DialogActions>}   

      </Dialog>
    );
  }
}

const mapStateToProps = ({ activitiesList, chatAppReducer, PokeForm, authUser, settings, GroupList }) => {
  const { selectedMemberId, selectedRole } = chatAppReducer
  const {groups} = GroupList
  const { pokeForm, isPokeForGroup } = PokeForm
  const { user, profile, credit , themeData, usersInfo} = authUser;
  const { startActivity, startActivityDetails, loading1, activityType, activityTimer,stepSliderLoading, executeActivity, apiError } = activitiesList;
  const achievement = startActivity && startActivity.achievement;
  const stepsId = startActivityDetails ? startActivityDetails.stepsId : [];
  const activity = startActivityDetails ? startActivityDetails.activity : {};
  const activityMessage = startActivity ? startActivity.message : "";
  const stepCount = startActivityDetails ? startActivityDetails.stepCount : [];
  const viewType = activity && activity.routineViewType ? activity.routineViewType : "Flow";
  let steps = achievement && achievement.routine ? achievement.routine.steps : {};
  let stepslist = [];
  if (steps) {
    Object.keys(steps).map((stepId, key) => {
      let obj = steps[stepId];
      obj.stepId = stepId;
      stepslist.push(obj);
    })
  }
  stepslist = stepslist.length > 0 ? stepslist.sort(function (a, b) { return a.position - b.position }) : []
  let stepsCompletion = achievement ? achievement.StepsCompletion : {};
  let stepsCompletionList = [];
  if (stepsCompletion) {
    Object.keys(stepsCompletion).map((stepCompId, key) => {
      stepsCompletionList.push(stepsCompletion[stepCompId]);
    })
  }
  const { popupThemeClass } = settings; 
  // Getting Activity Form Data ---
  let formData = achievement && achievement.form ? achievement.form : {};
  let statusData = formData ? Object.values(formData)[0] : null;
  const selfEvaluationFormId = achievement && achievement.routine && achievement.routine.selfEvaluationFormId ? achievement.routine.selfEvaluationFormId : null;
  const shouldTakePicture = achievement && achievement.routine && achievement.routine.shouldTakePicture ? achievement.routine.shouldTakePicture : null;
  const takePictureMessage = achievement && achievement.routine && achievement.routine.takePictureMessage ? achievement.routine.takePictureMessage : null;

  return {stepSliderLoading, viewType, statusData, stepsCompletionList, stepslist, selfEvaluationFormId, stepsId, activity, stepCount, achievement, user, startActivity, startActivityDetails, pokeForm, loading1, user, profile, credit, activityTimer, isPokeForGroup, selectedMemberId, activityType , themeData, usersInfo, popupThemeClass, executeActivity, apiError, activityMessage, shouldTakePicture, takePictureMessage, groups};
}

export default connect(mapStateToProps, {
  pauseAcivityForConfigurator,
  EndActivity,
  StartStep,
  EndStep,
  SkipStep,
  cancelActivityForConfigurator,
  getPokeFormFromConfigurator,
  savePokrFormForClients,
  setPokeFormForGroup, selectActivityId,
  clearPokeFormData, clearActivityDataOnClose,
  handleShowPhotoCamera,
  removeAPIError,
  setFormStatusData, updateStatus
})(Preview);
