/**
 * Ecommerce Dashboard
 */

 import React, { Component } from 'react'
 import { connect } from 'react-redux'
 
 // rct collapsible card
 import DashboardSummary from "../../../components/AppCenter";
 //Actions
 import {
     selectActivity,
     handlEditor,
     changeRole,
     showClientPanel,
     getActivitiesList,
     getUserById,
     getActivityGroupsList,
     hideActivityHistoryDetail,
     handleHideActivityDetailsPanel,
     getActivitiesCalendar
 } from '../../../actions'
 
import { withAuth0 } from "@auth0/auth0-react";
 
 class Dashboard extends Component {
 
     constructor(props) {
         super(props)
         this.state = {
             role: null,
             firebaseToken: null,
             activityId: '',
             activityType: '',
             previewId: null,
             assingnActivityPanel: false,
         }
     }
     showUserDetails = (isShowClientDetail) => {
         if (isShowClientDetail) {
             console.group("%c[UserDetails]%c Rendered user details panel", "color:black", "color:green");
         } else {
             console.log("%c[UserDetails]%c Closed user details panel", "color:black", "color:red");
             console.groupEnd();
         }
         let auth_uid = localStorage.getItem('auth_uid');
         const orgId = localStorage.getItem("orgId")
         if (this.props.clientPanel) {
             localStorage.setItem('uid', auth_uid);
             localStorage.setItem("selectedOrgId", orgId)
             this.props.changeRole({ role: 'admin', id: auth_uid });
             this.props.getActivityGroupsList(auth_uid, [orgId]);
             this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
             this.props.getActivitiesCalendar(auth_uid);
             this.props.handleHideActivityDetailsPanel();
         }
         this.props.showClientPanel(isShowClientDetail);
     }
 
     componentDidMount() {
         this.props.hideActivityHistoryDetail()
         const {widgetApp} = this.props;
         const auth_uid = localStorage.getItem('auth_uid');
         const uid = localStorage.getItem('uid');
         const idToken = localStorage.getItem('idToken')
         const orgId = localStorage.getItem("orgId")
         if (uid != auth_uid) {
             localStorage.setItem('uid', auth_uid);
             localStorage.setItem("selectedOrgId", orgId)
             this.props.changeRole({ role: 'admin', id: auth_uid });
         }
        
         if (idToken && auth_uid) {
             this.props.getUserById(auth_uid, idToken, widgetApp)
             this.props.getActivitiesCalendar(auth_uid);
         }
         this.setState({ firebaseToken: idToken });
     }
 
    handleStartActivity = (e) => {
        this.props.handlEditor(true)
        this.setState({ previewId: null, mobileOpen: true, activityId: e.activityId, activityType: e.type })
    }
 
    render() {
        window["auth0"] = this.props.auth0
        const { userRole, widgetVersion } = this.props;
        return (
            <div className="w-100 " data-testid="ls-dashboard">
                <div className="row">
                    <div
                        className="col-12 w-xs-full"
                    >
                        <DashboardSummary
                            handleStartActivity={this.handleStartActivity}
                            showUserDetails={this.showUserDetails}
                            userRole={userRole}
                            widgetVersion={widgetVersion}
                        />
                    </div>
                </div>
            </div>
        )
    }
 }
 
 const mapStateToProps = ({ authUser, Charts, MyCourses, clientsList, chatAppReducer, settings }) => {
     let { clientPanel } = clientsList;
     const { userRole } = authUser
     const { loading } = Charts
     const { selectedRole } = chatAppReducer
     const { myCourses } = MyCourses;
     const { assingnActivityPanel } = settings;
     return { selectedRole, userRole, loading, myCourses, clientPanel, assingnActivityPanel }
 }
 
 export default withAuth0(connect(mapStateToProps, {
     selectActivity,
     handlEditor,
     changeRole,
     showClientPanel,
     getActivitiesList,
     getUserById,
     getActivityGroupsList,
     hideActivityHistoryDetail,
     handleHideActivityDetailsPanel,
     getActivitiesCalendar,
 })(Dashboard))
 