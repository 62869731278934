import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import Moment from 'moment';
import {
    hideNotificationPopup,
    getResourcePost,
    getResourceList,
    getcategories,
    getKeywords
} from 'Actions';
import { IconButton } from '@mui/material';
import AppConfig from "../../constants/AppConfig";
import BulletinPostNotification from './BulletinPostNotification';
import LifeSherpaLoading from "../LifeSherpaLoading";
import AlertView from "../Notices/AlertView";

class NotificationPopup extends Component {
    state = {
        selected: '',
        notification: null,
        showClientAlert: false,
    }

    componentDidMount() {
        document.addEventListener('nativeBackClick', this.handleClose.bind(this));
    }

    componentWillUnmount(){
        document.removeEventListener('nativeBackClick', this.handleClose.bind(this));
    }

    handleConfirm = (notification) => {
        console.log("notification : ", notification);
        if (notification.type === "15") {
            const orgId = notification.organization || localStorage.getItem('orgId');
            this.props.getResourcePost(notification.categoryId, notification.postId, orgId);
        } else if (notification.type === "25") {
            this.setState({showClientAlert: true, notification:{...notification}})
        }
        this.props.hideNotificationPopup();
    }

    handleCloseAlerts = () => {
        this.setState({showClientAlert: false, notification: null})
    }

    handleClose = (notification) => {
        this.props.hideNotificationPopup();
        if (notification && notification.type === "15") {
            this.props.getResourceList('','new', '')
            this.props.getcategories()
            this.props.getKeywords()
        } 
    }

    render() {
        const { notificationData, showNotificationPopup, postLoading, notificationOpenLoading } = this.props;
        const { showClientAlert, notification } = this.state;
        return (
            <div> {
                notificationData && showNotificationPopup &&
                <Dialog
                    fullScreen={false}
                    open={showNotificationPopup}
                    fullWidth={true}
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} warning-dialog-popup`}
                    id="notification_popup"
                >
                    <DialogContent className="p-0">
                        <div className="text-center mb-3 mx-4 mt-4 execute-activity-alert">
                            <div className="d-flex align-items-center justify-content-center" style={{height: 80}}>
                                <Avatar
                                    alt="Remy Sharp"
                                    src={require('../../assets/img/help-logo.png')}
                                    style={{height: 80, width: 80, border: '0px solid #fff'}}
                                />
                            </div>
                            <h2 className=" exitDialog text-center">{notificationData.title}</h2>
                            <h2 className=" subHeading text-center mb-4">{notificationData.message}</h2>
                            <div className="d-flex pt-3  align-items-center justify-content-between">
                                <button className="btn btn-warning mr-2" onClick={() => this.handleClose(notificationData)}>CANCEL</button>
                                <button className="btn btn-grey ml-2" onClick={() => this.handleConfirm(notificationData)}>OK</button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            }
                <BulletinPostNotification />
                <LifeSherpaLoading loading={postLoading || notificationOpenLoading} />
                {notification && showClientAlert ?
                    <AlertView
                        postId={notification.postId}
                        categoryId={notification.categoryId}
                        organization={notification.organization || localStorage.getItem('orgId')}
                        title={notification.userName}
                        handleBackToList={this.handleCloseAlerts}
                        handleclosePanel={this.handleCloseAlerts}
                    />
                    : ""
                }

            </div>
        );
    }
}
const mapStateToProps = ({ Notifications, settings, Resources }) => {
    const { screenThemeClass, popupThemeClass } = settings;
    const { notificationData, showNotificationPopup, notificationOpenLoading } = Notifications;
    const { postLoading } = Resources
    return { screenThemeClass, popupThemeClass, notificationData, showNotificationPopup, postLoading, notificationOpenLoading };
};

export default withRouter(connect(mapStateToProps, { 
    hideNotificationPopup, 
    getResourcePost,
    getResourceList,
    getcategories,
    getKeywords 
})(NotificationPopup));
