import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import config from '../../config/config'
import audio from '../../assets/audio/serene_symphony_ringtones.mp3';
import { ref, onValue, off} from "firebase/database"
import {database} from '../../firebase';
import {isMobile} from '../../helpers/helpers';

import {
    hideChatPanel, 
    showVideoRoomPanel,
    hideVideoCallConfirmation,
    showVideoCallConfirmation,
    sendVideoCallResponse,
    sendGroupVideoCallResponse
} from 'Actions';
import { IconButton } from '@mui/material';

class VideoCallConfirmation extends Component {

    constructor(props) {
        super(props)
        this.audioRingtone = new Audio(audio)
    }

    state={
        timeOut:0,
    }

    componentDidMount() {
        let timeOutInterval = setTimeout(() => this.handleMissedCall(), 30000);
        this.audioRingtone.play()
        this.setState({timeOut:timeOutInterval});
        this.realtimeRoomDetails();
    }

    componentWillUnmount() {
        const {timeOut, roomRealtimePath} = this.state;
        this.stop_sound()
        clearTimeout(timeOut);
        if(roomRealtimePath) {
            const removeListener = ref(database, roomRealtimePath);
            off(removeListener);
        }  
    }

    handleMissedCall = () => {
        let {videoCallMessage} = this.props;
       this.props.hideVideoCallConfirmation();
    }

    realtimeRoomDetails = () =>  {
        const userId = localStorage.getItem('auth_uid');
        let {videoCallMessage} = this.props;
        const orgId = videoCallMessage.organization || localStorage.getItem('orgId');
        if(videoCallMessage && videoCallMessage.roomname) {
            const roomRealtimePath = `Realtime/${orgId}/Users/${userId}/groupCallsInProgress/${videoCallMessage.roomname}`;
            console.log( "Getting realtime room details  --> ",roomRealtimePath);
            onValue(ref(database, roomRealtimePath), (snapshot) => {
              const details = snapshot.val();
              console.log( "Room Details form realtime --> ",details);
              if(details) {
                if(details.group) {
                  this.handleGroupVideoCall(details);
                } else {
                  this.handleP2PVideoCall(details);
                }
              } else {
                this.props.hideVideoCallConfirmation();
              }
            });
            this.setState({roomRealtimePath});
        } 
    }; 

    handleGroupVideoCall = (details) => {
        console.log("Handling Group Video Call --> ", details);
        const userId = localStorage.getItem('auth_uid');
        details && details.members && details.members.length > 0 && details.members.map((user) => {
          if(user.status && user.userId == userId && (user.status == "cancel" || user.status == "declined" || user.status == "removed" || user.status == "unanswered" || user.status == 'accepted')) {
            this.props.hideVideoCallConfirmation();
          } 
        });
      }
    
    handleP2PVideoCall = (details) => {
        console.log("Handling P2P Video call --> ", details)
        const userId = localStorage.getItem('auth_uid');
        details && details.members && details.members.length > 0 && details.members.map((user) => {  
            if(user.status && (user.status == "cancel" || user.status == "declined" || user.status == "removed" || user.status == "unanswered" || (user.status == 'accepted' && user.userId == userId))) {
                this.props.hideVideoCallConfirmation();
            }
        })
    }

    handleOpenVideo = () => {
        this.stop_sound()
        let {videoCallMessage} = this.props;
        if(videoCallMessage && videoCallMessage.roomname) {
            const orgId = videoCallMessage.organization || localStorage.getItem('orgId');
            localStorage.setItem('selectedOrgId', orgId);
            this.handleResponseSend("accepted", orgId);
            // let openRoom = {name:videoCallMessage.roomname};
            // this.props.showVideoRoomPanel(openRoom);
           let openVideoCallRoom = `${config.lsPortalUrl}/lifesherpa/videocall?room=${videoCallMessage.roomname}`;
           // window.open(openVideoCallRoom,"_blank");
           this.props.hideVideoCallConfirmation(openVideoCallRoom, this.props.navigate);
        } else {
            this.props.hideVideoCallConfirmation();
        }
    }
    handleClose = () => {
        this.stop_sound()
        let {videoCallMessage} = this.props;
        if(videoCallMessage && videoCallMessage.roomname) { 
            const orgId = videoCallMessage.organization || localStorage.getItem('orgId');
            this.handleResponseSend("declined", orgId);
        }
        this.props.hideVideoCallConfirmation();
    }
    handleResponseSend = (action, orgId) => {
        let {videoCallMessage } = this.props;
        if(videoCallMessage && videoCallMessage.group == "1") {
            this.props.sendGroupVideoCallResponse(videoCallMessage.roomname, action, null, orgId);
        } else {
            this.props.sendVideoCallResponse(videoCallMessage.roomname, action, orgId);
        }
    }
      
    stop_sound(){
        // There's no 'this.sound.stop()' function. Can you believe it?
        this.audioRingtone.pause(); 
        this.audioRingtone.currentTime = 0;  
    }	

    render() {
      const { contactList, showVideoCallConfirmationPanel, videoCallMessage } = this.props;
      console.log(" videoCallMessage --> ", videoCallMessage);
      console.log(" showVideoCallConfirmationPanel --> ", showVideoCallConfirmationPanel);
      let callerName = "Unknown Caller"
      if(videoCallMessage) {
          if(videoCallMessage.message) {
            callerName = videoCallMessage.message.split(" request")[0];
          } else if(videoCallMessage.name) {
            callerName = videoCallMessage.name;
          }
      }
        return (
            <div> { showVideoCallConfirmationPanel && videoCallMessage && 
                <Dialog
					open={showVideoCallConfirmationPanel}
					className={`${this.props.popupThemeClass} videocallConfirmationDialog`}
                    fullScreen={isMobile()}
					>
					<DialogContent className={`vidoe-call-confirmation-details`}>
                        <div className="caller-details text-center"> 
                            <div className="my-2">Incoming video call ...</div>
                            <div className="callerTitle mt-1"><h2>{callerName}</h2></div>
                        </div>
                        <div className="d-flex align-items-center caller-image justify-content-center">
                           {videoCallMessage && videoCallMessage.callerImage ?
                           <Avatar
                                alt="Remy Sharp"
                                src={require('Assets/img/client-2.png')}
                                variant="circular"
                                overlap="circular"
                            />
                            :
                            <Avatar>{callerName.charAt(0)}</Avatar>
                           }
                           {/* <Avatar
                                alt="Remy Sharp"
                                src={require('Assets/img/client-2.png')}
                                variant="circular"
                                overlap="circular"
                            /> */}
                        </div>
					</DialogContent>
                    <DialogActions className="vidoe-call-confirmation-actions">
                          <IconButton className="accept-video-call" onClick={() => this.handleOpenVideo()}>
                              <CallIcon/>
                          </IconButton>
                          <IconButton className="reject-video-call" onClick={() => this.handleClose()}>
                              <CallEndIcon/>
                          </IconButton>
                    </DialogActions>
				</Dialog>}
            </div>
        );
    }
}
const mapStateToProps = ({ settings, Contacts, chatAppReducer }) => {
	const { screenThemeClass, popupThemeClass  } = settings; 
  let {contactList, showVideoCallConfirmationPanel, videoCallMessage} = Contacts;
	return {contactList, showVideoCallConfirmationPanel , screenThemeClass, popupThemeClass, videoCallMessage};
};

export default withRouter(connect(mapStateToProps,{ 
    hideChatPanel, 
    showVideoRoomPanel,
    hideVideoCallConfirmation,
    showVideoCallConfirmation,
    sendVideoCallResponse,
    sendGroupVideoCallResponse
})(VideoCallConfirmation));
