/**
 * Footer
 */
import React from 'react';
import { isMobile } from '../../helpers/helpers';

const Footer = ({ email, userId }) => {
	if(isMobile()) {
		if(window.location.pathname !== '/app/lsdashboard/more') {
			return ''
		}
	}
	return (
		<div className="list-card mt-4 p-4 footer ">
			<div className="row">
				<div className="col-lg-6 col-md-6 col-sm-12">
					<div data-testid="footer-email">{email}</div>
					<div data-testid="footer-userId">{userId}</div>
				</div>
				<div className='col-12 d-block d-sm-none'><hr className="my-2" /></div>
				<div className="col-lg-6 col-md-6 col-sm-12 text-right">
					<div>LifeSherpa Portal</div>
					<div>{process.env.BUILD_VERSION}</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;
