import React from 'react';

//custom input 
const CustomInput = ({id, name, label, className, onChange,color, checked, type, disabled = false}) => (
  <div className={`custom-control custom-${type} ${className}`}>
    <input disabled={disabled} type={type} checked={checked} color={color} name={name} className="custom-control-input" id={id} onChange={onChange}/>
    <label className="custom-control-label" htmlFor={id}>{label}</label>
  </div>
)

export default CustomInput