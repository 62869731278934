import React, { Component } from 'react';
import ChatItem from "../../LifesherpaContacts/ChatItem";
import { connect } from 'react-redux';
import LifeSherpaLoading from 'Components/LifeSherpaLoading';
import { updateContactsList, addChannelToContactList, setTwilioClient } from 'Actions';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import DotsLoader from "../../DotsLoader";
import { Client as ConversationsClient } from "@twilio/conversations";
import classNames from 'classnames';
import { Capacitor } from '@capacitor/core';

import {
  List,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {getTwilioChatInfo, isMobile} from "../../../helpers/helpers";

const styles = {
  textField: {
    width: "100%",
    borderWidth: 0,
    borderColor: "transparent",
    height: 45,
    marginBottom: 3
  },
  textFieldContainer: {
    flex: 1,
    marginRight: 3,
    marginLeft: 2
  },
  gridItem: {
    paddingTop: 12,
    paddingBottom: 12
  },
  gridItemChatList: {
    overflow: "auto",
    height: "320px"
  },
  gridFullScreen: {
    overflow: "auto",
  },
  gridItemChatList1: {
    overflow: "auto",
    height: "51vh"
  },
  gridItemMessage: {
    marginTop: 2,
    marginBottom: 2
  },
  sendButton: {
    backgroundColor: '#008C95',
    height: 45,
    marginLeft: 2
  },
  sendIcon: { color: "white" },
  mainGrid: { paddingTop: 100, borderWidth: 1 },
};

class ChatBlock extends Component {
  state = {
    loading: false,
    bottomScroll: false,
    unReadMessagesCount: 0,
    isTyping: false
  }
  componentDidMount = async () => {
    let { selectedUser, twilioClient } = this.props;
    console.log("[ChatBlock] selectedUser -->", selectedUser);
    this.setState({ loading: true });
    if (selectedUser.conversation) {
      this.joinConversation(selectedUser.conversation);
    } else {
      const details = await getTwilioChatInfo(selectedUser.userId);
      console.log("Chat Details : ", details);
      if(twilioClient && details.room) {
          this.createConversationWithUniqueName(twilioClient, details, true);
      } else if(details && details.token && details.room) {
        this.createTwilioClient(details);
      } else {
        this.setState({ loading: false });
      } 

    }
   
  }

  componentWillUnmount() {
    const { userCoversation } = this.props;
    if (userCoversation) {
      userCoversation.off("messageAdded", this.handleMessageAdded);
      userCoversation.off("typingStarted", () => this.handleTypingStarted());
      userCoversation.off("typingEnded", () => this.handleTypingEnded());
    }
  }

  createTwilioClient = async (details) => {
    const {token, room} = details;
    try {
      const client = new ConversationsClient(token);
      console.log("[ChatBlock] conversation client created --> ");
      client.on('stateChanged', (state) => {
        if (state === 'initialized') {
          console.log("[ChatBlock] Client state initialized --> ")
          this.props.setTwilioClient(client);
          this.createConversationWithUniqueName(client, details);
        }
      })
    } catch (e) {
      console.error("[ChatBlock] error occured while creating channel -->", e);
      this.setState({ loading: false });
    }
  }
  createConversationWithUniqueName = async (client, details, clientCreateRetry) => {
    const {token, room} = details;
    try {
      const conversation = await client.getConversationByUniqueName(room.uniqueName);
      console.log("[ChatBlock] fetched conversaton by unique name -->", conversation);
      this.updateConversation(conversation);
    } catch (err) {
      console.error("[ChatBlock] Unable to get conversation by unique name --> ", room, err);
      try {
        const conversation = await client.createConversation({
          friendlyName: room.friendlyName,
          uniqueName: room.uniqueName,
        })
        await conversation.add(this.props.selectedUser.userId)
        console.log("[ChatBlock] conversaton created -->", conversation);
        this.updateConversation(conversation);
      } catch (error) {
        console.error("[ChatBlock] Unable to create conversation, please reload this page --> ", error);
        if(token && clientCreateRetry) {
          this.createTwilioClient(details);
        } else {
          this.setState({ loading: false });
        }
       
      }
    }
  }

  updateConversation = (conversation) => {
    const { contactList, selectedUser } = this.props;
    this.props.updateConversation(conversation)
    this.joinConversation(conversation);
    this.props.addChannelToContactList(contactList, conversation, selectedUser.userId);
  }

  joinConversation = async (conversation) => {
    try {
      if (conversation._internalState.status !== "joined") {
        await conversation.join();
      }
      const messages = await conversation.getMessages(50);
      if (messages.items && messages.items.length > 0) {
        this.setState({ ...this.state, clientMessages: messages });
        this.props.updateMessages(messages.items, () => {
          this.scrollToBottom();
        });
      }
      conversation.on("messageAdded", this.handleMessageAdded);
      conversation.on("typingStarted", () => this.handleTypingStarted());
      conversation.on("typingEnded", () => this.handleTypingEnded());
      this.setState({ ...this.state, loading: false });
    } catch(error) {
      this.setState({ ...this.state, loading: false });
      console.error("[ChatBlock] error occured while fetching messages", error)
    }
   
  }

  handleMessageAdded = async (message) => {
    let { bottomScroll, unReadMessagesCount } = this.state;
    const { messages, userCoversation, selectedUser } = this.props;
    const userId = selectedUser ? selectedUser.userId : "";
    var objDiv = document.getElementById(`chatlists1${userId}`);
    let uid = localStorage.getItem('auth_uid');
    if (userCoversation && objDiv) {
      console.log("[ChatBlock] conversaton messages added --->", message, bottomScroll);
      const updatedMsgs = messages && messages.length > 0 ? [...messages, message] : [message];
      this.props.updateMessages(updatedMsgs, () => {
        if (message.author === uid || !bottomScroll) {
          this.scrollToBottom();
        } else {
          unReadMessagesCount = unReadMessagesCount + 1;
          this.setState({ unReadMessagesCount: unReadMessagesCount });
        }
      });
     
    }
  };

  scrollToBottom = () => {
    const { selectedUser, userCoversation } = this.props;
    const userId = selectedUser ? selectedUser.userId : "";
    var objDiv = document.getElementById(`chatlists1${userId}`);
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
      if (userCoversation) {
        userCoversation.setAllMessagesRead().then(status => {
          console.log("[ChatBlock] messages read -->", status);
        }).catch(e => {
          console.log("[ChatBlock] error occured while reading messages -->", e);
        })
      }
      this.setState({ bottomScroll: false, unReadMessagesCount: 0 });
    }
  };

  handleScrollOnChat = (event) => {
    var objDiv = event.currentTarget;
    const { bottomScroll } = this.state;
    // console.log(" clientHeight --> ", objDiv.clientHeight);
    // console.log(" scrollTop --> ", objDiv.scrollTop);
    // console.log("scrollHeight ----> ", objDiv.scrollHeight);
    const clientHeight = objDiv.clientHeight;
    const scrollTop = objDiv.scrollTop + clientHeight;
    const scrollHeight = objDiv.scrollHeight;
    if (scrollTop < (scrollHeight - clientHeight)) {
      if (!bottomScroll) {
        this.setState({ bottomScroll: true })
      }
    } else if (bottomScroll) {
      const { userCoversation } = this.props;
      if (userCoversation) {
        userCoversation.setAllMessagesRead().then(status => {
          console.log("[ChatBlock] messages read -->", status);
        }).catch(e => {
          console.log("[ChatBlock] error occured while reading messages -->", e);
        })
      }
      this.setState({ bottomScroll: false, unReadMessagesCount: 0 })
    }
    if (objDiv && objDiv.scrollTop === 0) {
      this.handleFetchPreMessages();
    }
  }

  handleFetchPreMessages = () => {
    const { clientMessages, loading } = this.state;
    const { messages, selectedUser } = this.props;
    const userId = selectedUser ? selectedUser.userId : "";
    if (clientMessages && clientMessages.hasPrevPage && !loading) {
      this.setState({ loading: true });
      clientMessages.prevPage().then(preMessages => {
        if (preMessages.items && preMessages.items.length > 0) {
          console.log("[ChatBlock] Fetched previous page messages --> ");
          let updatedMessagesList = [...preMessages.items, ...messages];
          const objDiv = document.getElementById(`chatlists1${userId}`);
          const previousHeight = objDiv ? objDiv.scrollHeight : 0;
          this.setState({ ...this.state, clientMessages: preMessages, loading: false, bottomScroll: true });
          this.props.updateMessages(updatedMessagesList, () => {
            this.handleScrollFocusOnOldMessagesLoaded(previousHeight);
          });
        }
      }).catch(error => {
        this.setState({ loading: false });
        console.log("[ChatBlock] error occured while fetching previous messages -->", error)
      })
    }
  }

  handleScrollFocusOnOldMessagesLoaded = (previousHeight) => {
    const { selectedUser } = this.props;
    const userId = selectedUser ? selectedUser.userId : "";
    var objDiv = document.getElementById(`chatlists1${userId}`);
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight - previousHeight;
    }
  }

  getFilteredMessages = (messages, conversaton) => {
    if (conversaton && conversaton.uniqueName) {
      return messages && messages.length > 0 ? messages.filter(msg => {
        let uniqueName = msg && msg.conversation && msg.conversation.uniqueName ? msg.conversation.uniqueName : "";
        return uniqueName === conversaton.uniqueName
      }) : [];
    } else {
      return messages;
    }
  }

  handleTypingStarted = () => {
    console.log("[ChatBlock] typing started --> ")
    let { bottomScroll } = this.state;
    if (!bottomScroll) {
      this.setState({ isTyping: true }, () => {
        this.scrollBottomToShowTypingIndicator()
      })
    }
  }

  scrollBottomToShowTypingIndicator = () => {
    const { selectedUser } = this.props;
    const userId = selectedUser ? selectedUser.userId : "";
    var objDiv = document.getElementById(`chatlists1${userId}`);
    // this.setState({ isTyping: true })
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  handleTypingEnded = () => {
    console.log("[ChatBlock] typing ended --> ")
    this.setState({ isTyping: false })
  }

  render() {
    const { messages, userCoversation, fullScreen, selectedUser, isSidebarEnable  } = this.props;
    const { loading, bottomScroll, unReadMessagesCount, isTyping } = this.state;
    const userId = selectedUser ? selectedUser.userId : "";
    let filteredMessages = [];
    filteredMessages = this.getFilteredMessages(messages, userCoversation);
    let uid = localStorage.getItem('auth_uid');
    //console.log(" unReadMessagesCount -->", unReadMessagesCount);
    return (
      <React.Fragment>
        {bottomScroll && unReadMessagesCount ?
          <Badge className="scroll-to-bottom-badge" badgeContent={unReadMessagesCount} color="primary" anchorOrigin={{ vertical: 'top', horizontal: 'left', }}>
            <IconButton className="scroll-to-bottom-icon" onClick={() => this.scrollToBottom()}><ArrowDownwardIcon /></IconButton>
          </Badge>
          : bottomScroll ?
            <div className="scroll-to-bottom-box">
              <IconButton className="scroll-to-bottom-icon" onClick={() => this.scrollToBottom()}><ArrowDownwardIcon /></IconButton>
            </div>
            : ""
        }
        <Grid className={Capacitor.getPlatform() == "ios" ? "chat-panel-height-ios"  : classNames({'chat-messages-list': !isMobile() , 'chat-list-sidebar': isMobile() })} item style={fullScreen ? styles.gridFullScreen : styles.gridItemChatList} onScroll={(e) => this.handleScrollOnChat(e)} id={`chatlists1${userId}`}>
          <LifeSherpaLoading loading={loading} />
          <List dense={true}>
            {filteredMessages &&
              filteredMessages.map((message, index) => (
                <ChatItem
                  key={message.state.timestamp + index}
                  message={message}
                  email={uid}
                />
              ))}
          </List>
          {isTyping &&
            <div className="d-flex align-items-center mx-3">
              <div style={{ width: "50px" }}>
                <DotsLoader />
              </div>
            </div>
          }
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ Contacts }) => {
  const { loading, chatToken, channel, contactList, twilioClient } = Contacts
  return { chatToken, channel, loading, contactList, twilioClient }
}

export default connect(mapStateToProps, { updateContactsList, addChannelToContactList, setTwilioClient })(ChatBlock);