/**
 * App Routes
 */
import React, { Component, Suspense } from 'react';
// import queryString from 'query-string';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
// Components
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import HorizontalMenu from '../SherpaHorizontalMenu/HorizontalMenu'
// preload Components 
import PreloadHeader from '../PreloadLayout/PreloadHeader';
import UnActiveScreenTimer from '../UnActiveScreenTimer';
import SherpaCamera from "../SherpaCamera";
// app config
import AppConfig from '../../constants/AppConfig';
import PreloadSidebar from '../PreloadLayout/PreloadSidebar';


// actions
import { collapsedSidebarAction, startUserTour, setViewSize, hideStartActivityPanel } from '../../actions';
//helpers
import { scrollFunction, lsTextSpeech, isAuth0SessionExpired, isMobile } from '../../helpers/helpers'
//components

import WidgetsTabHeader from '../Header/WidgetsTabsHeader';

import BottomTabContainer from '../Footer/BottomTabContainer';
import { Capacitor } from '@capacitor/core';
import PopupComponents from './PopupComponents';
import NotificationsHandler from "../NotificationsHandler";
import LifeSherpaLoading from '../LifeSherpaLoading';



class MainApp extends Component {

	constructor(props){
		super(props)
		this.state = {
			loadingHeader: false,
			loadingSidebar: true,
			applyStricky: false,
			windowWidth: window.innerWidth
		}
	}

	async componentDidMount() {
		const { windowWidth } = this.state;
		// window.addEventListener("resize", this.updateDimensions);
		if (AppConfig.enableUserTour && windowWidth > 600) {
			setTimeout(() => {
				this.props.startUserTour();
			}, 2000);
		} 
		setTimeout(() => {
			this.setState({ loadingHeader: false, loadingSidebar: false });
		}, 114);

		window.onscroll = function () { scrollFunction("rct-main-page") };
		const { widgetApp } = this.props;
		if(!widgetApp) {
			lsTextSpeech();
			try {
				await isAuth0SessionExpired();
			} catch(e) {
				console.log("error occured during auth0 re-authenication: ", e)
			}
			 
		}
	
		// if (window.innerWidth > 600) {
		// 	this.props.setViewSize(false)
		// } else {
		// 	this.props.setViewSize(true)
		// }

		this.props.hideStartActivityPanel()
	}

	componentWillUnmount() {
		// window.removeEventListener("resize", this.updateDimensions);
	}

	// UNSAFE_componentWillReceiveProps(nextProps) {
	// 	const { windowWidth } = this.state;
	// 	if (nextProps.location !== this.props.location) {
	// 		if (windowWidth <= 1199) {
	// 			this.props.collapsedSidebarAction(true);
	// 		}
	// 	}
	// }

	// updateDimensions = () => {
	// 	this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
	// 	if (window.innerWidth > 600) {
	// 		this.props.setViewSize(false)
	// 	} else {
	// 		this.props.setViewSize(true)
	// 	}
	// }

	// componentDidUpdate(prevProps) {
	// 	if (this.props.location.pathname !== prevProps.location.pathname) {
	// 		window.scrollTo(0, 0);
	// 	}
	// }

	renderPage() {
		const { pathname } = this.props.location;
		const { children } = this.props;
		if (pathname === '/app/chat' || pathname.startsWith('/app/mail') || pathname === '/app/todo' || pathname === '/app/user/my_activities') {
			return (
				<div className="rct-page-content p-0">
					<Suspense fallback={<LifeSherpaLoading loading={true}/>}>
						{children}
					</Suspense>
				</div>
			);
		}
		let email = localStorage.getItem('user_email')
		let uid = localStorage.getItem('uid')
		return (
			<Scrollbars
				className="rct-scroll"
				autoHide
				autoHideDuration={100}
				style={this.getScrollBarStyle()}
			>
				<div className="rct-page-content">
					<Suspense fallback={<LifeSherpaLoading loading={true}/>}>
						{children}
					</Suspense>
					{<Footer email={email} userId={uid} />}
				</div>
			</Scrollbars>
		);
	}

	getScrollHeight = () => {
		const { safeAreaParams } = this.props;
		if (Capacitor.getPlatform() === 'ios' && !this.isHideBottomTab() && safeAreaParams) {
			const bottomPanelHeight = 96 + safeAreaParams.bottom
			return  `calc(100vh - ${bottomPanelHeight}px)`;
		} else if(Capacitor.getPlatform() === 'android' && this.hasMobileView() && !this.isHideBottomTab()) {
			return  'calc(100vh - 55px)';
		}
		return 'calc(100vh - 0px)';
	}

	hasMobileView = () => {
		const { widgetApp } = this.props;
		if(isMobile() && !widgetApp) {
			return true;
		}
		return false;
	}

	isHideBottomTab = () => {
		if(window.location.href.includes("/chat")) {
			return true;
		}
		if(window.location.pathname === "/app/contacts" && this.props.selectedUser) {
			return true;
		}
		return false;
	}

	renderPageAllScrolling() {
		const { widgetApp, showFallbackScreen } = this.props;
		const { children, screenThemeClass, selectedUser } = this.props;
		const email = localStorage.getItem('user_email')
		const uid = localStorage.getItem('uid');

		return (
			<div className="app-container">
				<div className="rct-app-content ">
					<div className={screenThemeClass}>
						<div
							className="lifesherpa-main-container"
							style={{ height: this.getScrollHeight()}}
							id="app-main-conatainer-infinitescroll"
						>
							{widgetApp || showFallbackScreen ? ""
								: 
								<div className="app-header">
									{this.renderHeader()}
								</div>
							}

							{widgetApp || showFallbackScreen ? ""
								: isMobile() ?
									<div className="list_header">
											<WidgetsTabHeader />
									</div>
								:
								<div className="list_header">
									<HorizontalMenu />
								</div>
							}
							
							<div id="rct-main-page" className="rct-page">
								{widgetApp ?
									<div className="rct-page-content widget-rct-page-content">
										{children}
									</div>
									:
									<div className={`rct-page-content ${isMobile() ? 'mobile-rct-page-content' : ''}`}>
										<Suspense fallback={<LifeSherpaLoading loading={true}/>}>
											{children}
										</Suspense>
										{selectedUser || showFallbackScreen ? "" : <Footer email={email} userId={uid} />}
									</div>
								}
							</div>
						</div>
						<BottomTabContainer show={this.hasMobileView() && !this.isHideBottomTab()}/>
					</div>
				</div>
			</div>
		);
	}

	// render header
	renderHeader() {
		const { loadingHeader, windowWidth } = this.state;
		if (loadingHeader && !isMobile()) {
			return <PreloadHeader />;
		}
		return <Header windowWidth={windowWidth}/>

	}

	//render Sidebar
	renderSidebar() {
		const { loadingSidebar } = this.state;
		if (loadingSidebar) {
			return <PreloadSidebar />;
		}
	}

	//Scrollbar height
	getScrollBarStyle() {
		return {
			height: 'calc(100vh - 160px)'
		}
	}

	render() {
		const { screenThemeClass } = this.props;
		const { widgetApp } = this.props;
		let {  showCamera } = this.props;
		const { windowWidth } = this.state;
		if (widgetApp) {
			return (
				<div className="app">
					<div className="app-main-container">
						{this.renderPageAllScrolling()}
						<div className={screenThemeClass}>
							{showCamera && <SherpaCamera />}
						</div>	
					</div>
				</div>
			);
		} else {
			return (
				<div className="app">
					{isMobile() ? <></> : <UnActiveScreenTimer history={this.props.history} />}
					<div className="app-main-container">
						{this.renderPageAllScrolling()}
						<div className={screenThemeClass}>
							{showCamera && <SherpaCamera />}
							<PopupComponents/>
							<NotificationsHandler></NotificationsHandler>
						</div>
					</div>
				</div>				
			);
		}

	}
}

// map state to props
const mapStateToProps = ({ settings, chatAppReducer, activitiesList }) => {
	const { screenThemeClass, popupThemeClass, safeAreaParams, showFallbackScreen} = settings;
	const { selectedUser } = chatAppReducer;
	const { showCamera } = activitiesList;
	return { screenThemeClass, popupThemeClass, safeAreaParams, selectedUser, showCamera, showFallbackScreen }
}

export default withRouter(connect(mapStateToProps, {
	collapsedSidebarAction,
	startUserTour,
	setViewSize,
	hideStartActivityPanel
})(MainApp));

