/**
 * User Block Component
 */
import React, { Component } from 'react';
import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import DonutLargeRoundedIcon from '@mui/icons-material/DonutLargeRounded';
import ErrorIcon from '@mui/icons-material/Error';

import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

const legendList = [
    {
        bgColor: "blue",
        title: "User was alerted and has not started",
        icon: "alarm",
        class: "achievementStatusNotStarted"
    }, {
        bgColor: "red",
        title: "Achievement is expired (Created but not started and expired)",
        icon: "alarm",
        class: "achievementStatusExpired"
    } , {
        bgColor: "blue",
        title: "Routine execution is in progress",
        icon: "more",
        class: "achievementStatusInProgress"
    } , {
        bgColor: "red",
        title: "Not completed (Cancelled or Started and Expired)",
        icon: "X",
        class:"achievementStatusIncomplete"
    } , {
        bgColor: "#bdbdbd",
        title: "Completed with all steps, but no confirmation was required",
        icon: "done",
        class: "achievementStatusCompletedNoConfirmationNeeded"
    } , {
        bgColor: "#bdbdbd",
        title: "Completed with skipped Steps, but no confirmation was requited",
        icon: "S",
        class:"achievementStatusCompletedSkippedStepsNoConfirmationNeeded"
    } , {
        bgColor: "orange",
        title: "Completed. Need confirmation",
        icon: "?",
        class:"achievementStatusCompletedConfirmationNeeded"
    } , {
        bgColor: "red",
        title: "Negative Confirmation",
        icon: "done",
        class:"achievementStatusConfirmedNegative"
    } , {
        bgColor: "yellow",
        title: "Confirmed with Skipped Steps",
        icon: "done",
        class:"achievementStatusConfirmedSkippedSteps"
    } , {
        bgColor: "green",
        title: "Confirmed with No Skipped Step",
        icon: "done",
        class:"achievementStatusConfirmed"
    }
]

const workflowLegends = [
    {
        bgColor: "red",
        title: "Task waiting (Not executed yet)",
        icon: "alarm",
        class: "achievementStatusExpired"
    } , {
        bgColor: "blue",
        title: "Task execution is in progress",
        icon: "paused",
        class: "achievementStatusInProgress"
    } , {
        bgColor: "blue",
        title: "Task is getting ready",
        icon: "executing",
        class: "achievementStatusInProgress"
    } , {
        bgColor: "red",
        title: "Task not completed as workflow is cancelled",
        icon: "cancelled",
        class:"achievementStatusIncomplete"
    } , {
        bgColor: "red",
        title: "Error occurred during workflow execution",
        icon: "error",
        class:"achievementStatusConfirmedNegative"
    } , {
        bgColor: "grey",
        title: "Task skipped during workflow task execution",
        icon: "done",
        class:""
    } , {
        bgColor: "green",
        title: "Task completed",
        icon: "done",
        class:"achievementStatusConfirmed"
    }
]
 
class LegendComponent extends Component {
    componentDidMount() {
        document.addEventListener('nativeBackClick', this.handleNativeBack);
    }

    handleNativeBack = () => {
        console.log("native back clicked for Legends popup ........");
        this.props.handleClose()
    }

    componentWillUnmount() {
        document.removeEventListener('nativeBackClick', this.handleNativeBack);
    }

   
    render() {
        const {showLegend, isWorkflowLegend} = this.props
        const legends = isWorkflowLegend ? workflowLegends : legendList
        return (
            <Dialog 
                fullScreen={false}
                open={showLegend}
                onClose={() => this.props.handleClose()}
                fullWidth={true}
                maxWidth="xl"
                aria-labelledby="responsive-dialog-title"
                className={` setcurrent-status-dialog-popup legends-info-pupup  ${this.props.popupThemeClass}`}
                PaperProps={ 
                        { style: { maxWidth: "450px", borderRadius: 8, margin: 'auto' } }
                    }
                id='activity-history-legends-popup'
                >
                <DialogTitle>
                    <div className="  d-flex align-items-center py-2 pl-3 justify-content-between border-bottom bg-lifesherpa">
                        <h2 className="m-0">Legend Icons</h2>
                        <IconButton onClick={() => this.props.handleClose()}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <ul className="list-unstyled dropdown-list mb-0 lifesherpa-session-theme-support">
                        {legends.map((legend,index) => (
                            <li key={index} className="border-top border-bottom m-0 list-item">
                                <div className='d-flex align-items-center'>
                                    <Avatar 
                                        width="40" 
                                        height="40" 
                                        className={"align-self-center " + legend.class} 
                                        // style={{backgroundColor: legend.bgColor}}
                                        >
                                        {legend.icon === 'done' ? 
                                            <DoneRoundedIcon/>
                                            : legend.icon === 'X' ?
                                                <ClearRoundedIcon />
                                            : legend.icon === 'more' ?
                                                <MoreHorizRoundedIcon />
                                            : legend.icon === 'alarm' ?
                                                <AccessAlarmRoundedIcon />
                                            : legend.icon === 'paused' ?
                                                <PauseCircleOutlineIcon/>
                                            : legend.icon === "executing" ?
                                                <DonutLargeRoundedIcon/>
                                            : legend.icon === "error" ?
                                                <ErrorIcon />
                                            : legend.icon === "cancelled" ?
                                                <ClearRoundedIcon />
                                            : legend.icon
                                        }
                                    </Avatar>
                                    <div className='legend-title'>{legend.title}</div>
                                </div>
                            </li>
                            )
                            )
                        }
                    </ul>
                </DialogContent>
            </Dialog>
        );
    }
}
 
export default LegendComponent