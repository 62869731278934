import React, { Component } from 'react';
import SingleSelectionItem from './SingleSelectionItem'
import MultiSelectionItem from './MultiSelectionItem'
import TextAreaItem from './TextAreaItem'
import TextInpuItem from './TextInpuItem'
import TimerOptionItem from './TimerOptionItem'
import OverallEvaluation from './OverallEvaluation'
import SpacerItem from './SpacerItem'
// import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import DateTimeInputType from "./DateTimeInputType";
// import ImageInput from "./ImageInput"
// import FileInput from "./FileInput"

class ActivityForm extends Component {
    state = { 
        popoverTest: "Please provide values to all mandatory fields marked with *",
        showNameWarning: false,
        anchorEl: null,
        showError: false
    }

    componentDidMount() {
        const {pokeForm} = this.props;
    }

    handleEvaluationForm(e, obj, section) {
        console.log("obj -->", obj);
        this.props.handleEvaluationForm(e, obj, section);
    }
    
    handleUp(obj, sectionObj){
        this.props.handleThumbUp(obj, sectionObj);
    }

    handleDateAndTimeSection(obj, sectionObj){
        this.props.handleDateAndTimeSection(obj, sectionObj);
    }

    handleDown(obj, sectionObj){
        this.props.handleThumDown(obj, sectionObj);
    }

    handlePlus(obj, section, sectionObj) {
        this.props.handlePlus(obj, section, sectionObj);
    }

    handleMinus(obj, section, sectionObj) {
        this.props.handleMinus(obj, section, sectionObj);
    }

    handleSaveAndContinue = (e) => {
        const {evaluationData, executeActivity, pokeForm } = this.props;
        let isAnySectionMandatory = false;
        pokeForm && pokeForm.sections && Object.keys(pokeForm.sections).map((obj, key) => { 
            let sectionObj = pokeForm.sections[obj];
            if(sectionObj.isMandatory) {
                isAnySectionMandatory = true;
            }
        })
        if(executeActivity && isAnySectionMandatory) {
            if(evaluationData) {
                //console.log("evaluationData --> ", evaluationData.sections);
                let sectionIndex = evaluationData && evaluationData.sections && Object.keys(evaluationData.sections).findIndex((obj, key) => { 
                    let sectionObj = evaluationData.sections[obj];
                    let isInvalid = false;
                    if(sectionObj.isMandatory) {
                        if((sectionObj.type == 'text') && (!sectionObj.text)) {
                            isInvalid = true;
                        } else if(sectionObj.type == 'longText' && (!sectionObj.text)) {
                            isInvalid = true
                        } else if(sectionObj.type == 'checklist' && sectionObj.itemsList && sectionObj.itemsList.findIndex(item => item.value) == -1) {
                            isInvalid = true
                        } else if(sectionObj.type == 'number' && (sectionObj.value === undefined || sectionObj.value === null)) {
                            // isInvalid = true
                            sectionObj.value = sectionObj.minValue
                        } else if(sectionObj.type == 'thumbs' && (!sectionObj.value)) {
                            isInvalid = true
                        } else if(sectionObj.type == 'date' && (!sectionObj.date)) {
                            isInvalid = true
                        }/* else if(sectionObj.type == 'file' && (!sectionObj.file)) {
                            isInvalid = true
                        } else if(sectionObj.type == 'image' && (!sectionObj.image)) {
                            isInvalid = true
                        }*/
                    }
                    return isInvalid;
                  })
                  if(sectionIndex == -1) {
                    console.log(" form completed ")
                    this.props.handleSaveAndContinue();
                  } else {
                    this.setState({showNameWarning:true, anchorEl: e.currentTarget, showError: true });
                  }
            } else {
                this.setState({showNameWarning: true, anchorEl: e.currentTarget, showError: true});
            }
        } else {
            this.props.handleSaveAndContinue()
        }
    }

    handleFormValidation = () => {
        
    }

    handleImageInputSection(obj, sectionObj){
        this.props.handleImageInputSection(obj, sectionObj)
    }

    render() { 
        let {image , name ,title, pokeForm, evaluationData, executeActivity, notice} = this.props;
        let {showNameWarning, anchorEl, showError} = this.state;
        if(pokeForm && pokeForm.formName) {
            title = pokeForm.formName
        }
        if(evaluationData) {
            pokeForm = evaluationData
        }
        return (
            <div id="dialog-content" className="mb-4 activity-preview-form-container" data-testid="activity-form-component">  
                <div className="d-flex align-items-center border-top border-bottom w-100 py-2 pl-2 theme-header-background-style">
                    <div className="media-left position-relative mr-10">
                        <img data-testid="activity-form-client" src={image || require('Assets/avatars/user-15.jpg')} className=" rounded-circle" alt="user profile" width="40" height="40" />
                    </div>
                    <div className="media-body w-80">
                        <h2 data-testid="activity-form-clinet-name" className="mb-0">{name || "FirstName LastName"}</h2>
                        <h4 data-testid="activity-form-title" className="mb-0">{title ? title : 'Routine'}</h4>
                    </div> 
                    {pokeForm && pokeForm.helpURL &&
                        <IconButton title='HelpUrl' className="ml-auto mr-2" onClick={() => this.props.handleOpenPokeFormHelpURL(pokeForm.helpURL)}>
                            <HelpOutlineIcon className="helpUrl-icon"/>
                        </IconButton>
                    }
                </div>
                {notice && notice.description ? <div data-testid="notice-description" className='form-description'>{ notice.description}</div> : <></>}
                <div className="form-body-background pb-0 pt-0">
                  { pokeForm && pokeForm.sections && Object.keys(pokeForm.sections).sort((a, b) => pokeForm.sections[a].position - pokeForm.sections[b].position).map((obj, key) => (
                      pokeForm.sections[obj].type === 'checklist' && !pokeForm.sections[obj].isMultiValue ? <SingleSelectionItem key={key+"singleSelectionItem"} sectionObj={obj} section={key} showError={showError} updateform={this.handleEvaluationForm.bind(this)} obj={pokeForm.sections[obj]} />
                      : pokeForm.sections[obj].type === 'checklist' ? <MultiSelectionItem sectionObj={obj} obj={pokeForm.sections[obj]} key={key+"multiSelectionItem"} section={key} showError={showError} updateform={this.handleEvaluationForm.bind(this)} />
                      : pokeForm.sections[obj].type === 'number' ? <TimerOptionItem obj={pokeForm.sections[obj]} key={key+"timerOptionItem"} section={key} sectionObj={obj} showError={showError} handleMinus={this.handleMinus.bind(this)} handlePlus={this.handlePlus.bind(this)} labelValue={this.state.labelValue} updateform={this.handleEvaluationForm.bind(this)}/> 
                      : pokeForm.sections[obj].type === 'longText' ? <TextAreaItem obj={pokeForm.sections[obj]} key={key+"textAreaItem"} section={key} showError={showError} updateform={this.handleEvaluationForm.bind(this)}/>
                      : pokeForm.sections[obj].type === 'text' ? <TextInpuItem obj={pokeForm.sections[obj]} key={key+"textInputItem"} section={key} showError={showError} updateform={this.handleEvaluationForm.bind(this)}/>
                      : pokeForm.sections[obj].type === 'spacer' ? <SpacerItem obj={pokeForm.sections[obj]} key={key+"spacerItem"} section={key} showError={showError} updateform={this.handleEvaluationForm.bind(this)}/>
                      : pokeForm.sections[obj].type === 'date' ? <DateTimeInputType obj={pokeForm.sections[obj]} key={key+"spacerItem"} section={key} showError={showError} sectionObj={obj} handleDateAndTimeSection={this.handleDateAndTimeSection.bind(this)}/>
                      /*: pokeForm.sections[obj].type === 'image' ? <ImageInput executeActivityMode={executeActivity} username={this.props.name} obj={pokeForm.sections[obj]} key={key+"spacerItem"} section={key} showError={showError} sectionObj={obj} handleImageSection={this.handleImageInputSection.bind(this)}/>
                      : pokeForm.sections[obj].type === 'file' ? <FileInput executeActivityMode={executeActivity} username={this.props.name} obj={pokeForm.sections[obj]} key={key+"spacerItem"} section={key} showError={showError} sectionObj={obj} handleImageSection={this.handleImageInputSection.bind(this)}/>*/
                      : <OverallEvaluation obj={pokeForm.sections[obj]} sectionObj={obj} selectedThumb={this.state.selectedThumb} handleUp={this.handleUp.bind(this)} key={key+"overevaluationItem"} section={key} showError={showError} handleDown={this.handleDown.bind(this)}/>
                  ))}
                  {/* <DateTimeInputType/> */}
                </div>
                <Popover
                    open={showNameWarning}
                    onClose={() => this.setState({showNameWarning : false})}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                    }}
                >
                    <Typography className="p-3 base-font-color">{this.state.popoverTest}</Typography>
                </Popover>
                <div className="d-flex align-items-center justify-content-center border-top mt-1 pt-1 mb-4">
                    <Button color="primary" variant="contained" data-testid="submit-activity-form" className="h-25 primary-button" size="small" disabled={this.props.disabled} onClick={(e) => this.handleSaveAndContinue(e)}>
                    {'Submit'}
                     </Button>
                </div>

            </div>
        );
    }
}


export default ActivityForm;