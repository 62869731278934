import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    handleUserWorkflowPanel, 
    getUserPendingWorkflowList,
    executeActivityWithAchievementId,
	setActivityType,
    selectActivityId,
    handlEditor,
    setSelectedMemberId,
    setClientActivitiesType,
    changeRole,
    setActivityReminder,
    showStartActivityPanel
} from "../../../actions";
import UserWorkflowList from './UserWorkflowList';
import UserPendingTasksList from './UserPendingTasksList';

import UserWorkflowPendingTasks from './UserWorkflowPendingTasks';
import moment from 'moment';
import { Avatar, DialogActions, DialogContent, Dialog } from '@mui/material';
import Button from '@mui/material/Button';

class UserWorkflow extends Component {
    state = {
        selectedUserWorkflow: null,
        notifyClient: false,
        selectedTask: "",
        pendingTask: null
    };

    componentDidMount() {
        const {selectedUserId, selectedOrgId} = this.props
        const userId = selectedUserId || localStorage.getItem("uid")
        const orgId = selectedOrgId || localStorage.getItem("selectedOrgId") || localStorage.getItem("orgId")
        this.props.getUserPendingWorkflowList({userId, orgId});
        document.addEventListener('nativeBackClick', this.handleNativeBack);
    }
    componentWillUnmount() {
		document.removeEventListener('nativeBackClick', this.handleNativeBack);
	}

    handleNativeBack = () => {
        this.handleBackbutton();
	}

    handleUserWorkflowSelect = (selectedUserWorkflow) => {
        this.setState({ selectedUserWorkflow });
    };

    handleBackbutton = () => {
        const {selectedUserWorkflow} = this.state;
        if(selectedUserWorkflow) {
            this.setState({ selectedUserWorkflow: null });
        } else {
            const userId = localStorage.getItem("uid")
            const auth_uid = localStorage.getItem("auth_uid")
            let orgId = localStorage.getItem("selectedOrgId")
            if (auth_uid === userId) {
                orgId =  localStorage.getItem("orgId")
                localStorage.setItem("selectedOrgId", orgId)
            } 
            this.props.getUserPendingWorkflowList({userId, orgId});
            document.removeEventListener('nativeBackClick', this.handleNativeBack);
            this.props.handleUserWorkflowPanel()
        }
    };

    /* Execute Workflow Task*/
    handleExecuteActivity = (activity, selectedUserWorkflow) => {
        // const {selectedUserWorkflow} = this.state;
        const {selectedUserId} = this.props;
        if (selectedUserWorkflow && selectedUserWorkflow.organization) {
            localStorage.setItem("selectedOrgId", selectedUserWorkflow.organization)
        }
        const {activityAssignedTo = {}, type} = activity.parameters
        let {userId, groupId} = activityAssignedTo;
        let activityId;
        if(type === "groupRoleAssignment") {
            userId = selectedUserId || localStorage.getItem("uid");
            const executionId = selectedUserWorkflow.id;
            const taskId = activity.taskId;
            activityId = `${groupId},${executionId},${taskId}`
        }
        let date = moment().format('YYYY-MM-DD HH:mm:ss Z');
        let auth_uid = localStorage.getItem('auth_uid');

        if(userId != auth_uid) {
            localStorage.setItem('uid',userId);
            this.props.changeRole({ role: 'client', id: userId })
            this.props.setSelectedMemberId(userId)
            this.props.setClientActivitiesType("Touchpoint");
        } else {
			localStorage.setItem('uid',userId);
            this.props.setSelectedMemberId(null);
            this.props.setClientActivitiesType('Normal')
            this.props.changeRole({ role: 'admin', id: userId })
		}
        
        this.setState({notifyClient: false}, () => {
            // this.props.executeActivityWithAchievementId(activity.achievementId, date, userId);
            // this.props.selectActivityId(activity.achievementId);
            // this.props.handlEditor(true);
            if (activityId) {
                this.props.showStartActivityPanel({action:'execute', organization: selectedUserWorkflow.organization, activityId});
            } else {
                this.props.showStartActivityPanel({action:'execute', organization: selectedUserWorkflow.organization, achievementId: activity.achievementId});
            }

            this.props.handleUserWorkflowPanel();
        })
    }

    notifyClientForTask(pendingTask, selectedUserWorkflow) {
        this.setState({notifyClient: true,selectedTask: pendingTask.name, pendingTask, selectedUserWorkflow})
    }

    handleNo(){
        this.setState({notifyClient: false})
    }

    handleYes() {
        const {selectedUserWorkflow, pendingTask} = this.state;
        const {activityExecutedBy, organization, type} = pendingTask.parameters;
        const {selectedUserId} = this.props;
        let {role, userId, groupId} = activityExecutedBy || {};
        let activityId;
        if(type === "groupRoleAssignment") {
            userId = selectedUserId || localStorage.getItem("uid");
            const executionId = selectedUserWorkflow.id;
            const taskId = pendingTask.taskId;
            activityId = `${groupId},${executionId},${taskId}`
        }
        this.setState({notifyClient: false}, () => {
            if(activityId) {
                this.props.setActivityReminder(userId, activityId, organization, false);
            } else {
                this.props.setActivityReminder(userId, pendingTask.achievementId, organization, true);
            }
            
        })
    }


    render() {
        const { openUserWorkflow, clientName, popupThemeClass, userWorkflows, userWorkflowLoading, showProceedButton, userRole, organizations } = this.props;
        const { selectedUserWorkflow, notifyClient, selectedTask, pendingTask } = this.state;
        const {activityExecutedBy} = pendingTask && pendingTask.parameters ? pendingTask.parameters : {}
        const {role} = activityExecutedBy || {}

        return (
            <div>
                <Dialog
                    open={openUserWorkflow}
                    fullScreen={false}
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                    className={`${popupThemeClass} notices-list-panel start-workflow-popup`}
                    id="user-workflows-popup-screen"
                >
                    {selectedUserWorkflow && notifyClient ?
                        <DialogContent>
                            <Avatar className="align-self-center user-later-avatar mb-3">
                                <img src={require('../../../assets/img/help-logo.png')} height={40} width={40}/>
                            </Avatar>
                            <div style={{ fontFamily: "'Lato'", fontWeight: 400 }}>
                                {`Would you like to notify "${role && role.name || clientName}" to start "${selectedTask}"?`}</div>
                        </DialogContent>
                        : 
                        <UserPendingTasksList
                            handleCloseUseWorflow={this.props.handleUserWorkflowPanel}
                            handleUserWorkflowSelect={this.handleUserWorkflowSelect}
                            clientName={clientName}
                            userWorkflows={userWorkflows}
                            userWorkflowLoading={userWorkflowLoading}
                            getUserPendingWorkflowList={this.props.getUserPendingWorkflowList}
                            organizations={organizations}
                            showProceedButton={showProceedButton}
                            notifyClientForTask={this.notifyClientForTask.bind(this)}
                            startExecutingTask={this.handleExecuteActivity}
                            userRole={userRole}
                        />

                        
                        // selectedUserWorkflow ? 
                        // <UserWorkflowPendingTasks
                        //     handleBackbutton={this.handleBackbutton}
                        //     selectedUserWorkflow={selectedUserWorkflow}
                        //     showProceedButton={showProceedButton}
                        //     userRole={userRole}
                        //     startExecutingTask={this.handleExecuteActivity}
                        //     notifyClientForTask={this.notifyClientForTask.bind(this)}
                        // />
                        // : 
                        // <UserWorkflowList
                        //     handleCloseUseWorflow={this.props.handleUserWorkflowPanel}
                        //     handleUserWorkflowSelect={this.handleUserWorkflowSelect}
                        //     clientName={clientName}
                        //     userWorkflows={userWorkflows}
                        //     userWorkflowLoading={userWorkflowLoading}
                        //     getUserPendingWorkflowList={this.props.getUserPendingWorkflowList}
                        //     organizations={organizations}
                        // />
                    }
                    {notifyClient ? 
                        <DialogActions>
                            <div className='d-flex align-items-center justify-content-center'>
                                <Button color="primary" variant="contained" className="mr-2 bg-secondary" onClick={() => this.handleNo()}>NO</Button>
                                <Button color="primary" variant="contained" className="primary-button" onClick={() => this.handleYes()}>YES </Button>
                            </div>
                        </DialogActions>
                        : <></>
                    }
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = ({ settings, GroupList, workflowData, authUser, clientsList }) => {
    const { user_no_group, userRole } = authUser;
    const { popupThemeClass } = settings;
    const { groupMembersDetails } = GroupList;
    const { selectedUserId, userWorkflows, userWorkflowLoading, selectedOrgId} = workflowData;
    const selectedClient = groupMembersDetails && selectedUserId ? groupMembersDetails.filter(client => client.userId === selectedUserId)[0] || user_no_group : user_no_group;
    const clientName = selectedClient && (selectedClient.name || selectedClient.displayName || selectedClient.firstName) ? (selectedClient.name || selectedClient.displayName || selectedClient.firstName) : 'My';
    const showProceedButton = selectedUserId === localStorage.getItem("auth_uid") ? true : false
    const { organizations } = clientsList;

    return { popupThemeClass, clientName, userWorkflows, userWorkflowLoading, showProceedButton, selectedOrgId, selectedUserId, userRole, organizations };
}

export default connect(mapStateToProps, {
    handleUserWorkflowPanel,
    getUserPendingWorkflowList,
    executeActivityWithAchievementId,
	setActivityType,
    selectActivityId,
    handlEditor,
    setSelectedMemberId,
    setClientActivitiesType,
    changeRole,
    setActivityReminder,
    showStartActivityPanel
})(UserWorkflow);