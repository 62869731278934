/**
* Chat
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
// reactstrap components
import { Label } from 'reactstrap';
// Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";

import {
    handleShowClientDashboard,
    clearHistoryData,
    getClientUnconfirmedAchievementsList,
    showActivityHistoryDetail,
    handleNoticesPanel,
    addSelectedChatUser,
    setSelectedMemberId,
    changeRole,
    clientNetworkActivity,
    networkActivity,
    getUserInfo,
    handleUserWorkflowPanel,
    getUserPendingWorkflowList,
    showFullChatPanelForSelectedUser
} from '../../../actions';
import { scrollMaincontainerToTop, isMobile } from "../../../helpers/helpers";

// Material UI Icons
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded';
import MissedVideoCallRoundedIcon from '@mui/icons-material/MissedVideoCallRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import LifeSherpaLoading from '../../LifeSherpaLoading';

class ClientDashboard extends Component {

    state = {
        enableWorkflows: false
    };

    componentDidMount() {
        const {selectedUserId, widgetVersion, organizations } = this.props;
        const orgId = localStorage.getItem("selectedOrgId")
        if(!widgetVersion && !isMobile()) {
            let enableWorkflows = false;
            if(organizations) {
                const selectedOrg = organizations.find(org => org.id === orgId);
                enableWorkflows = (selectedOrg && selectedOrg.enableWorkflows)
    
            }
            if(enableWorkflows) {
                this.props.getUserPendingWorkflowList({userId: selectedUserId, orgId});
            }
            this.setState({enableWorkflows});
            
        }
        this.props.clientNetworkActivity(selectedUserId);
        document.addEventListener('nativeBackClick', this.handleNativeBack);
    }

    handleNativeBack = () => {
        console.log("native back clicked for client dashboard ........");
        this.handleCloseDashboard();
    }

    componentWillUnmount() {
        document.removeEventListener('nativeBackClick', this.handleNativeBack);
    }

    handleCloseDashboard = () => {
        const {widgetApp, clientPanel} = this.props;
        const {enableWorkflows} = this.state;
        const orgId = localStorage.getItem("orgId")
        const auth_uid = localStorage.getItem("auth_uid")
        if(widgetApp) {
            window.location.href = window.location.origin + "/success";
        } else {
            const {selectedUserId } = this.props;
            this.props.clientNetworkActivity(selectedUserId);
            this.props.networkActivity()
            this.props.handleShowClientDashboard(false, null)
            if (!clientPanel && enableWorkflows) {
                localStorage.setItem("selectedOrgId", orgId)
                this.props.getUserPendingWorkflowList({userId: auth_uid, orgId})
            }
        }
       
    }

    handleOpenUnconfirmAchievements = (user) => {
        this.props.clearHistoryData();
        const activityConfirmation = localStorage.getItem("activityConfirmation");
        if (activityConfirmation) {
            localStorage.removeItem("activityConfirmation");
        }
        localStorage.setItem('uid', user.userId);
        user.organization ? localStorage.setItem("selectedOrgId", user.organization) : console.log("Organisation data not found!")
        this.props.changeRole({ role: 'client', id: user.userId });
        this.props.setSelectedMemberId(user.userId);     
        this.props.getClientUnconfirmedAchievementsList(user.userId, null, !isMobile());
        scrollMaincontainerToTop()
        this.props.showActivityHistoryDetail(true);
        this.props.handleShowClientDashboard(false, null);
        localStorage.setItem('prevUrl', window.location.pathname);
        this.props.navigate("/app/lsdashboard/activities/history")
    }

    handleOpenNoticePanel = (user) => {
        this.props.handleNoticesPanel(true, user.userId)
        this.setState({ ...this.state, anchorEl: null });
        this.props.handleShowClientDashboard(false, null)
    }

    handleOpenWorkflowPanel = (user) => {
        this.props.handleUserWorkflowPanel(true, user.userId)
        this.props.handleShowClientDashboard(false, null)
    }

    handleOpenUserChatPanel = (user) => {
        const { contactList } = this.props;
        let chatUser = contactList ? contactList.find(contact => contact.userId == user.userId) : null;
        if(chatUser) {
            this.showChatPanelForSelectedUser(chatUser ? chatUser : user);
        } else {
            this.props.getUserInfo(user.userId).then(result => {
                const {displayName, firstName, lastName, profileImageURL } = result || {};
                const userDetails = {userId: user.userId, name: displayName || `${firstName || 'Client'} ${lastName || ''}`, profileImageURL: profileImageURL};
                this.showChatPanelForSelectedUser(userDetails);
            })
        }    
    }

    showChatPanelForSelectedUser = (chatUser) => {
        let { selectedChatUsers } = this.props;
        console.log(" Chat opening for user ->", chatUser);
        if(isMobile()) {
            this.props.showFullChatPanelForSelectedUser(chatUser);
            this.props.handleShowClientDashboard(false, null)
			localStorage.setItem('prevUrl', window.location.pathname);
			this.props.navigate("/app/lsdashboard/chat");
            return;
        }
        if (chatUser) {
            if (selectedChatUsers && selectedChatUsers.length > 0) {
                let index = selectedChatUsers.findIndex(user => user && user.userId == chatUser.userId);
                if (index >= 0) {
                    // selectedChatUsers.splice(index,1)
                    // this.props.addSelectedChatUser(selectedChatUsers);
                    // selectedChatUsers.push(chatUser);  
                } else {
                    selectedChatUsers.push(chatUser);
                }
            } else {
                let usersList = [];
                usersList.push(chatUser);
                selectedChatUsers = usersList;
            }

            console.log(" Chat opened ->", selectedChatUsers);
            this.props.addSelectedChatUser(selectedChatUsers);
            this.props.handleShowClientDashboard(false, null)        
        }
    }


    render() {
        const {clientNetworkActivities, clientNetworkActivitiesLoading, clientName, selectedUserId, notificationOpenLoading, userWorkflows, userWorkflowLoading, widgetVersion } = this.props;
        const {enableWorkflows} = this.state;
        let user = clientNetworkActivities || {userId: selectedUserId};
        return (
            <div>
                {
                    <Dialog
                        open={true}
                        aria-labelledby="responsive-dialog-title"
                        fullWidth={true}
                        maxWidth="xs"
                        fullScreen={isMobile()}
                        className={`${this.props.popupThemeClass} notices-list-panel`}
                    >
                        <DialogTitle>
                            <div className={`d-flex justify-content-between align-items-center border-bottom bg-lifesherpa pl-3 py-2`}>
                                <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">{clientName}</Label>
                                <IconButton className="text-white mr-2" onClick={() => this.handleCloseDashboard()}><CloseIcon /></IconButton>
                            </div>
                        </DialogTitle>
                        <DialogContent className="m-0 p-2 activity-details-height">
                            <LifeSherpaLoading loading={clientNetworkActivitiesLoading || notificationOpenLoading || userWorkflowLoading} />
                            <div className="list-card mb-2 justify-content-between align-items-center px-1 py-1 pb-2">
                                <div className="d-flex px-2 align-items-center justify-content-center">
                                    <h3 className="d-flex  mx-2 my-2 heading-text">Dashboard</h3>
                                </div>
                                
                                <hr className="mt-1 mb-2" />
                                <div className={`${(clientNetworkActivities && clientNetworkActivities.unreadClientAlerts) ? 'text-danger' : 'heading-text' } d-flex cps px-2 align-items-center justify-content-between`} onClick={() => this.handleOpenNoticePanel(user)}>
                                    <div className='d-flex align-items-center justify-content-center'><WarningRoundedIcon className='mr-2'/>Unread Client Alerts</div>
                                    <div>{(clientNetworkActivities && clientNetworkActivities.unreadClientAlerts) || 0}</div>
                                </div>
                                <hr className="my-2" />
                                <div className={`${(clientNetworkActivities && clientNetworkActivities.unreadMessages) ? 'text-danger' : 'heading-text' } d-flex heading-text cps px-2 align-items-center justify-content-between`} onClick={() => this.handleOpenUserChatPanel(user)}>
                                    <div className='d-flex align-items-center justify-content-center'><MarkUnreadChatAltRoundedIcon className='mr-2'/>Unread Text Messages</div>
                                    <div>{(clientNetworkActivities && clientNetworkActivities.unreadMessages) || 0}</div>
                                </div>
                                {/* <hr className="my-2" />
                                <div className="d-flex heading-text cps px-2 align-items-center justify-content-between">
                                    <div className='d-flex align-items-center justify-content-center'><MissedVideoCallRoundedIcon  className='mr-2'/>Missed Video Calls</div>
                                    <div>0</div>
                                </div> */}
                                <hr className="my-2" />
                                <div className={`${(clientNetworkActivities && clientNetworkActivities.unconfirmedAchievements) ? 'text-danger' : 'heading-text' } cps d-flex px-2 align-items-center justify-content-between`} onClick={() => this.handleOpenUnconfirmAchievements(user)}>
                                    <div className='d-flex align-items-center justify-content-center'><ListAltRoundedIcon className='mr-2'/>Unconfirmed Activities</div>
                                    <div data-testid="unconfirmed-activities-count">{(clientNetworkActivities && clientNetworkActivities.unconfirmedAchievements) || 0}</div>
                                </div>
                                {!widgetVersion && enableWorkflows ? 
                                <>
                                    <hr className="my-2" />
                                    <div className="text-danger cps d-flex px-2 align-items-center justify-content-between" onClick={() => this.handleOpenWorkflowPanel(user)}>
                                        <div className='d-flex align-items-center justify-content-center'><Diversity3Icon className='mr-2' />Tasks Awaiting Completion in Workflows</div>
                                        <div data-testid="user-pending-workflow-tasks-count">{userWorkflows ? userWorkflows.count : 0}</div>
                                    </div>
                                </>
                                : ""
                                }
                            </div>
                        </DialogContent>

                    </Dialog>


                }
            </div>
        );
    }
}

const mapStateToProps = ({ GroupList, settings, clientsList, authUser, chatAppReducer, Contacts, Notifications, workflowData}) => {
    const { popupThemeClass } = settings;
    const { selectedUserId, clientNetworkActivities, clientNetworkActivitiesLoading, clientPanel, organizations } = clientsList;
    const { selectedChatUsers } = chatAppReducer
    const { contactList } = Contacts;
    const { groupMembersDetails } = GroupList;
    const {notificationOpenLoading } = Notifications;
    const selectedClient = groupMembersDetails && selectedUserId ? groupMembersDetails.filter(client => client.userId === selectedUserId)[0] : null;
    let clientName = selectedClient && selectedClient.name ? selectedClient.name : 'Client';
    const {userWorkflows, userWorkflowLoading} = workflowData

    return {selectedUserId, clientName, popupThemeClass,  selectedChatUsers, contactList, clientNetworkActivities, clientNetworkActivitiesLoading, notificationOpenLoading, clientPanel, userWorkflows, userWorkflowLoading , organizations };
}

export default withRouter(connect(mapStateToProps, {
    handleShowClientDashboard,
    clearHistoryData,
    getClientUnconfirmedAchievementsList,
    showActivityHistoryDetail,
    handleNoticesPanel,
    addSelectedChatUser,
    setSelectedMemberId,
    changeRole,
    clientNetworkActivity,
    networkActivity,
    getUserInfo,
    handleUserWorkflowPanel,
    getUserPendingWorkflowList,
    showFullChatPanelForSelectedUser
})(ClientDashboard));

