import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
import {firebase} from '../../firebase';
import { NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { getToken, onMessage, getMessaging, isSupported } from "firebase/messaging";
import {delay} from "../../helpers/helpers";
import { 
  hideChatPanel, 
  selectMenuItem, 
  showFullChatPanelForSelectedUser,
  registerDeviceForNotification,
  showVideoCallConfirmation,
  showMissedCallConfirmation,
  displayPushNotiAlertPopup,
  showExecuteActivityConfirmation,
  hidePushNotiAlert,
  networkActivity,
  handleActivityCompletionConfirmation,
  showNotificationPopup,
  getUserInfo,
  hideVideoRoomPanel,
  hideVideoCallConfirmation,
  sendGroupVideoCallResponse,
  sendVideoCallResponse,
  getActivitiesList,
  getResourceList,
  getcategories,
  getKeywords,
  getClientActivitiesList,
  getHelpContactsWithChannel,
  getPendingRewards,
  addtoFavourite,
  confirmRewards,
  getClientDetailById,
  hideExecuteActivityConfirmation 
} from 'Actions';

import AppConfig from "../../constants/AppConfig"

import { Toast } from "@capacitor/toast";
import { LocalNotifications } from '@capacitor/local-notifications';

import notificationSound from "../../assets/audio/message-alert.mp3"
import NotificationAlert from './NotificationAlert';

import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import config from '../../config/config';
import { FileOpener } from '@capacitor-community/file-opener';
import { Device } from '@capacitor/device';

//Toast on mobile
const showToast = async (msg) => {
  await Toast.show({
    text: msg,
    position: "top",
    duration: "long"
  })
}

const vapidKey = "BNIrai_6Va8f7IH7uxmmzn9OO5F3ZMyAPm-e8mM5uX03CFUut6xSRUCAsyUADBKTYc7O0RS5Yy9mPptmyKQGa2I"

import {CallKitVoip} from "callkit-voip-capacitor-plugin"


const localNotification = async (notifications) => {
  await LocalNotifications.schedule({notifications: notifications,});
}

import { App } from '@capacitor/app';
import ProvideReward from './ProvideReward';

class NotificationsHandler extends Component {

  state = {
    permissionStatus:"",
    showEnableAlert: false,
    notifications: [],
    showforegroundNotification: false,
    notificationData: null,
    isAppActive: true,
    claimReward: false,
    selectedMemberId: "0I1aJIkeHPOdG4V896xnmvjrStq1" 
  }

  formatNotification = (notification) => {
    const formatedData = {
      title: notification.title,
      body: notification.body,
      id: notification.id,
      schedule: { at: new Date(Date.now() + 1000 * 1) },
      sound: notificationSound,
      attachments: null,
      actionTypeId: '',
      extra: null,
      smallIcon: AppConfig.lsIconLogo
    }
    return formatedData
  }

  handleCancel(){
    this.setState({showforegroundNotification: false})
  }

  handleConfirm(data){
    const notificationData  = data || this.state.notificationData
    if (notificationData.type === "9") {
      const {userId, organization} = notificationData
      this.setState({showforegroundNotification: false, claimReward: true, selectedMemberId: notificationData.userId}, () => {
        localStorage.setItem("uid", userId)
        localStorage.setItem("selectedOrgId", organization)
        this.props.getPendingRewards()
      })
    } else {
      this.setState({showforegroundNotification: false}, () => {
        if((notificationData.twi_body || notificationData.twi_message_id) && notificationData.conversation_title) {
          this.handleShowNotificationChat(notificationData.conversation_title);
        } else if(notificationData.title || notificationData.message) {
          if(notificationData.type === "16" && Capacitor.getPlatform() === 'android') {
            // CallKitVoip.show_call_notification(notificationData)
            this.handleOpenVideoCall(notificationData)
          } else if(notificationData.type === "16") {
            this.handleOpenVidoeRoom(notificationData);
          } else if(notificationData.type === "24") {
            this.handleShowMissedCallConfirmation(notificationData);
          } else if(notificationData.type === "14") {
            if(this.props.startActivityPanel || this.props.executeActivityNotification) {
              this.pushLocalNotification(notificationData)
            } else {
              this.props.showExecuteActivityConfirmation(notificationData);
            }
            
          } else if(notificationData.type === "7" && notificationData.needConfirmation === "true") {
            if(this.props.activityCompletionMessage) {
              this.pushLocalNotification(notificationData)
            } else {
              this.props.handleActivityCompletionConfirmation(notificationData);
            }
          } else if(notificationData.type === "15" || notificationData.type === "25") {
            this.props.showNotificationPopup(notificationData);
          } else if (notificationData.type === "26") {
            return
          } 
        }
      })
    }
  }

  //Check browser supports push notification
  checkBrowserSupportPushnotification(){
    isSupported().then(result => {
      if (result) {
        this.checkPermissionAndRegister();
        console.log(" messaging supported",)
      } else {
        this.props.displayPushNotiAlertPopup(true)
        console.log(" messaging not supported",)
      }  
    }).catch(error => {
      this.props.displayPushNotiAlertPopup(true)
      console.log(" messaging not supported",)
    })
  
  }

  //Mobile push notification registration
  checkPermissionAndRegister() { 
    FirebaseMessaging.checkPermissions().then((res) => {
      if (res.receive !== 'granted') {
        FirebaseMessaging.requestPermissions().then((res) => {
          if (res.receive === 'granted') {
            showToast('Push Notification permission granted');
            this.register();
            if (Capacitor.getPlatform() != 'web') { 
              this.registerVoipNotification()
            }
          } else {
            showToast('Push Notification permission denied');
          }
        });
      } else {
        this.register();
        if (Capacitor.getPlatform() != 'web') { 
          this.registerVoipNotification()
        }
      }
    })
  }

  async getToken() {
    const options = {
      vapidKey: vapidKey,
    };
    if (Capacitor.getPlatform() == "android") {
      const customToken = localStorage.getItem("customToken")
      const firebaseAuth = await FirebaseMessaging.authenticateWithCustomToken({ token: customToken })
      console.log(" Native SDK authentication with custom token", firebaseAuth)
    }

    const { token: fcmToken, apnsToken = null } = await FirebaseMessaging.getToken(options); 
    // this.token = fcmToken;
    // const currentToken = fcmToken
    if (Capacitor.getPlatform() == "ios") {
      localStorage.setItem("apnsToken",apnsToken);
    } else {
      localStorage.setItem("fcmToken",fcmToken);
    }
    this.registerDeviceForNotification(fcmToken);
    //console.log(" Message receiving enabled for foreground", currentToken)
  }

  register = async() => {
    console.log('Initializing HomePage');

    // Register with Apple / Google to receive push via APNS/FCM
    // await PushNotifications.register();
    await this.getToken()

    FirebaseMessaging.addListener("tokenReceived", (event) => {
      console.log("tokenReceived: ", { event });
      console.log("token", event.token)
      const currentToken = event.token
      localStorage.setItem("fcmToken",currentToken);
      this.registerDeviceForNotification(currentToken);
      //console.log(" Message receiving enabled for foreground")
    });

    FirebaseMessaging.addListener("notificationReceived", (event) => {
      console.log("notificationReceived: ", { event });
      const {notification} = event
      if(notification && notification.data && notification.data.local) {
        return;
      }
      if((notification.data.twi_body || notification.data.twi_message_id) && notification.data.conversation_title) {
        const title = notification.data.author || (notification.data.twi_body.replace(/\:/, '&').split('&')[1] || notification.body.replace(/\:/, '&').split('&')[1])
        const body = notification.data.twi_body ? notification.data.twi_body.replace(/\:/, '&').split('&')[1] : notification.body.replace(/\:/, '&').split('&')[1]
        let notifications = []
        const formatedNotification = this.formatNotification({title,  body, id: notifications.length})
        notifications.push(formatedNotification)
        if (Capacitor.getPlatform() === 'web') {
          this.handleTwiolioNotification(notification.data.conversation_title, notification.data.twi_body, notification.data.author);
        } else if(this.checkUserInActive(notification.data.conversation_title)) {
          if(this.state.showforegroundNotification) {
            const notificationData = notification.data;
            notificationData["title"] = title;
            notificationData["body"] = body;
            notificationData["message"] = body;
            this.pushLocalNotification(notificationData)
          } else {
            this.setState({showforegroundNotification: true, notifications, notificationData: notification.data, notificationTitle: title, notificationBody: body})
          } 
        }
      } else if(notification.data.title || notification.data.message) {
        console.log(" notification type on foreground message ----> ", notification.data.type);
        showToast(notification.data.message)
        if ((["14", "15", "16", "24", "25", "26" ].includes(notification.data.type)) || (notification.data.type === '7' && notification.data.needConfirmation === 'true') ){
          this.handleConfirm(notification.data)
        } else {
          const title = notification.data.title || "LifeSherpa Notification"
          const body = notification.data.message
          const type = notification.data.type
          if (type == "9" ) {
            const {userId, organization} = notification.data
            this.props.getClientDetailById(userId, organization)
          }
          if(this.state.showforegroundNotification) {
            const notificationData = notification.data;
            notificationData["title"] = title;
            notificationData["body"] = body;
            notificationData["message"] = body;
            this.pushLocalNotification(notificationData)
          } else {
            this.setState({showforegroundNotification: true, notificationData: notification.data, notificationTitle: title, notificationBody: body})
          } 
        }
      }
    });
    
    FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
      console.log("notificationActionPerformed: ", { event });
      const {notification} = event;
      if(notification && notification.data && notification.data.local) {
        return;
      }
      this.handleConfirm(notification.data)
    });

    
    LocalNotifications.addListener('localNotificationActionPerformed', async (notification) => {
      console.log("Local Notification Action Performed: ", notification);
      const {extra: notificationData = {}} = notification.notification || {};
      if (notificationData.filepath) {
        const {filepath, mimeType} = notificationData;
        console.log('File path from notification:', filepath);
        try {
            const fileOpenerOptions = {
                filePath: filepath,
                contentType: mimeType,
                openWithDefault: true,
            };
            await FileOpener.open(fileOpenerOptions);
        } catch (e) {
            console.log('Error opening file', e);
        }
      } else if(notificationData.type === "14") {
        if(this.props.startActivityPanel) {
          notificationData.local = false;
          this.pushLocalNotification(notificationData)
        } else {
          this.props.showExecuteActivityConfirmation(notificationData);
        }
      } else if(notificationData.type === "7" && notificationData.needConfirmation === "true") {
          this.props.handleActivityCompletionConfirmation(notificationData);
      } else if(notificationData.type === "24") {
        this.handleShowMissedCallConfirmation(notificationData);
      } else if((notificationData.twi_body || notificationData.twi_message_id) && notificationData.conversation_title) {
        this.setState({showforegroundNotification: true, notificationData, notificationTitle: notificationData["title"], notificationBody: notificationData["body"]})
      } else if(notificationData.title && notificationData.type) {
        this.setState({showforegroundNotification: true, notificationData, notificationTitle: notificationData["title"], notificationBody: notificationData["body"]})
      } else {
        this.handleConfirm()
      }
    })
    
  } 

async pushLocalNotification(notification) {
  try {
    console.log("pushLocalNotification :", notification)
    const {title, message = "", type = "", local = false} = notification;
    if(local) {
      return;
    }
    notification["local"] = true;
    if(title) {
      const pushKey = Math.floor(Math.random() * (9999 - 1000 +1)) + 1000;
      await LocalNotifications.schedule({
        notifications: [
          {
            title,
            body: message,
            id: pushKey,
            schedule: { at: new Date(Date.now() + 1000) }, // Schedule for immediate display
            sound: null,
            attachments: null,
            actionTypeId: type,
            extra: notification, // Include notification data
          },
        ],
      });
    }
  } catch(error) {
      console.log("Failed to push local notification :", error)
  }

}

async registerVoipNotification() {
  
  // init plugin, start registration of VOIP notifications 
  // await CallKitVoip.register(); // can be used with `.then()`
  CallKitVoip.register().then((result) => {
    console.log("Push notification has been registered")
    
    // register token 
    CallKitVoip.addListener("registration", (token) => {
      console.log(`VOIP token has been received ${token.value}`)
      localStorage.setItem("voipToken", token.value)
    })

    // start call
    CallKitVoip.addListener("callAnswered", (callData) => {
      const {username, connectionId} = callData
      console.log(`Call has been received from ${username} (connectionId: ${connectionId})`);
      this.handleOpenVideoCall(callData)
      // this.handleOpenVidoeRoom(callData)

    })

    // End call
    CallKitVoip.addListener("callEnded", (callData) => {
      const {username, connectionId} = callData
      console.log(`Call has been received from ${username} (connectionId: ${connectionId})`);
      this.handleCloseVideoCall(callData);
      
    })

    if (Capacitor.getPlatform() == "ios") {
      const customToken = localStorage.getItem("customToken")
      CallKitVoip.authenticateWithCustomToken({ token: customToken }).then((authUser) => {
        console.log(`iOS firebase Authenticated ${authUser}`)
      }).catch(error => {
        console.log("Failed iOS firebase Authentication: ", error)
     })
    }

 }).catch(error => {
    console.log("Failed voip registration: ", error)
 })
  
}

  handleOpenVideoCall = (videoCallData) => {
    
    let {showVideoRoom, showVideoCallConfirmationPanel} = this.props;
    if(videoCallData && videoCallData.roomname && !showVideoRoom && !showVideoCallConfirmationPanel) {
        const {roomname, group, organization} = videoCallData;
        const orgId = organization || localStorage.getItem('orgId');
        localStorage.setItem('selectedOrgId', orgId);
        this.handleResponseSend("accepted", orgId, group, roomname);
        // let openRoom = {name:videoCallMessage.roomname};
        // this.props.showVideoRoomPanel(openRoom);
      let openVideoCallRoom = `${config.lsPortalUrl}/lifesherpa/videocall?room=${roomname}`;
      // window.open(openVideoCallRoom,"_blank");
      this.props.hideVideoCallConfirmation(openVideoCallRoom, this.props.navigate);
    } 
  }

  handleCloseVideoCall = (videoCallData) => {
    if(videoCallData && videoCallData.roomname) { 
        const {roomname, group, organization} = videoCallData;
        const orgId = organization || localStorage.getItem('orgId');
        this.handleResponseSend("declined", orgId, group, roomname);
    }
  }

  handleResponseSend = (action, orgId, group, roomname) => {
    if(group == "1") {
        this.props.sendGroupVideoCallResponse(roomname, action, null, orgId);
    } else {
        this.props.sendVideoCallResponse(roomname, action, orgId);
    }
  }


  //Component Life Cycle Method
  componentDidMount() {
    this.addPostMessageCall()
    if (Capacitor.getPlatform() === 'web') {
      this.checkBrowserSupportPushnotification();
    } else {
      this.checkPermissionAndRegister();
      App.addListener('appStateChange', ({ isActive }) => {
        console.log('App state changed. Is active?', isActive);
        this.setState({isAppActive: isActive})
      });
    }
    document.addEventListener('nativeBackClick', this.handleNativeBack.bind(this));
  }

  componentWillUnmount(){
    document.removeEventListener('nativeBackClick', this.handleClose.bind(this));
  }

  handleNativeBack() {
    this.setState({claimReward: false, showforegroundNotification: false}, () => {
      this.props.hideExecuteActivityConfirmation()
    })
  }

  handleNotificationPermission = () => {
    let permissionStatus = "";
    if("Notification" in window) { 
      permissionStatus = Notification.permission;
      console.log(" permission status --> ", permissionStatus);
      if(permissionStatus === "default") {
        setTimeout(() => {
          this.setState({permissionStatus : permissionStatus, showEnableAlert: true});
        }, 4000)
      } else {
        this.getFirebaseMessagingToken();
        this.setState({permissionStatus : permissionStatus});
      }
    } else {
      this.getFirebaseMessagingToken()
    }
  }

  getFirebaseMessagingToken = () => {
    try {
      const messaging = getMessaging(firebase);
      this.turnOnNotification(messaging).then((currentToken) => {
        if(currentToken) {
            localStorage.setItem("fcmToken",currentToken);
            this.registerDeviceForNotification(currentToken);
            if (this.state.permissionStatus === "default") {
              NotificationManager.success('Notification permission enabled!')
            }
            // console.log(" Message receiving enabled for foreground")
            onMessage(messaging, (message) => {
              let notification = message && message.data ? message.data : {};
              console.log(" Message received -->",message, "notification -->", notification);
              if(notification.twi_body && notification.conversation_title /*&& auth.currentUser*/) {
                this.handleTwiolioNotification(notification.conversation_title, notification.twi_body, notification.author);
              } else if(notification.title || notification.message /*&& auth.currentUser*/) {
                console.log(" notification type on foreground message ----> ", notification.type);
                this.handleLifeSherpaNotificationAlertForeground(notification, true);
              }
            });
        } else {
          console.log('No registration token available. Request permission to generate one.');
          this.registerDeviceForNotification();
          // this.props.getHelpContactsWithChannel();
          // const errorMsg = "Need notification permission!";
          // NotificationManager.error(errorMsg);
        }
      }).catch((error) => {
        const errorMsg = "Need notification permission!";
        NotificationManager.error(errorMsg);
        console.log('An error occurred while retrieving token. ', error); 
        this.registerDeviceForNotification();
      }) 
    } catch (err) {
        console.error('Failed to initialize Firebase Messaging', err);
    }
    
  }

  turnOnNotification = async (messaging) => {
    const maxRetry = 3;
    for (let attempt = 1; attempt <= maxRetry; attempt++) {
      try {
        // Generate a new token
        const token = await getToken(messaging);
        console.log('Generated token for client:', token);
        return token;
      } catch (err) {
        if (attempt === maxRetry) {
          console.error("Max retries reached. Unable to generate token:", err);
        } else if ((err.code == "messaging/token-unsubscribe-failed") || (err.message.includes("Failed to execute 'subscribe' on 'PushManager'") || (err.message == `Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker`))) {
          console.warn("Retrying turnOnNotification due to subscription error:", attempt, err);
          await delay(2000); // Pause before retrying
        } else {
          console.error('turnOnNotification failed:', err);
          return;
        }
      }
    }
  };
    
  handleConfirmNotificationAlert = () => {
    this.getFirebaseMessagingToken();
    this.setState({ showEnableAlert: false});
  }

  handleCloseNotificationAlert = () => {
    this.setState({ showEnableAlert: false});
    this.registerDeviceForNotification();
  }

  handleTwiolioNotification = (channelTitle, body, author ) => {
    this.props.networkActivity();
    if(this.checkUserInActive(channelTitle)) {
      let notificationTitle = author || body.replace(/\:/, '&').split('&')[1];
      let notificationOptions = {
        body: body.replace(/\:/, '&').split('&')[1],
        icon: AppConfig.lsIconLogo,
      };
      var notification = new Notification(notificationTitle, notificationOptions);
      let self = this;
      notification.onclick = function (event) {
        // event.preventDefault(); // prevent the browser from focusing the Notification's tab
        console.log(" Foreground notification clicked ---->");
        self.handleShowNotificationChat(channelTitle);
        notification.close();
      }
      // setTimeout(() => {
      //   notification.close();
      // }, 10000)
    }
  }

  handleShowNotificationChat = (channelTitle) => {
    console.log( " OnScreen notification selected -->")
    let {contactList , openChatPanel} = this.props;
    let notificationUser = contactList.find(user => user.conversation && user.conversation.channelState ? user.conversation.channelState.uniqueName  == channelTitle : channelTitle.includes(user.userId));
    console.log(" User who received notification --> ", notificationUser);
   
    if (notificationUser) {
      const selectedOrgId = (notificationUser.assignedOrganizations && Object.keys(notificationUser.assignedOrganizations)[0]);
      if(selectedOrgId) {
          localStorage.setItem('selectedOrgId', selectedOrgId);
      }
      this.props.showFullChatPanelForSelectedUser(notificationUser);
      this.props.selectMenuItem(0);
      localStorage.setItem('prevUrl', window.location.pathname);
      this.props.navigate("/app/lsdashboard/chat");
    } else {
      const auth_uid = localStorage.getItem("auth_uid");
      const userId = channelTitle.split("-").find(uid => uid !== auth_uid);
      this.props.getUserInfo(userId).then(result => {
        const {displayName, firstName, lastName, profileImageURL } = result;
        const tempContactUser = {userId, name: displayName || `${firstName || 'Client'} ${lastName || ''}`, profileImageURL: profileImageURL};
        this.props.showFullChatPanelForSelectedUser(tempContactUser);
        this.props.selectMenuItem(0);
        localStorage.setItem('prevUrl', window.location.pathname);
        this.props.navigate("/app/lsdashboard/chat");
      })
    }
  }

  checkUserInActive = (channelTitle) => {
    let {selectedChatUsers, selectedUser} = this.props;
    console.log(" selectedChatUsers List --> ", selectedChatUsers);
    console.log(" selectedUser --> ", selectedUser)
    let index = selectedChatUsers && selectedChatUsers.length > 0 ? selectedChatUsers.findIndex(user => channelTitle.includes(user.userId)) : -1
    if(index >= 0) {
        console.log(" User chat active in minipanels....")
      return false;
    } else if(selectedUser && channelTitle.includes(selectedUser.userId)) {
      console.log(" User chat active ...");
      return false;
    } else {
      console.log(" User chat Inactive ...");
      return true;
    }
  }

  addPostMessageCall = () => {
    // check background notification data
    this.backgroundNotificationDataHandler();
    // adding listener
    window.addEventListener("message",  (event) => {
      if(event.data === "Background Notification") {
        console.log("Notification post message received ...");   
        this.backgroundNotificationDataHandler();
      }    
    });

  }

  backgroundNotificationDataHandler = () => {
    const backgroundNotificationData = localStorage.getItem("backgroundNotificationData");
    if(backgroundNotificationData) {
      localStorage.removeItem("backgroundNotificationData");
      const notificationData = JSON.parse(backgroundNotificationData) || {};
      console.log("backgroundNotificationData :", notificationData);
      if(notificationData.type == "twilioChat") {
        this.props.networkActivity();
        if(notificationData.channelTitle) {
            this.handleShowNotificationChat(notificationData.channelTitle)
        }
      } else if(notificationData.title || notificationData.message) {
        console.log(" notification type on post message ----> ", notificationData.type);
        this.handleLifeSherpaNotificationAlertForeground(notificationData) 
      }
    }
  }

  handleLifeSherpaNotificationAlertForeground = (notificationData, foreground) => {
    if(notificationData && notificationData.local) {
      return;
    }
   if(notificationData.type === "16" && Capacitor.getPlatform() === 'web') {
      this.handleOpenVidoeRoom(notificationData);
    } else if(notificationData.type === "24") {
      this.handleShowMissedCallConfirmation(notificationData);
    } else if(notificationData.type === "14") {
      if(this.props.startActivityPanel || this.props.executeActivityNotification) {
        this.pushLocalNotification(notificationData)
      } else {
        const auth_uid = localStorage.getItem("auth_uid")
        const userId = localStorage.getItem('uid');
        const orgId = localStorage.getItem("selectedOrgId")
        const {clientActivitiesType, selectedMember} = this.props
        const {groupId} = selectedMember || {};
        const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null;
        this.props.showExecuteActivityConfirmation(notificationData);
        if (activityType && auth_uid != userId) {
          this.props.getClientActivitiesList({userId, orgId, groupId, filter:'', activityType});
        } else {
          this.props.getActivitiesList(auth_uid, "");
        }
      }

    } else if(notificationData.type === "7" && notificationData.needConfirmation === "true") {
      if(this.props.activityCompletionMessage) {
        this.pushLocalNotification(notificationData)
      } else {
        this.props.handleActivityCompletionConfirmation(notificationData);
      }
      
    } else if(notificationData.type === "15") {
      this.props.showNotificationPopup(notificationData);
      this.props.getResourceList('','new', '')
      this.props.getcategories()
      this.props.getKeywords()
    } else if(notificationData.type === "25") {
      this.props.showNotificationPopup(notificationData);
    } else if (notificationData.type === "26") {
      return
    }

    this.handleLifeSherpaForegroundNotification(notificationData, foreground); 

  }

  handleLifeSherpaForegroundNotification = (notificationData, foreground) => {
    if(foreground) {
      let notificationTitle = notificationData.title || "LifeSherpa Notification";
      let notificationOptions = {
        body: notificationData.message || "",
        icon: AppConfig.lsIconLogo,
      };
      var notification = new Notification(notificationTitle, notificationOptions);
      let self = this;
      notification.onclick = function (event) {
        // event.preventDefault(); // prevent the browser from focusing the Notification's tab
        self.handleLifeSherpaNotificationClicked(notificationData)
        notification.close();
      }
      setTimeout(() => {
        notification.close();
      }, 10000)
    }
  }

  handleLifeSherpaNotificationClicked = (notificationData) => { 
    console.log(" notification type ----> ", notificationData.type)
    // switch(notificationData.type) {
    //   case "14":  // Start activity notification
    //   this.startActivityOnNotificationClicked(notificationData);
    //   break;
    // }
  }

  handleShowMissedCallConfirmation = (notificationData) => {
    if(notificationData && notificationData.group == "0") {
      if(this.props.showMissedCallConfirmationPanel) {
        this.pushLocalNotification(notificationData)
      } else {
        if(Capacitor.getPlatform() === 'android') {
          CallKitVoip.abortCall()
        }
        this.props.showMissedCallConfirmation(notificationData);
      }
    } else {
      this.handleLifeSherpaForegroundNotification(notificationData);
    }   
  }

  handleOpenVidoeRoom = (notificationData) => {
    let {showVideoRoom, showVideoCallConfirmationPanel} = this.props;
    if(!showVideoRoom && !showVideoCallConfirmationPanel) {
      this.props.showVideoCallConfirmation(notificationData)
    }
  }

  startActivityOnNotificationClicked = (notificationData) => {
    // console.log(" achievementId --> ", notificationData.achievementId);
    // let date=Moment().format('YYYY-MM-DD HH:mm:ss Z');
    // let uid=localStorage.getItem('auth_uid');
    // this.props.executeActivityWithAchievementId(notificationData.achievementId,date,uid);
    // this.props.selectActivityId(notificationData.achievementId);
    // this.props.handlEditor(true);  
  }

  registerDeviceForNotification = async(fcmToken) => {
    const deviceInfo = await Device.getInfo();
    let payloadData = null
    const voipDeviceId = localStorage.getItem("voipToken");
    if (Capacitor.getPlatform() === 'ios') {
      payloadData = {
        appBuildNumber: process.env.BUILD,
        appVersion: process.env.APP_VERSION,
        deviceId:fcmToken,
        deviceType:`iOS ${deviceInfo.osVersion}`,
        OS:`iOS ${deviceInfo.osVersion}`,
        appName: "ls-portal-ios",
        voipDeviceType: "debug",
        voipDeviceId 
      }
    } else if (Capacitor.getPlatform() === 'android') {
      payloadData = {
        appBuildNumber: process.env.BUILD,
        appVersion: process.env.APP_VERSION,
        deviceId:fcmToken,
        deviceType:`Android ${deviceInfo.osVersion}`,
        OS:`Android ${deviceInfo.osVersion}`,
        appName: "ls-portal-android" 
      }
    } else {
      payloadData = {
        appBuildNumber: process.env.BUILD,
        appVersion: process.env.APP_VERSION,
        deviceId:fcmToken,
        deviceType:`${deviceInfo.operatingSystem.toUpperCase()} ${deviceInfo.osVersion}`,
        OS:`Web ${deviceInfo.osVersion}`,
        appName: "ls-portal-web" 
      }
    }
    this.props.registerDeviceForNotification(payloadData);
    this.props.getHelpContactsWithChannel();
  }

  browserDetection() {
    var ua= navigator.userAgent, tem, 
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }

  handleReload() {
    this.setState({reload: false})
    window.location.reload()
  }

  handleClose(){
    this.setState({reload: false})
  }

  handleCloseProvideReward() {
    const auth_uid = localStorage.getItem("auth_uid")
    const orgId = localStorage.getItem("orgId")
    this.setState({claimReward: false, selectedMemberId: null}, () => {
      localStorage.setItem("uid", auth_uid)
      localStorage.setItem("selectedOrgId", orgId)
    })
  }


  render() {
    const {showEnableAlert, permissionStatus, showforegroundNotification, notificationTitle, notificationBody, claimReward, selectedMemberId} = this.state;
    const { children, contactList, loading, chatToken, twilioClient, displayPushNotiAlert, displayOnce, screenThemeClass, pendingReward, rewardLoading, userCredit, clientImage, clientName, popupThemeClass } = this.props;
    const browser = this.browserDetection()
    const warningMessage = "" //this.getAlertMessage(browser)

    return (
        <React.Fragment>
            {children}
            <div className={screenThemeClass}>
            { displayPushNotiAlert && !displayOnce ?
              <SweetAlert
                warning
                btnSize="sm"
                customClass="warningText"
                confirmBtnText="OK"
                confirmBtnBsStyle="warning"
                title="Push Notification Alert!"
                onConfirm={() => this.props.hidePushNotiAlert(false)}
                >
                <div className="sweet-alert-text">{warningMessage}</div>
                </SweetAlert> : ""
            }
            {permissionStatus === 'default' && showEnableAlert ?
              <SweetAlert
                custom
                btnSize="sm"
                closeOnClickOutside={false}
                customClass="warningText"
                confirmBtnText="Yes"
                cancelBtnText="No"
                confirmBtnBsStyle="warning"
                cancelBtnBsStyle="default"
                title="Would you like to receive notifications from LifeSherpa?"
                showCancel
                onConfirm={() => this.handleConfirmNotificationAlert()}
                customIcon = {<div className='text-center'><NotificationsIcon style={{fontSize: '5.1875rem'}}/></div>}
                onCancel={() => this.handleCloseNotificationAlert()}
                >
                  <div className="sweet-alert-text">This will enable notifications in the browser.</div>
              </SweetAlert>
            : <></>
          }
          </div>
          {showforegroundNotification ?
            <NotificationAlert 
              popupThemeClass={popupThemeClass} 
              open={showforegroundNotification}
              handleCancel={this.handleCancel.bind(this)}
              handleConfirm={this.handleConfirm.bind(this)}
              title={notificationTitle}
              body={notificationBody}
              notificationData={this.state.notificationData}
            /> :
            <></>
          }
          {claimReward && 
           <ProvideReward
              pendingReward={pendingReward}
              rewardLoading={rewardLoading}
              clientImage={clientImage}
              clientName={clientName}
              selectedMemberId={selectedMemberId}
              claimReward={claimReward}
              userCredit={userCredit}
              popupThemeClass={popupThemeClass}
              confirmRewards={this.props.confirmRewards}
              handleCloseProvideReward={this.handleCloseProvideReward.bind(this)}
           />

          }
         
        </React.Fragment>
      )
      
  }
}

const mapStateToProps = ({ settings, Contacts, chatAppReducer, activitiesList, authUser, GroupList, Reward, clientsList }) => {
	const { screenThemeClass, popupThemeClass  } = settings; 
  const { allActivities, clientActivitiesType, startActivityPanel, executeActivityNotification, activityCompletionMessage} = activitiesList
  const { selectedGroup } = GroupList

  const { displayPushNotiAlert, displayOnce } = authUser
  let {contactList, loading, chatToken, twilioClient, showVideoRoom, showVideoCallConfirmationPanel, showMissedCallConfirmationPanel} = Contacts;
  const { selectedChatUsers, selectedUser , openChatPanel, selectedMember} = chatAppReducer;

  const { clientUserDetail } = clientsList
  const { pendingReward, loading: rewardLoading } = Reward;
  const {profileImageURL: clientImage, displayName: clientName = "Client", credit: userCredit, organization: selectedUserOrgId} = clientUserDetail || {}
	return {screenThemeClass, contactList, loading, chatToken, twilioClient, selectedChatUsers, selectedUser, openChatPanel,  showVideoRoom, showVideoCallConfirmationPanel, allActivities, displayPushNotiAlert, displayOnce, selectedGroup, clientActivitiesType, selectedMember, startActivityPanel, executeActivityNotification, showMissedCallConfirmationPanel, activityCompletionMessage, pendingReward, rewardLoading, userCredit, clientImage, clientName, popupThemeClass, selectedUserOrgId};
};

export default withRouter(connect(mapStateToProps, {
  hideChatPanel, 
  selectMenuItem, 
  showFullChatPanelForSelectedUser, 
  registerDeviceForNotification,
  showVideoCallConfirmation,
  showMissedCallConfirmation,
  displayPushNotiAlertPopup,
  showExecuteActivityConfirmation,
  hidePushNotiAlert,
  networkActivity,
  handleActivityCompletionConfirmation,
  showNotificationPopup,
  getUserInfo,
  hideVideoRoomPanel,
  hideVideoCallConfirmation,
  sendGroupVideoCallResponse,
  sendVideoCallResponse,
  getActivitiesList,
  getResourceList,
  getcategories,
  getKeywords,
  getClientActivitiesList,
  getHelpContactsWithChannel,
  getPendingRewards,
  addtoFavourite,
  confirmRewards,
  getClientDetailById,
  hideExecuteActivityConfirmation 
})(NotificationsHandler));
