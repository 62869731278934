
import {axiosClient} from '../sherpaApi'
import { NotificationManager } from 'react-notifications';

import {
    GET_CHARTS_LIST,
    GET_SUCCESS_CHARTS_LIST,
    GET_CHART_BY_CHART_ID,
    GET_SUCCESS_CHART_BY_CHART_ID,
    FILTER_CHART_DATA,
    FILTER_SUCCESS_CHART_DATA,
    SHOW_CHARTS_LOADING,
    HIDE_CHARTS_LOADING,
 } from './types';

export const getChartsList = (id, orgId, idToken, moduleName) => (dispatch) => {
    let url = `reports/${moduleName}/${id}/charts?orgId=${orgId}`
    console.log(" Fetching charts data for user......");
    dispatch({ type: GET_CHARTS_LIST });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.status === 200){
            console.log("%c Fetched charts data for userId:"+id,"color:green");
            if(response.data && response.data.length > 0) {
                let consoleLogsTable = [];
                response.data.map(chart => {
                    consoleLogsTable.push({chartId:chart.chartId, title:chart.title, class: chart.class});
                });
            }
            dispatch({ type: GET_SUCCESS_CHARTS_LIST, payload: response.data });
        } else {
            console.log("%c Error Occured while fetching user charts data ","color:red");
            dispatch({ type: HIDE_CHARTS_LOADING });
        }
    }).catch((error) => {
        dispatch({ type: HIDE_CHARTS_LOADING });
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user charts data ","color:red", errorMsg);
    })
}

export const getChartById = (id, orgId, idToken, moduleName, chartId) => (dispatch) => {
    let url = `reports/${moduleName}/${id}/charts/${chartId}?orgId=${orgId}`
    dispatch({ type: GET_CHART_BY_CHART_ID });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.status === 200){
            dispatch({ type: GET_SUCCESS_CHART_BY_CHART_ID, payload: {data: response.data, chartId} });
        } else {
            dispatch({ type: HIDE_CHARTS_LOADING });
            NotificationManager.error(response.data)
        }
    }).catch(async(error) => {
        dispatch({ type: HIDE_CHARTS_LOADING });
        console.log("error occured ", error);
     })
}

export const getFilterChartById = (id, orgId, idToken, moduleName, chartId, filterData) => (dispatch) => {
    let url = `reports/${moduleName}/${id}/charts/${chartId}?orgId=${orgId}&fromDate=${encodeURIComponent(filterData.fromDate)}&toDate=${encodeURIComponent(filterData.toDate)}`
    console.log(" Fetching filter data for chartId:"+chartId)
    dispatch({ type: FILTER_CHART_DATA });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.status === 200){
           console.log("%c Fetched filter data for chartId:"+chartId,"color:green");
            dispatch({ type: FILTER_SUCCESS_CHART_DATA, payload: {filterData: response.data, filterChartId: chartId} });
            if(response.data) {
                let consoleLogsTable = [];
                consoleLogsTable.push({chartId:chartId, title:response.data.title, class: response.data.class});
            }
        } else {
            dispatch({ type: HIDE_CHARTS_LOADING });
            NotificationManager.error(response.data)
        }
    }).catch(async(error) => {
        dispatch({ type: HIDE_CHARTS_LOADING });
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error occured while Fetching filter data for chartId:"+chartId,"color:red",errorMsg);
     })
}

export const showActivityChartsLoading = () => (dispatch) =>{
    dispatch({type: SHOW_CHARTS_LOADING})
}

export const hideActivityChartsLoading = () => (dispatch) =>{
    dispatch({type: HIDE_CHARTS_LOADING})
}
