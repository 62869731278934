/**
* Chat
*/
import React, { Component } from 'react';

// components
import { connect } from 'react-redux';
import Moment from 'moment';

import StartActivityListView from "./components/StartActivityListView";

import {
	hideStartActivityPanel,
	setActivityType,
	executeActivityWithAchievementId,  
	StartActivity,
	executeActivity,
	getActivityFilter,
	handlEditor,
	selectActivity,
	setSelectedMemberId,
} from "../../actions"

const drawerWidth = 400;

class AppMyActivity extends Component {

	state = {
		openPopup: false,
		mobileOpen: true,
		openForm: false,
		previewId: null,
		selected: 'preview',
		activityId: '',
		activityType: '',
		showDetails: '',
		type: '',
		showRewards: false
	};

	componentDidMount() {
	     const {startActivityPanel} = this.props;
		 console.log("startActivityPanel :", startActivityPanel);
		 const date = Moment().format('YYYY-MM-DD HH:mm:ss Z');
		 const uid = localStorage.getItem('uid');
		 if(startActivityPanel && (startActivityPanel.id || startActivityPanel.activityId || startActivityPanel.achievementId)) {
			const action = startActivityPanel.action || 'preview';
			const activityId = startActivityPanel.id || startActivityPanel.activityId ;
			const achievementId = startActivityPanel.achievementId;
			const activityType = startActivityPanel.isPrivateActivity ? 'private' : 'common';
			if(startActivityPanel.organization) {
				localStorage.setItem("selectedOrgId", startActivityPanel.organization);
			}
			this.props.setActivityType(activityType)
			if(action === 'execute') {
				if(achievementId && !activityId) {
					this.props.executeActivityWithAchievementId(achievementId, date, uid);
				} else {
					this.props.executeActivity(activityId, date, uid, startActivityPanel.scheduleKey)
				}
			} else {
				this.props.StartActivity(activityId, date, uid)
			}

		 }
	}

	handleClose() {
		this.props.hideStartActivityPanel();
	}
	
	render() {
		const { startActivityPanel} = this.props;
		return (
			<>
				{startActivityPanel && (startActivityPanel.id || startActivityPanel.activityId || startActivityPanel.achievementId) ?
					<StartActivityListView  handleClose={()=> this.handleClose()}/>
					: <></>
				}
			</>
		);
	}
}
const mapStateToProps = ({ activitiesList }) => {
	const { startActivityPanel} = activitiesList
	return {startActivityPanel}
}

export default connect(mapStateToProps, {
	hideStartActivityPanel,
	setActivityType,
	executeActivityWithAchievementId,  
	StartActivity,
	executeActivity,
	getActivityFilter,
	handlEditor,
	selectActivity,
	setSelectedMemberId,
})(AppMyActivity);
