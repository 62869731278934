/**
 * User List
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
import ActivityDetails from "./components/ActivityDetails";
// component's
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// redux actions
import {
    getClientMuteNotification,
    getClientDetailById,
    checkGeneralActivityPermission,
    changeRole,
    showClientPanel,
    handleChangeMode,
} from '../../actions';
import { Route, Routes } from 'react-router-dom';


import UserBlock from './components/UserBlock';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import config from '../../config/config';
import HomePage from './components/HomePage';
import { isMobile } from '../../helpers/helpers';

class UserDetails extends Component {

    state = {
        select: "activity",
        openEditor: false,
        activityId: null,
        editorType: null,
        anchorEl: null,
        openMenu: false
    }

    componentDidMount() {
        let userId = localStorage.getItem('uid');
        const adminUserId = localStorage.getItem("auth_uid");
        this.props.checkGeneralActivityPermission()
        this.props.getClientDetailById(userId)
        this.props.getClientMuteNotification(adminUserId, userId);
        // this.props.handleChangeMode("My Clients")
    }

    handleClose() {
        // this.setState({popupOpen: !this.state.popupOpen})
        //this.props.handleOpenPopup()
    }

   
    handleCloseClientDetailsPanel = () => {
        const auth_uid = localStorage.getItem("auth_uid")
        localStorage.setItem('uid', auth_uid);
        this.props.changeRole({ role: 'admin', id: auth_uid });
        this.props.showClientPanel(false);
        this.props.navigate("/app/lsdashboard");
    }

    render() {
        const { match, clientUserDetail } = this.props;

        return (
            <div data-testid="my-client-details-panel" >
                {isMobile() ? "" : 
                    <div className="row">
                        <div className="d-flex w-100 mb-1 px-3  justify-content-between align-items-center breadcumb-for-user-details lifesherp-breadrumb-header">
                            <div className="d-flex align-items-left">
                                <Breadcrumb className="mb-0 tour-step-7 breadcomp-for-panel" tag="nav">
                                    <BreadcrumbItem
                                        tag={"span"}>Home</BreadcrumbItem>
                                    <BreadcrumbItem
                                        tag={"span"}>Clients</BreadcrumbItem>
                                    <BreadcrumbItem
                                        tag={"span"}>{clientUserDetail ? clientUserDetail.displayName : 'Info'}</BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                            <div className="d-flex d-flex  justify-content-end  align-items-right">
                                <IconButton data-testid="details-panel-close-button" onClick={() => this.handleCloseClientDetailsPanel()} className="" title="Back To Home">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className="col-12 mb-2 mt-0 pt-0">
                            <div className="list-card  p-3">
                                <UserBlock handleClose={this.handleClose.bind(this)}/>
                            </div>
                        </div>
                    </div>
                }
                <React.Fragment>
                    <Routes>
                        <Route path="/activity/detail" element={<ActivityDetails />} />
                        <Route path="/*" element={<HomePage />}
                        />
                    </Routes>
                </React.Fragment>
            </div>
        );
    }
}

const mapStateToProps = ({ clientsList }) => {
    const {clientUserDetail} = clientsList
    return { clientUserDetail};
};

export default withRouter(connect(mapStateToProps, {
    getClientMuteNotification,
    getClientDetailById,
    checkGeneralActivityPermission,
    changeRole,
    showClientPanel,
    handleChangeMode,
})(UserDetails));
;
