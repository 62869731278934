import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getKeywords, setResorcesAndCategories, applyUserOrgFilter, clearUserOrgFilter } from '../../actions'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid2';
import { Label } from 'reactstrap'
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DialogTitle from "@mui/material/DialogTitle";

class ResourcesFilter extends Component {

    state = {
        collection: '',
        filterOrgOptions: []
    }

    componentDidMount() {
        console.log("%c[ResourcesFilter]%c Rendered resources filter popup","color:black","color:green");
        document.addEventListener('nativeBackClick', this.handleNativeBack);
    }

    handleNativeBack = () => {
        console.log("native back clicked for resources filter popup ........");
        this.props.handleCloseFilter()
    }

    componentWillUnmount() {
        document.removeEventListener('nativeBackClick', this.handleNativeBack);
    }

    handleSort = (value) => {
        this.props.handleSort(value);
        this.setState({ collection: value });
    }

    handleReport = (value) => {
        this.props.handleReport(value);
        this.setState({ collection: value });
    }

    handleKeywords = (value) => {
        this.props.handleKeywords(value);
        this.setState({ collection: value });
    }

    componentWillUnmount() {
       console.log("%c[ResourcesFilter]%c Resources filter popup closed","color:black","color:red");
    }

    handelOrgIdFilter(id) {
        let {filterOrgOptions} = this.state
        if(filterOrgOptions && filterOrgOptions.length > 0) {
            const index = filterOrgOptions.findIndex(ele => ele === id)
            if (index === -1) {
                filterOrgOptions.push(id)
            } else {
                filterOrgOptions.splice(index, 1)
            }
        } else {
            filterOrgOptions = []
            filterOrgOptions.push(id)
        }
        this.setState({filterOrgOptions})
    }

    applyFilter(){
        const {filterOrgOptions} = this.state
        const {panel} = this.props
        this.props.applyUserOrgFilter({panel, orgIds: filterOrgOptions})
        this.props.applyFilter(filterOrgOptions)
    }

    clearFilter(){
        const {panel} = this.props
        this.props.clearUserOrgFilter({panel})
        this.props.clearFilter()
    }

    render() {
        let { Resources,popupThemeClass, organizations, resourcePanelOrgFiltered } = this.props;
        const {filterOrgOptions} = this.state
        const orgsArray = organizations && organizations.length > 0 ? [...organizations] : null
        if ( orgsArray && orgsArray.length > 0 && (orgsArray.findIndex(org => org.id === "Private Resources") === -1)) {
            orgsArray.push({id: "Private Resources", name: "Private Resources"})
        }
        
        const loggedInUser = localStorage.getItem("auth_uid")
        const clientUserId = localStorage.getItem("uid")
        const showOrgFilter = loggedInUser === clientUserId ? true : false

        let filteredCategories = []
        if (filterOrgOptions && Resources.categories && Resources.categories.length > 0 && filterOrgOptions.length > 0) {
            for (let index = 0; index < filterOrgOptions.length; index++) {
                const id = filterOrgOptions[index];
                const orgResourceCategories = Resources.categories.filter(ele => ele.organization === id)
                filteredCategories = filteredCategories.concat(orgResourceCategories)
            }
        } else {
            filteredCategories = Resources ? Resources.categories : []
        }

        let filteredKeywords = []
        if (filterOrgOptions && Resources && Resources.keywords && Resources.keywords.length> 0 && filterOrgOptions.length > 0) {
            for (let index = 0; index < filterOrgOptions.length; index++) {
                const id = filterOrgOptions[index];
                const orgKeyword = Resources.keywords.filter(ele => (id in ele.organizations))
                filteredKeywords = filteredKeywords.concat(orgKeyword)
            }
        } else {
            filteredKeywords = Resources ? Resources.keywords : []
        }

        return (
            <Dialog
                fullScreen={false}
                open={this.props.showFilter}
                onClose={() => this.props.handleCloseFilter()}
                fullWidth={true}
                maxWidth="sm"
                className={`${popupThemeClass}`}
                aria-labelledby="responsive-dialog-title"
                id='resources-filter-popup'
                >
                <DialogTitle>
                    <div data-testid="resource-filter-dialog" className="d-flex justify-content-between align-items-center py-2 px-3 bg-lifesherpa">
                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">Filters</Label>
                        <IconButton data-testid="close-icon-for-filter-dialog" className="text-white" onClick={() => this.props.handleCloseFilter()}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="pl-4 pr-4">
                {showOrgFilter ?
                    <>
                        <div className="text-secondary filter-heading  fw-semi-bold my-2 pt-2">Organization</div>
                        <Grid
                            container
                            direction="row"
                            justifycontent="flex-start"
                            alignItems="flex-start"
                            className='border-bottom pb-2'
                            >
                            {orgsArray && orgsArray.map((list, key) => (
                                <Grid item key={key}>
                                    {filterOrgOptions && filterOrgOptions.find(gid => gid == list.id) ?
                                        <Chip
                                            data-testid={`Selected-${list.name}`}
                                            key={key}
                                            className={" mr-1 my-1 chipSelected "}
                                            avatar={<CheckCircleOutlinedIcon />}
                                            label={list.name}
                                            style={{ fontSize: "16px" }}
                                            onClick={() => this.handelOrgIdFilter(list.id)} 
                                            />
                                        :
                                        <Chip
                                            data-testid={`Unselected-${list.name}`}
                                            key={key}
                                            className={" mr-1 my-1 chipStyle"}
                                            label={list.name}
                                            style={{ fontSize: "16px" }}
                                            onClick={() => this.handelOrgIdFilter(list.id)} 
                                            />
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </>
                    : <></>
                }
                    <div className="text-secondary filter-heading  fw-semi-bold my-2">Sort</div>
                    <div className="d-flex mt-2 mb-3 ">
                        <Chip
                            data-testid="latest-report-option"
                            avatar={this.props.sort === 'asc' ? <CheckCircleOutlinedIcon  /> : <></>}
                            label="Latest"
                            className={" mr-1 " + (this.props.sort === 'asc' ? "chipSelected " : "chipStyle")}
                            style={{ fontSize: "16px" }}
                            onClick={() => this.handleSort('asc')} />
                        <Chip
                            data-testid="oldest-report-option"
                            className={" mr-1 " + (this.props.sort === 'desc' ? "chipSelected " : "chipStyle")}
                            avatar={this.props.sort === 'desc' ? <CheckCircleOutlinedIcon  />: <></>}
                            label="Oldest"
                            style={{ fontSize: "16px" }}
                            onClick={() => this.handleSort('desc')} />
                    </div>
                    <div className="text-secondary filter-heading  fw-semi-bold my-2 pt-2 border-top">Show Collection</div>
                    <Grid
                        container
                        direction="row"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                        >
                        {filteredCategories && filteredCategories.length > 0 ? filteredCategories.map((category, key) => (
                            <Grid item key={key}>
                                 {category.selected ?
                                    <Chip
                                        data-testid={`category-option-${key+1}`}
                                        key={key}
                                        className={" mr-1 my-1 chipSelected "}
                                        avatar={<CheckCircleOutlinedIcon data-testid={`check-category-${key+1}`}  />}
                                        label={category.name}
                                        style={{ fontSize: "16px" }}
                                        onClick={() => this.handleReport(category)} />
                                    :
                                    <Chip
                                        data-testid={`category-option-${key+1}`}
                                        key={key}
                                        className={" mr-1 my-1 chipStyle"}
                                        label={category.name}
                                        style={{ fontSize: "16px" }}
                                        onClick={() => this.handleReport(category)} />
                                 }
                            </Grid>

                        )) :  <Grid item key={0}>No Category found!</Grid>}
                    </Grid>
                    <div className="text-secondary  filter-heading fw-semi-bold my-2 pt-2 border-top">Filter by Tags</div>
                    <Grid
                        container
                        direction="row"
                        className="border-bottom pb-2"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                        >
                        {filteredKeywords && filteredKeywords.length > 0 ? filteredKeywords.map((keyword, key) => (
                            <Grid item key={key}>
                                {keyword.selected ?
                                <Chip
                                    data-testid={`keyward-option-${key+1}`}
                                    className={" mr-1 my-1 chipSelected "}
                                    avatar={<CheckCircleOutlinedIcon data-testid={`check-keyward-${key+1}`}  /> }
                                    label={keyword.name}
                                    style={{ fontSize: "16px" }}
                                    onClick={() => this.handleKeywords(keyword)} />
                                    :
                                <Chip
                                    data-testid={`keyward-option-${key+1}`}
                                    className={" mr-1 my-1 chipStyle"}
                                    label={keyword.name}
                                    style={{ fontSize: "16px" }}
                                    onClick={() => this.handleKeywords(keyword)} />
                                }
                            </Grid>
                        )):  <Grid item key={0}>No Keyword found!</Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions className="p-4 filter-buttons-styles">
                     <Button color="primary" variant="outlined" data-testid="cancel-filter-button" className="  w-50 filter-button-cancel"  onClick={() => this.clearFilter()}>
                        CLEAR FILTERS
                     </Button>
                     <Button color="primary" variant="contained" data-testid="apply-filter-button" className=" w-50 filter-button-apply"  onClick={() => this.applyFilter()}>
                        APPLY
                     </Button>
                </DialogActions>
            </Dialog>

        );
    }
}
const mapStateToProps = ({Resources,settings, clientsList}) => { 
    const { screenThemeClass, popupThemeClass } = settings; 
    const {organizations, resourcePanelOrgFiltered} = clientsList 
    return { Resources ,screenThemeClass, popupThemeClass, organizations, resourcePanelOrgFiltered };
}

export default connect(mapStateToProps, { 
 getKeywords, setResorcesAndCategories, applyUserOrgFilter, clearUserOrgFilter 
})(ResourcesFilter);