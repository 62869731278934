/**
 * Email Listing
 */
import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';

// component
import LandingPageItem from './LandingPageItem';

class LandingPageList extends Component {
    
    render() {
        const { inProgressActivities, userId } = this.props;
        return (
            <div style={{ borderTop: "1px solid lightgrey", marginTop: "12px" }} className='w-100 ml-0 mr-0 mb-0   p-0 '>
                {inProgressActivities.length > 0 ?
                    <div style={{ paddingLeft: "7px" }} className="mb-0 pb-0 mt-2">
                        <span  className={`activities-inprogress-title`}>
                            Activities In Progress
                        </span>
                    </div>
                    : <></>
                }
                <ul className="list-unstyled m-0 list-sherpa-activity mt-1 mb-4 p-0 " >
                    <div>
                        {inProgressActivities.length > 0 ? inProgressActivities.map((activity, key) =>    
                               key != 0  ?
                               <LandingPageItem
                                    key={key + "landingpageitem"}
                                    allActivities={inProgressActivities}
                                    itemKey={key}
                                    activity={activity}
                                    userId={userId}
                                /> : <></>
                            )
                            :
                            <div  className="d-flex justify-content-center align-items-center py-50">
                                <h4>No In Progress activity available</h4>
                            </div>
                        }
                    </div>
                </ul>
            </div>
        );
    }
}

export default withRouter(LandingPageList);
