/**
 * Clients List Actions
 */

import {axiosClient} from '../sherpaApi'
import { NotificationManager } from 'react-notifications';
import moment from 'moment'
import {mergeRealtimeActivityData, mergeRealtimeTouchpointSummaryData} from '../workers/workers';
import {
    SELECT_ACTIVITY_ID,
    GET_RECENT_ACTIVITIES,
    GET_SELECTED_ACTIVITIES,
    UPDATE_ACTIVITIES_SEARCH,
    SEARCH_ACTIVITIES,
    GET_ACTIVITIES_LIST,
    GET_SUCCESS_ACTIVITIES_LIST,
    SET_ACTIVITIES_REMINDER,
    SET_SUCCESS_ACTIVITIES_REMINDER,
    SET_ACTIVITIES_INPROGESS,
    SET_ACTIVITIES_RESET,
    SET_SUCCESS_ACTIVITIES_RESET,
    GET_REALTIME_ACTIVITY_DETAIL,
    GET_SUCCESS_REALTIME_ACTIVITY_DETAIL,
    SHOW_LOADING_INDICATOR_ACTIVITIES_LIST,
    HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,
    GET_ACTIVITY_FILTERS,
    GET_START_ACTIVITY,
    GET_START_ACTIVITY_DETAILS,
    GET_PAUSE_ACTIVITY,
    GET_END_ACTIVITY,
    GET_CANCEL_ACTIVITY,
    GET_RESUME_ACTIVITY,
    GET_RESUME_ACTIVITY_DETAILS,
    GET_LOADING,
    EDITOR_LOADING,
    GET_ENDSTEP,
    STOP_LOADING,
    GET_NETWORK_ACTIVITY,
    GET_NETWORK_ACTIVITY_SUCCESS,
    GET_LAUNCHPAD,
    GET_ACTIVITY_CALENDER,
    GET_LIST_STYLE,
    SET_ACTIVITY_TYPE,
    GET_SUMMERY_ACTIVITY,
    ACTIVITY_START_TIME,
    GET_COURES,
    GET_PINRESPONSE,
    REMOVE_PINRESPONSE,
    GET_WPQLINK,
    GET_REALTIME_USER_ACTIVITIES,
    CLEAR_START_ACTIVITY_DETAILS,
    GET_LOADING_FOR_COURSE,
    START_STEP_SLIDER_LOADING,
    STOP_STEP_SLIDER_LOADING,
    GET_ACTIVITY_GROUPS_LIST_SUCCESS,
    GET_ACTIVITY_GROUPS_LIST,
    GET_ACTIVITY_GROUPS_LIST_FAILED,
    EXECUTE_ACTIVITY,
    SET_ACTIVITIES_ACHIEVEMENTS,
    GET_WPQ_LOADING,
    HIDE_WPQ_LOADING,
    HANDLE_SHOW_PHOTO_CAMERA,
    CATCH_API_ERROR,
    SET_FILTERED_ACTIVITIES_DATA,
    CLEAR_FILTERED_ACTIVITIES_DATA,
    HIDE_EXECUTE_ACTIVITY_CONFIRMATION_PANEL,
    SHOW_EXECUTE_ACTIVITY_CONFIRMATION_PANEL,
    ACTIVITY_PREVIEW_LOADING,
    SHOW_ACTIVITY_DETAILS_PANEL,
    HIDE_ACTIVITY_DETAILS_PANEL,
    ADJUST_ACHIEVEMENT_TIMER,
    ADJUST_ACHIEVEMENT_TIMER_SUCCESS,
    ADJUST_ACHIEVEMENT_TIMER_FAILED,
    GET_USERS_ECOUNTER_TYPES_ACTIVITIES,
    GET_USERS_ECOUNTER_TYPES_ACTIVITIES_SUCCESS,
    MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA,
    MAP_USERS_ECOUNTER_TYPES_ACTIVITIES_REALTIME_DATA_SUCCESS,
    HANDLE_ENCOUNTER_ACTIVITY_HISTORY,
    HANDLE_ENCOUNTER_ACTIVITY_DETAILS,
    HANDLE_ENCOUNTER_ACTIVITY_EXECUTION,
    SET_CLIENT_ACTIVITIES_TYPE,
    GET_ECOUNTER_ACTIVITIES_SUMMARY,
    GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS,
    GET_ECOUNTER_ACTIVITIES_SUMMARY_FAILED,
    GET_SUMMARY_LOADING,
    REALTIME_UPDATE_ACTIVITIES,
    RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST,
    UNASSIGN_CLIENT_COMMON_ACTIVITY,
    UNASSIGN_CLIENT_COMMON_ACTIVITY_SUCCESS,
    UNASSIGN_ACTIVITY_TO_CLIENT_FAILED,
    HIDE_UNASSIGN_ACTIVITY_LOADING,
    SELECT_ACTIVITY_FILTER_GROUP,
    RESET_ACTIVITIES_LIST,
    UPDATE_ACTIVITEIS_LIST,
    HIDE_START_ACTIVITY_PANEL,
    SHOW_START_ACTIVITY_PANEL,
    SHOW_ACTIVITY_EDITOR,
    HANDLE_SHOW_ADD_ACTIVITY_OPTIONS,
    CLEAR_USER_ACTIVITIES_DATA,
    SET_USER_ACTIVITIES_ACHIEVEMENTS,
    GET_ALL_ACTIVITIES_LIST,
    GET_ALL_ACTIVITIES_LIST_SUCCESS,
    GET_ALL_ACTIVITIES_LIST_FAILED,
    GET_ALL_USER_ACTIVITIES_LIST,
    GET_ALL_USER_ACTIVITIES_LIST_SUCCESS,
    GET_ALL_USER_ACTIVITIES_LIST_FAILED,
    SHOW_EXECUTE_ACTIVITY_PANEL,
    CLEAR_ORGANIZATIONS_FILTER_FROM_ACTIVITIES
} from './types';


 export const getLoading =()=>(dispatch)=>{
    dispatch({ type: GET_ACTIVITIES_LIST });
 }

// export const getRealtimeCommonActivityDetail = (orgIds, userId) => async (dispatch) => {
//     const promises = [];
//     async function initOnActivityListener(orgId, userId) {
//         off(ref(database, `Realtime/${orgId}/Users/${userId}/achievementsInProgress`));;
//         return onValue(ref(database, `Realtime/${orgId}/Users/${userId}/achievementsInProgress`), (snapshot) => {
//             const realtimeData = snapshot.val() || {};
//             dispatch({ type: SET_ACTIVITIES_ACHIEVEMENTS, payload: {orgAchievementsObj: realtimeData, orgId, callback: (activitiesList) => {
//                     mergeRealtimeActivityData({orgId, activitiesList, achievementsObj: realtimeData}, ({mergedObj, achievementsObj}) => {
//                             dispatch({ type: SET_ACTIVITIES_ACHIEVEMENTS, payload: {orgAchievementsObj: achievementsObj, orgId} });
//                             dispatch({ type: UPDATE_ACTIVITEIS_LIST, payload: mergedObj });
//                             dispatch({type:REALTIME_UPDATE_ACTIVITIES, payload: Math.random()});
//                         })
//                     }
//                 }  
//             });
           
//         })
//     }
    
//     for(const orgId of orgIds) {
//         promises.push(initOnActivityListener(orgId, userId));
//     }

//     // Wait for all promises to resolve
//     await Promise.all(promises);
// };

export const getRealtimeCommonActivityDetail = (orgIds, userId) => (dispatch) => {
    // Initialize Web Worker
    const worker = new Worker(new URL('../workers/WebWorkers/ActivityFirebaseRealtimeWorker.js', import.meta.url));

    // Listener for messages from the worker
    worker.onmessage = (event) => {
        const { orgId, realtimeData } = event.data;

        // Update Redux store with received data
        dispatch({
            type: SET_ACTIVITIES_ACHIEVEMENTS,
            payload: { orgAchievementsObj: realtimeData, orgId,
                callback: (activitiesList) => {
                    mergeRealtimeActivityData({ orgId, activitiesList, achievementsObj: realtimeData }, ({ mergedObj, achievementsObj }) => {
                        dispatch({ type: SET_ACTIVITIES_ACHIEVEMENTS, payload: { orgAchievementsObj: achievementsObj, orgId } });
                        dispatch({ type: UPDATE_ACTIVITEIS_LIST, payload: mergedObj });
                        dispatch({ type: REALTIME_UPDATE_ACTIVITIES, payload: Math.random() });
                    });
                }
            }
        });
    };

    // Send the Firebase reference paths to the worker
    orgIds.forEach((orgId) => {
        worker.postMessage({ type: 'initListener', orgId, userId });
    });

    // Cleanup function to terminate worker when component unmounts
    return () => {
        worker.postMessage({ type: 'cleanup' });
        worker.terminate();
    };
};



// export const getRealtimeCommonActivityDetailN = ({ orgId, groupId, userId }) => (dispatch) => {
//     const organization = orgId || localStorage.getItem('organization');
//     const auth_uid = localStorage.getItem("auth_uid");

//     // Ensure required data is available before proceeding
//     if (!organization || !userId || !groupId) {
//         return;
//     }

//     if (Array.isArray(groupId)) {
//         groupId = groupId[0]?.id || '';
//     }
    
//     const url = `Realtime/${organization}/Groups/${groupId}/${userId}/achievementsInProgress`;
//     const refUrl = ref(database, url);
//     const topnRef = query(refUrl, orderByChild('preview'), equalTo(false));
//     off(refUrl);
//     // Fetch data from Firebase Realtime Database
//     onValue(topnRef, (snapshot) => {
        
//         const realtimeData = snapshot.val() || {};
//         dispatch({ type: SET_USER_ACTIVITIES_ACHIEVEMENTS, payload: {achievementsObj: realtimeData, callback: (activitiesList) => {
//             mergeRealtimeActivityData({orgId, activitiesList, achievementsObj: realtimeData, auth_uid, isClientActivity: true}, ({mergedList, achievementsObj}) => {
//                     dispatch({ type: SET_USER_ACTIVITIES_ACHIEVEMENTS, payload: {achievementsObj: achievementsObj} });
//                     dispatch({ type: GET_REALTIME_USER_ACTIVITIES, payload: mergedList });
//                     dispatch({type:REALTIME_UPDATE_ACTIVITIES, payload: Math.random()});
//                 })
//             }
//         }  
//     });
        
//     });
// };

export const getRealtimeCommonActivityDetailN = ({ orgId, groupId, userId }) => (dispatch) => {

    const organization = orgId || localStorage.getItem('organization');
    const auth_uid = localStorage.getItem("auth_uid");

    // Ensure required data is available before proceeding
    if (!organization || !userId || !groupId) {
        return;
    }

    if (Array.isArray(groupId)) {
        groupId = groupId[0]?.id || '';
    }

    // Initialize Web Worker
    const worker = new Worker(new URL('../workers/WebWorkers/UserActivityFirebaseRealtimeListener.js', import.meta.url));

    // Listener for messages from the worker
    worker.onmessage = (event) => {
        const { orgId, realtimeData } = event.data;

        // Update Redux store with received data
        dispatch({
            type: SET_USER_ACTIVITIES_ACHIEVEMENTS,
            payload: { achievementsObj: realtimeData,
                callback: (activitiesList) => {
                    mergeRealtimeActivityData({ orgId, activitiesList, achievementsObj: realtimeData, auth_uid, isClientActivity: true }, ({ mergedList, achievementsObj }) => {
                        dispatch({ type: SET_USER_ACTIVITIES_ACHIEVEMENTS, payload: {achievementsObj: achievementsObj} });
                        dispatch({ type: GET_REALTIME_USER_ACTIVITIES, payload: mergedList });
                        dispatch({type:REALTIME_UPDATE_ACTIVITIES, payload: Math.random()});
                    });
                }
            }
        });
    };

    // Send the Firebase reference paths to the worker
    worker.postMessage({ type: 'initListener', organization, groupId, userId });

    // Cleanup function to terminate worker when component unmounts
    return () => {
        worker.postMessage({ type: 'cleanup' });
        worker.terminate();
    };

};


 export const getClientActivitiesList = ({userId, orgId, groupId, filter, activityType, clearData, onRealtimeListener = false}) => (dispatch) => {
    let idToken = localStorage.getItem('idToken');
    let auth_uid = localStorage.getItem('auth_uid')
    let activityGroupId=filter?filter:'';
    // console.log(" Fetching user activities list for userId:"+userId+"......");
    let url = ""
    if (activityType){
        url = `/users/${userId}/activities?activityGroupId=${activityGroupId}&type=${activityType}` //`/users​/${memberUerId}​/activities`
    } else {
        url = `/users/${userId}/activities?activityGroupId=${activityGroupId}&includeQuickTasks=true`
    }
    //clear filter activities and starting spinner
    dispatch({ type: CLEAR_FILTERED_ACTIVITIES_DATA});
    let inProgressUserAchievements = null;
    dispatch({ type: GET_ALL_USER_ACTIVITIES_LIST, payload: {clearData, callback: (inProgressAchievements) => {
        inProgressUserAchievements = inProgressAchievements;
    }}});
    if (userId) {
        axiosClient.get(url, {headers: {
            'Authorization': `Bearer ${idToken}`
         }
        })
        .then((response) => {
            // console.log("activities api response ----.",response.data);
            // console.log("%c Fetched user activities list for user:"+userId,"color:green");
            const activitiesList = response.data;
            if(activitiesList.length && inProgressUserAchievements && Object.keys(inProgressUserAchievements).length) {
                mergeRealtimeActivityData({orgId, activitiesList, achievementsObj: inProgressUserAchievements, auth_uid, isClientActivity: true}, ({mergedList, achievementsObj}) => {
                    dispatch({ type: GET_ALL_USER_ACTIVITIES_LIST_SUCCESS, payload: mergedList });
                    dispatch({type: REALTIME_UPDATE_ACTIVITIES, payload: Math.random()});
                    if(onRealtimeListener) {
                        dispatch(getRealtimeCommonActivityDetailN({ orgId, groupId, userId}))
                    }
                    
                })
            } else {
                dispatch({ type: GET_ALL_USER_ACTIVITIES_LIST_SUCCESS, payload: activitiesList});
                if(onRealtimeListener) {
                    dispatch(getRealtimeCommonActivityDetailN({ orgId, groupId, userId}))
                }
            }
        }).catch(error => {
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error Occured while fetching user activities list for user:"+userId,"color:red", errorMsg); 
            dispatch({type: GET_ALL_USER_ACTIVITIES_LIST_FAILED})
         })
    } else {
        console.log("%c Error Occured while Fetching user activities list","color:red");
        dispatch({type: GET_ALL_USER_ACTIVITIES_LIST_FAILED})
    }   
}
export const getActivityGroupsList = (userId, orgIds, type) => (dispatch) => {
    const orgId = localStorage.getItem('orgId')	
    const idToken = localStorage.getItem('idToken');
    let url = ""
    if (orgIds && orgIds.length > 0) {
        url = `/users/${userId}/activities/groups?type=${type}&organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    } else {
        url = `/users/${userId}/activities/groups?type=${type}`
    }
    dispatch({ type: GET_ACTIVITY_GROUPS_LIST });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
        }).then((response) => {
            let activityData = response.data || [];
            activityData.map(ele => {
                if (ele.name === "Private Activities") {
                    ele.orgId = orgId 
                } else {
                    ele.orgId = ele.organization 
                }
            })
            dispatch({ type: GET_ACTIVITY_GROUPS_LIST_SUCCESS, payload: response.data });
           
        }).catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({type: GET_ACTIVITY_GROUPS_LIST_FAILED})
        })
}

 
export const getSelectedActivity = (activity) => ({
    type: GET_SELECTED_ACTIVITIES,
    payload: activity
});
export const getUnconfirmAchivForm = (userId) => (dispatch) => {
    
}
export const getRecentActivities = (activities) => ({
    type: GET_RECENT_ACTIVITIES,
    payload: activities
});

export const setActivityReminder = (userId, activityId, orgId, workflow) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let url = `/users/${userId}/notify/routine-start?organization=${orgId}` ///users/{recipientId}/notify/routine-start
    const body = {}
    if (workflow) {
        body['achievementId'] = activityId
    } else {
        body['activityId'] = activityId
    }
     
    dispatch({ type: SET_ACTIVITIES_REMINDER });
    axiosClient.post(url, body, { headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.data && response.data.message) {
            NotificationManager.success(response.data.message);
        }
        dispatch({ type: SET_SUCCESS_ACTIVITIES_REMINDER, payload: response.data });
    }).catch((error) => {
        dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
        console.log("error occured ", error);
    })
}

export const notifyWorkflowGroupRoleAssignmentActivity = (groupId, groupRolePendingTaskId, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let url = `/workflow/groups/${groupId}/notify/role-assignment?organization=${orgId}`;
    const body = {groupRolePendingTaskId};
     
    dispatch({ type: SET_ACTIVITIES_REMINDER });
    axiosClient.post(url, body, { headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if(response.data && response.data.message) {
            NotificationManager.success(response.data.message);
        }
        dispatch({ type: SET_SUCCESS_ACTIVITIES_REMINDER, payload: response.data });
    }).catch((error) => {
        dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
        console.log("error occured ", error);
    })
}

export const setActivityReset = (userId, activity) => (dispatch) => {
    const orgId = (activity && activity.organization) || localStorage.getItem('selectedOrgId');
    let idToken = localStorage.getItem('idToken');
    let url = `/users/${userId}/achievements/${activity.achievementId}/cancel?organization=${orgId}` ///users/{userId}/achievements/{achievementId}/cancel
    let params = {
        'timestamp': moment().format("YYYY-MM-DD HH:mm:ss Z")
    }
    dispatch({ type: SET_ACTIVITIES_RESET });
    axiosClient.post(url, null, { params: params, headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: SET_SUCCESS_ACTIVITIES_RESET, payload: response.data });
    }).catch((error) => {
        dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
        console.log("error occured ", error);
    })
}
export const getActivitiesList = (memberId,filter, onRealtimeListener = false) => (dispatch) => {
    const orgIds = (localStorage.getItem("orgIds") && localStorage.getItem("orgIds").split(",")) || [localStorage.getItem("orgId")]
    const idToken = localStorage.getItem('idToken')
    const activityGroupId = filter ? filter : ''
    let url = `/users/${memberId}/activities?activityGroupId=${activityGroupId}&includeQuickTasks=true` //`/users​/${memberUerId}​/activities`
    // console.log(" Fetching user activities list for userId:"+memberId+"......");
    let inProgressAchievementsByOrg = null;
    dispatch({ type: CLEAR_FILTERED_ACTIVITIES_DATA });
    dispatch({ type: GET_ALL_ACTIVITIES_LIST, payload: {callback: (inProgressAchievements) => {
        inProgressAchievementsByOrg = inProgressAchievements;
    }}});
    if (memberId) {
        axiosClient.get(url, {headers: {
            'Authorization': `Bearer ${idToken}`
         }
        }).then((response) => {
            // 
            const activitiesList = response.data;
            // console.log("%c Fetched user activities list for user:"+memberId,"color:green");
            if(activitiesList.length && inProgressAchievementsByOrg && Object.keys(inProgressAchievementsByOrg).length) {
                mergeRealtimeActivityData({activitiesList, inProgressAchievementsByOrg}, ({mergedList}) => {
                    dispatch({ type: RESET_ACTIVITIES_LIST});
                    dispatch({ type: GET_ALL_ACTIVITIES_LIST_SUCCESS, payload: mergedList });
                    if(onRealtimeListener) {
                        dispatch(getRealtimeCommonActivityDetail(orgIds, memberId));
                    }
                    
                });
            } else {
                dispatch({ type: RESET_ACTIVITIES_LIST});
                dispatch({ type: GET_ALL_ACTIVITIES_LIST_SUCCESS, payload: activitiesList });
                if(onRealtimeListener) {
                    dispatch(getRealtimeCommonActivityDetail(orgIds, memberId));
                }
            }
            
            
            
        }).catch((error) => {
            dispatch({ type: GET_ALL_ACTIVITIES_LIST_FAILED });
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error Occured while fetching user activities list for user:"+memberId,"color:red", errorMsg, error);
            // console.log("error occured ", error);
        })
    } else {
       // dispatch({ type: GET_ACTIVITIES_LIST, payload: null });
       dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
    }
    
}

export const getActivitiesCalendar = (uid) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("selectedOrgId")
    dispatch({type:GET_SUMMARY_LOADING})
    let url =`/users/${uid}/activities/calendar` //users/{userId}/activities/calendar
    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        let expiredActivity=0;
        let startedActivity=0
        response.data.scheduled.map((scheduled)=>{
            if(scheduled.status==='expired') expiredActivity++
            if(scheduled.status==='notStarted') startedActivity++
        })
        let data={expiredActivity:expiredActivity,startedActivity:startedActivity}
        dispatch({type:GET_SUMMERY_ACTIVITY,payload:response.data})
        dispatch({type:GET_ACTIVITY_CALENDER,payload:data})
        
    }).catch((error) => {
        // error handling
        console.log("error occured ", error);
        dispatch({type:GET_SUMMERY_ACTIVITY,payload:null})
        dispatch({type: CATCH_API_ERROR, payload: error})  
    })
}
export const handleListStyle= (value) => (dispatch) => {
    if(value === "summary") {
        let auth_uid = localStorage.getItem('auth_uid');
        dispatch(getActivitiesCalendar(auth_uid))
    }
    dispatch({type:GET_LIST_STYLE,payload:value})
}

export const UpdateSummeryList=(data)=> (dispatch) => {
    dispatch({type:GET_ACTIVITIES_LIST})
    dispatch({type:GET_SUMMERY_ACTIVITY,payload:data})
}
 
export const getFilteredActivitiesList = (memberId, filter, activityType, organizations) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    const orgIds = organizations
    const activityGroupId = filter || []
    let url = "" //`/users/${memberId}/activities?activityGroupId=${activityGroupId}` //`/users​/${memberUerId}​/activities`
    if (activityType) {
        url = `/users/${memberId}/activities?activityGroups=${encodeURIComponent(JSON.stringify(activityGroupId))}&type=${activityType}`
    } else {
        url = `/users/${memberId}/activities?activityGroups=${encodeURIComponent(JSON.stringify(activityGroupId))}`
    }

    if (orgIds && orgIds.length > 0) {
        url = url + `&organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    }

    // console.log(" Fetching user activities list for userId:"+memberId+"......");
    dispatch({ type: GET_ACTIVITIES_LIST });
    if (memberId) {
        axiosClient.get(url, {headers: {
            'Authorization': `Bearer ${idToken}`
         }
        }).then((response) => {
            // console.log("fetched filtered activities response ----.",response.data);
            dispatch({ type: SET_FILTERED_ACTIVITIES_DATA, payload: response.data });
        }).catch((error) => {
            dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error Occured while fetching filter activities list for user:"+memberId,"color:red", errorMsg);
            // console.log("error occured ", error);
        })
    } else {
       // dispatch({ type: GET_ACTIVITIES_LIST, payload: null });
    } 
}
export const removeFilteredActivitiesList = () => (dispatch) => {
        dispatch({ type: CLEAR_FILTERED_ACTIVITIES_DATA });
}

export const getRealtimeClienActivity=(data)=>(dispatch)=>{
    dispatch({ type: GET_ACTIVITIES_LIST });
    dispatch({ type: GET_SUCCESS_ACTIVITIES_LIST, payload: data });
}

export const getActivityFilter=()=>(dispatch)=>{
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    let url = `/users/${uid}/activities/groups` //users/{userId}/activities/groups
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: GET_ACTIVITY_FILTERS,payload: response.data});
    }).catch((error) => {
        dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
        console.log("error occured ", error);
    })
}

/**
 * Redux Action To Update Client Search
 */
export const updateActivitiesSearch = (value) => ({
    type: UPDATE_ACTIVITIES_SEARCH,
    payload: value
});

/**
 * export const to search Clients
 */
export const onSearchActivities = (value) => ({
    type: SEARCH_ACTIVITIES,
    payload: value
});

/**
 * Redux Action To Update Client Search
 */
export const showActivitiesListLoading = () => ({
    type: SHOW_LOADING_INDICATOR_ACTIVITIES_LIST,
});

/**
 * export const to search Clients
 */
export const hideActivitiesListLoading = () => ({
    type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,
});

export const showStartActivityPanel = (data) => ({
    type: SHOW_START_ACTIVITY_PANEL,
    payload: data
});


export const showActivityEditor = (data) => ({
    type: SHOW_ACTIVITY_EDITOR,
    payload: data
});

export const handleShowAddActivityOptions = (bool) => ({
    type: HANDLE_SHOW_ADD_ACTIVITY_OPTIONS,
    payload: bool
});


export const hideStartActivityPanel = () => ({
    type: HIDE_START_ACTIVITY_PANEL
});


export const StartActivity = (activityId,timestamp,uid, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    // console.log(" Starting activity preview for activityId:"+activityId+"....");
    dispatch({type:ACTIVITY_PREVIEW_LOADING})
    let url =`/users/${uid}/achievements/start?activityId=${activityId}&timestamp=${date}&preview=true` //users/{userId}/achievements/start
    let selectedOrgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
    if(selectedOrgId || orgId) {
        url = `${url}&organization=${selectedOrgId || orgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        // console.log("%c Activity preview started for activityId:"+activityId,"color:green");
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity=response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0?Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            }):""
        }
        let date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_START_ACTIVITY_DETAILS,payload: date});
    }).catch((error) => {
        dispatch({ type: STOP_LOADING});
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while starting activity preview","color:red",errorMsg);
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const executeActivity = (activityId, timestamp, uid, scheduleKey) => (dispatch) => {
    let idToken = localStorage.getItem('idToken');
    let url = ""
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    // console.log(" Executing activity preview for activityId:"+activityId+"....");
    dispatch({type:EXECUTE_ACTIVITY})
    // dispatch({type:GET_LOADING})
    url = scheduleKey ? `/users/${uid}/achievements/start?activityId=${activityId}&timestamp=${date}&scheduleKey=${scheduleKey}` :  `/users/${uid}/achievements/start?activityId=${activityId}&timestamp=${date}` //users/{userId}/achievements/start
    let selectedOrgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
    if(selectedOrgId) {
        url = `${url}&organization=${selectedOrgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        // console.log("%c Activity Executing for activityId:"+activityId,"color:green");
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity = response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0 && Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            })
            const activityMessage = response.data.message
            const achievementId = response.data.achievementId
            const selfEvaluationFormId = activity && activity.selfEvaluationFormId ? activity.selfEvaluationFormId : null
            const stepCompletionList = response.data.achievement.StepsCompletion && Object.keys(response.data.achievement.StepsCompletion).length > 0 ? Object.keys(response.data.achievement.StepsCompletion).filter(stepCompId => response.data.achievement.StepsCompletion[stepCompId].status === 'Done' || response.data.achievement.StepsCompletion[stepCompId].status == "Skipped") : []
            if ((stepslist.length == stepCompletionList.length || stepslist.length === 0) && (activityMessage == "Achievement resumed successfully") && !selfEvaluationFormId) {
                dispatch(EndActivity(achievementId, timestamp, uid, null, null, selectedOrgId))
            }

        }
        let date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_START_ACTIVITY_DETAILS,payload: date});
    }).catch((error) => {
        dispatch({ type: STOP_LOADING});
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Executing activity","color:red",errorMsg);
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}
export const executeActivityWithAchievementId = (achievementId,timestamp,uid) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    // console.log(" Executing activity preview for achievementId:"+achievementId+"....");
    dispatch({type:EXECUTE_ACTIVITY})
    // dispatch({type:GET_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/start?timestamp=${date}` //users/{userId}/achievements/start
    let selectedOrgId = localStorage.getItem('selectedOrgId') || localStorage.getItem("orgId");
    if(selectedOrgId) {
        url = `${url}&organization=${selectedOrgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        // console.log("%c Activity Executing for achievementId:"+achievementId,"color:green");
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity=response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0 && Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            })
            const activityMessage = response.data.message
            const selfEvaluationFormId = activity && activity.selfEvaluationFormId ? activity.selfEvaluationFormId : null
            const stepCompletionList = response.data.achievement.StepsCompletion && Object.keys(response.data.achievement.StepsCompletion).length > 0 ? Object.keys(response.data.achievement.StepsCompletion).filter(stepCompId => response.data.achievement.StepsCompletion[stepCompId].status === 'Done' || response.data.achievement.StepsCompletion[stepCompId].status == "Skipped") : []
            if ((stepslist.length == stepCompletionList.length || stepslist.length === 0) && (activityMessage == "Achievement resumed successfully") && !selfEvaluationFormId) {
                dispatch(EndActivity(achievementId, timestamp, uid, null, null, selectedOrgId))
            }
        }
        let date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_START_ACTIVITY_DETAILS,payload: date});
    }).catch((error) => {
        dispatch({ type: STOP_LOADING});
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Executing activity","color:red",errorMsg);
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}
export const executeActivityForConfigurator = (activityId, timestamp, uid, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    const queryParam = `userId=${uid}&organization=${orgId}`
    console.log(" Executing activity preview for activityId:"+activityId+"....");
    dispatch({type:EXECUTE_ACTIVITY})
    // dispatch({type:GET_LOADING})
    let url =`/users/${uid}/achievements/start?activityId=${activityId}&timestamp=${date}&${queryParam}` //users/{userId}/achievements/start
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Activity Executing for activityId:"+activityId,"color:green");
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity=response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0?Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            }):""
        }
        let date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_START_ACTIVITY_DETAILS,payload: date});
    }).catch((error) => {
        dispatch({ type: STOP_LOADING});
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Executing activity","color:red",errorMsg);
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const StartActivityPreviewConfigurator = (activityId,timestamp,uid, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    const queryParam = `&userId=${uid}&organization=${orgId}&previewSource=configurator`
    dispatch({type:ACTIVITY_PREVIEW_LOADING})
    let url =`/users/${uid}/achievements/start?activityId=${activityId}&timestamp=${date}&preview=true${queryParam}` //users/{userId}/achievements/start
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        let stepslist=[]
        let activity={}
        let stepsId=[]
        let stepCount=[]
        if(response.data&&response.data.achievement){
            activity=response.data.achievement.routine
            activity.steps && Object.keys(activity.steps).length > 0?Object.keys(activity.steps).map((dateObjKey, key) => {
                stepslist.push(activity.steps[dateObjKey])
                stepsId.push(dateObjKey)
                stepCount.push(key+1)
            }):""
        }
        let date={stepslist:stepslist,activity:activity,stepsId:stepsId,stepCount:stepCount}
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_START_ACTIVITY_DETAILS,payload: date});
    }).catch((error) => {
        // error handling
        console.log("error occured fetching start activity details", error);
        const errorData = error.response ? error.response.data : error
        dispatch({ type: STOP_LOADING});
        dispatch({ type: GET_START_ACTIVITY,payload: errorData});
        dispatch({ type: GET_START_ACTIVITY_DETAILS, payload: errorData});
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const pauseAcivity = (achievementId,timestamp,uid,routineId,timer) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EDITOR_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/pause?timestamp=${date}` //users/{userId}/achievements/{achievementId}/pause
    let selectedOrgId = localStorage.getItem('selectedOrgId');
    if(selectedOrgId) {
        url = `${url}&organization=${selectedOrgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        let doneStep=[]
        response.data.achievement.StepsCompletion&&Object.keys(response.data.achievement.StepsCompletion).map((obj, key) => {
            doneStep.push(response.data.achievement.StepsCompletion[obj])
            })	
        dispatch({ type: GET_PAUSE_ACTIVITY,payload: response.data});
        let timerData={routineId:routineId,timer:timer}            
        if(timer) dispatch({type:ACTIVITY_START_TIME,payload:timerData})
    }).catch((error) => {
         // error handling
         console.log("error occured fetching start activity details", error);
         dispatch({ type: STOP_LOADING});
         dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const pauseAcivityForConfigurator = (achievementId,timestamp,uid,routineId,timer, executeActivity) => (dispatch) => {
    let idToken = localStorage.getItem('idToken');
    const orgId = localStorage.getItem('porgId');
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EDITOR_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/pause?timestamp=${date}&organization=${orgId}` //users/{userId}/achievements/{achievementId}/pause
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        let doneStep=[]
        response.data.achievement.StepsCompletion&&Object.keys(response.data.achievement.StepsCompletion).map((obj, key) => {
            doneStep.push(response.data.achievement.StepsCompletion[obj])
            })	
        dispatch({ type: GET_PAUSE_ACTIVITY,payload: response.data});
        let timerData={routineId:routineId,timer:timer}            
        if(timer) {
            dispatch({type:ACTIVITY_START_TIME,payload:timerData});
        }
        if(executeActivity) {
            window.location.href = "/success";
        }
    }).catch((error) => {
         // error handling
         console.log("error occured fetching start activity details", error);
         dispatch({ type: STOP_LOADING});
         dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const StartStep = (achievementId,timestamp,stepId,uid, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid'
    let date=encodeURIComponent(timestamp)
    dispatch({type:START_STEP_SLIDER_LOADING})
    dispatch({type:EDITOR_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/steps/${stepId}/start?timestamp=${date}&organization=${orgId}`; //users/{userId}/achievements/{achievementId}/steps/{stepId}/start
    
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: STOP_LOADING});
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({type:STOP_STEP_SLIDER_LOADING})
    }).catch((error) => {
       // error handling
       console.log("error occured ", error);
       dispatch({ type: STOP_LOADING});
       dispatch({type:STOP_STEP_SLIDER_LOADING})
       dispatch({type: CATCH_API_ERROR, payload: error})
    })
}

export const SkipStep = (achievementId,timestamp,stepId,uid, endActivity, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:START_STEP_SLIDER_LOADING})
    dispatch({type:EDITOR_LOADING})
    console.log(" Step skipping......");
    let url =`/users/${uid}/achievements/${achievementId}/steps/${stepId}/skip?timestamp=${date}&organization=${orgId}`; //users/{userId}/achievements/{achievementId}/steps/{stepId}/skip
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        // console.log("skip activity data=>",response.data)
        console.log("%c Step skipped for stepId:"+stepId,"color:green");
        dispatch({ type: STOP_LOADING});
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({type:STOP_STEP_SLIDER_LOADING});
        if(endActivity) {
            dispatch(EndActivity(achievementId, timestamp, uid, null, null, orgId))
        }
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while skipping step","color:red",errorMsg);
        dispatch({ type: STOP_LOADING});
        dispatch({type:STOP_STEP_SLIDER_LOADING})
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}
 export const EndStep = (achievementId,timestamp,stepId,uid, stepResponseData, endActivity, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp) 
    dispatch({type:START_STEP_SLIDER_LOADING})
    dispatch({type:EDITOR_LOADING})
    let stepPayloadData = null;
    if(stepResponseData) {
        stepPayloadData = stepResponseData;
    }
    console.log(" Step ending......");
    let url =`/users/${uid}/achievements/${achievementId}/steps/${stepId}/end?timestamp=${date}&organization=${orgId}`; //users/{userId}/achievements/{achievementId}/steps/{stepId}/end
   
    axiosClient.post(url, stepPayloadData, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Step ended for stepId:"+stepId,"color:green");
        dispatch({ type: GET_START_ACTIVITY,payload: response.data});
        dispatch({ type: GET_ENDSTEP});
        dispatch({type:STOP_STEP_SLIDER_LOADING});
        if(endActivity) {
            dispatch(EndActivity(achievementId, timestamp, uid, null, null, orgId))
        }
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while ending step","color:red",errorMsg);
        dispatch({ type: STOP_LOADING});
        dispatch({type:STOP_STEP_SLIDER_LOADING})
        dispatch({type: CATCH_API_ERROR, payload: error})
    })
}
export const EndActivity = (achievementId,timestamp,uid,endActivityResponseData,timer, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EDITOR_LOADING});
    let evaluationFormResponse = null;
    if(endActivityResponseData) {
        evaluationFormResponse = endActivityResponseData;
    }
    console.log(" Activity preview ending......");
    let url =`/users/${uid}/achievements/${achievementId}/end?timestamp=${date}&organization=${orgId}`; //users/{userId}/achievements/{achievementId}/end
    axiosClient.post(url, evaluationFormResponse, { 
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        }
    ).then((response) => {
        console.log("%c Activity preview ended for activityId:"+achievementId,"color:green");
        dispatch({ type: GET_END_ACTIVITY,payload:response.data});
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while ending activity preview","color:red",errorMsg);
        dispatch({ type: STOP_LOADING}); 
        dispatch({type: CATCH_API_ERROR, payload: error})      
    })
}

 export const cancelActivity = (achievementId,timestamp,uid, orgId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EDITOR_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/cancel?timestamp=${date}` //users/{userId}/achievements/{achievementId}/cancel
    let selectedOrgId = localStorage.getItem('selectedOrgId');
    if(selectedOrgId || orgId) {
        url = `${url}&organization=${selectedOrgId || orgId}`;
    }
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: GET_CANCEL_ACTIVITY,payload:response.data});
    }).catch((error) => {
         // error handling
         console.log("error occured ", error);
         dispatch({ type: STOP_LOADING});  
         dispatch({type: CATCH_API_ERROR, payload: error})   
    })
}
export const cancelActivityForConfigurator = (achievementId,timestamp,uid, routineId, timerTime, executeActivity) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("porgId")
    //let uid = localStorage.getItem('uid')
    let date=encodeURIComponent(timestamp)
    dispatch({type:EDITOR_LOADING})
    let url =`/users/${uid}/achievements/${achievementId}/cancel?timestamp=${date}&organization=${orgId}` //users/{userId}/achievements/{achievementId}/cancel
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({ type: GET_CANCEL_ACTIVITY,payload:response.data});
        if(executeActivity) {
            window.location.href = "/success";
        }
    }).catch((error) => {
         // error handling
         console.log("error occured ", error);
         dispatch({ type: STOP_LOADING});  
         dispatch({type: CATCH_API_ERROR, payload: error})   
    })
}
export const networkActivity = (orgIds, showLoading) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    const uid = localStorage.getItem('auth_uid')
    let url = `/users/${uid}/network-activity` //`/users/${uid}/network-activity` 
    if (orgIds && orgIds.length > 0) {
        url =`/users/${uid}/network-activity?organizations=${encodeURIComponent(JSON.stringify(orgIds))}` //users/{userId}/network-activity
    } 
    // showLoading != "hideLoading" ? dispatch({type:EDITOR_LOADING}) : ""
    dispatch({type: GET_NETWORK_ACTIVITY})
    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type:GET_NETWORK_ACTIVITY_SUCCESS,payload:response.data})
    }).catch((error) => {
        // error handling
        console.log("error occured ", error);
        dispatch({ type: STOP_LOADING}); 
        dispatch({type: CATCH_API_ERROR, payload: error})
    
    })
}

export const getlaunchpad = (orgIds) => (dispatch) => {
    console.log(" Fetching user launchpad-items for userId....");
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    dispatch({type:GET_LOADING})
    let url =`/users/${uid}/launchpad-items` //users/{userId}/launchpad-items
    if (orgIds && orgIds.length > 0) {
        url = `/users/${uid}/launchpad-items?organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    } 

    axiosClient.get(url,{headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched user lauchpad-items for userId:"+uid,"color:green");
        dispatch({type:GET_LAUNCHPAD,payload:response.data})
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user launchpad-items ","color:red", errorMsg);
        //console.log("error occured ", error);
        dispatch({ type: STOP_LOADING}); 
        dispatch({type: CATCH_API_ERROR, payload: error})  
    })
}

export const fetchlaunchpadURL = (selectedIndex) => (dispatch) => {
    const launchpadURL = new Promise(function(resolve, reject) {
        console.log(" Fetching user launchpad-items for userId....");
        let idToken = localStorage.getItem('idToken')
        let uid = localStorage.getItem('uid')
        dispatch({type:GET_LOADING})
        let url =`/users/${uid}/launchpad-items`
        axiosClient.get(url,{headers: {
            'Authorization': `Bearer ${idToken}`
            }
        }).then((response) => {
            console.log("%c Fetched user lauchpad-items for userId:"+uid,"color:green");
            dispatch({type:GET_LAUNCHPAD,payload:response.data})
            const url = response.data &&  response.data[selectedIndex] ? response.data[selectedIndex].url : null
            resolve(url)
        }).catch((error) => {
            // error handling
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error Occured while fetching user launchpad-items ","color:red", errorMsg);
            //console.log("error occured ", error);
            dispatch({ type: STOP_LOADING}); 
            dispatch({type: CATCH_API_ERROR, payload: error})  
            reject(null)
        })
    })
    return launchpadURL
}

export const adjustAchievementTimer = (id, duration, timestamp) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid');
    const orgId = localStorage.getItem("selectedOrgId")
    let date=encodeURIComponent(timestamp)
    let url =`/users/${uid}/achievements/${id}/expected-duration?timestamp=${date}&organization=${orgId}`;
    let payload = {
        "duration": duration
    }
    dispatch({type:ADJUST_ACHIEVEMENT_TIMER})
    axiosClient.post(url,payload, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" timer updated for achievement --> ", response.data)
        dispatch({type:ADJUST_ACHIEVEMENT_TIMER_SUCCESS,  payload: response.data})
    }).catch((error) => {
        // error handling
        dispatch({type:ADJUST_ACHIEVEMENT_TIMER_FAILED})
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Adjusting achievement timer ","color:red", errorMsg);
    })
}

export const adjustAchievementStepTimer = (id, stepId, duration, timestamp) => (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid');
    const orgId = localStorage.getItem("selectedOrgId")
    let date=encodeURIComponent(timestamp)
    let url =`/users/${uid}/achievements/${id}/steps/${stepId}/expected-duration?timestamp=${date}&organization=${orgId}`;
    let payload = {
        "duration": duration
    }
    dispatch({type:ADJUST_ACHIEVEMENT_TIMER})
    axiosClient.post(url,payload, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" timer updated for step --> ", response.data)
        dispatch({type:ADJUST_ACHIEVEMENT_TIMER_SUCCESS, payload: response.data})
    }).catch((error) => {
        // error handling
        dispatch({type:ADJUST_ACHIEVEMENT_TIMER_FAILED})
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Adjusting achievement timer ","color:red", errorMsg);
    })
}


export const getCourseFromConfigurator=(courseId)=> (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    let orgId =  localStorage.getItem('orgId');
    dispatch({type:GET_LOADING_FOR_COURSE})
    let url =`/users/${uid}/lms/courses/${courseId}/goto-url?organization=${orgId}`
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        //console.log("GET_COURES",response.data)
        dispatch({type:GET_COURES,payload:response.data})
    }).catch((error) => {
       // error handling
       console.log("error occured ", error);
       dispatch({ type: STOP_LOADING}); 
       dispatch({type: CATCH_API_ERROR, payload: error})   
    })
}
export const getCourse=(courseId)=> (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    const orgId = localStorage.getItem("selectedOrgId")
    console.log(" Fetching course details for courseId:",courseId);
    dispatch({type:GET_LOADING_FOR_COURSE})
    let url =`/users/${uid}/lms/courses/${courseId}/goto-url?organization=${orgId}`
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched course details for courseId:"+courseId,"color:green");
        dispatch({type:GET_COURES,payload:response.data})
    }).catch((error) => {
        // error handling
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error occured while fetching course details for courseId:"+courseId,"color:red",errorMsg);
        dispatch({ type: STOP_LOADING}); 
        dispatch({type: CATCH_API_ERROR, payload: error})    
    })
}
export const checkPIN=(value)=> (dispatch) => {
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    let body={pin:value}
    let url =`/users/${uid}/verify-admin-pin` //users/{userId}/verify-admin-pin
    axiosClient.post(url,body, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        //console.log("checkPIN",response.data)
        dispatch({type:GET_PINRESPONSE,payload:response.data.message})
    }).catch((error) => {
       // error handling
       console.log("error occured ", error);
       dispatch({type: CATCH_API_ERROR, payload: error})
       dispatch({ type:GET_PINRESPONSE,payload:'error'});
    })
}
export const removePinMsg=()=>(dispatch)=>{
    dispatch({type:REMOVE_PINRESPONSE})
}
export const getWPQ=()=>(dispatch)=>{
    let idToken = localStorage.getItem('idToken')
    let uid = localStorage.getItem('uid')
    let url =`/users/${uid}/wpq-sign-in-link` //users/{userId}/wpq-sign-in-link
    dispatch({type:GET_WPQ_LOADING})
    axiosClient.post(url,null, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        //console.log("getWPQ",response.data)
        dispatch({type:GET_WPQLINK,payload:response.data})
    }).catch((error) => {
         // error handling
         dispatch({type:HIDE_WPQ_LOADING})
         error = {...error, type:"wpq"}
         dispatch({type: CATCH_API_ERROR, payload: error});
         console.log("error occured ", error);
    })
}

export const setActivityType = (value) => (dispatch) => {
    dispatch({type:SET_ACTIVITY_TYPE,payload:value})
}

export const selectActivityId= (value) => (dispatch) => {
    dispatch({type:SELECT_ACTIVITY_ID,payload:value})
}

export const clearActivityDataOnClose = () => (dispatch) => {
    dispatch({ type: CLEAR_START_ACTIVITY_DETAILS});
}
export const handleShowPhotoCamera = (bool) => (dispatch) => {
    dispatch({ type: HANDLE_SHOW_PHOTO_CAMERA, payload: bool});
}

export const removeAPIError = (data) => (dispatch) => {
    dispatch({type: CATCH_API_ERROR, payload: data})
}

export const hideExecuteActivityConfirmation=()=>(dispatch)=>{
    dispatch({type:HIDE_EXECUTE_ACTIVITY_CONFIRMATION_PANEL})
}
export const showExecuteActivityConfirmation=(value)=>(dispatch)=>{
    dispatch({type:SHOW_EXECUTE_ACTIVITY_CONFIRMATION_PANEL,payload: value})
}

export const handleShowActivityDetailsPanel=(value)=>(dispatch)=>{
    dispatch({type:SHOW_ACTIVITY_DETAILS_PANEL,payload: value})
}

export const handleHideActivityDetailsPanel=()=>(dispatch)=>{
    dispatch({type:HIDE_ACTIVITY_DETAILS_PANEL})
}

export const setRealtimeCommoneActivities = (data) => (dispatch) => {
    dispatch({type: GET_ACTIVITIES_LIST})
    dispatch({ type: UPDATE_ACTIVITEIS_LIST,payload:data });
    dispatch({type:REALTIME_UPDATE_ACTIVITIES, payload: Math.random()});
 }


// Helper function to get steps completion list
const getStepsCompletionList = (stepsCompletion) => {
    if (!stepsCompletion) return [];
    return Object.values(stepsCompletion).filter(step => step.status === "Done" || step.status === "Skipped");
};


export const handleShowEncounterActivityHistory = (bool) => (dispatch) => {
    dispatch({type: HANDLE_ENCOUNTER_ACTIVITY_HISTORY, payload: bool})
}
export const handleShowEncounterActivityDetails = (bool) => (dispatch) => {
    dispatch({type: HANDLE_ENCOUNTER_ACTIVITY_DETAILS, payload: bool})
}
export const handleExecuteEncounterActivity = (bool) => (dispatch) => {
    dispatch({type: HANDLE_ENCOUNTER_ACTIVITY_EXECUTION, payload: bool})
}

export const setClientActivitiesType = (type) => (dispatch) => {
    dispatch({type: SET_CLIENT_ACTIVITIES_TYPE, payload: type})
}

export const gettouchpointActivitiesSummary = (userId, orgIds) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    let url = `/users/${userId}/activities/touchpoint-summary`
    if (orgIds && orgIds.length > 0) {
        url = `/users/${userId}/activities/touchpoint-summary?organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    }
    dispatch({ type: GET_ECOUNTER_ACTIVITIES_SUMMARY });
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log(" Fetched encounter activitties summary ");
        dispatch({ type: GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS, payload: response.data });
        dispatch(mappedEncounterSummaryWithRealtime(userId, response.data))
    }).catch(error => {
        dispatch({type: GET_ECOUNTER_ACTIVITIES_SUMMARY_FAILED})
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching encounter activities list for user:"+userId,"color:red", errorMsg);
    })
}

// export const mappedEncounterSummaryWithRealtime = (userId, apiData) => (dispatch) => {
//     const organization = localStorage.getItem('selectedOrgId');
//     const auth_uid = localStorage.getItem("auth_uid");

//     if (!organization || !userId) {
//         return; // Early exit if essential parameters are missing
//     }

//     const refValue = ref(database, `Realtime/${organization}/Users/${userId}/touchpointsInProgress`);
//     onValue(refValue, (snapshot) => {
//         const realtimeData = snapshot.val();
//         mergeRealtimeTouchpointSummaryData({summaryList: apiData, realtimeData, auth_uid}, ({ mergedList, realtimeEncounterList }) => {
//             dispatch({ type: GET_ECOUNTER_ACTIVITIES_SUMMARY });
//             dispatch({ type: GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS, payload: mergedList });
//         });
//     });
// };

export const mappedEncounterSummaryWithRealtime = (userId, apiData) => (dispatch) => {
    const organization = localStorage.getItem('selectedOrgId');
    const auth_uid = localStorage.getItem("auth_uid");

    // Early exit if essential parameters are missing
    if (!organization || !userId) {
        return;
    }

    // Initialize the Web Worker
    const worker = new Worker(new URL('../workers/WebWorkers/EncounterSummaryRealtimeWorker.js', import.meta.url));

    // Listen for messages from the worker
    worker.onmessage = (event) => {
        const { realtimeData } = event.data;

        mergeRealtimeTouchpointSummaryData({summaryList: apiData, realtimeData, auth_uid}, ({ mergedList }) => {
            dispatch({ type: GET_ECOUNTER_ACTIVITIES_SUMMARY });
            dispatch({ type: GET_ECOUNTER_ACTIVITIES_SUMMARY_SUCCESS, payload: mergedList });
        });

    };

    // Post initialization message to the worker
    worker.postMessage({
        type: 'initListener',
        path: `Realtime/${organization}/Users/${userId}/touchpointsInProgress`
    });

    // Cleanup function to terminate the worker
    return () => {
        worker.postMessage({ type: 'cleanup' });
        worker.terminate();
    };
};


export const resetWidgetData = () => (dispatch) => {
    dispatch({type: RESET_ACTIVITIES_CHAT_CONTACT_GROUP_LIST})
}

export const assingCommonActivityToClient = (filter, groupId, activityType, activityId) => (dispatch) => {

    const userId = localStorage.getItem("uid")
    const orgId = localStorage.getItem("selectedOrgId")
    const idToken = localStorage.getItem("idToken")

    console.log(" Unassigning unchecked activities for userId:",userId,"......");
    dispatch({ type: UNASSIGN_CLIENT_COMMON_ACTIVITY });
    axiosClient.post(`/users/${userId}/activities/un-assign?orgId=${orgId}&scope=General`, { activityIds: activityId }, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        if (response.status === 200) {   
            console.log("%c Unassigned unchecked activities for userId:"+userId,"color:green"); 
            dispatch({ type: UNASSIGN_CLIENT_COMMON_ACTIVITY_SUCCESS });
            dispatch(getClientActivitiesList({orgId, userId, filter, groupId, activityType}))
        } else {
            dispatch({ type: HIDE_UNASSIGN_ACTIVITY_LOADING });
        }
            
    }).catch(error => {
        const errorData = error.response ? { message: (error.response.data && error.response.data.messsage), title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
        // const errorData = { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
        dispatch({ type: UNASSIGN_ACTIVITY_TO_CLIENT_FAILED, payload: errorData })
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error occured while unassigning unchecked activity for userId:"+userId,"color:red",errorMsg);
    })
}

export const setActivityGroup = (activityGroups) => (dispatch) => {
    dispatch({type: SELECT_ACTIVITY_FILTER_GROUP, payload: activityGroups})
}

export const clearUserActivitiesData = () => (dispatch) => {
    dispatch({type: CLEAR_USER_ACTIVITIES_DATA})
}
