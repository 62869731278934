import React, { Component } from "react";
import LifeSherpaLoading from "../LifeSherpaLoading";

class AssingActivitiesIFrame extends Component {
    state = {
        loading: true,
    }
   
    hideSpinner = (e) => {
        this.setState({ ...this.state, loading: false });
        console.log("%c Assign activity app loaded successfully............","color:green")
        window.clsAssignRef = this
        window.onmessage = function(e) {
        if (e.data == 'close_iframe') {
            window.clsAssignRef.setState({ ...this.state, loading: true });
            window.clsAssignRef.props.handleCloseAssignActivity();
        }
    };
       
    }

    render() {
        let { assignActivityUrl } = this.props;
        let { loading } = this.state;
        // console.log(loading, assignActivityUrl);
        return (
            <React.Fragment>

                 <LifeSherpaLoading loading={loading}/>
                <div style={{ visibility: loading ? "hidden" : "visible" }}>
                    <iframe
                        src={assignActivityUrl ? assignActivityUrl : ''}
                        onLoad={this.hideSpinner}
                        scrolling="yes"
                        id="assignActivityIFrame"
                        className="w-100 assign-activity-iframe"
                        display="initial"
                        position="relative"
                    ></iframe>
                </div>
            </React.Fragment>
        )
    }
}

export default AssingActivitiesIFrame;
