/**
 * User List Item
 */
import React from 'react';
import {
	Label,
	Input,
} from 'reactstrap';
import Divider from '@mui/material/Divider';

const TextInpuItem = ({ obj, updateform, section, showError }) => (
    <div key={section+"textinputitem1"} className="mb-2">
		{obj.heading ? <Label className="form-label">{`${obj.heading}`}<span className='text-red'>{obj.isMandatory ? " *" : ""}</span></Label> : <Divider className="my-2"/>}
        <Input className="form-input-field" style={{backgroundColor: 'white'}} onChange={(e) => updateform(e, obj, section)} value={obj.text} type="text" placeholder={obj.placeholder}/>
        {/*<Divider className="mb-2"/>*/}
		{showError && obj.isMandatory && (!obj.text) && <div className="input-options-error">{`${obj.heading} is required`}</div>}
    </div>
);

export default TextInpuItem;
