import React from 'react'

//Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
//import withMobileDialog from '@mui/material/withMobileDialog';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import UnConifrmChievements from '../UnConfirmAchievements'


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
  closeButtonMobile: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'white',
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <div>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { md: 'none' } }}>
            <IconButton aria-label="close" className={classes.closeButtonMobile} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </div>
      ) : null}
    </MuiDialogTitle>
  );
});

class SherpaPopup extends React.Component {

  handleSaveAndContinue(){

  }

  handleCancel(){
    this.props.handleClose()
  }

  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={this.props.open}
        // disableBackdropClick={true}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="bg-primary text-white" onClose={this.handleCancel.bind(this)} style={{borderBottom: '1px solid'}}>
          <div className="d-flex align-items-center w-90">
            <div className="media-left position-relative mr-10">
              <img src={this.props.profileImage} className="img-fluid rounded-circle" alt="user profile" width="40" height="40" />
            </div>
            <div className="media-body pt-5">
              <h2 className="mb-0">{this.props.displayName}</h2>
              <h4 className="mb-0">{'Unconfirmed Activities'}</h4>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers id="scrollable" className="p-0" style={{backgroundColor: '#f5f5f5'}}>
          <UnConifrmChievements selectedMemberId={this.props.selectedMemberId} unconfirmAchievements={this.props.length} />
        </DialogContent>
        <DialogActions className="bg-primary text-white d-flex align-items-center justify-content-center" style={{borderTop: '1px solid'}}>
          <Button className="text-white" >{''}</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default SherpaPopup;

//withMobileDialog();