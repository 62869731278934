/**
 * App Routes
 */
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';


import NotificationPopup from "../NotificationsHandler/NotificationPopup";



// actions
//helpers
//components
import ChatSections from "../LifesherpaChatPanel/components/ChatSections";
import VideoChatPanel from "../LifesherpaChatPanel/components/VideoChatPanel";

import VideoCallConfirmation from "../LifesherpaContacts/VideoCallConfirmation";
import MissedCallConfirmation from "../LifesherpaContacts/MissedCallConfirmation";

import ExecuteActivityConfirmation from "../NotificationsHandler/ExecuteActivityConfirmation";
import ActivityCompletionConfirmation from "../NotificationsHandler/ActivityCompletionConfirmation";
import Notices from '../Notices';
import ClientDashboard from '../UserDetails/components/ClientDashboard';

// rct page loader
import AppMyActivity from '../AppMyActivity';
import PasswordUpdate from '../Header/PasswordUpdate';
import ActivityEditorSidebar from "../AppMyActivity/components/ActivityEditorSidebar";

import UserWorkflow from "../Workflows/components/UserWorkflow";
import StartWorkflowView from "../Workflows";



class PopupComponents extends Component {
	


	render() {
		let { selectedChatUsers, selectedVideoChat, showVideoRoom, showVideoCallConfirmationPanel, videoCallMessage, showMissedCallConfirmationPanel } = this.props;
		const { windowWidth } = this.props;
		return (
            <React.Fragment>
                {selectedChatUsers && selectedChatUsers.length > 0 ? <ChatSections windowWidth={windowWidth} /> : <></>}
                <ExecuteActivityConfirmation />
                <ActivityCompletionConfirmation />
                <NotificationPopup />
                {this.props.noticePanel && <Notices />}
                {this.props.showClientDashBaord && <ClientDashboard />}
                {this.props.startActivityPanel && <AppMyActivity/>}
                {showVideoRoom && selectedVideoChat ? <VideoChatPanel windowWidth={windowWidth} /> : <></>}
                {showVideoCallConfirmationPanel && videoCallMessage && <VideoCallConfirmation />}
                {showMissedCallConfirmationPanel && videoCallMessage && <MissedCallConfirmation />}
                {this.props.passwordDialog && <PasswordUpdate/>}
                {Boolean(this.props.activityEditorConfig) ? <ActivityEditorSidebar/> : <></>}
                {this.props.userWorkflowPanel ? <UserWorkflow openUserWorkflow={this.props.userWorkflowPanel}/> : <></>}
                {this.props.startWorkflowPanel ? <StartWorkflowView />	: <></>}
            </React.Fragment>
        )
	}
}

// map state to props
const mapStateToProps = ({ chatAppReducer, Contacts, activitiesList, noticesReducer, clientsList, authUser, workflowData }) => {
	const { selectedVideoChat, showVideoRoom, showVideoCallConfirmationPanel, videoCallMessage, showMissedCallConfirmationPanel } = Contacts
	const {  selectedChatUsers } = chatAppReducer;
	const { startActivityPanel, activityEditorConfig } = activitiesList;
	const { noticePanel } = noticesReducer;
	const { showClientDashBaord } = clientsList;
	const { passwordDialog} = authUser;
	const {userWorkflowPanel, startWorkflowPanel} = workflowData
	return { selectedChatUsers, selectedVideoChat, showVideoRoom, showVideoCallConfirmationPanel, videoCallMessage, showMissedCallConfirmationPanel, noticePanel, showClientDashBaord, startActivityPanel, passwordDialog, activityEditorConfig, userWorkflowPanel, startWorkflowPanel }
}

export default connect(mapStateToProps, {})(PopupComponents);

