/**
 * Chat Area Component
 */
import React, { Component } from 'react';

import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
import RctCollapsibleChatCard from 'Components/RctCollapsibleCard/RctCollapsibleChatCard';
import SendIcon from '@mui/icons-material/Send';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LifeSherpaLoading from 'Components/LifeSherpaLoading';
import config from '../../../config/config'
import ChatBlock from "./ChatBlock";
import { v4 as uuidv4 } from 'uuid';
import SweetAlert from 'react-bootstrap-sweetalert';

// actions
import {
    activitiesOpen,
    chatOpen,
    savePokrFormForClients,
    removeSelectedChatUser,
    showFullChatPanelForSelectedUser,
    selectMenuItem,
    networkActivity
} from '../../../actions'

// app layouts
import { getAppLayout } from 'Helpers/helpers';

class MiniChatSection extends Component {

    state = {
        text: "",
        messages: [],
        loading: false,
        conversation: null,
        anchorEl: null,
        chatOptions: [
            'Mute Notifications',
            'Block',
            'Clear Chat',
            'Send Contact'
        ],
        textMenuOpen: null,
        minimize: false,
        videoCallRoomName: null,
        showUnAvailabelAlert: false,
        alertMessage: ""
    }
    componentDidMount = async () => {
        let { selectedUser } = this.props;
        console.log("[MiniChatSection] SelectedUser -->", selectedUser);
        if (selectedUser.conversation) {
            this.setState({ conversation: selectedUser.conversation, messages: selectedUser.messages });
        }
        if(selectedUser && selectedUser.status == "Unavailable") {
			this.setState({showUnAvailabelAlert: true, alertMessage: "User is unavailable."})
		}
    }
    updateMessages = (messages, callback) => {
        this.setState({ messages: messages }, () => {
            if(callback) {
                callback();
            }
        })
    }
    updateConversation = (conversation) => {
        this.setState({ conversation: conversation })
    }
    sendMessage = (e) => {
        const { text, conversation, videoCallRoomName } = this.state;
        let { selectedUser } = this.props;
        if (text && String(text).trim()) {
            this.setState({ loading: true, text: "" });
            conversation.sendMessage(text).then(() => {
                this.setState({ loading: false });
            }).catch((error) => {
                console.log("[MiniChatSection] error occured while sending message", error, error.message, error.data);
                this.setState({ loading: false , showUnAvailabelAlert: true, alertMessage: error.message});
            })
        }
    }

    handleMinimizeSection = () => {
        this.setState({ minimize: !this.state.minimize });
    }
    chatOptionsHandler = event => {
        this.setState({ anchorEl: event.currentTarget });
    }
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    openTextMenuOptions = event => {
        this.setState({ textMenuOpen: event.currentTarget });
    }
    closeTextMenuOptions = () => {
        this.setState({ textMenuOpen: null });
    };
    sendVidoeLink = () => {
        let { selectedUser } = this.props;
        const selectedVideoChat = { ...selectedUser };
        const roomname = uuidv4() //`${Math.random().toString(36).substr(2, 6)}-${Math.random().toString(36).substr(2, 5)}-${Math.random().toString(36).substr(2, 3)}`;
        selectedVideoChat.name = roomname;
        let videoCallRoom = `Join Video Call: ${config.lsPortalUrl}/lifesherpa/videocall?room=${selectedVideoChat.name}`;
        //let videoCallRoom = `Join Video Call: http://localhost:8000/lifesherpa/videocall?room=${selectedVideoChat.name}`;
        this.setState({ text: videoCallRoom, textMenuOpen: null, videoCallRoomName: selectedVideoChat.name });
    }
    
    removeSelectedChatUser = (userId) => {
        let { selectedChatUsers } = this.props;
        let index = selectedChatUsers.findIndex(user => user.userId == userId);
        if (index >= 0) {
            selectedChatUsers.splice(index, 1);
            this.props.removeSelectedChatUser(selectedChatUsers);
        }
       this.props.networkActivity()
    }
    showFullChatPanelForUser = () => {
        const { selectedUser } = this.props;
        const { userId } = selectedUser;
        const { messages, conversation } = this.state;
        selectedUser.messages = messages;
        selectedUser.conversation = conversation;
        this.removeSelectedChatUser(userId);
        // this.props.selectMenuItem(2);
        this.props.showFullChatPanelForSelectedUser(selectedUser);
        this.props.navigate("/app/lsdashboard/chat");
    }

    handleEnter = (evt) => {
        if (evt.keyCode == 13 && evt.shiftKey) {
            //evt.preventDefault();
            //this.setState({text: this.state.text+"\n"})
            //evt.preventDefault();
            this.activeTyping();
        } else if (evt.keyCode == 13) {
            evt.preventDefault();
            this.sendMessage(evt)
        } else {
            this.activeTyping();
        }
    }
    activeTyping = () => {
		const { conversation } = this.state;
		if (conversation) {
			conversation.typing().then((s) => {
			console.log("[MiniChatSection] typing enable", s)
		  }).catch((error) => {
			console.log("[MiniChatSection] error occured while enabling typing", error);
		  })
		}
	}


    render() {
        const { selectedUser, loading } = this.props;
        const { text, messages, conversation, chatOptions, anchorEl, minimize, textMenuOpen, showUnAvailabelAlert, alertMessage } = this.state;
        return (
            <div className={this.props.screenThemeClass}>
                <RctCollapsibleChatCard
                    colClasses="chatsection"
                    heading={selectedUser.name || "Client"}
                    fullBlock
                    customClasses="overflow-hidden theme-background"
                    headingCustomClasses="bg-lifesherpa "
                    removeSelectedChatUser={() => this.removeSelectedChatUser(selectedUser.userId)}
                    userKey={selectedUser.userId}
                    handleMinimizeSection={this.handleMinimizeSection}
                    minimize={minimize}
                    showFullChatPanelForUser={this.showFullChatPanelForUser}
                >
                    <div className=" list-card">
                        <div className="d-flex align-items-center justify-content-between">
                            {/* <div className="pl-2 d-flex align-items-center  chat-title fw-semi-bold">
                                <FiberManualRecordIcon className={`${selectedUser.status && selectedUser.status == "Available" ? "active-status-icon" : "inactive-status-icon "}`} />
                                <div className="ml-1">
                                    {selectedUser.status ? selectedUser.status : "Unavailable"}
                                </div>
                            </div> */}
                            {/* <IconButton
									aria-owns={anchorEl ? 'simple-menu' : null}
									aria-haspopup="true"
									onClick={this.chatOptionsHandler}
                                    className="menu-icon-button"
								>
									<MoreVertIcon color="primary"/>
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                            >
                                {chatOptions.map((option, key) => (
                                    <MenuItem key={key} onClick={this.handleClose}>{option}</MenuItem>
                                ))}
                            </Menu> */}
                        </div>
                    </div>
                    { showUnAvailabelAlert ?
						<SweetAlert
							warning
							btnSize="sm"
							customClass="warningText"
							confirmBtnText="Close"
							confirmBtnBsStyle="warning"
							title={alertMessage}
							onConfirm={() => this.setState({showUnAvailabelAlert: false})}
						>
							<div className="sweet-alert-text">Please try again later.</div>
						</SweetAlert> : ""
					}
                    {loading ?
                        <div style={{ height: "320px" }}>
                            <LifeSherpaLoading loading={true} />
                        </div>
                        :
                        <div className="chat-panel-messges">
                            <ChatBlock selectedUser={selectedUser} messages={messages} userCoversation={conversation} updateConversation={this.updateConversation} updateMessages={this.updateMessages} />
                        </div>
                    }

                    <div className="chat-footer d-flex px-1 align-items-center py-2">
                        {/* <IconButton 
                            aria-owns={textMenuOpen ? 'simple-menu-text' : null}
                            aria-haspopup="true"
                            onClick={this.openTextMenuOptions}
                            className="add-more-icon-button"
                             >
                            <AddIcon className="primary-icon"/>
                        </IconButton>
                        <Menu
                            id="simple-menu-text"
                            anchorEl={textMenuOpen}
                            open={Boolean(textMenuOpen)}
                            onClose={this.closeTextMenuOptions}
                            >
                                <MenuItem onClick={() => this.sendVidoeLink()}> <VideocamIcon className="primary-icon mr-1"/> Create Video Room</MenuItem>
                         </Menu> */}
                        <div className="ml-1 w-100">
                            {/* <FormGroup className="mb-0">
                                 <Input
                                     type="text"
                                     id="search-msg"
                                     placeholder="Type your message"
                                     value={this.state.text}
                                     className="msg-input"
                                     onChange={(event) => this.setState({ text: event.target.value, })}
                                     onKeyPress={(e) => this.handleEnter(e)}
                                     onKeyDown={(e) => this.handleEnter(e)}
                                 />
                             </FormGroup> */}
                            <textarea
                                rows="2"
                                autoComplete="off"
                                className="chat-msg-input-field"
                                placeholder={selectedUser.status === "Unavailable" ? "User is unavailable" : (selectedUser.chat != undefined && !selectedUser.chat) ? "Chat is not supported" : "Type your message"}
                                onChange={(event) => this.setState({ text: event.target.value })}
                                onKeyPress={(e) => this.handleEnter(e)}
                                onKeyDown={(e) => this.handleEnter(e)}
                                value={this.state.text}
                                disabled={selectedUser.status === "Unavailable" || (selectedUser.chat != undefined && !selectedUser.chat)}
                            />
                        </div>
                        <IconButton
                            onClick={(e) => this.sendMessage(e)}
                            disabled={selectedUser.status === "Unavailable" || (selectedUser.chat != undefined && !selectedUser.chat) ? true : text && String(text).trim() ? false : true}
                            className="send-icon-button">
                            <SendIcon />
                        </IconButton>
                    </div>
                </RctCollapsibleChatCard>
            </div>
        )


    }
}

const mapStateToProps = ({ settings, chatAppReducer, Contacts }) => {
    const { screenThemeClass, popupThemeClass } = settings;
    const { selectedChatUsers } = chatAppReducer;
    const { loading } = Contacts
    return { screenThemeClass, selectedChatUsers, loading }
}

export default withRouter(connect(mapStateToProps, {
    activitiesOpen,
    chatOpen,
    savePokrFormForClients,
    removeSelectedChatUser,
    showFullChatPanelForSelectedUser,
    selectMenuItem,
    networkActivity
})(MiniChatSection));
