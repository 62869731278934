/**
* Chat
*/
import React, { Component } from 'react';
import Drawer from '@mui/material/Drawer';
import { withStyles } from '@mui/styles';
import config from '../../../config/config';
import {Capacitor} from '@capacitor/core'

// components
import { connect } from 'react-redux';
import { } from "../../../actions"
import ActivityEditorIframe from "../../LifesherpaiFrame/ActivityEditorIframe";
import { reauthenticateIfTokenExpireWithIn30Min } from '../../../helpers/helpers';
import LifeSherpaLoading from "../../LifeSherpaLoading";
import { 
	getActivitiesList,
	getActivitiesCalendar,
	showActivityEditor,
	logoutCallbackForAuth0Expired,
	getClientActivitiesList,
	getFilteredActivitiesList
} from 'Actions'

const drawerWidth = 400;

const styles = theme => ({
	root: {
		flexGrow: 1,
		height: 'auto',
		zIndex: 1,
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		width: '100%',
	},
	drawerHeight: {
		height: '100%',
	},
	content: {
		flexGrow: 1
	},
});

class ActivityEditorSidebar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			editorURL:""
		}
	}

	async componentDidMount() {
        const tokenAuthentication = await reauthenticateIfTokenExpireWithIn30Min();
		const { activityEditorConfig } = this.props;
		console.log("activityEditorConfig ", activityEditorConfig);
		document.addEventListener('nativeBackClick', this.handleNativeBack);
		if(tokenAuthentication) {
			const {editorType, activityId, scope, organization} = activityEditorConfig;
			const themeName = this.props.activeTheme && this.props.activeTheme.name ? this.props.activeTheme.name : "default"
			const theme = themeName.charAt(0).toUpperCase() + themeName.slice(1);
			const idToken = localStorage.getItem("idToken")
			const orgId = organization || localStorage.getItem("selectedOrgId")
			const userId = localStorage.getItem("uid");
			let editorURL = `${config.objectEditorUrl}/v2/activity/${editorType}?userId=${userId}&idToken=${idToken}&orgId=${orgId}&mode=web&theme=${theme == "Danger" ? "Theme4" : theme}`;
			if (scope) {
				editorURL += `&scope=${scope}`
			}
			if (activityId) {
				editorURL += `&activityId=${activityId}`
			}
			editorURL += `&platform=${Capacitor.getPlatform()}`
			
			this.setState({editorURL, loading:false});
		} else {
			console.log("Token authentication failed -->", tokenAuthentication)
			this.setState({loading:false})
			this.props.showActivityEditor(null);
			logoutCallbackForAuth0Expired()
		}
	}

    componentWillUnmount() {
		document.removeEventListener('nativeBackClick', this.handleNativeBack);
	}

    handleNativeBack = () => {
		this.props.showActivityEditor(null);
	}

	handleClickOnOutSide(){
		console.log("Please click on back button to dismiss sidebar!");
	}

	handleCloseObjectEditor() {
		console.log('Activity Editor closed');
		const { activityEditorConfig, clientActivitiesType, appliedActivityFilter, appliedClientActivityFilter} = this.props;
		const {organization} = activityEditorConfig || {};
		const orgId = organization || localStorage.getItem("selectedOrgId");
		const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
		if(auth_uid !== uid) {
			const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null;
            appliedClientActivityFilter ? this.props.getFilteredActivitiesList(uid, appliedClientActivityFilter, null, null) : this.props.getClientActivitiesList({userId: uid, orgId, activityType});
		} else {
			appliedActivityFilter ? this.props.getFilteredActivitiesList(auth_uid, appliedActivityFilter, null, null) : this.props.getActivitiesList(auth_uid, '');
			this.props.getActivitiesCalendar(auth_uid);
		}
		this.props.showActivityEditor(null);
	}


	render() {
		const { classes } = this.props;
		const { loading, editorURL } = this.state;
		console.log("editorURL  -> ", editorURL, loading)
		return (
			<>
				<Drawer
					anchor='right'
					className={classes.drawerHeight}
					open={Boolean(this.props.activityEditorConfig)}
					onClose={process.env.SERVER_MODE === 'development' ? this.handleCloseObjectEditor.bind(this) : this.handleClickOnOutSide()}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						sx: (theme) => ({
							width: '100%',
							height: '100%',
							[theme.breakpoints.up('md')]: {
								width: drawerWidth,
							},
							backgroundColor: '#fff',
							overflow: "hidden"
						})
					}}
					id="activity-editor-drawer"
				>
					<LifeSherpaLoading loading={loading} />
					{!loading &&
						<ActivityEditorIframe
							editorURL={editorURL}
							handleCloseObjectEditor={this.handleCloseObjectEditor.bind(this)}
							scope={this.props.scope}

						/>
					}
				</Drawer>

			</>
		);
	}
}

const mapStateToProps = ({ settings, activitiesList, GroupList, ActivityReducer}) => {
	const { activeTheme } = settings;
	const {activityEditorConfig, clientActivitiesType} = activitiesList;
	const { selectedGroup } = GroupList;
	const { appliedActivityFilter, appliedClientActivityFilter } = ActivityReducer
	return { activeTheme , activityEditorConfig, selectedGroup, clientActivitiesType, appliedActivityFilter, appliedClientActivityFilter}

}

export default connect(mapStateToProps, {
	getActivitiesList,
	getActivitiesCalendar,
	showActivityEditor,
	getClientActivitiesList,
	getFilteredActivitiesList
})(withStyles(styles, { withTheme: true })(ActivityEditorSidebar));
