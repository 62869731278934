/**
 * User List
 */
import React, { Component, Suspense, lazy} from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
// redux actions
// import { Route, Switch } from 'react-router-dom';

// component's

import LifeSherpaLoading from '../../LifeSherpaLoading';

import { isMobile } from '../../../helpers/helpers';
// import Dashboard from './Widgets/Dashboard';
// import Activities from './Widgets/Activities';
// import Courses from './Widgets/Courses';
// import Resources from './Widgets/Resources';
// import Rewards from './Widgets/Rewards';
// import Workflow from './Widgets/Workflow';

const Dashboard = lazy(() => import('./Widgets/Dashboard'));
const Activities = lazy(() => import('./Widgets/Activities'));
const Courses = lazy(() => import('./Widgets/Courses'));
const Rewards = lazy(() => import('./Widgets/Rewards'));
const Resources = lazy(() => import('./Widgets/Resources'));
import FallbackPage from '../../Widgets/FallbackPage';
const Workflow = lazy(() => import('./Widgets/Workflow'));


class HomePage extends Component {

    state = {}

    render() {
        const { match, selectedClientMenuItem, clientPanel, enableWorkflows } = this.props;

        return (
            <React.Fragment>
                {isMobile() ? 
                    clientPanel ? 
                        <Suspense fallback={<LifeSherpaLoading loading={true}/>}>
                            <React.Fragment>
                                <div className="row" style={{ display: selectedClientMenuItem === '/app/lsdashboard/client/activities' ? 'block' : 'none' }}>
                                    <Activities  />
                                </div>
                                {enableWorkflows &&
                                    <div className="row" style={{ display: selectedClientMenuItem === '/app/lsdashboard/client/workflows' ? 'block' : 'none' }}>
                                        <Workflow  />
                                    </div>
                                }
                                <div className="row" style={{ display: selectedClientMenuItem === '/app/lsdashboard/client/resources' ? 'block' : 'none' }}>
                                    <Resources  />
                                </div>
                                <div className="row" style={{ display: selectedClientMenuItem === '/app/lsdashboard/client/rewards' ? 'block' : 'none' }}>
                                    <Rewards  />
                                </div>
                                <div className="row" style={{ display: selectedClientMenuItem === '/app/lsdashboard/client/courses' ? 'block' : 'none' }}>
                                    <Courses  />
                                </div>
                                <div className="row" style={{ display: selectedClientMenuItem === '/app/lsdashboard/client' ? 'block' : 'none' }}>
                                    <Dashboard  />
                                </div>
                            </React.Fragment>
                        </Suspense>
                    :
                    <FallbackPage/>
                : 
                <div className='row'>
                    <Suspense fallback={<LifeSherpaLoading loading={true}/>}>
                        <Dashboard />
                        {enableWorkflows && <Workflow />}
                        <Activities />
                        <Resources />
                        <Courses />
                        <Rewards />
                    </Suspense>
                </div>
                }
                {/* {isMobile() ?
                    <div className='row'>
                         {
                                selectedClientMenuItem === "/app/lsdashboard/client/activities" ? 
                                    <Suspense fallback={<LifeSherpaLoading loading={true}/>}><Activities /></Suspense>
                                : selectedClientMenuItem === "/app/lsdashboard/client/resources" ? 
                                    <Suspense fallback={<LifeSherpaLoading loading={true}/>}><Resources /></Suspense>
                                : selectedClientMenuItem === "/app/lsdashboard/client/rewards" ? 
                                    <Suspense fallback={<LifeSherpaLoading loading={true}/>}><Rewards /></Suspense>
                                : selectedClientMenuItem === "/app/lsdashboard/client/courses" ? 
                                    <Suspense fallback={<LifeSherpaLoading loading={true}/>}><Courses /></Suspense>
                                : selectedClientMenuItem === "/app/lsdashboard/client" ? 
                                    <Suspense fallback={<LifeSherpaLoading loading={true}/>}><Dashboard /></Suspense>
                                : 
                                <   Suspense fallback={<LifeSherpaLoading loading={true}/>}><Dashboard /></Suspense>
                            }
                    </div>
                    // <Switch>
                    //     <Route path={`${match.url}/dashboard`} render={(props) => <Dashboard {...props} isSidebarEnable={isSidebarEnable} />} />
                    //     <Route path={`${match.url}/activities`} render={(props) => <Activities {...props} isSidebarEnable={isSidebarEnable} />} />
                    //     <Route path={`${match.url}/courses`} render={(props) => <Courses {...props} isSidebarEnable={isSidebarEnable} />} />
                    //     <Route path={`${match.url}/resources`} render={(props) => <Resources {...props} isSidebarEnable={isSidebarEnable} />} />
                    //     <Route path={`${match.url}/rewards`} render={(props) => <Rewards {...props} isSidebarEnable={isSidebarEnable} />} />
                    //     <Route path={`${match.url}/workflows`} render={(props) => <Workflow {...props} isSidebarEnable={isSidebarEnable} />} />
                    //     <Route path={`${match.url}/`} render={(props) => <Dashboard {...props} isSidebarEnable={isSidebarEnable} />} />
                    // </Switch>
                    :
                    <div className='row'>

                        <Suspense fallback={<LifeSherpaLoading loading={true}/>}><Dashboard /></Suspense>
                        <Suspense fallback={<LifeSherpaLoading loading={true}/>}><Activities /></Suspense>
                        <Suspense fallback={<LifeSherpaLoading loading={true}/>}><Resources /></Suspense>
                        <Suspense fallback={<LifeSherpaLoading loading={true}/>}><Courses /></Suspense>
                        <Suspense fallback={<LifeSherpaLoading loading={true}/>}><Rewards /></Suspense>
                    </div>
                } */}

            </React.Fragment>

        );
    }
}

const mapStateToProps = ({ settings, clientsList, authUser }) => {
    const { selectedClientMenuItem } = settings;
    const {clientPanel} = clientsList;
    const {enableWorkflows} = authUser
    return { selectedClientMenuItem, clientPanel, enableWorkflows };
};

export default withRouter(connect(mapStateToProps, {})(HomePage));;
