import React, { Component } from 'react';
//Redux method to subscribe class
import { connect } from 'react-redux';
// WithRouter 
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { getClientStatusDataById, getWPQ, logoutUserFromFirebase, removePinMsg, checkPIN, getCourse, collapsedSidebarAction, updateStatus, setStatusColor, getUserStatusById, getPokeForm } from '../../../actions';
import '../../../assets/scss/custom/startActivityDetails.scss'
//SweetAlert
import SweetAlert from 'react-bootstrap-sweetalert'
//reactstrap Components
import { FormGroup, Input, Label, Badge } from 'reactstrap';
//Auth0 Client
import { withAuth0 } from "@auth0/auth0-react";
//Material UI Components
import LifeSherpaLoading from '../../LifeSherpaLoading';
//Material UI Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SetCurrentStatus from '../../SetCurrentStatus';
import Button from '@mui/material/Button';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import { openURL, capTextSpeech } from '../../../helpers/helpers';

class SActivityListViewDetails extends Component {
	state = {
		speak: false,
		StatusPopup: false,
		statusList: [],
		maxStepTimeout: null,
		showCheckIn: false,
		pin: '',
		pinErr: null,
		totalTry: 4,
		showTimer: false,
		hour: '',
		min: '',
		sec: '',
		showEvent: false,
		openedItem: "",
		warningPopup:"",
		countDownStart: false,
		countDownInterval: 0
	}
	componentDidMount() {
		const { checkList, stepIndex } = this.props;
		if (this.props.step.cardType === 'Course') {
			this.props.getCourse(this.props.step.courseId)
		}
		if (this.props.step.cardFormId) {
			if (!checkList.completeForm) {
				this.props.updateCheckList("completeForm");
			}
		}
		if (this.props.step.cardType === 'wpq') this.props.getWPQ()
		
		let { maxStepTimeout } = this.state
		  maxStepTimeout = this.props.step.countDownInterval ? this.props.step.countDownInterval : 0;
		let { step } = this.props;
		let countDownInterval = 0
		if ((step.cardType === "countDown" || step.cardType === "countDownEnforced" || step.cardType === "countDownPermissive") && step.countDownInterval > 0) {
			countDownInterval = step.countDownInterval
		}
		this.setState({ maxStepTimeout, countDownInterval })
	}

	componentWillUnmount() {
		clearInterval(this.intervalID1);
	}

	handlecountDownInterval = () => {
		this.setState({ countDownStart: true })
		if (this.state.countDownInterval > 0) {
			this.intervalID1 = setInterval(
				() => {
					this.setState({
						countDownInterval: this.state.countDownInterval - 1
					})
					if (this.props.step.cardType === "countDownEnforced" && this.state.countDownInterval <= 1) {
						this.props.handleNextStep(this.props.step.stepId, this.props.stepIndex)
						clearInterval(this.intervalID1);
					} else if(this.state.countDownInterval <= 1) {
						clearInterval(this.intervalID1);
					}
				},
				1000
			);
		} else {
			if (this.props.step.cardType === "countDownEnforced") {
				this.props.handleNextStep(this.props.step.stepId, this.props.stepIndex)
			}
		}
		
	}

	updateStatusPopup = () => {
		let idToken = localStorage.getItem('idToken')
		let uid = localStorage.getItem('uid')
		const auth_uid = localStorage.getItem('auth_uid');
		if (auth_uid == uid) {
			console.log("got auth user activiity........")
			this.props.getUserStatusById(auth_uid, idToken)
		}
		else {
			console.log("got user activiity........")
			this.props.getClientStatusDataById(uid, idToken)
		}
		this.setState({ StatusPopup: true })
	}

	handleStepFrom = (formId) => {
		this.props.setActiveStep(this.props.stepIndex);
		this.props.showForm(this.props.step.stepId,formId, this.props.stepIndex)
	}

	handleStart = () => {
		this.handlecountDownInterval()
	}

	handlePIN = (n) => {
		let { pin, totalTry } = this.state
		pin = pin + n
		if (pin.length === 4) {
			this.props.checkPIN(pin)
			totalTry = totalTry - 1
			this.setState({ totalTry, pin })
		}
		else this.setState({ pin })
	}

	handleBack = () => {
		let { pin } = this.state
		pin = pin.slice(0, -1)
		this.setState({ pin })
	}

	handleClose = () => {
		this.props.removePinMsg()
		this.setState({ pin: '', showCheckIn: false, totalTry: 4 })
		this.props.handleNextStep(this.props.step.stepId, this.props.stepIndex)
	}

	logoutUser() {
		const {logout} = this.props.auth0
		this.props.logoutUserFromFirebase(this.props.history, logout);
	}

	handleHour = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 25) {
			this.setState({ hour: +e.currentTarget.value })
		}
	}

	handleMin = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 60) {
			//console.log("handleHour",i)
			this.setState({ min: +e.currentTarget.value })
		}
	}

	handleSec = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if ((i > 0 || i === 0) && +e.currentTarget.value < 60) {
			this.setState({ sec: +e.currentTarget.value })
		}
	}

	handleSec = (e) => {
		let numbers = /^[0-9]+$/;
		let i = +e.currentTarget.value.match(numbers)
		if (i > 0 && +e.currentTarget.value < 59) {
			this.setState({ sec: +e.currentTarget.value })
		}
	}

	updateTimer = () => {
		let { hour, min, sec } = this.state
		let totalhrs = hour ? hour * 3600 : 0;
		let totalmin = min ? min * 60 : 0;
		let total = totalhrs + totalmin + sec
		this.setState({ showTimer: false , countDownInterval: total});
	}
  
	handelStatusEditClose = () => {
		this.setState({ StatusPopup: false, statusList: [] })
		this.props.handleNextStep(this.props.step.stepId, this.props.stepIndex)
	}
	updateStatus = (statusList) => {
		if(this.props.executeActivity) {
			console.log(" Clicked on submit set status form");	
			let data = this.props.Status.status
			if(data && data.sections) {
				Object.keys(data.sections).map((obj) => {
					const index = statusList.findIndex(item => obj.includes(item.userStatusSetId))
					data.sections[obj] = statusList[index]
				});
				let uid = localStorage.getItem('uid')
				this.props.updateStatus(uid, data);
			}
		}
		this.handelStatusEditClose();
	}
	setOpenSteps =(p) => {
		if (this.props.step.cardType === 'Course') {
			this.props.getCourse(this.props.step.courseId)
		}
		if(this.props.step.cardType === 'wpq') {
			this.props.getWPQ()
		}
		this.props.setOpenSteps(p);
	}

	handleCourseOpenAlert = () => {
		this.props.setStepEnabled(this.props.stepIndex);
		this.props.showOpenCourseAlert(this.props.step.stepId, this.props.stepIndex, this.props.coures.url, 'List');
	}

	handleDoButton = (step, stepIndex) =>  {
		const stepActions = ["changeStatus", "Course", "Form","event","wpq", "countDownEnforced"]
		if (step.cardType && stepActions.indexOf(step.cardType) !== -1){
			this.setOpenSteps(step.position)
		} else if(step.actionButtonType && stepActions.indexOf(step.actionButtonType) !== -1) {
			this.setOpenSteps(step.position)
		} else {
			this.props.handleNextStep(step.stepId, stepIndex)
		}
	}

    handelShowWarningPopup = (warning) => {
		let {step, stepIndex, executeActivity, wpqLink} = this.props;
		if(executeActivity && step.cardType === 'wpq') {
			if(wpqLink) {
				openURL(wpqLink)
			}
			console.log(" wpqLink -->", wpqLink);
			this.props.handleNextStep(step.stepId, stepIndex);
		} else if(executeActivity && step.cardType === 'event') {
			this.setState({...this.state,warningPopup:"Not Supported"});
		} else {
			this.setState({...this.state,warningPopup:warning});
		}	
	}

	handleCloseWarningPopup =() => {
		let {step, stepIndex} = this.props;
		this.props.handleNextStep(step.stepId, stepIndex);
		this.setState({...this.state,warningPopup:""});
	}

	handleSkipStep = (stepId, stepIndex, status) => {
		if(status == "Done" || status == "Skipped") {
			console.log("step completed")
		} else {
			this.props.handleSkipStep(stepId, stepIndex);
			clearInterval(this.intervalID1);
			// this.setState({countDownInterval: 0})
		}
	}
	handleDoneStep = (step, stepIndex, status) => {
		const stepActions = ["changeStatus", "Course", "Form","event","wpq", "countDownEnforced"]
        if(status == "Done" || status == "Skipped") {
			console.log(" Step completed")
		} else {
			if(step && step.isStartButtonEnabled && !status){
				this.props.handleNextStep(step.stepId, stepIndex)
			} else if (step.cardType && stepActions.indexOf(step.cardType) >= 0){
				this.setOpenSteps(step.position)
			} else if(step.actionButtonType && stepActions.indexOf(step.actionButtonType) >= 0) {
				this.setOpenSteps(step.position)
			} else {
				this.props.handleNextStep(step.stepId, stepIndex)
				clearInterval(this.intervalID1)
				// this.setState({countDownInterval: 0})
			}
		}
	}

	getFormatedDuration = (duration) => {
        var timeleft = (+duration) * 1000;
        let seconds = (Math.floor(timeleft / 1000) % 60)
        let minutes = (Math.floor(timeleft / 60000) % 60)
        let hours = Math.floor(timeleft / 3600000);
        seconds = seconds < 10 ? "0" + seconds : seconds;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        hours = hours < 10 ? "0" + hours : hours;
        return `${hours}:${minutes}:${seconds}`;
    }

    getExpectedDurationSeconds = (formatedString) => {
        let total = 0;
        if (formatedString) {
            const dateArray = formatedString.split(":");
            let hr = dateArray[0];
            let min = dateArray[1];
            let sec = dateArray[2];
            if (!sec) {
                if (min) {
                    sec = min;
                    min = hr;
                    hr = 0
                } else {
                    sec = hr;
                    min = 0;
                    hr = 0;
                }
            }
            let totalhrs = hr ? Number(hr) * 3600 : 0;
            let totalmin = min ? Number(min) * 60 : 0;
            total = totalhrs + totalmin + Number(sec);
        }
        return total;
    }
	
	render() {
		const { statusList, maxStepTimeout, pin, totalTry, showEvent } = this.state;
		let {openedStep, stepsCompletionList, step, stepKey, stepIndex, stepNo, activity, stepslist, stepsDisabled, clientImage, clientName } = this.props;
		let stepCompletionDetail = stepsCompletionList ? stepsCompletionList.find(ele => ele.step.id == step.stepId) : {};
		let status = stepCompletionDetail ? stepCompletionDetail.status : "";
		let duration = stepCompletionDetail ? stepCompletionDetail.duration : 0;
        let achievementDelayed = false;
		if(step.expectedDuration && step.expectedDuration.split(":")[1] && this.props.isTimerEnabled) {
			let expectedDurationSec = this.getExpectedDurationSeconds(step.expectedDuration);
			if (expectedDurationSec < duration) {
				duration = duration - expectedDurationSec;
				achievementDelayed = true;
			} 
		}
		
                                
		const stepActions = ["changeStatus", "Course","Form","event","wpq", "countDownEnforced"]
		var timeleft = this.state.countDownInterval * 1000;
		let seconds = ("0" + (Math.floor(timeleft / 1000) % 60)).slice(-2);
		let minutes = ("0" + (Math.floor(timeleft / 60000) % 60)).slice(-2);
		let hours = ("0" + Math.floor(timeleft / 3600000)).slice(-2)
		let num = [1, 2, 3, 4, 5, 6, 7, 8, 9, "", 0, 'back']
		if (totalTry === 0 && this.props.pinMsg && this.props.pinMsg != "PIN verified successfully") {
			this.logoutUser()
		}
		let show = false
		if (this.props.pinMsg === "PIN verified successfully") {
			show = true
			setTimeout(() => {
				show = false
				this.handleClose()

			}, 2000);
		}
		const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
		
		return (
			<div key={this.props.key}>
				{this.props.pinMsg === "PIN verified successfully" && show &&
					<div className="col-12 p-4">
						<SweetAlert
							success
							btnSize="sm"
							customClass="successText"
							confirmBtnText="YES"
							cancelBtnText="NO"
							confirmBtnBsStyle="success"
							title="Success"
							onConfirm={() => this.handleClose()}
						>
							<div className="sweet-alert-text">{this.props.pinMsg}</div>
						</SweetAlert>
					</div>
				}
				{!this.state.showCheckIn && step.title &&
					<div className="list-card my-2 mx-0">
						<div className="d-flex mx-0  py-2 px-1 justify-content-between  align-items-center">
							<div className="d-flex pl-1 align-items-left text-left w-90 " >
								{status && status == "Skipped" && <CloseIcon  className="step-skipped-icon" style={{ fontSize: "18", marginRight: "8px", color: "#fff", marginTop: "3px", borderRadius: "2px" }} /> }
								{status && status == "Done" && <CheckBoxIcon className="step-completed-checkbox" style={{ fontSize: "24", marginRight: "5px", color: "rgb(31, 179, 31)" }} />}
								{!status && <CheckBoxOutlineBlankIcon data-testid={`status-for-step-${stepIndex+1}`}  style={{ fontSize: "24", marginRight: "5px", color: "rgb(165, 163, 163)" }} />}
								<div className="activity-title  fw-semi-bold d-block ">
									{step.referenceURL ?
										<a data-testid={`title-for-step-${stepIndex+1}`}  className="text-black" style={{ textDecoration: 'underline' }} href={step.referenceURL} target="_blank">{step.title}</a>
										:
										<h4 data-testid={`title-for-step-${stepIndex+1}`} className="mb-0 mt-1">{step.title}</h4>
									}
									{step.expectedDuration && step.expectedDuration.split(":")[1] && this.props.isTimerEnabled && 
									<div className="sub-text">
										<span className='mr-1'>{step.expectedDuration}</span>
										[<span style={ achievementDelayed ? {color:"red"}:{}}>+{this.getFormatedDuration(duration)}</span>]
									</div>
									}
								</div>
							</div>
							<div className="d-flex align-items-right text-right w-10" >
								<div className="ml-auto mr-0 pt-1">
									{
										openedStep == step.position ?
											<KeyboardArrowUpIcon data-testid={`arrow-up-icon-for-step-${stepIndex+1}`} style={{ margin: "0px", padding: "0px", cursor: "pointer", marginLeft: "0px" }}
												className="m-0 p-0" onClick={() => this.setOpenSteps(step.position)} /> :
											<KeyboardArrowDownIcon data-testid={`arrow-down-icon-for-step-${stepIndex+1}`} style={{ margin: "0px", padding: "0px", cursor: "pointer" }} className="m-0 p-0"
												onClick={() => this.setOpenSteps(step.position)} />
									}
								</div>
							</div>
						</div>
						{openedStep == step.position&&
							<div data-testid={`details-panel-for-step-${stepIndex+1}`} className="text-center border-top  pb-2 pt-1  px-3">
								<div className="row">
									<div className="col-3">
									</div>
									<div className="col-6"></div>
									<div data-testid={`speech-icon-for-step-${stepIndex+1}`} className="col-3 text-right theame-text on-focus">
										<div>
											<IconButton className='activity-speech-icon-button' onClick={() => capTextSpeech(step.title)}>
												<VolumeUpOutlinedIcon/>
											</IconButton>
										</div>
									</div>
								</div>
								{step.cardType ?
									<>
										{step.imageURL && step.cardType !== 'Course' ?
											<div  data-testid={`image-for-step-${stepIndex+1}-container`} className="text-center font-18 text-gray">
												<img data-testid={`image-for-step-${stepIndex+1}`} className="img-fluid mt-3 img-height-330" src={step.imageURL} alt="" />
											</div>
											: <></>
										}
										{step.cardType === 'Course' && this.props.coures &&
											<div  data-testid={`open-course-step-${stepIndex+1}`}className='mx-auto mt-2'>
												 {this.props.courseLoading ? 
												  	<LifeSherpaLoading loading={this.props.courseLoading}/>
												  :
												  <>
													<div className="text-center font-18 text-gray">{this.props.coures.name}</div>
												   		<div className="text-center font-18 text-gray">
														<img className="img-fluid mt-3 img-height-330" src={this.props.coures.big_avatar} alt="" />
													</div>
													<Button color="primary" variant="contained" disabled={status && status != "Started" ? true : false} className=" theame-color text-white mt-4 primary-button" onClick={() => this.handleCourseOpenAlert()}>Open Course</Button>

												  </>
												  }												
											</div>
										}
										{step.cardType === 'wpq' &&
											<div className="mx-auto mt-2">
												{this.props.wpqLoading ? 
												  	<LifeSherpaLoading loading={this.props.wpqLoading}/>
												  :
												  <Button color="primary" variant="contained" disabled={status ? true : false} className="mt-4 primary-button" onClick={()=> this.handelShowWarningPopup("Cannot open WPQ in activity preview")}>Open Questionnaire</Button>
												  }
											</div>
										}
										{step.cardType === 'event' &&
											<div className="mx-auto mt-2">
												<Button color="primary" variant="contained" disabled={status && status != "Started" ? true : false} className="mt-4 primary-button" onClick={()=> this.handelShowWarningPopup("Cannot open event in activity preview")} /*onClick={() => this.handleEvent()}*/>Check Event</Button>
											</div>
										}
										{step.cardFormId &&
											<div className='mx-auto mt-2'>
												<Button color="primary" variant="contained" disabled={status && status != "Started" ? true : false} data-testid={`complete-form-step-${stepIndex+1}`} className="primary-button" onClick={() => this.handleStepFrom(step.cardFormId)}>Complete Form</Button>
											</div>}
										{step.cardType === "changeStatus" &&
											<div className='mx-auto mt-2'>
												<Button color="primary" variant="contained" disabled={status && status != "Started" ? true : false} data-testid={`change-status-step-${stepIndex+1}`} className="primary-button" onClick={() => this.updateStatusPopup()}>Change Status</Button>
											</div>
										}
										{step.cardType === "checkIn" &&
											<div className='mx-auto mt-2'>
											</div>
										}
										{((step.cardType === "countDown" || step.cardType === "countDownEnforced" || step.cardType === "countDownPermissive") && status != "Done") &&
											<div className='countdown-height'>
												<div className='mx-auto mt-2'>
													<div className="text-center countdown-time my-50 text-danger">{hours + ':' + minutes + ':' + seconds}</div>
												</div>
												{!this.state.countDownStart && <div className='d-flex mx-auto mt-2'>
													{ (step.cardType === "countDown" || step.cardType === "countDownPermissive") && <Button color="primary" variant="outlined" disabled={status === "Done" ? true : false} className="countdown-edit-button mx-auto" onClick={() => this.setState({ showTimer: true, hour: hours, min: minutes, sec: seconds })}>EDIT</Button>}
													<Button color="primary" variant="contained" disabled={status === "Done" ? true : false} className="mx-auto primary-button" onClick={() => this.handleStart()}>START</Button>
												</div>}
											</div>
										}
									</>
									:
									<>
										{step.imageURL &&
											<div className="text-center font-18 text-gray">
												<img data-testid={`image-for-step-${stepIndex+1}`} className="img-fluid mt-3 img-height-330" src={step.imageURL} alt="" />
											</div>
										}
										{step.actionButtonType === "changeStatus" &&
											<div className='mx-auto mt-2'>
												<Button color="primary" variant="contained" data-testid={`change-status-step-${stepIndex+1}`} className="primary-button" onClick={() => this.updateStatusPopup()}>Change Status</Button>
											</div>
										}
										{step.actionButtonType === "checkIn" &&
											<div className='mx-auto mt-2'>
												<Button color="primary" variant="contained" disabled={status && status != "Started" ? true : false} className="primary-button" onClick={() => this.setState({ showCheckIn: true })}>CheckIn</Button>
											</div>
										}
										{((step.actionButtonType === "countDown" || step.actionButtonType === "countDownEnforced" || step.actionButtonType === "countDownPermissive") && status != "Done") &&
											<div className='countdown-height'>
												<div className='mx-auto mt-2'>
													<div className="text-center countdown-time my-50 text-danger">{hours + ':' + minutes + ':' + seconds}</div>
												</div>
												{!this.state.countDownStart && <div className='d-flex mx-auto mt-2'>
													{(step.actionButtonType === "countDown" || step.actionButtonType === "countDownPermissive") && <Button color="primary" variant="outlined" disabled={status && !step.isStartButtonEnabled ? true : false} className="countdown-edit-button  mx-auto" onClick={() => this.setState({ showTimer: true, hour: hours, min: minutes, sec: seconds })}>EDIT</Button>}
													<Button color="primary" variant="contained"  disabled={status && !step.isStartButtonEnabled ? true : false} className="theame-color text-white mx-auto primary-button" onClick={() => this.handleStart()}>START</Button>
												</div>}
											</div>
										}
									</>
								}
								{step.contentUrl &&
									<div className="text-center font-18 text-gray">
										<img className="img-fluid mt-3 img-height-330" src={step.contentUrl} alt="" />
									</div>
								}
							</div>

						}
						<div className="d-flex mx-0 border-top py-2 px-1 justify-content-between  align-items-center">
								{
									openedStep == step.position &&
									<div className={""}>
										<div className='d-flex align-items-center justify-content-center'>
											<IconButton data-testid={`help-icon-for-step-${stepIndex+1}`} className='p-0' onClick={() => this.props.handleHelpVideoStep(stepslist[stepIndex].stepId)}><img className="list-layout-help-image" width={30} height={30} src={require("../../../assets/img/help-logo.png")} /></IconButton>
										</div>
									</div>
								}
								
								<div style={{ opacity: status == "Done" || status == "Skipped" ? "0.5" : "1" }} className="d-flex mx-0 w-100 justify-content-end  align-items-center">
									{activity && activity.showSkipButton && !step.isMandatory ?
										<div className={"d-flex pl-1 justify-content-center align-items-center " + (status == "Done" || status == "Skipped" ? "" : "cps")} onClick={() => this.handleSkipStep(stepslist[stepIndex].stepId, stepIndex, status)} >
											<div className="d-flex ">
												<ThumbDownRoundedIcon data-testid={`thumb-down-icon-for-step-${stepIndex+1}`} className="mr-1 d-inline-block" style={{ color: "rgb(243, 62, 62)", fontSize: "14px" }} />
											</div>
											<div className="d-flex">
												<span className={"fw-semi-bold d-inline-block "} style={{ fontSize: "16px", color: status  ? "rgb(165, 163, 163)" : "rgb(243, 62, 62)" }}>I Skipped It</span>
											</div>
										</div> : <div className="d-flex pl-1 justify-content-center w-50 "></div>
									}
									<div className={"d-flex justify-content-center w-30 align-items-center " + (status == "Done" || status == "Skipped" ? "" : "cps")} onClick={() => this.handleDoneStep(stepslist[stepIndex], stepIndex, status)} >
										<div className='d-flex '>
											<ThumbUpAltRoundedIcon data-testid={`thumb-up-icon-for-step-${stepIndex+1}`} className="mr-1 d-inline-block done-button-thumb-icon" style={{ fontSize: "14px" }} />
										</div>
										<div className="d-flex">
											<span style={{ fontSize: "16px" }} className={" fw-semi-bold d-inline-block "+(status == "Done" || status == "Skipped" ? "stepDone" : "stepDo" )} > 
											{stepslist && stepslist[stepIndex].isStartButtonEnabled && !status ? 'Start' : status == "Done" ? "Done" : step.cardType && stepActions.indexOf(step.cardType) !== -1 ? "Do" : step.actionButtonType && stepActions.indexOf(step.actionButtonType) !== -1 ? "Do" : 'Done'}
											</span>
										</div>
									</div>

								</div>
						</div>

					</div>

				}

				{this.state.StatusPopup &&
					<SetCurrentStatus
						image={this.props.image}
						name={this.props.name}
						showStatus={this.state.StatusPopup}
						profile={auth_uid == uid ? this.props.profile : undefined}
						statusList={statusList}
						handelStatusEditClose={this.handelStatusEditClose}
						updateStatus={this.updateStatus}
						preview={!this.props.executeActivity}
						popupMode={auth_uid == uid ? "Admin": "Client"}
					/>
				}

				{this.state.showTimer &&
					<Dialog
						fullScreen={false}
						open={this.state.showTimer}
						onClose={() => this.setState({ showTimer: false })}
						fullWidth={true}
						maxWidth="xl"
						aria-labelledby="responsive-dialog-title"
						className={this.props.popupThemeClass}
						PaperProps={
							{ style: { maxWidth: "500px", borderRadius: 8, margin: 'auto' } }
						}
						>
						<div>
							<div className="row bg-lifesherpa p-3">
								<div className="col">
									<h2 className="text-white">{step.title}</h2>
									<h4 className="text-white mb-0 pb-0">Edit Timer</h4>
								</div>
								<IconButton className="ml-auto my-auto text-white" onClick={() => this.setState({ showTimer: false })}><CloseIcon /></IconButton>
							</div>
							<div className="row p-3 mt-3">
								<FormGroup className="col text-center">
									<Label>HH</Label>
									<Input className="msg-inpu text-center" value={this.state.hour} /*pattern="^[0-9]*$"*/ min={0} max={25} type='number' placeholder='00' name='timer' onChange={(e) => this.handleHour(e)} />
								</FormGroup>
								<h1 className="mx-1 my-auto">:</h1>
								<FormGroup className="col text-center">
									<Label>MM</Label>
									<Input className="msg-inpu text-center" value={this.state.min} min={0} max={60} type='number' placeholder='00' name='timer' onChange={(e) => this.handleMin(e)} />
								</FormGroup>
								<h1 className="mx-1 my-auto">:</h1>
								<FormGroup className="col text-center">
									<Label>SS</Label>
									<Input className="msg-inpu text-center" value={this.state.sec} min={0} max={60} type='number' placeholder='00' name='timer' onChange={(e) => this.handleSec(e)} />
								</FormGroup>
							</div>
							<div className="row d-flex align-items-center justify-content-center mt-4 update-time-buttons">
								<Button color="primary" variant="outlined" className=" mb-3 mr-2 form-cancel-button" onClick={() => this.setState({ showTimer: false })}>Cancel</Button>
								<Button color="primary" variant="contained" className=" mb-3 ml-2 primary-button" onClick={() => this.updateTimer()}>Update Timer</Button>
							</div>
						</div>
					</Dialog>	
				  }
				  {this.state.warningPopup &&
					<div className="col-12 p-4">
						<SweetAlert
							warning
							btnSize="sm"
							customClass="warningText"
							confirmBtnText="OK"
							confirmBtnBsStyle="warning"
							title={this.state.warningPopup}
							onConfirm={() => this.handleCloseWarningPopup()}
							>
						<div className="sweet-alert-text"></div>
						</SweetAlert>
					</div>
					}
			</div>);
	}
}
const mapStateToProps = ({ settings, authUser, Status, activitiesList, chatAppReducer, GroupList }) => {
	const { popupThemeClass } = settings; 
	const { selectedMemberId, selectedRole } = chatAppReducer;
	const { groupMembersDetails } = GroupList;
	const { coures, pinMsg, wpqLink ,courseLoading, wpqLoading} = activitiesList;
	let { clientStatus, status } = Status;
	const { user, profile, credit } = authUser;
	const selectedClient = groupMembersDetails && selectedMemberId ? groupMembersDetails.filter(client => client.userId === selectedMemberId)[0] : null
	return {popupThemeClass, courseLoading, clientStatus, status, settings, user, profile, credit, Status, coures, pinMsg, wpqLink, selectedMemberId, groupMembersDetails, wpqLoading};
};

export default withAuth0(withRouter(connect(mapStateToProps, {
	getClientStatusDataById, getWPQ, logoutUserFromFirebase, removePinMsg, checkPIN, getCourse, collapsedSidebarAction, updateStatus, setStatusColor, getUserStatusById, getPokeForm
})(SActivityListViewDetails)));
