import React, { useState, useEffect, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';


// import required modules
import { Navigation, Virtual } from 'swiper/modules';
// import FileDownloader from "../Widgets/FileDownload";
import moment from 'moment' 
import Linkify from './Linkify';
import IconButton  from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import { shareFileFromUrl } from '../../helpers/helpers';
import CircularProgress from '@mui/material/CircularProgress';
// Import Swiper styles

const PostsSwipper = ({slides, onSlideChange, showImg}) => {
    const [loading, setLoading] = useState(false);
    const swiperRef = useRef(null);  // Ref to get Swiper instance

   

    const handleSlideChange = (swiper) => {
        // setLoading(true);
        if(onSlideChange) {
            onSlideChange(swiper.activeIndex)
        }
    };

    const handleSlideChangeEnd = () => {
        // setLoading(false); // Hide loading indicator when sliding ends
    };

    useEffect(() => {
        if (swiperRef.current) {
          swiperRef.current.slideTo(0);  // Reset to the first slide (index 0)
        }
    }, [slides]);  // Trigger when slides change
    
    const handleShare = async(post) => {
        shareFileFromUrl(post, setLoading)
    }

    return (
        <React.Fragment>
            { slides && slides.length ?
                <Swiper modules={[Virtual, Navigation]}
                        onSwiper={(swiper) => (swiperRef.current = swiper)}  // Assign swiper instance to ref
                        slidesPerView={1}        // Number of slides visible at a time
                        virtual={true} 
                        navigation={true}
                        onSlideChange={handleSlideChange}
                        onTransitionEnd={handleSlideChangeEnd}
                        watchSlidesProgress={true}      // Ensure Swiper watches for slides being loaded
                        lazy={{
                          loadPrevNext: true,           // Preload next/previous slides
                          loadPrevNextAmount: 2         // Preload 2 slides before and after
                        }}
                    >
                        {slides.map((slide, index) => (
                            <SwiperSlide key={index} virtualIndex={index}>
                                <div  className="list-card-slider mt-3 " style={{borderRadius:16+"px",height: '300px', overflowY: "auto", overflowX: "hidden"}}  key={index}>
                                    <div  onClick={()=> showImg(slide)} className="mx-auto my-auto text-center p-3 d-flex align-items-center justify-content-center cps" style={{width:'180px',height:'175px'}}>
                                        <img className="img-fluid border" style={{maxHeight:'150px'}} src={slide.thumbnailURL} alt="" />
                                    </div>
                                    {slide.fileURL ? 
                                    <div className="w-100 text-right font-md  mb-2">
                                        <div className="mr-3">
                                            {slide.sharingOption && slide.fileURL && ["Sharable", "Shareable"].includes(slide.sharingOption) ?
                                                <IconButton title="Share Post" disabled={loading} onClick={() => handleShare(slide)}>
                                                    <ShareIcon color="primary" />
                                                    {loading && 
                                                        <CircularProgress
                                                            size={40}
                                                            sx={{
                                                            color: "primary",
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-20px',
                                                            marginLeft: '-20px',
                                                            }}
                                                        />
                                                    }
                                                </IconButton> 
                                                : <></>
                                            }
                                        </div>  
                                    </div>
                                    :""}
                                    <div className="cps" onClick={()=> showImg(slide)}>
                                        <div title={slide.title} className="text-truncate font-md text-secondary">{slide.title}</div>
                                        <div className="col-12 text-muted font-sm mb-1">{moment(slide.createdOn).format('Do MMM YYYY')}</div>
                                        {slide.description && <div className="col-12 font-sm text-muted mb-3"><Linkify text={slide.description}/></div>}
                                    </div>
                                </div>     
                            </SwiperSlide>
                        ))}
                </Swiper>
                :
                <div data-testid={`no-report-data-found`} className="list-card-slider mt-3 mb-4 " style={{borderRadius:16+"px",height: '250px'}}  >
                    <div className="mx-auto my-auto text-center p-3 d-flex align-items-center justify-content-center" style={{width:'200px',height:'175px'}}>
                        <img className="img-fluid border" style={{height:'150px'}} src={"my image"} alt="" />
                        </div>
                        <div className="col-12 mb-4 font-md text-center text-secondary">No Report Found</div>
                    <div className="col-12 font-md text-secondary mb-2" style={{fontSize:"12px"}}>{"  "}</div>
    
                    <div className="col-12 font-md text-secondary">{"   "}</div>
                    <div className="col-12 text-muted font-sm mb-3">{"  "}</div>
                </div>    
            }
        </React.Fragment>
    );
};

export default PostsSwipper;
