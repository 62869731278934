import {legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import reducers from '../reducers';
import config from '../config/config'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const loadState = (initialState) => {
    try {
        let serializedState = localStorage.getItem(config.localStorageBucket);

        if (serializedState === null) {
            return initialState;
        }

        return JSON.parse(serializedState);
    }
    catch (err) {
        return initialState;
    }
}

const saveState = (store) => {
    try {
        let serializedState = JSON.stringify(store.getState());
        localStorage.setItem(config.localStorageBucket, serializedState);

    }
    catch (err) {
    }
}


export function configureStore(initialState) {

    const store = createStore(
        reducers,
        loadState(initialState),
        composeEnhancers(applyMiddleware(thunk))
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }

    store.subscribe(() => {
        saveState(store)
    });

    return store;
}
