import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import {
    hideExecuteActivityConfirmation,
    executeActivityWithAchievementId,
    handlEditor,
    clearActivityDataOnClose,
    setSelectedMemberId,
	changeRole,
	getcategories,
	getKeywords,
	showClientPanel,
    clearHistoryData,
    getSelectedActivity,
    showActivityHistoryDetail,
    handleHideActivityDetailsPanel,
    setClientActivitiesType,
    showStartActivityPanel
} from 'Actions';

class ExecuteActivityConfirmation extends Component {
    state = {

    }

    handleConfirm = () => {
        let { executeActivityNotification, clientPanel } = this.props;
        const {userId: clientUserId, achievementId, organization} = executeActivityNotification || {};
        if(!achievementId) {
            console.log('Achievement Not found')
            this.handleClose();
            return;
        }
        const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
        const orgId = organization || localStorage.getItem("orgId");
        const userId = clientUserId || auth_uid;

        const isUserSelected = uid != auth_uid;
        const isClientActivity = clientUserId && clientUserId != auth_uid;
        const isActivityClientSelected = isUserSelected && (clientUserId && clientUserId == uid);

        if(isActivityClientSelected) {
            this.handleExecuteActivity(achievementId, orgId, userId);
            return;
        }

		if(isUserSelected ) {
			localStorage.setItem('uid', auth_uid);
            localStorage.setItem("selectedOrgId", orgId)
            this.props.changeRole({ role: 'admin', id: auth_uid });
			this.props.setSelectedMemberId(null);
            if(clientPanel) {
				this.props.showClientPanel(false);
                localStorage.removeItem('prevUrl');
			}
            const activityConfirmation = localStorage.getItem("activityConfirmation");
            if(activityConfirmation) {
                localStorage.removeItem("activityConfirmation");
            }
            this.props.clearHistoryData();
            this.props.getSelectedActivity(null);
            this.props.showActivityHistoryDetail(false)
		} 
        if(isClientActivity) {
            localStorage.setItem('uid',clientUserId);
            this.props.changeRole({ role: 'client', id: clientUserId })
            this.props.setSelectedMemberId(clientUserId)
            this.props.handleHideActivityDetailsPanel();
            this.props.setClientActivitiesType("Touchpoint");
            // this.props.showClientPanel(true);
        }

        console.log("clientUserId :", clientUserId, {achievementId, orgId, userId});
        this.handleExecuteActivity(achievementId, orgId, userId);
       
    }
    handleExecuteActivity = (achievementId, orgId) => {
        this.props.showStartActivityPanel({action:'execute', achievementId, organization: orgId});
    }



    handleClose = () => {
        this.props.hideExecuteActivityConfirmation();
    }

    render() {
        const { executeActivityNotification } = this.props;
        // console.log(" executeActivityNotification --> ", executeActivityNotification);
        return (
            <div> {executeActivityNotification && executeActivityNotification.title &&
                <Dialog
                    fullScreen={false}
                    open={executeActivityNotification && executeActivityNotification.title ? true : false}
                    fullWidth={true}
                    aria-labelledby="responsive-dialog-title"
                    className={`${this.props.popupThemeClass} warning-dialog-popup`}
                    id="notification_exe_activity"
                >
                    <DialogContent className="p-0">
                        <div className="text-center mb-3 mx-4 mt-4 execute-activity-alert">
                            <div className="d-flex align-items-center justify-content-center" style={{height: 80}}>
                                <Avatar
                                    alt="Remy Sharp"
                                    src={require('../../assets/img/help-logo.png')}
                                    style={{height: 80, width: 80, border: '0px solid #fff'}}
                                />
                            </div>
                            <div className='col-12'>
                                <h2 className="exitDialog">{executeActivityNotification.title}</h2>
                                <h2 className="subHeading mb-4">{executeActivityNotification.message}</h2>
                            </div>
                            <div className="d-flex mt-3 col-12 align-items-center justify-content-between">
                                <button className="btn btn-warning mr-2" onClick={() => this.handleClose()}>NO</button>
                                <button className="btn btn-grey ml-2" onClick={() => this.handleConfirm()}>OK</button>
                                {/* <button className="btn btn-warning mb-3 ml-2" onClick={()=> this.handleClose()}><a className="text-white" target="_self" href="/success">ok</a></button>*/}
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            }

            </div>
        );
    }
}
const mapStateToProps = ({ settings, activitiesList, authUser,clientsList }) => {
    const { screenThemeClass, popupThemeClass } = settings;
    const { executeActivityNotification, selectedId } = activitiesList
    const { userRole } = authUser;
    let { clientPanel } = clientsList;

    return { screenThemeClass, popupThemeClass, executeActivityNotification, selectedId, userRole, clientPanel };
};

export default withRouter(connect(mapStateToProps, {
    hideExecuteActivityConfirmation,
    executeActivityWithAchievementId,
    handlEditor,
    clearActivityDataOnClose,
    setSelectedMemberId,
	changeRole,
	getcategories,
	getKeywords,
	showClientPanel,
    clearHistoryData,
    getSelectedActivity,
    showActivityHistoryDetail,
    handleHideActivityDetailsPanel,
    setClientActivitiesType,
    showStartActivityPanel
})(ExecuteActivityConfirmation));
