/**
 * User Block Component
 */
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Input, Label, FormFeedback} from 'reactstrap';
import { connect } from 'react-redux';
import Divider from '@mui/material/Divider';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from '@mui/material/DialogActions';
import LifeSherpaLoading from '../LifeSherpaLoading';
// redux action
import {clearUpdatePasswordError, logoutUserFromFirebase, updatePassword, getUserStatusById,showUpdatePasswordDialog, deleteAppDataForNotification } from '../../actions';

// intl messages
import IntlMessages from '../../util/IntlMessages'; 
import { IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Button from '@mui/material/Button';
import { withAuth0 } from "@auth0/auth0-react";
import LSPortalLogoutPopup from "./LSPortalLogoutPopup";
import config from "../../config/config";
import { Capacitor } from '@capacitor/core';

class SherpaUserProfile extends Component {

	state = {
		userDropdownMenu: false,
		isSupportModal: false,
		isDisconnected: false,
		PasswordDialog: false,
		oldPassword: '',
		newPassword: '',
		error: '',
		error2: '',
		updated:false,
		openLogoutPopup:false,
	}

	/**
	 * Logout User
	 */
	logoutUser(e) {
		e.preventDefault();
		this.handleUnRegisterPushNotification();
		this.setState({...this.state,openLogoutPopup:true});
	}

	handleAboutUs(e){
		// e.preventDefault();
		this.setState({ userDropdownMenu: false });
	}

	handleCloseLogoutPopup= ()=> {
		const {logout} = this.props.auth0
		this.setState({...this.state,openLogoutPopup:false}, () => {
			this.props.logoutUserFromFirebase(this.props.history, logout);
		});
	}

	handleUnRegisterPushNotification = () => {
		let {twilioClient} = this.props;
		const platform = Capacitor.getPlatform()
		if(twilioClient) {
			platform === "ios" ? twilioClient.unsetPushRegistrationId('apn') : twilioClient.unsetPushRegistrationId('fcm');
		   console.log(`${platform === "ios" ? "APNS" : "FCM"} unregistered for chat push notification`);
		}
	}

	passwordUpdate(e) {
		e.preventDefault();
		this.props.showUpdatePasswordDialog(true);
		this.toggleUserDropdownMenu();
	}
	/**
	 * Toggle User Dropdown Menu
	 */
	toggleUserDropdownMenu() {
		this.setState({ userDropdownMenu: !this.state.userDropdownMenu });
	}

	handleNativeBack = () => {
		this.closePasswordDialog();
	}

	closePasswordDialog = () => {
		this.setState({PasswordDialog: false,updated:true, oldPassword: '', newPassword: '',error: '',error2: ''});
		this.props.showUpdatePasswordDialog(false);
		document.removeEventListener('nativeBackClick', this.handleNativeBack);
	}

	handleUpdate = () => {
		const { oldPassword, newPassword } = this.state;
		const { updatePasswordLoading } = this.props;
		let smala = (/[a-z]/.test(newPassword))
		if (oldPassword === '') this.setState({ error2: "Old Password is a required field." })
		if (oldPassword && newPassword && newPassword.length >= 6 && smala && newPassword.charAt(0) == newPassword.charAt(0).toUpperCase() &&
			isNaN(newPassword) === true) {
			this.props.updatePassword(oldPassword, newPassword, this.props.user.firebaseToken);
			this.setState({PasswordDialog: false})	
		}
		else this.setState({ error: true })
	}

	handleChange = (e) => {
		const { oldPassword, newPassword } = this.state;
		let smala = (/[a-z]/.test(e.currentTarget.value))
		let num = /\d/.test(e.currentTarget.value)

		if (e.currentTarget.value === '') {
			this.setState({ error: "New Password is a required field." })
		} else if (e.currentTarget.value.length <= 7) {
			this.setState({ error: "New Password should have at least 8 characters." })
		} else if (e.currentTarget.value.charAt(0) != e.currentTarget.value.charAt(0).toUpperCase()) {
			this.setState({ error: "First letter should be upper case lettes." })
		} else if (!smala) {
			this.setState({ error: "New Password should contains atleast one small lettes." })
		} else if (!num) {
			this.setState({ error: "New Password should contains atleast one Numeric value." })
		} else {
			this.setState({ error: true })
		}

		if(this.props.updateError) { 
			this.props.clearUpdatePasswordError() 
		}

		this.setState({ newPassword: e.currentTarget.value })
	}

	handlePasswordChange =(e) => {
		let {updateError} = this.props;
		if(updateError) this.props.clearUpdatePasswordError();
		this.setState({ oldPassword: e.currentTarget.value, error2: '' })
	}

	render() {
		let {openLogoutPopup } = this.state;
		let {updatePasswordLoading,passwordDialog,updateError, profile,usersInfo, auth0ClientConfig, allowChangePassword} = this.props;
		let displayName = profile ? profile.nickname || profile.name || (profile.given_name && profile.family_name ? profile.given_name + " " + profile.family_name : profile.given_name || profile.family_name ) : 'Display Name'
		displayName  =  displayName == 'Display Name' && usersInfo ? usersInfo.displayName  ||  `${usersInfo.firstName || "Display"}  ${usersInfo.lastName || "Name"}` : displayName;
		const userImage = this.props.profile && this.props.profile.picture ? this.props.profile.picture : usersInfo && usersInfo.profileImageURL ;

		return (
			<div className="top-sidebar">
				<div className="sidebar-user-block p-3">
					<Dropdown 
				    	isOpen={this.state.userDropdownMenu}
						toggle={() => console.log("Dropdown menu toggled")}
						className="user-profile-dropdown"
					
					>
						<DropdownToggle tag="div" aria-haspopup={true} aria-expanded={true} onClick={() => this.toggleUserDropdownMenu()}>
						<div className="d-flex align-items-center">
							<div data-testid="client-profile-toggle" style={{ cursor: "pointer" }} className="user-profile mr-0 pr-1">
								{this.state.isDisconnected ?
									(
										<img
											src={require('Assets/avatars/user-15.jpg')}
											alt="user profile"
											className="rounded-circle img-fluid"
											width="35"
											height="35"
										/>
									) :
									(
										<img
											src={userImage || require('Assets/avatars/user-15.jpg')}
											alt="user profile"
											className="rounded-circle img-fluid"
											width="35"
											height="35"
											data-testid="client-header-image"
										/>
									)
								}


							</div>
							<div style={{ cursor: "pointer", fontSize: "14px", fontFamily: 'Lato' }} className="d-none d-md-inline-block">
								<span data-testid="client-header-name" className='d-none d-lg-inline-block mb-0 pb-0'>{` Welcome ${displayName}`}</span>
							</div>
							<div style={{ cursor: "pointer" }} className="m-0 pl-0  profile-dropdown-icon">

								{this.state.userDropdownMenu ? <ArrowDropUpIcon style={{ color: "#4D4E4F", marginLeft: "0px" }} />
									: <ArrowDropDownIcon style={{ color: "#4D4E4F", marginLeft: "0px" }} />}

							</div>
							</div>
						</DropdownToggle>
						<DropdownMenu end>
						<div className="dropdown-content">
								<div className="p-15 border-bottom dropdown-top  rounded-top bg-lifesherpa">
									<p className="mb-0 fs-14 text-white">{displayName}</p>
									<span className="fs-14 text-white">{this.props.profile && this.props.profile.email ? this.props.profile.email : usersInfo && usersInfo.email ? usersInfo.email : "demo@mail.com"}</span>
								</div>
								<ul className="list-unstyled dropdown-list mb-0">
									<li title="Privacy Policy" className="border-top m-0">
										<a data-testid="privacy-policy" href='https://lifesherpapp.com/privacy-policy/' target='_blank' onClick={(e) => this.handleAboutUs(e)}>
											<i className="zmdi zmdi-shield-security text-primary mr-3 font-2x"></i>
											<span>Privacy Policy</span>
										</a>
									</li>
									<Divider className="inscopix-divider" />
									{(auth0ClientConfig && auth0ClientConfig.allowChangePassword) || allowChangePassword ?
										<li title="Update Password" className="border-top m-0">
											<a data-testid="update-password-item" href="#" onClick={(e) => this.passwordUpdate(e)}>
												<i className="zmdi zmdi-key text-warning mr-3 font-2x"></i>
												<span><IntlMessages id="widgets.passwordUpdate" /></span>
											</a>
										</li>
										:
										<></>
									}
									{(auth0ClientConfig && auth0ClientConfig.allowChangePassword) || allowChangePassword ? 
										<Divider className="inscopix-divider" />
										:
										<></>
									}
									<li title="About Us" data-testid="about-us-page" className="border-top m-0">
										<a href='https://lifesherpapp.com/about-us/' target='_blank' onClick={(e) => this.handleAboutUs(e)}>
											<i className="zmdi zmdi-info text-info mr-3 font-2x"></i>
											<span>About Us</span>
										</a>
									</li>
									<Divider className="inscopix-divider" />
									<li title="Logout" data-testid="logout-item" className="border-top m-0">
										<a href="#" onClick={(e) => this.logoutUser(e)}>
											<i className="zmdi zmdi-power text-danger mr-3 font-2x"></i>
											<span><IntlMessages id="widgets.logOut" /></span>
										</a>
									</li>
									<Divider className="inscopix-divider" />
								</ul>
							</div>
						</DropdownMenu>
					</Dropdown>

				</div>
				{openLogoutPopup && <LSPortalLogoutPopup openLogoutPopup={openLogoutPopup} handleCloseLogoutPopup={this.handleCloseLogoutPopup.bind(this)}/>}
				{passwordDialog &&
					<Dialog
						fullScreen={false}
						open={this.props.passwordDialog}
						onClose={() => this.closePasswordDialog()}
						fullWidth={true}
						maxWidth="xl"
						aria-labelledby="responsive-dialog-title"
                        className={this.props.popupThemeClass}
						PaperProps={
							{ style: { maxWidth: "500px", borderRadius: 8, margin: 'auto' } }
						}
						id="password-update"
					>
						<LifeSherpaLoading loading={this.props.updatePasswordLoading} />
						<DialogTitle>
							<div className="p-3 d-flex align-items-center justify-content-between bg-lifesherpa" >
								<h2 className="text-white">Password Update</h2>
								<IconButton data-testid="close-update-password-dialog" className="text-white" onClick={() => this.closePasswordDialog()}><CloseIcon /></IconButton>
							</div>
						</DialogTitle>
						<DialogContent className='pt-3' >
							<FormGroup>
								<Label>Old Password </Label>
								<Input data-testid="old-password-text-field" type='password' className="form-control my-2" placeholder="Enter Old Password" value={this.state.oldPassword} onChange={(e) =>this.handlePasswordChange(e)} />
								{this.state.error2 ? <FormFeedback data-testid="old-password-error"  className="d-block" style={{ fontFamily: "'Lato'" }}>{this.state.error2}</FormFeedback> : ""}
								{updateError ? <FormFeedback data-testid="update-password-error"  className="d-block" style={{ fontFamily: "'Lato'" }}>{updateError}</FormFeedback> : ""}
							</FormGroup>
							<FormGroup>
								<Label>New Password</Label>
								<Input data-testid="new-password-text-field" type='password' className="form-control my-2" placeholder="Enter New Password" value={this.state.newPassword} onChange={(e) => this.handleChange(e)} />
								{this.state.error ? <div data-testid="new-password-error" className="form_feedback" style={{ fontFamily: "'Lato'" }}>{this.state.error}</div> : ""}
							</FormGroup>
						</DialogContent>
						<DialogActions className='p-3'>
							<div className="w-100 d-flex align-items-center justify-content-between update-passwords-button">
								<Button color="primary" variant="outlined" data-testid="cancel-update-password-form" className="form-cancel-button" onClick={() => this.closePasswordDialog()}>Cancel </Button>
								<Button color="primary" variant="contained" data-testid="submit-update-password-form" className="primary-button" onClick={() => this.handleUpdate()}>Update </Button>
							</div>
						</DialogActions>
					</Dialog>}
			</div>
		);
	}
}

// map state to props
const mapStateToProps = ({ settings, authUser, Status, Contacts }) => {
	const {userOrgDetail, userOrgFromToken, allowChangePassword} = authUser;
	const {twilioClient}=Contacts
	const { profile, user, updatePasswordLoading,passwordDialog,updateError,usersInfo, isConfiguratorOpened, isLMSOpened } = authUser;
	const fakaDataForAuth0 = config.defaultAuth0DataForForUSA
	const {auth0ClientConfig} = (userOrgDetail && userOrgDetail.auth0ClientConfig) ? userOrgDetail : (userOrgFromToken && userOrgFromToken.auth0ClientConfig)  ? userOrgFromToken : {auth0ClientConfig: fakaDataForAuth0}
	const { popupThemeClass } = settings; 
	return {usersInfo, settings, profile, user, Status ,updatePasswordLoading,passwordDialog,updateError,popupThemeClass, isConfiguratorOpened, isLMSOpened, auth0ClientConfig, twilioClient, allowChangePassword};
}

export default withAuth0(withRouter(connect(mapStateToProps, {
	logoutUserFromFirebase,
	updatePassword,
	getUserStatusById,
	showUpdatePasswordDialog,
	clearUpdatePasswordError
})(SherpaUserProfile)));
