import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { connect } from 'react-redux';


class ActivityCalenderPopup extends Component {

    componentDidMount() {
        document.addEventListener('nativeBackClick', this.handleNativeBack);
    }

    componentWillUnmount() {
		document.removeEventListener('nativeBackClick', this.handleNativeBack);
	}

    handleNativeBack = () => {
		this.props.handleClose();
	}

    render() {
        let { showCalenderPopup, popupThemeClass, activity } = this.props;
        const calenderList = activity.sortedSchedules || []
       
        return (
            <Dialog
                fullScreen={false}
                open={showCalenderPopup}
                onClose={this.props.handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
                className={`${popupThemeClass} schedules-list-dialog-popup`}
                id="activity-calender-popup"
            >
            <DialogTitle>
                <div className="row py-2 px-3 border-bottom bg-lifesherpa" >
                    <CalendarTodayIcon className="my-auto ml-1 text-white" /><h2 className="text-white my-auto ml-2">{activity?.title || ''}</h2>
                    <IconButton className="ml-auto text-white my-auto" style={{ cursor: "pointer" }} onClick={this.props.handleClose}><CloseIcon /></IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="m-3" >
                    <div data-testid="scheduled-details-dialog" className="my-5 py-5 mx-3" style={{ minHeight: "300px" }}>
                        {calenderList.length > 0 ? calenderList.map((list, index) => (
                            <div className="row mb-2 pb-2 border-bottom" key={index}>{list.text}</div>
                        )) : ""}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <div className="w-100 text-center" >
                    <div data-testid="scheduled-details-dialog-close-button" className="helpbutton mx-auto" style={{ width: '150px', fontSize: '16px', borderRadius: '75px' }} onClick={this.props.handleClose}>OK</div>
                </div>
            </DialogActions>
        </Dialog>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { popupThemeClass } = settings;
    return { popupThemeClass };
}

export default connect(mapStateToProps, {})(ActivityCalenderPopup);

