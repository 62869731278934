/**
 * Chat App Reducers
 */

// actions types
import {
    GET_RECENT_HISTORY,
    GET_SELECTED_HISTORY,
    UPDATE_HISTORY_SEARCH,
    SEARCH_HISTORY,
    GET_HISTORY_LIST,
    GET_SUCCESS_HISTORY_LIST,
    GET_ACHEIVEMENTS_DETAIL,
    POST_EVALUATION_FORM,
    POST_SUCCESS_EVALUATION_FORM,
    POST_STEPS_NOTES_FORM,
    POST_SUCCESS_STEPS_NOTES_FORM,
    GET_SUCCESS_ACHEIVEMENTS_DETAIL,
    SHOW_LOADING_INDICATOR_HISTORY_DETAIL,
    HIDE_LOADING_INDICATOR_HISTORY_DETAIL,
    SET_SELECTED_STEP_ID,
    SET_ACHIEVEMENTS_PAGE_COUNT,
    SET_ACHIEVEMENTS_HAS_MORE_DATA,
    SHOW_LOADING_INDICATOR_HISTORY_LIST,
    HIDE_LOADING_INDICATOR_HISTORY_LIST,
    SET_ACHIEVEMENTS_DATA_CLEAR,
    USER_CONFIRM_ACHEIVEMENT,
    USER_CONFIRM_ACHEIVEMENT_SUCCESS,
    SHOW_UNCONFIRMED_ACHIEVEMENTS,
    SET_HISTORY_LIST_PAGE_COUNT,
    UPDATE_HISTORY_LIST
} from '../actions/types';

// chat users
// import recentActivitiesHistory from 'Assets/data/chat-app/users';

const INITIAL_STATE = {
    allActivitiesHistory: null,
    recentActivitiesHistory: null,
    allRecentActivitiesHistory: null,
    selectedActivitiesHistory: null,
    searchActivitiesHistory: '',
    achievementDetail: null,
    loading: false,
    loadingDetail: false,
    postEvaluation: null,
    postStepsNotes: null,
    selectedStepId: null,
    hasMoreData: true,
    pageCount: 1,
    confirmedAcheivement: null,
    showUnconfirmedAcheivemts:false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        // get recent chat user
        case GET_RECENT_HISTORY:
            return { ...state, recentActivitiesHistory: null };
        case SET_SELECTED_STEP_ID:
            return{...state, selectedStepId: action.payload}
        // get recent chat user
        case GET_SELECTED_HISTORY:
            return { ...state, selectedActivitiesHistory: action.payload };
        // get recent chat user
        case POST_EVALUATION_FORM:
            return { ...state, loadingDetail: true };
        // get recent chat user
        case POST_SUCCESS_EVALUATION_FORM:
            return { ...state, postEvaluation: action.payload, loadingDetail: false };
        // get recent chat user
        case POST_STEPS_NOTES_FORM:
            return { ...state, loadingDetail: true };
        // get recent chat user
        case POST_SUCCESS_STEPS_NOTES_FORM:
            return { ...state, postStepsNotes: action.payload, loadingDetail: false };
        // get recent chat user
        case GET_ACHEIVEMENTS_DETAIL:
            return { ...state, loadingDetail: true, achievementDetail: null };
        // get recent chat user
        case GET_SUCCESS_ACHEIVEMENTS_DETAIL:
            return { ...state, achievementDetail: action.payload, loadingDetail: false };
        case SET_ACHIEVEMENTS_DATA_CLEAR:
            return {...state, pageCount: 1, hasMoreData: true, achievementDetail: null, allActivitiesHistory: null, selectedActivitiesHistory: null, showUnconfirmedAcheivemts:false}
        case SET_ACHIEVEMENTS_PAGE_COUNT:
            return { ...state, pageCount: action.payload };
        case SET_ACHIEVEMENTS_HAS_MORE_DATA:
            return { ...state, hasMoreData: action.payload, pageCount };
        // get recent chat user
        case GET_HISTORY_LIST:
            //let loading = state.pageCount > 1 ? false : true
            return { ...state, loading:true, hasMoreData: true }; 
        // get recent chat user
        case GET_SUCCESS_HISTORY_LIST:
            let hasMoreData = action.payload.length > 0 ? true : false
            let pageCount = action.payload.length > 0 ? state.pageCount : 1
            let allActivitiesHistory =  state.allActivitiesHistory ? state.allActivitiesHistory.concat(action.payload) : action.payload
            return { ...state, allActivitiesHistory, loading: false, pageCount: state.pageCount + 1, hasMoreData};
        case UPDATE_HISTORY_LIST:
            const {achievementId, answer} = action.payload;
            if(state.allActivitiesHistory) {
                let itemIndex = state.allActivitiesHistory.findIndex(item => item.id === achievementId);
                if(itemIndex !== -1) {
                    state.allActivitiesHistory[itemIndex]["status"] = answer ? "confirmed" : "confirmedNegative";
                }
            }
            if(state.selectedActivitiesHistory) {
                state.selectedActivitiesHistory["status"] = answer ? "confirmed" : "confirmedNegative";
            }
            return { ...state, allActivitiesHistory: state.allActivitiesHistory, selectedActivitiesHistory: state.selectedActivitiesHistory };
        // update search
        case UPDATE_HISTORY_SEARCH:
            return { ...state, searchActivitiesHistory: action.payload };

        // search user
        case SEARCH_HISTORY:
            if (action.payload === '') {
                return { ...state, recentActivitiesHistory: state.allRecentActivitiesHistory};
            } else {
                const searchActivitiesHistory = state.allRecentActivitiesHistory.filter((user) =>
                    user.first_name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
                return { ...state, recentActivitiesHistory: searchActivitiesHistory }
            }
        // show loading 
        case SHOW_LOADING_INDICATOR_HISTORY_LIST:
            return { ...state, loading: true };
        // hide loading 
        case HIDE_LOADING_INDICATOR_HISTORY_LIST:
            return { ...state, loading: false , loadingDetail: false};
        // show loading 
        case SHOW_LOADING_INDICATOR_HISTORY_DETAIL:
            return { ...state, loadingDetail: true };
        // hide loading 
        case HIDE_LOADING_INDICATOR_HISTORY_DETAIL:
            return { ...state, loadingDetail: false };
        
        case USER_CONFIRM_ACHEIVEMENT:
            return { ...state, loadingDetail: true };
            
        case USER_CONFIRM_ACHEIVEMENT_SUCCESS:
            return { ...state, confirmedAcheivement: action.payload, loadingDetail: false };
        case SHOW_UNCONFIRMED_ACHIEVEMENTS:
            return { ...state, showUnconfirmedAcheivemts: true, loading:true, hasMoreData: true };
        case SET_HISTORY_LIST_PAGE_COUNT:
            return { ...state, pageCount: action.payload };

        default: return { ...state };
    }
}
