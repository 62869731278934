import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
import config from '../../config/config'
import WarningDialog from "../WarningDialog";

import { 
  hideChatPanel, 
  selectMenuItem, 
  showVideoRoomPanel,
  hideMissedCallConfirmation,
  showVideoCallConfirmation,
} from 'Actions';
import { v4 as uuidv4 } from 'uuid';

class MissedCallConfirmation extends Component {
  state={
    timeOut:0,
  }

  handleOpenVideo = () => {
      let {videoCallMessage} = this.props;
      const roomname = uuidv4() //`${Math.random().toString(36).substr(2, 6)}-${Math.random().toString(36).substr(2, 5)}-${Math.random().toString(36).substr(2, 3)}`;
      if(videoCallMessage && videoCallMessage.callerId) {
        const orgId = videoCallMessage.organization || localStorage.getItem('orgId');
        localStorage.setItem('selectedOrgId', orgId);
          let selectedVideoChat = {name: roomname, userId: videoCallMessage.callerId};
          let videoCallRoomDetails = JSON.stringify(selectedVideoChat);
          localStorage.setItem("videoCallRoomDetails",  videoCallRoomDetails)
          let openVideoCallRoom = `${config.lsPortalUrl}/lifesherpa/videocall?room=${roomname}`;
          this.props.hideMissedCallConfirmation(openVideoCallRoom, this.props.navigate);
      } else {
          this.props.hideMissedCallConfirmation();
      }
  }

  handleClose = () => {
      this.props.hideMissedCallConfirmation();
  }

  render() {
    const {showMissedCallConfirmationPanel, videoCallMessage } = this.props;
    console.log(" videoCallMessage --> ", videoCallMessage);
    console.log(" showMissedCallConfirmationPanel --> ", showMissedCallConfirmationPanel);
    let callerName = "Unknown Caller"
    if(videoCallMessage) {
        if(videoCallMessage.message) {
          callerName = videoCallMessage.message.split(" request")[0];
        } else if(videoCallMessage.name) {
          callerName = videoCallMessage.name;
        }
    }

    return (
      <div> 
        { showMissedCallConfirmationPanel && videoCallMessage ? 
          <WarningDialog
              showWarning = {showMissedCallConfirmationPanel}
              warningText = {videoCallMessage.message}
              cancelText = {"CANCEL"}
              acceptText = {"CALL BACK"}
              handleCancel = {()=> this.handleClose()}
              handleAccept = {()=> this.handleOpenVideo()}
          />
          : <></>
          }
      </div>
    );
  }
}

const mapStateToProps = ({ settings, Contacts }) => {
	const { screenThemeClass, popupThemeClass  } = settings; 
  let {contactList, showMissedCallConfirmationPanel, videoCallMessage} = Contacts;
	return {contactList, showMissedCallConfirmationPanel , screenThemeClass, popupThemeClass, videoCallMessage};
};

export default withRouter(connect(mapStateToProps, {
  hideChatPanel, 
  selectMenuItem, 
  showVideoRoomPanel,
  hideMissedCallConfirmation,
  showVideoCallConfirmation,
})(MissedCallConfirmation));
