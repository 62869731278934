import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../../router';
import { connect } from 'react-redux';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import VideoChat from './VideoChat';

class VideoChatPanel extends Component {

    componentDidMount() {
         const {videoToken, loading, selectedVideoChat} = this.props;
        if(selectedVideoChat && selectedVideoChat.name) {
            //this.props.getVideoCallDetails(selectedVideoChat.name);
        }  
	}

    render() { 
		const {  loading, selectedVideoChat, videoToken, videoTokenLoading } = this.props;
		let idToken=localStorage.getItem("idToken")
         if(!idToken) this.props.navigate("/launchscreen")
		 console.log(" Constacts loading ---->",loading);
         console.log(" videoTokenLoading loading ---->",videoTokenLoading);
        return (
			 <div className="vidio-commuinication-panel">
                {videoTokenLoading || !videoToken ?
                    <LifeSherpaLoading loading={videoTokenLoading || !videoToken}/>
                    : 
                    <VideoChat windowWidth={this.props.windowWidth}/>
                }	
             </div> 
             );
    }
}

const mapStateToProps = ({Contacts,authUser, chatAppReducer}) => {
	const {userRole}=authUser
	const {videoToken, loading, videoTokenLoading}=Contacts
	const { selectedChatUsers, selectedUser } = chatAppReducer;
	return {videoToken,userRole, loading, selectedUser, videoTokenLoading};
};

export default withRouter(connect(mapStateToProps, {})(VideoChatPanel));
