/**
 * Signin Firebase
 */

 import React, { Component } from 'react';
 import AppBar from '@mui/material/AppBar';
 import Toolbar from '@mui/material/Toolbar';
 import { Form, FormGroup } from 'reactstrap';
 import HeaderWithCancelButton from '../../Header/HeaderWithCancelButton'
 import config from '../../../config/config'
 import { withAuth0 } from "@auth0/auth0-react";
 import LifeSherpaLoading from '../../LifeSherpaLoading'
 import { connect } from 'react-redux';

 class LoadingVideoUI extends Component {
   render() {
      const {text} = this.props;
      return (
         <div type="bottom" duration={2000}> 
            <div className={`${config.rctSessionWrapper} ${this.props.screenThemeClass}`}>
            <div className='login-screen'>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderWithCancelButton activeTheme={this.props.activeTheme} themeLogo={this.props.themeLogo} screenThemeClass={this.props.screenThemeClass} themeData={this.props.themeData} location={this.props.location} href={`/success`}/>
                  </Toolbar>
               </AppBar>
               <LifeSherpaLoading loading={true}/>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center pt-2">
                              <Form>
                                 <FormGroup className="mb-10">
                                 <p className="mb-0 loader-text">{text}</p>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = ({ authUser , settings}) => {
   const { screenThemeClass, popupThemeClass, themeLogo, activeTheme } = settings; 
   const { themeData} = authUser; 
   return { screenThemeClass, popupThemeClass, themeLogo, activeTheme, themeData}
}

export default withAuth0(connect(mapStateToProps, {})(LoadingVideoUI));