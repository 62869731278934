/**
 * User Block Component
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';

// redux action
import { 
} from '../../actions';

import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import StarIcon from '@mui/icons-material/Star';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import config from '../../config/config';
import { 
	getMobileTabsConfig,
	isMobile,
	reauthenticateIfTokenExpireWithIn30Min, 
	scrollMaincontainerToTop
 } from '../../helpers/helpers';

 import {
	changeRole,
	showClientPanel,
	setClientActivitiesType,
	postClientMuteNotification,
	handleShowClientDashboard,
	addSelectedChatUser,
	handleNoticesPanel,
	logoutCallbackForAuth0Expired,
	showAssignActivityIFramePanel,
	getClientUnconfirmedAchievementsList,
	showActivityHistoryDetail,
	clearHistoryData,
	showFullChatPanelForSelectedUser,
	getClientMuteNotification,	
	handleChangeBottomTab,
	handleShowFallbackScreen,
	setUserSelected
 } from '../../actions'
 import _ from 'lodash';


class UserProfileBlock extends Component {

	state = {

	}

	handleClickMoreIcon(event) {
        this.setState({ anchorEl: event.currentTarget });
    };

	componentDidMount() {
		document.addEventListener('nativeBackClick', this.handleCloseMenu);
	}

	componentWillUnmount() {
		document.removeEventListener('nativeBackClick', this.handleCloseMenu);
	}

	handleCloseMenu = () => {
		this.setState({ anchorEl: null })
	}

	// Function to trigger page rendering with debounce
    triggerCloseClientDetailPage = _.debounce(() => {
		const auth_uid = localStorage.getItem("auth_uid")
        this.props.setClientActivitiesType('Normal');
        localStorage.setItem('uid', auth_uid);
        this.props.changeRole({ role: 'admin', id: auth_uid });
        this.props.showClientPanel(false);
		this.props.getClientMuteNotification(auth_uid, auth_uid);
		this.props.setUserSelected({});
		const prevUrl = localStorage.getItem('prevUrl');
		if(prevUrl) {
			localStorage.removeItem('prevUrl');
			this.props.navigate(prevUrl);
		} else {
			if (isMobile()) {
				this.props.handleChangeBottomTab({tabMode: "My Clients", selectedClientMenuItem: "/app/lsdashboard/client",  showFallbackScreen: true });
			}
			this.props.navigate("/app/lsdashboard/clients");
		}
        this.props.handleShowFallbackScreen(false);
    }, 500);

	handleCloseClientDetailsPanel = () => {
		this.props.handleShowFallbackScreen(true);
        this.triggerCloseClientDetailPage();
    }

	handleMuteNotification() {
        const { muteNotifications } = this.props
        const enabled = !muteNotifications
        const adminUserId = localStorage.getItem("auth_uid")
        const clientUserId = localStorage.getItem("uid")
        this.setState({ anchorEl: null });
        this.props.postClientMuteNotification(adminUserId, clientUserId, enabled)
    }

	handleOpenDashboardPanel = (userId) => {
        this.props.handleShowClientDashboard(true, userId)
        this.setState({ ...this.state, anchorEl: null });
    }

	showChatPanelForSelectedUser = (userId, displayName, profileImageURL) => {
		const {contactList} = this.props;
		let chatUser = contactList ? contactList.find(userInfo => userInfo.userId == userId) : null;
		console.log(" Chat opening for user ->", chatUser);
        if (chatUser) {
			this.props.showFullChatPanelForSelectedUser(chatUser);
			
        } else {
			const userDetails = {name: displayName, profileImageURL, userId};
			this.props.showFullChatPanelForSelectedUser(userDetails);
		}
		localStorage.setItem('prevUrl', window.location.pathname);
		this.props.navigate("/app/lsdashboard/chat");

    }

	handleOpenNoticePanel = (userId) => {
        this.props.handleNoticesPanel(true, userId)
        this.setState({ ...this.state, anchorEl: null });
    }

	//assign| unassign activity
    async handleOpenAssignActivity(userId) {
        this.setState({ ...this.state, anchorEl: null  });
        const themeName = this.props.activeTheme && this.props.activeTheme.name ? this.props.activeTheme.name : "default"
        const theme = themeName.charAt(0).toUpperCase() + themeName.slice(1);
        let idToken = localStorage.getItem("idToken");
        const orgId = localStorage.getItem("selectedOrgId");
        let openAssignActivityAppUrl = `${config.assignActivityUrl}?userId=${userId}&idToken=${idToken}&orgId=${orgId}&mode=web&theme=${theme == "Danger" ? "Theme4" : theme}`;
        let assingActivityInfo = { assignActivityUrl: openAssignActivityAppUrl}
        this.setState({ ...this.state, loading: true, anchorEl: null });
        const reauthenticated = await reauthenticateIfTokenExpireWithIn30Min();
        if (reauthenticated) {
            console.log("%c Token reauthenticated --------> ", "color:green");
            idToken = localStorage.getItem("idToken");
            openAssignActivityAppUrl = `${config.assignActivityUrl}?userId=${userId}&idToken=${idToken}&orgId=${orgId}&mode=web&theme=${theme == "Danger" ? "Theme4" : theme}`;
            assingActivityInfo = { assignActivityUrl: openAssignActivityAppUrl};
            this.setState({ ...this.state, loading: false, anchorEl: null  });
            this.handleOpenAssingActivity(assingActivityInfo)
            // window.open(openAssignActivityAppUrl,"_blank");
        }
        else {
            this.setState({ ...this.state, loading: false, anchorEl: null  });
            console.log("%c Token reauthentication failed ------->", "color:red");
            logoutCallbackForAuth0Expired();
        }

    }

    handleOpenAssingActivity = (assingActivityInfo) => {
		this.props.showAssignActivityIFramePanel(assingActivityInfo);
		localStorage.setItem('prevUrl', window.location.pathname);
		this.props.navigate('/app/lsdashboard/client/activities/assign')
	}

     //Unconfirmed activities achievements
     handleOpenUnconfirmAchievements = (userId) => {
        this.props.clearHistoryData();
        const activityConfirmation = localStorage.getItem("activityConfirmation");
        if (activityConfirmation) {
            localStorage.removeItem("activityConfirmation");
        }
        this.setState({ anchorEl: null });
        this.props.getClientUnconfirmedAchievementsList(userId, null, !isMobile());
        scrollMaincontainerToTop()
        this.props.showActivityHistoryDetail(true);
		localStorage.setItem('prevUrl', window.location.pathname)
        this.props.navigate("/app/lsdashboard/activities/history")
    }
	handleStatus = (mode) => {
		console.log("mode :", mode);
		const uid = mode === 'Client' ? localStorage.getItem('uid') : localStorage.getItem('auth_uid');
		this.props.handleStatus(uid);
	}

	render() {
		const {profile, usersInfo, showStatus, selectedMemberId, groupMembers, selectedMember, muteNotifications, clientUserDetail} = this.props;
		let { statusData} = this.props;
		const {mode} = getMobileTabsConfig();
		const {anchorEl} = this.state;
		let displayName;
		let profileImageURL;
		// console.log("mode :", mode);
		// console.log("User Profile Mute Notifications :", muteNotifications);
		if(mode === 'Client') {
			displayName = (selectedMember && selectedMember.name) || (clientUserDetail && clientUserDetail.displayName) || "Display Name"
			profileImageURL = (selectedMember && selectedMember.profileImageURL) || (clientUserDetail && clientUserDetail.profileImageURL)
			statusData = [];
			if(groupMembers && selectedMember) {
				let  selectedMemberDetails = groupMembers[selectedMember.groupId] && groupMembers[selectedMember.groupId][selectedMember.userId];
				if (selectedMemberDetails && selectedMemberDetails.lastUserStatusSets) {
					Object.keys(selectedMemberDetails.lastUserStatusSets).map((status, key) => {
						let obj = { color: selectedMemberDetails.lastUserStatusSets[status].color, value: selectedMemberDetails.lastUserStatusSets[status].status, direction: selectedMemberDetails.lastUserStatusSets[status].direction };
						statusData.push(obj);
					})
				}
			}
			
		} else {
			if(usersInfo && usersInfo.displayName) {
				displayName = usersInfo.displayName;
			} else {
				displayName = profile ? profile.nickname || profile.name || (profile.given_name && profile.family_name ? profile.given_name + " " + profile.family_name : profile.given_name || profile.family_name) : '';
			}

			if(usersInfo && usersInfo.profileImageURL) {
				profileImageURL = usersInfo.profileImageURL
			} else {
				profileImageURL = profile && profile.picture;
			}
		}
        if(!mode || mode === "Workflows") {
            return '';
        }
		return (
			<div className="user-profile-block" id="user-profile-header">
				{mode === 'Client' ? 
					<div className='d-flex align-items-center back-block' >
						<IconButton onClick={() => this.handleCloseClientDetailsPanel()}>
							<ArrowBackIcon/>
						</IconButton>
					</div> 
					: ''
				}
				<div className={`d-flex main-container ${mode === 'Client' ? 'top-padding-remove': ''}`}>
					<div className='media-block d-flex align-items-center'>
						<div className='d-flex align-items-center media-block-container '>
							<div className='d-flex align-items-center justify-content-center user-image-block '>
								<img src={profileImageURL || require('Assets/avatars/user-15.jpg')} alt="user profile" className="user-image" />
								{mode === 'Client' ? "" :
									<div className='star-icon'>
										<StarIcon/>
									</div> 
								}
								{muteNotifications ? <></> : 
									<div className='notification-icon' title="Muted">
										<VolumeOffOutlinedIcon />
									</div>
								}
							</div>
							{mode === 'Client' ?
								<div className='d-flex align-items-center justify-content-between user-actions-block'>
									<IconButton className='mr-2' onClick={() => this.showChatPanelForSelectedUser(selectedMemberId, displayName, profileImageURL)}>
										<ChatOutlinedIcon className="reply-messages-icon"/>
									</IconButton>
									<IconButton onClick={() => this.handleOpenUnconfirmAchievements(selectedMemberId)}>
										<AssignmentTurnedInOutlinedIcon className="unconfirmed-activity-icon"/>
									</IconButton>
								</div>
								: ""
							}
						</div>
					</div>
					<div className='content-block w-100'>
						<div className="d-flex align-items-center justify-content-between  ">
							<div className='user-title'>{displayName || 'Display Name'}</div>
							{ mode === 'Client' ?
								<IconButton className="user-more-icon-button" title="Client More Actions"  onClick={(e) => this.handleClickMoreIcon(e)}>
									<MoreVertIcon fontSize='medium' aria-label="More" aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" />
								</IconButton>
								: ""
							}
							 <Menu id="simple-menu-user-block" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => this.setState({ anchorEl: null })}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                getcontentanchorel={null}
                            >
                                {/* <MenuItem className="menuEditItem" onClick={() => this.handleOpenDashboardPanel(selectedMemberId)}>Dashboard</MenuItem> */}
                                {/* {chatUser ? <MenuItem className="menuEditItem" onClick={() => this.showChatPanelForSelectedUser(chatUser)}>Chat</MenuItem> : ""} */}
                                <MenuItem className="menuEditItem" onClick={() => this.handleMuteNotification()}>{muteNotifications ? "Mute Notifications" : "Unmute Notifications"}</MenuItem>
                                <MenuItem className="menuEditItem" onClick={() => this.handleOpenNoticePanel(selectedMemberId)}>Alerts</MenuItem>
								<MenuItem className="menuEditItem" onClick={() => this.handleOpenAssignActivity(selectedMemberId)}>Assign | Unassign Activity</MenuItem>
                            </Menu>
						</div>
						<div className="d-flex align-items-center status-block">
							{showStatus && statusData && statusData.length > 0 ? statusData.map((status, key) => {
								let { direction, value, color} = status;
								return (
									<div key={key} className={"w-auto status-item list-inline-item text-white mt-2"} style={{ borderRadius: 20 + "px", background: color ? color : '', color: color === '#ffff00' ? '#677080' : 'white' }}>
										<div className="d-flex">
											{direction && direction === 'positive' ?
												<div className='mr-1'>
													<i className="zmdi zmdi-trending-up zmdi-hc-lg"></i>
												</div>
												: direction && direction === 'neutral' ?
													<div className='mr-1'>
														<i className="zmdi zmdi-long-arrow-right zmdi-hc-lg"></i>
													</div>
													: direction && direction === 'negative' &&
													<div className='mr-1'>
														<i className="zmdi zmdi-trending-down zmdi-hc-lg"></i>
													</div>
											}
											{value}
										</div>
									</div>
								)
								})
								: ""
							}
							{showStatus && statusData && statusData.length ? 
								<div className='w-auto list-inline-item text-white mt-1 '>
									<IconButton className="edit-status-button" title="Status" aria-label="settings" onClick={() => this.handleStatus(mode)} >
											{mode === 'Client'? 
												<VisibilityIcon/>: 
												<BorderColorRoundedIcon data-testid="client-status-edit-button" />
											}
									</IconButton>
								</div> : ""
							}
							

						</div>
					</div>

				</div>

			</div>
		);
	}
}

// map state to props
const mapStateToProps = ({ settings, authUser, GroupList, chatAppReducer, Contacts, clientsList }) => {
	const { profile, usersInfo, statusData } = authUser;
	const { showStatus } = usersInfo ? usersInfo : { showStatus: false };
	const {selectedMenu, activeTheme } = settings;
	const {groupMembers } = GroupList;
	const { selectedMemberId , selectedMember, selectedChatUsers} = chatAppReducer;
	let { contactList } = Contacts;
	const { muteNotifications, clientUserDetail } = clientsList;



	return {muteNotifications, contactList, usersInfo, profile, statusData, showStatus, selectedMenu, groupMembers, selectedMemberId, selectedMember, selectedChatUsers, activeTheme, clientUserDetail };
}

export default withRouter(connect(mapStateToProps, {
	changeRole,
	showClientPanel,
	setClientActivitiesType,
	postClientMuteNotification,
	handleShowClientDashboard,
	addSelectedChatUser,
	handleNoticesPanel,
	showAssignActivityIFramePanel,
	getClientUnconfirmedAchievementsList,
	showActivityHistoryDetail,
	clearHistoryData,
	showFullChatPanelForSelectedUser,
	getClientMuteNotification,
	handleChangeBottomTab,
	handleShowFallbackScreen,
	setUserSelected
})(UserProfileBlock));
