/**
 * Change user status
 */
import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
// import { withRouter } from 'react-router-dom';
import withRouter from '../../router';
import LifeSherpaLoading from '../LifeSherpaLoading';
import moment from 'moment';
import {setFormStatusData} from '../../actions';
import Button from '@mui/material/Button';
import DialogTitle from "@mui/material/DialogTitle";
import {getMobileTabsConfig, isMobile} from "../../helpers/helpers";
import DialogContent from '@mui/material/DialogContent';
import CustomInput from '../CustomInput'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

class SetCurrentStatus extends Component {

	state = {
		statusList:[],
	}

    componentDidMount() {
		console.log("%c[SetCurrentStatus]%c Rendered set current status dialog popup","color:black","color:green");
		document.addEventListener('nativeBackClick', this.handleNativeBack);
	}

	handleNativeBack = () => {
		const {hideCancel} = this.props
		if (!hideCancel) {
			document.removeEventListener('nativeBackClick', this.handleNativeBack);
			this.props.handelStatusEditClose();
		}
	}

	componentWillUnmount() {
		console.log("%c[SetCurrentStatus]%c SetCurrentStatus dialog popup closed","color:black","color:red");
		document.removeEventListener('nativeBackClick', this.handleNativeBack);
		this.props.setFormStatusData(null);
	}

	handleStatusChange = (e, position, i) => {
		const { statusList } = this.props;
		
		if (!statusList) return;
	
		const updatedStatusList = statusList.map((status) => {
			if (status.position === position) {
				const updatedItemsList = status.itemsList.map((item, index) => ({
					...item,
					value: index === i, // Set the clicked item's value to true, others to false
				}));
				const updatedItems = status.items;
				for(const item in updatedItems) {
					if(updatedItemsList[i] && updatedItemsList[i]["name"] === item) {
						updatedItems[item] = true;
					} else {
						updatedItems[item] = false;
					}
				}
				status["itemsList"] = updatedItemsList;
				status["items"] = updatedItems;
				return status
				
				// return {
				// 	...status,
				// 	itemsList: status.itemsList.map((item, index) => ({
				// 		...item,
				// 		value: index === i, // Set the clicked item's value to true, others to false
				// 	})),
				// 	items: {
				// 		...status.items,
				// 		[e.currentTarget.value]: true, // Set current item to true
				// 	},
				// };
			}
			return status;
		});
	
		this.props.setFormStatusData(updatedStatusList);
	};
	
	
	render() {
		const { showStatus, image, name , statusList, profile, status,loading, clientUserDetail, usersInfo, isStatusUpdated, hideCancel, popupMode, preview } = this.props
		console.log(" statuslist updated in render statusList: ", statusList, isStatusUpdated)
		// const {mode} = getMobileTabsConfig();
		let displayName;
		let profileImageURL;
		if(name || image) {
			displayName = name;
			profileImageURL = image;
		} if(popupMode === "Client") {
			if(clientUserDetail) {
				displayName = clientUserDetail.displayName;
				profileImageURL = clientUserDetail.profileImageURL;
			}
		} else {
			displayName = profile ? profile.nickname || profile.name || (profile.given_name && profile.family_name ? profile.given_name + " " + profile.family_name : profile.given_name || profile.family_name ) : usersInfo || usersInfo.displayName;
			profileImageURL = profile && profile.picture ? this.props.profile.picture : usersInfo || usersInfo.profileImageURL;
		}
		
		return (
			<>
				<Dialog
					fullScreen={isMobile()}
					open={showStatus}
					onClose={() => this.props.handelStatusEditClose()}
					fullWidth={true}
					maxWidth="xl"
					aria-labelledby="responsive-dialog-title"
					className={` setcurrent-status-dialog-popup ${this.props.popupThemeClass}`}
					PaperProps={
							{ style: { maxWidth: "450px", borderRadius: 8, margin: 'auto', overflow:'hidden' } }
						}
					>
					{isMobile() ? 
						<DialogTitle>
							<div className="row px-3 py-3 border-bottom bg-lifesherpa">
								{hideCancel && popupMode != "Client" && !preview ?
								     <></> :
									<IconButton data-testid="close-status-dialog"  className="my-auto text-white" onClick={() => this.props.handelStatusEditClose()}><ArrowBackIcon /></IconButton>
								}
								<h2 className="text-white pl-2  my-auto">Set your current status</h2>
							</div>
						</DialogTitle> :
						<DialogTitle>
							<div className="row px-3 py-3 border-bottom bg-lifesherpa">
								<h2 className="text-white pl-2  my-auto">Set your current status</h2>
								{hideCancel && popupMode != "Client" && !preview ?
									<></> :
									<IconButton data-testid="close-status-dialog"  className="ml-auto my-auto text-white" onClick={() => this.props.handelStatusEditClose()}><CloseIcon /></IconButton>
								}
							</div>
						</DialogTitle>
					}

					<DialogContent>
					<div data-testid="set-current-status-form" id="dialog-content" className="col-12">
						<div className="row user-status-profile-background ">
							<div className="row px-4 py-2">
								<img data-testid="set-status-dialog-client-image" className=" rounded-circle mr-2" src={profileImageURL || require('Assets/avatars/user-15.jpg')} alt='' width={40} height={40} style={{ borderRadius: 10 + "px" }} />
								<h3 data-testid="set-status-dialog-client-name" className="text-secondary my-auto">{displayName || "Display Name"}</h3>
							</div>
							
						</div>
						 <LifeSherpaLoading loading={this.props.loading} />
						{statusList && statusList.length && status && status.timeStamp ? 
							<div className="row px-2 py-2 border-top theme-header-background-style" >
								<h3 className="text-secondary ml-1 my-auto">{`Updated: ${moment(status.timeStamp, "YYYY-MM-DD HH:mm:ss Z").format('MMM DD, YYYY hh:mm A')}`}</h3>
							</div> : ""
						}
						{statusList && statusList.length > 0 ? statusList.map((status,statusIndex) => {
							return (
								<div key={statusIndex} className="user-status-list-form" data-testid={`client-status-form${statusIndex}`}>
									<div className="row px-4 py-2 border-top border-bottom theme-header-background-style" ><h3 className="text-secondary my-auto">{statusList.length > 0 && status ? status.heading : ''}</h3></div>
									{
										 status.itemsList && status.itemsList.map((st, key) => (
											<div className="row border-bottom user-status-list-options" key={key+statusIndex}>
													<CustomInput
													 className="my-auto ml-4 status-list-input-options"
													 id={`selStatus${status.userStatusSetId+key}`}
													 name={status.userStatusSetId}
													 onChange={(e) => this.handleStatusChange(e,status.position, key)} 
													 type="radio" 
													 label={st.name} 
													 value={st.name}
													 checked={st.value}
													 disabled={this.props.configuratorPreview || preview || popupMode == 'Client'}
													 />
											</div>))
									}
								</div>
							)
						}) :
							<div className="activity-details-height d-flex justify-content-center align-items-center">
								<div className=" no-data-found">
									<h4>{loading ? "":"Status Data Not Found"}</h4>
								</div>
							</div>
						}
						{this.props.configuratorPreview || preview || popupMode == 'Client' || !statusList || !statusList.length ? 
							<div className="row d-flex align-items-center justify-content-center mt-4"></div> :
							<div className="row d-flex align-items-center justify-content-center mt-4">
								{hideCancel ?
									<></> 
									: <Button color="primary" variant="outlined" data-testid="cancel-status-form" className="  mb-3 mr-2 form-cancel-button" onClick={() => this.props.handelStatusEditClose()}>Cancel </Button>
								}
								{ <Button color="primary" variant="contained" data-testid="submit-status-form" className=" mb-3 ml-2  primary-button"  onClick={() => this.props.updateStatus(statusList)}>Submit </Button>}
							</div>
						}
						
					</div>
					</DialogContent>
				</Dialog>
			</>
		);
	}
}

// map state to props
const mapStateToProps = ({ Status, settings, clientsList, authUser}) => {
	const {loading, statusList, status, isStatusUpdated} = Status;
	const { profile, usersInfo } = authUser;
	const { popupThemeClass } = settings; 
	const { clientUserDetail } = clientsList
	return {loading, statusList, popupThemeClass, status, clientUserDetail, profile, usersInfo, isStatusUpdated};
};

export default withRouter(connect(mapStateToProps, {setFormStatusData})(SetCurrentStatus));

