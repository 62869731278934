export const mergeRealtimeUserData = (groupMembers,  groupMembersDetails, callback) => {
    if(groupMembers && groupMembersDetails && groupMembersDetails.length) {
        // Create a new Web Worker instance
        const worker = new Worker(new URL('./WebWorkers/UserRealtimeWorker.js', import.meta.url));

        // Post data to the worker
        worker.postMessage({ groupMembers,  groupMembersDetails });

        // Receive message from worker
        worker.onmessage = function(e) {
            const { clientsList } = e.data;
            callback(clientsList);
            worker.terminate();
        };
    }
}

export const mergeRealtimeActivityData = (data, callback) => {
    if(data.activitiesList && data.activitiesList.length) {
        // Create a new Web Worker instance
        const worker = new Worker(new URL('./WebWorkers/ActivityRealtimeWorker.js', import.meta.url));

        // Post data to the worker
        worker.postMessage(data);

        // Receive message from worker
        worker.onmessage = function(e) {
            callback(e.data);
            worker.terminate();
        };
    }
}

export const mergeRealtimeAgendaViewData = (data, callback) => {
    console.log("mergeRealtimeAgendaViewData Run :", data);
    if(data.activitySummery) {
        // Create a new Web Worker instance
        const worker = new Worker(new URL('./WebWorkers/AgendaViewWorker.js', import.meta.url));

        // Post data to the worker
        worker.postMessage(data);

        // Receive message from worker
        worker.onmessage = function(e) {
            callback(e.data);
            worker.terminate();
        };
    }
}

export const processRealtimeGroupsCallData = (data, callback) => {
    if(data.realtimeData) {
        // Create a new Web Worker instance
        const worker = new Worker(new URL('./WebWorkers/GroupCallsRealtimeWorker.js', import.meta.url));

        // Post data to the worker
        worker.postMessage(data);

        // Receive message from worker
        worker.onmessage = function(e) {
            callback(e.data);
            worker.terminate();
        };
    }
}


export const mergeRealtimeTouchpointSummaryData = (data, callback) => {
    if(data.realtimeData && data.summaryList && data.summaryList.length) {
        // Create a new Web Worker instance
        const worker = new Worker(new URL('./WebWorkers/TouchpointSummaryWorker.js', import.meta.url));

        // Post data to the worker
        worker.postMessage(data);

        // Receive message from worker
        worker.onmessage = function(e) {
            callback(e.data);
            worker.terminate();
        };
    }
}

export const getContactsConversationsData = (data, callback) => {
    if(data.contactList && data.contactList.length) {
        // Create a new Web Worker instance
        const worker = new Worker(new URL('./WebWorkers/TwilioConversationWorker.js', import.meta.url));
        // Post data to the worker
        worker.postMessage(data);
        // Receive message from worker
        worker.onmessage = function(e) {
            callback(e.data);
            worker.terminate();
        };
    }
}