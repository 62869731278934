// Date Picker
import React, { Fragment, Component } from 'react';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export default class DatePickers extends Component {

	state = {
		selectedDate: null,
		open: false
	};
	
	handlePickerOpen = (bool) => {
		this.setState({...this.state, open: bool});
	}

	handleDateChange = (date) => {
		try {
			console.log("picker value --> ",date);
			let dateObj = new Date(date);
			let month = dateObj.getMonth();
			let day = dateObj.getDate();
			let year = dateObj.getFullYear();
			console.log("month value --> ",month);
			console.log("day value --> ",day);
			console.log("year value --> ",year);
			month = month >= 0 && month < 12 ? true : false;
			day = day >= 1 && day < 32 ? true : false;
			year = year > 1900 && year < 2050 ? true : false;
			if(month && day && year) {
				this.props.handleOnDateChange(date)
			}
		} catch(e) {
			console.log("Invalid date, please enter valid date", e)
		}
	};

	render() {
		const {open} = this.state;
        const datestring = this.props.value ? new Date(this.props.value) : new Date()
		const minDateString = this.props.minDate ? new Date(this.props.minDate) : undefined

		return (
			<Fragment>
				<div className="rct-picker">
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DesktopDatePicker
							label={this.props.label}
							open={open}
							onOpen={() => this.handlePickerOpen(true)}
							onClose={() => this.handlePickerOpen(false)}
							value={datestring}
							format="MMM dd yyyy"
							onChange={this.handleDateChange}
							animateYearScrolling={true}
							minDate={minDateString}
							minDateMessage={"Invalid Schedule! Please ensure that the End Date is greater than Start Date"}
							leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
							rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
							fullWidth
							slots={{
								textField: (params) => (
								<TextField
									data-testid={this.props.testid}
									ref={React.createRef()}
									variant="standard"
									onClick={() => this.handlePickerOpen(true)}
									{...params}
									slotProps={{ ...params.InputProps, readOnly: true }}
								/>
								)
							}}
							InputAdornmentProps={{ position: 'start' }}
							disableMaskedInput={true}
							showToolbar={false}
							toolbarFormat="MMM dd yyyy"
							className='w-100'
						/>
					</LocalizationProvider>
				</div>
			</Fragment>
		)

	}
}