import { useEffect } from 'react';
import { TextToSpeech } from '@capacitor-community/text-to-speech';
import { App } from '@capacitor/app';

const AppTTSManager = () => {
  // Function to preload or initialize the TTS engine
  const preloadTTS = async () => {
    try {
      await TextToSpeech.speak({
        text: "",
        lang: 'en-US',
        voice: "Fred",
        rate: 1.0,
        pitch: 1.0,
        volume: 1.0,
      });
    } catch (error) {
      console.error('Error preloading TTS engine:', error);
    }
  };

  // Use this function to handle the app state changes
  const handleAppStateChange = (state) => {
    if (state.isActive) {
      // App has become active (either started or resumed), so preload the TTS engine
      preloadTTS();
    }
  };

  useEffect(() => {
    // Preload TTS engine when the app is first loaded
    preloadTTS();

    // Listen for app state changes (active, background, or killed)
    const appStateListener = App.addListener('appStateChange', handleAppStateChange);

    // Cleanup listener when the component is unmounted
    return () => {
      appStateListener.remove();
    };
  }, []);

  return null; // This component only manages TTS preloading
};

export default AppTTSManager;
