import React, {Component} from "react";
// import DatePickers from '../Pickers/DatePickers';
// import TimePickers from '../Pickers/TimePickers'
// import DateTimeInput from '../Pickers/DateTimeInput'
import {FormGroup, Label} from 'reactstrap'
import moment from 'moment';
import DatePickers from '../../components/Pickers/DatePickers'


class DateTimeInputType extends Component
{
    state={
            date: moment().format('YYYY-MM-DD HH:mm:ss Z'),
            time: moment().format('hh:mm A'),
            dateAndTime: moment().format('MMM DD YYYY hh:mm A'),
            objDate: "",
            objTime: moment(),
            ObjDateAndTime: moment(),
    }
    componentDidMount() {
        const {obj} = this.props;
        if(obj.date) {
           const dateTimeString =  moment(obj.date, "YYYY-MM-DD HH:mm:ss Z")
            this.setState({objDate: dateTimeString});
        } else {
            this.setState({objDate: ""});
        }
    }
    handleStartDateChange = (objDate) => {
        const {obj, sectionObj} = this.props;
        let date = moment(objDate).format('YYYY-MM-DD HH:mm:ss Z');
        console.log(" date --> ", date);
        this.setState({date: date, objDate: objDate}); 
        obj.date = date;
        this.props.handleDateAndTimeSection(obj, sectionObj);
    }
    handleStartTimeChange = (objTime) => {
        const {obj, sectionObj} = this.props;
        let time = moment(objTime).format('hh:mm A');
        console.log(" time --> ", time);
        this.setState({time: time, objTime: objTime});  
        // obj.value = time;
        // this.props.handleDateAndTimeSection(obj, sectionObj);
   }
   handleDateTimeChange = (ObjDateAndTime) => {
        const {obj, sectionObj} = this.props;
        //let date = Moment().format('YYYY-MM-DD HH:mm:ss Z')
        let dateAndTime = moment(ObjDateAndTime).format('MMM DD YYYY hh:mm A');
        console.log(" time --> ", dateAndTime);
        this.setState({dateAndTime: dateAndTime, ObjDateAndTime: ObjDateAndTime});  
        // obj.value = dateAndTime;
        // this.props.handleDateAndTimeSection(obj, sectionObj);
   }
    render() {
        const {objDate, objTime, ObjDateAndTime} = this.state;
        const {obj, sectionObj, section, showError} = this.props;
        return (
            <div className="" key={section+"dataAndTime"}>
                <Label className="form-label">{`${obj.heading || "Date & Time"}`}<span className='text-red'>{obj.isMandatory ? " *" : ""}</span></Label>
                <FormGroup  className="mt-2">
                    <DatePickers placeholder="Select a date" value={objDate} label={obj.text || "Date"}  handleOnDateChange={this.handleStartDateChange}/>
                </FormGroup>
                {/* <FormGroup className="mb-2">
                    <TimePickers  label={"Time"} value={objTime} handleOnTimeChange={this.handleStartTimeChange}/>
                </FormGroup> 
                <FormGroup className="mb-2">
                    <DateTimeInput  label={"Date & Time"} value={ObjDateAndTime} handleDateTimeChange={this.handleDateTimeChange}/>
                </FormGroup>  */}
                 {showError && obj.isMandatory && !obj.date && <div className="input-options-error">{`${obj.heading || obj.text || ""} section is required`}</div>}
            </div>
        )
    }
}
export default DateTimeInputType;