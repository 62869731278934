import React, { Component } from 'react';

import { Button, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import moment from 'moment'
import { isMobile } from '../../helpers/helpers';
import LifeSherpaLoading from '../LifeSherpaLoading';

class ProvideReward extends Component {

    getDateDiffence(datestring) {
        const years = moment().diff(moment(datestring, 'YYYY-MM-DD HH:mm:ss Z'), "years")
        const months = moment().diff(moment(datestring, 'YYYY-MM-DD HH:mm:ss Z'), "months")
        const days = moment().diff(moment(datestring, 'YYYY-MM-DD HH:mm:ss Z'), "days")
        const hours = moment().diff(moment(datestring, 'YYYY-MM-DD HH:mm:ss Z'), "hours")
        const minutes = moment().diff(moment(datestring, 'YYYY-MM-DD HH:mm:ss Z'), "minutes")
        const actualvalue = years > 0 ? `${years}y` : months > 0 ? `${months}mo` : days > 0 ? `${days}d` : hours > 0 ? `${hours}h`: `${minutes}m`
        return actualvalue
    }

    render() {
        const { pendingReward, rewardLoading, clientImage, clientName, selectedMemberId, claimReward, userCredit, popupThemeClass } = this.props;
        return (
            <Dialog
                fullScreen={isMobile()}
                open={claimReward}
                fullWidth={false}
                className={`${popupThemeClass}`}
                aria-labelledby="responsive-dialog-title"
                id="notification_provide_reward"
            >
                <DialogTitle className='p-3 bg-lifesherpa'>
                    <div className="d-flex align-items-center justify-content-between text-white border-bottom">
                        <div>Rewards</div>
                        <div className="d-flex align-items-center ml-auto my-auto font-lg">
                            <img className="mr-1" src={require("Assets/img/diamond_icon.png")} alt="project logo" width="22" height="20" />
                            <div data-testid="rewards-panel-credit">{userCredit ? userCredit : 0}</div>
                            <IconButton className="text-white ml-2" title="Close Provide Reward" onClick={() => this.props.handleCloseProvideReward()}><CloseIcon /></IconButton>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-white mt-10">
                        <div className="media-left position-relative mr-10">
                            <img src={clientImage || require('Assets/avatars/user-15.jpg')} className="img-fluid rounded-circle" alt="user profile" width="40" height="40" />
                        </div>
                        <h4 className='mb-0'>{`${clientName.toUpperCase()} IS WAITING ON`}</h4>
                    </div>
                </DialogTitle>
                <DialogContent className='p-3' style={{ minHeight: "400px" }}>
                    <LifeSherpaLoading loading={rewardLoading}/>
                    <ul className="list-unstyled">
                        {pendingReward && pendingReward.length > 0 ? pendingReward.map((opt, index) => (
                            opt.shopping.map((op, key) => (
                                <li key={key} className="list-card mb-1 list-item border-bottom p-3" style={{ fontWeight: 500 }} data-testid={"waiting-reward-list-item-"+index}>
                                    {opt.url && opt.url.includes("http") &&
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img className="img-fluid-ext border" width="auto" src={opt.url} alt="" style={{ borderRadius: 15 + "px", maxHeight: 250 }} data-testid="reward-image-item"/>
                                        </div>
                                    }
                                    <div className="d-flex justify-content-between align-items-center my-2 border-bottom pb-2">
                                        <h3 className="heading-text mb-0" style={{ fontWeight: 550 }}>{opt.title}</h3>
                                        <div>
                                            {opt.favorite ? 
                                                <StarOutlinedIcon title="Favourite" fontSize='large' className='text-warning' /> 
                                                : 
                                                <StarBorderIcon title="Not Favourite" fontSize='large' className='text-warning' />
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between pt-2" style={{ fontWeight: 400 }}>
                                        <div className="d-flex align-items-center">
                                            <AccessTimeOutlinedIcon className="mr-1" />
                                            <h2 className="mb-0 heading-text">{this.getDateDiffence(op.timeStamp)}</h2>
                                        </div>
                                        <Button title="Provide Reward" color="primary" variant="contained" size="small" style={{ borderRadius: 16 + "px", width: 150, paddingTop: 6 }} onClick={() => this.props.confirmRewards(op.shoppingId, selectedMemberId)}>
                                            PROVIDE REWARD
                                        </Button>
                                    </div>
                                </li>))))
                            :
                            <div className="heading-text my-3 text-center">
                                Waiting Rewards Not Found
                            </div>
                        }
                    </ul>
                </DialogContent>
            </Dialog>                            
        );
    }
}

export default ProvideReward