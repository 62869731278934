/**
 * Recent Chat Users
 */
import React, { Component } from 'react';
// components
import NewUserListItem from './NewUserListItem';
// actions

class RecentChatUsers extends Component {
	state = {
		selectedRoutine: null,
		openForm: false,
		selUser: null,
		id: null,
		expandedRows: {}, // Keep track of which rows are expanded
		clientsList: [],
	}

	render() {		
		const { groupMembers, selectedMemberId, groupMembersDetails, groupMembersList, realtimeUpdate, loading} = this.props;
		
		if (loading) {
			return (
				<div data-testid="no-group-members-found" className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found" style={{ height: '100%' }}>
					<h4>Fetching Members...</h4>
				</div>
			);
		} else if (groupMembers === null || groupMembersList.length === 0 && !loading) {
			return (
				<div data-testid="no-group-members-found" className="no-found-user-wrap d-flex align-items-center justify-content-center no-data-found" style={{ height: '100%' }}>
					<h4>No Group member Found</h4>
				</div>
			);
		}

		return (
			<React.Fragment>
				{groupMembersList.map((user, key) => (
					<div data-testid={`my-clients-memebers-list${key}`} className="mx-2 mb-2" key={key}>
						<NewUserListItem
                            user={user}
                            itemIndex={key}
                            onClickListItem={() => this.props.openClientDetail(user)}
                            showChatPanelForSelectedUser={this.props.showChatPanelForSelectedUser}
							handleOpenUnconfirmAchievements={this.props.handleOpenUnconfirmAchievements}
							handleOpenNoticePanel={this.props.handleOpenNoticePanel}
							handleOpenUserWorkflow={this.props.handleOpenUserWorkflow}
							handleOpenDashboardPanel={this.props.handleOpenDashboardPanel}
							handleMuteNotification={this.props.handleMuteNotification}
							handleOpenAssignActivity={this.props.handleOpenAssignActivity}
							notifications={this.props.notifications}
							networkActivities={this.props.networkActivities}
							organizationsObj={this.props.organizationsObj}
                        />

					</div>
				))}
			</React.Fragment>
		);
	}
}


export default RecentChatUsers;