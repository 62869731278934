/**
 * Recent Orders
 */
import React, { Component } from 'react';
// api
import api from 'Api';

class AgendaViewActivity extends Component {

	state = {
		recentOrders: [
            {
                id: '1',
                title: 'Test 1',
                desc: 'The activity is only for testing prupose',
                schedule: '10:00 - 11:00 PM',
                labelClass: 'badge-info',
                status: 'Not Started'
            },
            {
                id: '2',
                title: 'CLE Morning Routine',
                desc: 'The activity is CLE Morning Routine',
                schedule: '08:56 - 09:50 AM',
                labelClass: 'badge-warning',
                status: 'Yet To Start'
            },
            {
                id: '3',
                title: 'Timer Test',
                desc: 'The activity is Timer Start',
                schedule: '11:45 - 12:00 AM',
                labelClass: 'badge-danger',
                status: ' Expired '
            },
            {
                id: '4',
                title: 'Step Post Activiy',
                desc: 'The activity is Step Post Activiy',
                schedule: '08:45 - 09:00 AM',
                labelClass: 'badge-warning',
                status: 'Yet To Start'
            },
            {
                id: '5',
                title: 'Routine With Card Type 2',
                desc: 'The activity is Routine With Card Type 2',
                schedule: '08:45 - 09:00 AM',
                labelClass: 'badge-danger',
                status: ' expired '
            }

        ]
	}

	render() {
		const { recentOrders } = this.state;
		return (
			<div className="table-responsive">
				<table className="table table-hover mb-0">
					<thead>
						<tr>
							<th>S No.</th>
							<th>Activity</th>
							<th>Schedule</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{recentOrders && recentOrders.map((order, key) => (
							<tr key={key}>
								<td>{order.id}</td>
								<td>
									<span className="d-block fw-normal">{order.title}</span>
									<span className="fs-12">{order.desc}</span>
								</td>
								<td>{order.schedule}</td>
								<td>
									<span style={{width:100}}className={`badge ${order.labelClass}`}>{order.status}</span>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

export default AgendaViewActivity;
