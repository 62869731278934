/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Form, FormGroup } from 'reactstrap';
import HeaderWithCancelButton from '../Header/HeaderWithCancelButton'
import config from '../../config/config'
import { withAuth0 } from "@auth0/auth0-react";
import LifeSherpaLoading from '../LifeSherpaLoading'
import { connect } from 'react-redux';
import { Browser } from '@capacitor/browser';
import { App as CapApp } from "@capacitor/app";
import { isMobile } from '../../helpers/helpers';
import withRouter from '../../router';

class Auth0Login extends Component {

   componentDidMount(){
      console.log("%c[Auth0Login] %c Redirected on auth0login screen","color:black","color:green");
        const {loginWithRedirect, handleRedirectCallback} = this.props.auth0;
        if(isMobile()) {
         this.login(loginWithRedirect)
         this.handleBrowserClose()
         CapApp.addListener("appUrlOpen", async ({ url }) => {
            console.log("appUrlOpen--->", url)
            if (url.includes("/callback")) {
              if (
                url.includes("state") &&
                (url.includes("code") || url.includes("error"))
              ) {
                await handleRedirectCallback(url);
              }
              this.props.navigate('/signin');
              await Browser.close();
            }
          });

        } else {
         loginWithRedirect()
        }

   }

   login = async (loginWithRedirect) => {
      await loginWithRedirect({
        async openUrl(url) {
          await Browser.open({
            url,
            windowName: "_self"
          });
        }
      });
   };

   handleBrowserClose = async () => {
      // Listen for the browser closing event
      Browser.addListener('browserFinished', () => {
        // Change route when browser is closed
        this.props.navigate('/launchscreen');  // Specify the route you want to navigate to
      });
   };

   componentWillUnmount() {
      Browser.removeAllListeners();
   }
 
   render() {
      return (
         <div type="bottom" duration={2000}> 
            <div className={`${config.rctSessionWrapper} ${this.props.screenThemeClass}`}>
             <div className='login-screen'>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderWithCancelButton activeTheme={this.props.activeTheme} themeLogo={this.props.themeLogo} screenThemeClass={this.props.screenThemeClass} themeData={this.props.themeData} location={this.props.location} href={`/success`}/>
                  </Toolbar>
               </AppBar>
               <LifeSherpaLoading loading={true}/>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center pt-2">
                              <Form>
                                 <FormGroup className="mb-10">
                                  <p className="mb-0 loader-text">Please wait redirecting to authentication page...</p>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
               </div>
            </div>
         </div>
      );
   }
}
const mapStateToProps = ({ authUser , settings}) => {
   const { screenThemeClass, popupThemeClass, themeLogo, activeTheme } = settings; 
   const { themeData} = authUser; 
   return { screenThemeClass, popupThemeClass, themeLogo, activeTheme, themeData}
}
export default withRouter(withAuth0(connect(mapStateToProps, {})(Auth0Login)));