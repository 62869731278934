/**
 * Clients List Actions
 */

 import {axiosClient} from '../sherpaApi'
 import {getActivitiesCalendar, getActivitiesList, getClientActivitiesList} from '../actions'
 import { NotificationManager } from 'react-notifications';

 import {
    GET_PRIVATE_LIST,
    GET_ACTIVITIES_LIST,
    HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,
    SUCCESS_CHECK_GENERAL_ACTIVITY_PERMISSION,
    CHECK_GENERAL_ACTIVITY_PERMISSION,
    FAILED_CHECK_GENERAL_ACTIVITY_PERMISSION
 } from './types';

export const deletePrivateActivity = (memberId,activityId) => (dispatch) => {
    let idToken = localStorage.getItem('idToken');
    const orgId = localStorage.getItem('orgId');
    let url = `/users/${memberId}/activities/${activityId}?scope=private&orgId=${orgId}` //`/users​/${memberUerId}​/activities/${activityId}`
    dispatch({ type: GET_ACTIVITIES_LIST });
    if (memberId) {
        axiosClient.delete(url, {headers: {
            'Authorization': `Bearer ${idToken}`
            }
        }).then((response) => {
            NotificationManager.success(response.data.message);
            dispatch(getActivitiesList(memberId,""));
            dispatch(getActivitiesCalendar(memberId));
        }).catch(async(error) => {
            console.log("error occured ", error);
            dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
            })
    } 
}

export const deleteGeneralActivity = (userId, activityId, activityType, groupId) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem('selectedOrgId')
    const url = `/users/${userId}/activities/${activityId}?scope=general&orgId=${orgId}`
    dispatch({ type: GET_ACTIVITIES_LIST });
    axiosClient.delete(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        NotificationManager.success(response.data.message || "Activity successfully deleted!");
        dispatch(getClientActivitiesList({orgId, userId, groupId, activityType}));
    }).catch(async(error) => {
        console.log("error occured ", error);
        dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST });
    })
}

export const checkGeneralActivityPermission = () => (dispatch) => {
    dispatch({type: CHECK_GENERAL_ACTIVITY_PERMISSION})
    const idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem('selectedOrgId')
    const clientId = localStorage.getItem("uid")
    const loggedInUserId = localStorage.getItem("auth_uid")
    const url = `/users/${loggedInUserId}/clients/${clientId}/permissions/add-edit-client-activity?orgId=${orgId}`
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        dispatch({type: SUCCESS_CHECK_GENERAL_ACTIVITY_PERMISSION, payload: response.data})
    }).catch(async(error) => {
        console.log("error occured ", error);
        dispatch({ type: FAILED_CHECK_GENERAL_ACTIVITY_PERMISSION });
    })
}
 
 
 
 
 
 